import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { openToastr } from "../redux/toastrSlice";

export const useToastr = ({ messages, deps, type, cb }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (deps) {
      dispatch(
        openToastr({
          text: messages,
          type,
        })
      );
      cb && cb();
    }
  }, [deps]);
};

import "./RequiredFieldsWarning.scss";

const RequiredFieldsWarning = () => (
  <div className="required-fields-warning">
    <span>
      The fields marked with <strong>*</strong> are required
    </span>
  </div>
);

export default RequiredFieldsWarning;

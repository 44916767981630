import { faPlus, faTimes, faClone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  DEFAULT_REPS_VALUE,
  DEFAULT_WEIGHT_VALUE,
  EDITED_TYPES,
} from "../../../../../../../constants/templates";
import { editProgramsValue } from "../../../../../../../helpers";
import {
  setExerciseSetErrors,
  removeExerciseSetErrors,
} from "../../../../../../../redux/programTemplatesSlice";
import ExerciseName from "../../../../../../Clients/ClientTrainingPage/components/TrainingDay/components/ExerciseName";

import ExerciseSet from "./ExerciseSet/ExerciseSet";

const Sets = ({
  push,
  remove,
  replace,
  sets,
  dayIdx,
  workoutIdx,
  exerciseIdx,
  exerciseName,
}) => {
  const dispatch = useDispatch();
  const lastSet = sets?.length && sets[sets.length - 1];
  const handleAddSet = () => {
    const weight = lastSet
      ? lastSet[EDITED_TYPES.WEIGHT]
      : DEFAULT_WEIGHT_VALUE;
    const reps = lastSet ? lastSet[EDITED_TYPES.REPS] : DEFAULT_REPS_VALUE;
    if (weight === null && reps === null) {
      push({ weight: DEFAULT_WEIGHT_VALUE, reps: DEFAULT_REPS_VALUE });
      return;
    }
    push({ weight, reps });
  };
  const handleRemoveSet = (idx) => () => remove(idx);

  const handleChange = (idx) => (e, currentType) => {
    const payload = {
      dayIdx,
      workoutIdx,
      setIdx: idx,
      exerciseIdx,
      exerciseName,
    };
    const isReps = currentType === EDITED_TYPES.REPS;
    const checkType = isReps ? EDITED_TYPES.WEIGHT : EDITED_TYPES.REPS;
    const newValue = editProgramsValue(e.target.value, currentType);
    const currentSet = sets[idx];
    if (
      currentSet[checkType] === null &&
        (newValue === null || newValue === "")
    )
      dispatch(setExerciseSetErrors(payload));
    else dispatch(removeExerciseSetErrors(payload));
    replace(idx, {
      ...sets[idx],
      [currentType]: newValue === "" ? null : newValue,
    });
  };

  return (
    <>
      {sets.map((item, idx) => (
        <ExerciseSet
          key={idx}
          idx={idx}
          data={item}
          withDelete={idx !== 0}
          onRemove={handleRemoveSet(idx)}
          handleChange={handleChange(idx)}
          weightValue={sets[idx][EDITED_TYPES.WEIGHT]}
          repsValue={sets[idx][EDITED_TYPES.REPS]}
          notNullableError={
            sets[idx][EDITED_TYPES.WEIGHT] === null &&
            sets[idx][EDITED_TYPES.REPS] === null
          }
        />
      ))}
      <div className="exercise-constructor__add-set">
        <span>{`set ${sets.length + 1}`}</span>
        <button
          type="button"
          onClick={handleAddSet}
          className="day__icon-btn day__icon-btn--add exercise-constructor__add-btn"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </>
  );
};

const MemoSets = React.memo(Sets);

function ExerciseConstructor({
  className,
  data,
  onRemove,
  onCopy,
  editExercise,
  dayIdx,
  workoutIdx,
  exerciseIdx,
}) {
  const selectedExerciseDayIdx = useSelector(
    (state) => state.programTemplates.selectedExerciseDayIdx
  );
  const selectedExerciseWorkoutIdx = useSelector(
    (state) => state.programTemplates.selectedExerciseWorkoutIdx
  );
  const selectedExerciseIdx = useSelector(
    (state) => state.programTemplates.selectedExerciseIdx
  );
  const selectedExerciseId = useSelector(
    (state) => state.programTemplates.selectedExercise?.id
  );
  const changeAllExercise = useSelector((state) => state.programTemplates.changeAllExercise);

  const exerciseName = data?.name;

  const isSelectedExercise =
    dayIdx === selectedExerciseDayIdx &&
    workoutIdx === selectedExerciseWorkoutIdx &&
    exerciseIdx === selectedExerciseIdx;
  const isSameExercise = data.id === selectedExerciseId;
  const isAfter =
    dayIdx > selectedExerciseDayIdx ||
    (dayIdx === selectedExerciseDayIdx &&
      (workoutIdx > selectedExerciseWorkoutIdx ||
        (workoutIdx === selectedExerciseWorkoutIdx &&
          exerciseIdx > selectedExerciseIdx)));
  const isActive =
    isSelectedExercise || (changeAllExercise && isSameExercise && isAfter);

  return (
    <div className={`exercise-constructor${className && ` ${className}`}`}>
      <div className="exercise-constructor__name">
        <button
          type="button"
          onClick={onRemove}
          className="day__icon-btn day__icon-btn--delete exercise-constructor__delete-btn"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <button
          type="button"
          onClick={onCopy}
          className="day__icon-btn day__icon-btn--duplicate exercise-constructor__duplicate-btn"
        >
          <FontAwesomeIcon icon={faClone} />
        </button>
        <ExerciseName
          name={exerciseName}
          onClick={editExercise}
          isActive={isActive}
        />
      </div>
      <div className="exercise-constructor__sets">
        <FieldArray
          name={`days[${dayIdx}].workouts[${workoutIdx}].exercises[${exerciseIdx}].sets`}
        >
          {({ push, remove, replace }) => (
            <MemoSets
              push={push}
              remove={remove}
              replace={replace}
              workoutIdx={workoutIdx}
              dayIdx={dayIdx}
              exerciseIdx={exerciseIdx}
              sets={data.sets}
              exerciseName={exerciseName}
            />
          )}
        </FieldArray>
      </div>
    </div>
  );
}

ExerciseConstructor.defaultProps = {
  className: "",
};

ExerciseConstructor.propTypes = {
  className: PropTypes.string,
};

export default ExerciseConstructor;

import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

/**
 * block going away with a message prompt
 * @param {boolean} when
 * @param {string} message
 */
export const usePrompt = (when, message) => {
  const history = useHistory();

  const self = useRef(null);

  const onWindowOrTabClose = (event) => {
    if (!when) {
      return;
    }

    if (typeof event == "undefined") {
      event = window.event;
    }

    if (event) {
      event.returnValue = message;
    }

    return message;
  };

  useEffect(() => {
    self.current = history.block(when ? () => window.confirm(message) : true);

    window.addEventListener("beforeunload", onWindowOrTabClose);

    return () => {
      if (self.current) {
        self.current();
        self.current = null;
      }

      window.removeEventListener("beforeunload", onWindowOrTabClose);
    };
  }, [when]);
};

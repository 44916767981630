import { Route, Switch, useRouteMatch } from "react-router-dom";

import { USER_TYPES } from "../../constants/common";
import useIsUserType from "../../hooks/useIsUserType";

import CompanyProfilePage from "./CompanyProfilePage";
import ProfilePage from "./ProfilePage/ProfilePage";

const Profile = () => {
  const match = useRouteMatch();
  const isCompanyAdmin = useIsUserType(USER_TYPES.COMPANY_ADMIN);

  return (
    <Switch>
      <Route exact path={`${match.url}`}>
        {isCompanyAdmin ? <CompanyProfilePage /> : <ProfilePage />}
      </Route>
    </Switch>
  );
};

export default Profile;

import "./Search.scss";
import PropTypes from "prop-types";

const Search = ({ onChange, value }) => (
  <input
    type="text"
    className="search-input"
    placeholder="search..."
    onChange={onChange}
    value={value}
  />
);

Search.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default Search;

import "./FormField.scss";

import classnames from "classnames";
import { Field, useFormikContext } from "formik";
import lodash from "lodash";
import PropTypes from "prop-types";
import { memo } from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { DATE_MASK } from "../../constants/clientForm";
import {
  REGEXP_FORM_INT_NUM,
  REGEXP_FORM_FLOAT_NUM_FIXED_ONE,
  REGEXP_FORM_FLOAT_NUM_FIXED_TWO,
} from "../../constants/regexp";
import FormLabel from "../FormLabel";
import {CURRENCY_MASK_OPTIONS} from "../../constants/payment";

const FormField = ({
  validate,
  type,
  name,
  label,
  sublabel,
  inputClassDefault,
  inputDivClass,
  inputClassError,
  options,
  isInline,
  info,
  allowFloat,
  allowFloatToFixedOne,
  allowRegexp,
  autoComplete,
  submitOnBlur,
  ...props
}) => {
  const { touched, errors, values, handleChange, setFieldValue, setFieldTouched, handleBlur, handleSubmit } = useFormikContext();
  const isError = lodash.get(errors, name);
  const isTouched = lodash.get(touched, name);
  const value = lodash.get(values, name);
  const propsField = {
    ...props,
    type,
    name,
    validate,
    autoComplete: autoComplete,
    value,
    className: classnames(props.className, inputClassDefault, {
      [inputClassError]: isError && isTouched,
    }),
    onBlur: submitOnBlur ? (e) => {
      handleBlur(e);
      !isError && handleSubmit();
    } : handleBlur
  };

  const onChangeNumber = (e) => {
    // TODO: sane interface and/or implementation
    let re = REGEXP_FORM_INT_NUM;
    if (allowFloat) {
      re = REGEXP_FORM_FLOAT_NUM_FIXED_TWO;
    } else if (allowFloatToFixedOne) {
      re = REGEXP_FORM_FLOAT_NUM_FIXED_ONE;
    } else if (allowRegexp) {
      re = allowRegexp;
    }

    if (e.target.value === "" || re.test(e.target.value)) {
      handleChange(e);
    }
  };

  const fields = {
    text: <Field {...propsField} />,
    select: (
      <Field {...propsField}>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </Field>
    ),
    number: (
      <Field
        {...propsField}
        type="text"
        onChange={(event) => onChangeNumber(event)}
      />
    ),
    birthday: (
      <Field
        name="birth"
        render={() => (
          <MaskedInput
            {...propsField}
            onBlur={(e) => {
              setFieldTouched("birth", true);
              propsField.onBlur(e);
            }}
            onChange={(e) => {
              setFieldValue("birth", e.target.value, true);
            }}
            mask={DATE_MASK}
          />
        )}
      />
    ),
    currency: (
      <Field
        {...propsField}
        render={() => (
          <MaskedInput
            {...propsField}
            mask={createNumberMask(CURRENCY_MASK_OPTIONS)}
            placeholder="$0.00"
            type="text"
            onChange={(e) => {
              handleChange(e);
            }}
          />
        )}
      />
    )
  };

  return (
    <div
      className={classnames({
        "inline-field": isInline,
        [inputDivClass]: !isInline,
      })}
    >
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      {sublabel && <label className="form-label sublabel">{sublabel}</label>}
      <div
        className={classnames({
          [inputDivClass]: isInline,
        })}
      >
        {fields[type] || <Field {...propsField} />}
        {info && <div className="text-info">{info}</div>}
      </div>
    </div>
  );
};

FormField.propTypes = {
  validate: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  inputClassDefault: PropTypes.string,
  inputClassError: PropTypes.string,
  inputDivClass: PropTypes.string,
  options: PropTypes.array,
  isInline: PropTypes.bool,
  info: PropTypes.string,
  allowFloat: PropTypes.bool,
  allowFloatToFixedOne: PropTypes.bool,
  submitOnBlur: PropTypes.bool,
  autoComplete: PropTypes.string.isRequired,
};

FormField.defaultProps = {
  type: "text",
  inputClassDefault: "form-input",
  inputDivClass: "w-100",
  inputClassError: "field-error",
  validate: null,
  options: [],
  isInline: false,
  info: "",
  allowFloat: false,
  allowFloatToFixedOne: false,
  autoComplete: "new-password",
  submitOnBlur: false,
};

export default memo(FormField);

import { useDispatch, useSelector } from "react-redux";

import Divider from "../../../../../components/Divider";
import Widget from "../../../../../components/widget/Widget";
import { TOASTR_TYPE } from "../../../../../constants/toastr";
import { trainerFormVariants } from "../../../../../constants/trainers";
import { useToastr } from "../../../../../hooks/useToastr";
import {
  addTrainer,
  clearError,
  clearSuccessfullyTrainerAdded,
} from "../../../../../redux/trainersSlice";
import TrainerForm from "../TrainerForm/TrainerForm";
import "./AddTrainer.scss";

const AddTrainer = () => {
  const dispatch = useDispatch();
  const successfullyTrainerAdded = useSelector(
    (state) => state.trainers.successfullyTrainerAdded
  );
  const error = useSelector((state) => state.trainers.error);

  useToastr({
    messages: "Trainer added!",
    deps: successfullyTrainerAdded,
    cb: () => dispatch(clearSuccessfullyTrainerAdded()),
  });
  useToastr({
    messages: error,
    deps: error,
    type: TOASTR_TYPE.ERROR,
    cb: () => dispatch(clearError()),
  });

  const onSubmit = (values) => {
    dispatch(addTrainer(values));
  };

  return (
    <Widget>
      <Widget.Header className="pl-10">
        <Widget.Title>add coach</Widget.Title>
      </Widget.Header>
      <Widget.Body className="add-trainer">
        <TrainerForm
          variant={trainerFormVariants.CREATE}
          onSubmit={onSubmit}
          isEditPage={false}
        />
        <Divider />
      </Widget.Body>
    </Widget>
  );
};

AddTrainer.propTypes = {};

export default AddTrainer;

import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import * as CompaniesAPI from "../api/companiesAPI";
import {
  CREATE_PAYMENT_ACCOUNT_ERROR,
  CREATE_PAYMENT_ACCOUNT_FULFILLED,
  DELETE_PAYMENT_ACCOUNT_ERROR,
  DELETE_PAYMENT_ACCOUNT_FULFILLED,
  LOAD_PAYMENT_ACCOUNT_ERROR
} from "../constants/payment";

// only id and name field
export const loadCompaniesPicker = createAsyncThunk(
  "companies/loadCompaniesPicker",
  (args) => CompaniesAPI.loadCompaniesPickerWithCache.call(args)
);

export const addCompany = createAsyncThunk(
  "companies/addCompany",
  CompaniesAPI.addCompany
);

export const loadCompanies = createAsyncThunk(
  "companies/loadCompanies",
  (args) => CompaniesAPI.loadCompaniesWithCache.call(args)
);

export const loadCompanyById = createAsyncThunk(
  "companies/loadCompanyById",
  (args) => CompaniesAPI.loadCompanyByIdWithCache.call(args)
);

export const deleteCompanyById = createAsyncThunk(
  "companies/deleteCompanyById",
  CompaniesAPI.deleteCompanyById
);

export const editCompany = createAsyncThunk(
  "companies/editCompany",
  CompaniesAPI.editCompany
);

export const addThemeCompany = createAsyncThunk(
  "companies/addThemeCompany",
  CompaniesAPI.addThemeCompany
);

export const deleteThemeCompany = createAsyncThunk(
  "companies/deleteThemeCompany",
  CompaniesAPI.deleteThemeCompany
);

export const createPaymentAccount = createAsyncThunk(
  "payment/createPaymentAccount",
  CompaniesAPI.createPaymentAccount
);

export const loadPaymentAccount = createAsyncThunk(
  "payment/loadPaymentAccount",
  CompaniesAPI.loadPaymentAccount
);

export const deletePaymentAccount = createAsyncThunk(
  "payment/deletePaymentAccount",
  CompaniesAPI.deletePaymentAccount
);

const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    companies: null,
    companiesPicker: [],
    currentCompany: null,
    loading: false,
    loadingTheme: false,
    successfullyGettingCompanyPicker: false,
    successfullyGettingCompanyById: false,
    successfullyCompanyCreated: false,
    successfullyCompanyEdited: false,
    successfullyCompanyDeleted: false,
    isGetCompanySent: false,
    isPaymentRequestSuccessful: false,
    last4: null,
    cardHolderName: null,
    isTaskFinished: true,
    error: "",
    message: ""
  },
  reducers: {
    clearCurrentCompany(state) {
      state.currentCompany = null;
    },
    clearStatuses(state) {
      state.success = false;
      state.loadingTheme = false;
      state.successfullyGettingCompanyPicker = false;
      state.loading = false;
      state.successfullyCompanyCreated = false;
      state.successfullyCompanyDeleted = false;
      state.isGetCompanySent = false;
      state.successfullyCompanyEdited = false;
    },
    clearSuccessfullyCompanyDeleted(state) {
      state.successfullyCompanyDeleted = false;
    },
    clearSuccessfullyCompanyEdited(state) {
      state.successfullyCompanyEdited = false;
    },
    setPaymentAccount(state, action) {
      const {last4, card_holder} = action.payload;
      state.last4 = last4;
      state.cardHolderName = card_holder;
    },
    clearError(state) {
      state.error = "";
    },
    clearMessage(state) {
      state.message = "";
    }
  },
  extraReducers: {
    [loadCompaniesPicker.pending]: (state) => {
      state.loading = true;
    },
    [loadCompaniesPicker.fulfilled]: (state, action) => {
      state.loading = false;
      state.successfullyGettingCompanyPicker = true;
      state.error = "";
      state.companiesPicker = action.payload;
    },
    [loadCompaniesPicker.rejected]: (state, action) => {
      state.loading = false;
      state.successfullyGettingCompanyPicker = false;
      state.error = action.payload;
    },
    [addCompany.pending]: (state) => {
      state.loading = true;
    },
    [addCompany.fulfilled]: (state, action) => {
      state.loading = false;
      state.successfullyCompanyCreated = true;
      state.error = "";
      state.currentCompany = action.payload;
      state.companiesPicker.push({
        id: action.payload.id,
        name: action.payload.name,
      });
      state.companiesPicker.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        if (a.name === b.name) return 0;
      });
    },
    [addCompany.rejected]: (state, action) => {
      state.loading = false;
      state.successfullyCompanyCreated = false;
      state.error = action.error.message;
    },
    [loadCompanies.pending]: (state) => {
      state.loading = true;
    },
    [loadCompanies.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = "";
      state.companies = action.payload;
    },
    [loadCompanies.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.error.message;
    },
    [loadCompanyById.pending]: (state) => {
      state.loading = true;
      state.successfullyGettingCompanyById = false;
    },
    [loadCompanyById.fulfilled]: (state, action) => {
      state.loading = false;
      state.successfullyGettingCompanyById = true;
      state.error = "";
      state.currentCompany = action.payload;
    },
    [loadCompanyById.rejected]: (state, action) => {
      state.loading = false;
      state.successfullyGettingCompanyById = false;
      state.error = action.error.message;
    },
    [editCompany.pending]: (state) => {
      state.successfullyCompanyEdited = false;
      state.loading = true;
    },
    [editCompany.fulfilled]: (state, action) => {
      state.successfullyCompanyEdited = true;
      state.loading = false;
      state.error = "";
      state.currentCompany = action.payload;
      const foundIndex = state.companiesPicker.findIndex(
        (c) => c.id === action.payload.id
      );
      if (foundIndex === -1) {
        state.companiesPicker.push({
          id: action.payload.id,
          name: action.payload.name,
        });
        return;
      }
      state.companiesPicker[foundIndex] = {
        id: action.payload.id,
        name: action.payload.name,
      };
    },
    [editCompany.rejected]: (state, action) => {
      state.successfullyCompanyEdited = false;
      state.loading = false;
      state.error = action.error.message;
    },
    [deleteCompanyById.pending]: (state) => {
      state.loading = true;
    },
    [deleteCompanyById.fulfilled]: (state) => {
      state.loading = false;
      state.successfullyCompanyDeleted = true;
      state.error = "";
      state.companiesPicker = state.companiesPicker.filter(
        (el) => el.id !== state.currentCompany.id
      );
      state.currentCompany = null;
    },
    [deleteCompanyById.rejected]: (state, action) => {
      state.loading = false;
      state.successfullyCompanyDeleted = false;
      state.error = action.error.message;
    },
    [addThemeCompany.pending]: (state) => {
      state.loadingTheme = true;
    },
    [addThemeCompany.fulfilled]: (state, action) => {
      state.loadingTheme = false;
      state.currentCompany = action.payload;
    },
    [addThemeCompany.rejected]: (state) => {
      state.loadingTheme = false;
    },
    [deleteThemeCompany.pending]: (state) => {
      state.loadingTheme = true;
    },
    [deleteThemeCompany.fulfilled]: (state, action) => {
      state.loadingTheme = false;
      state.currentCompany = action.payload;
    },
    [deleteThemeCompany.rejected]: (state) => {
      state.loadingTheme = false;
    },
    [createPaymentAccount.fulfilled]: (state) => {
      state.isPaymentRequestSuccessful = true;
      state.isTaskFinished = false;
      state.message = CREATE_PAYMENT_ACCOUNT_FULFILLED;
    },
    [createPaymentAccount.rejected]: (state) => {
      state.isPaymentRequestSuccessful = false;
      state.error = CREATE_PAYMENT_ACCOUNT_ERROR;
    },
    [loadPaymentAccount.pending]: (state) => {
      state.isPaymentRequestSuccessful = false;
    },
    [loadPaymentAccount.fulfilled]: (state, action) => {
      state.isPaymentRequestSuccessful = true;
      const {is_task_finished: isTaskFinished} = action.payload;
      if (!isTaskFinished && state.isTaskFinished) {
        state.message = CREATE_PAYMENT_ACCOUNT_FULFILLED;
      }
      state.isTaskFinished = isTaskFinished;
      companiesSlice.caseReducers.setPaymentAccount(state, action);
    },
    [loadPaymentAccount.rejected]: (state) => {
      state.isPaymentRequestSuccessful = false;
      state.error = LOAD_PAYMENT_ACCOUNT_ERROR;
    },
    [deletePaymentAccount.pending]: (state) => {
      state.isPaymentRequestSuccessful = false;
    },
    [deletePaymentAccount.fulfilled]: (state) => {
      state.isPaymentRequestSuccessful = true;
      state.isTaskFinished = false;
      state.message = DELETE_PAYMENT_ACCOUNT_FULFILLED;
      companiesSlice.caseReducers.setPaymentAccount(state, {
        payload: {
          last4: null,
          card_holder: null
        }
      });
    },
    [deletePaymentAccount.rejected]: (state) => {
      state.isPaymentRequestSuccessful = false;
      state.error = DELETE_PAYMENT_ACCOUNT_ERROR;
    }
  },
});

export const {
  clearStatuses,
  clearCurrentCompany,
  clearSuccessfullyCompanyDeleted,
  clearSuccessfullyCompanyEdited,
  clearError,
  clearMessage
} = companiesSlice.actions;

export default companiesSlice.reducer;

import cn from "classnames";
import { useHistory } from "react-router-dom";

import "./ProgressWidget.scss";
import { ReactComponent as NextPageArrow } from "../../../../../assets/svg/next-page-arrow.svg";
import { ReactComponent as PrevPageArrow } from "../../../../../assets/svg/prev-page-arrow.svg";
import Button from "../../../../../components/Button";
import Spinner from "../../../../../components/Spinner";
import ProgressHeaders from "../../../../../components/progress/ProgressHeaders";
import ProgressValues from "../../../../../components/progress/ProgressValues";
import Widget from "../../../../../components/widget/Widget";
import WidgetBody from "../../../../../components/widget/WidgetBody";
import WidgetHeader from "../../../../../components/widget/WidgetHeader";
import WidgetTitle from "../../../../../components/widget/WidgetTitle";
import {
  BODY_PROGRESS_PAGE_SLUG,
  CLIENT,
} from "../../../../../constants/router";

import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

const ProgressWidget = () => {
  const history = useHistory();
  const latest = useSelector((state) => state.client.latest);
  const progress = useSelector((state) => state.client.progress);
  const client = useSelector((state) => state.client.client);
  const clientId = client.id;
  const [column, setColumn] = useState(1);
  const [isReady, setReady] = useState(false);
  const [slicedProgress, setSlicedProgress] = useState([]);
  const [prevPageExists, setPrevPageExists] = useState(false);
  const [nextPageExists, setNextPageExists] = useState(false);
  const widgetRef = useRef(null);

  const openProgressPage = () => {
    history.push(`${CLIENT.root}${clientId}${BODY_PROGRESS_PAGE_SLUG}`);
  };

  useEffect(() => {
    if (Array.isArray(progress) && latest) {
      setSlicedProgress([...progress].reverse());
      setReady(true);
    }
  }, [progress, latest]);

  useEffect(() => {
    if (isReady) {
      solveDiffs();
      setPrevPageExists(column !== 1);
      setNextPageExists(progress.length - column - 1 > 0);
    }
  }, [column, isReady]);

  const loadPrevPage = () => {
    if (prevPageExists) {
      setColumn(column - 1);
    }
  };
  const loadNextPage = () => {
    if (nextPageExists) {
      setColumn(column + 1);
    }
  };

  const solveDiff = (keys) => {
    if (latest?.[keys[0]]?.[keys[1]] && slicedProgress?.[column]?.[keys[0]]) {
      const value =
        latest[keys[0]][keys[1]] - slicedProgress[column][keys[0]][keys[1]];
      if (isNaN(value)) return null;
      return (value > 0 ? "+" : "") + value.toFixed(1);
    }
    return null;
  };

  const solveDiffs = () => {
    return {
      body: {
        weight: solveDiff(["body", "body_weight"]),
        fat: solveDiff(["body", "body_fat"]),
        lean: solveDiff(["body", "lean_mass"]),
        fatMass: solveDiff(["body", "fat_mass"]),
      },
      strength: {
        squat: solveDiff(["strength", "squat"]),
        bench: solveDiff(["strength", "bench"]),
        deadlift: solveDiff(["strength", "deadlift"]),
      },
      training: {
        squat: solveDiff(["training", "squat"]),
        bench: solveDiff(["training", "bench"]),
        deadlift: solveDiff(["training", "deadlift"]),
      },
    };
  };

  return (
    <Widget className="progress-widget">
      <WidgetHeader>
        <WidgetTitle>progress</WidgetTitle>
        <Button onClick={openProgressPage}>show more</Button>
      </WidgetHeader>
      <WidgetBody className="progress-widget__body widget-body--pb-50">
        <div className="progress-widget__arrow-wrapper">
          <PrevPageArrow
            width="40px"
            heigth="40px"
            className={cn("body-arrow", {
              "body-arrow--disabled": !prevPageExists,
            })}
            onClick={loadPrevPage}
          />
          <NextPageArrow
            width="40px"
            heigth="40px"
            className={cn("body-arrow", {
              "body-arrow--disabled": !nextPageExists,
            })}
            onClick={loadNextPage}
          />
        </div>
        <div className="progress-widget__tables-wrapper ">
          {!isReady && <Spinner />}
          {isReady && (
            <div ref={widgetRef}>
              <div className="client-progress-table">
                <ProgressHeaders progress={latest} />
                <ProgressValues progress={latest} isLatest />
                {slicedProgress?.[column] && (
                  <ProgressValues
                    progress={slicedProgress[column]}
                    diffs={solveDiffs()}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div className="progress-widget__footnote">
          * estimated strength based on change in lean mass since last test
        </div>
      </WidgetBody>
    </Widget>
  );
};

ProgressWidget.propTypes = {};

export default ProgressWidget;

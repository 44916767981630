import "./AddProgramWidget.scss";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../../../../components/Button";
import Spinner from "../../../../../components/Spinner/Spinner";
import Widget from "../../../../../components/widget/Widget";
import {
  MAX_MOBILE_SCREEN_WIDTH,
  USER_TYPES,
} from "../../../../../constants/common";
import { PROGRAM_TEMPLATES } from "../../../../../constants/router";
import { createPermittedIdList } from "../../../../../helpers";
import useFilteredList from "../../../../../hooks/useFilteredList";
import useIsSmallScreen from "../../../../../hooks/useIsSmallScreen";
import useIsUserType from "../../../../../hooks/useIsUserType";
import { loadProgramTemplates } from "../../../../../redux/programTemplatesSlice";
import { loadProgramIds } from "../../../../../redux/programsSlice";

import AddProgramItem from "./components/AddProgramItem/AddProgramItem";

const AddProgramWidget = ({ hideWidget }) => {
  const [searchValue, setSearchValue] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  const isMobileVersion = useIsSmallScreen(
    MAX_MOBILE_SCREEN_WIDTH.MOBILE_VIEW_ON_TRAINING
  );
  const items = useSelector((state) => state.programTemplates.items);
  const programIds = useSelector((state) => state.programs.programIds);
  const loadingIds = useSelector((state) => state.programs.loadingIds);
  const client = useSelector((state) => state.client.client);
  const isClient = useIsUserType(USER_TYPES.CLIENT);
  const loading = useSelector((state) => state.programTemplates.loading);
  const favs = useSelector((state) => state.user.user?.favourite_programs);

  const userType = useSelector((state) => state.user.user?.type);
  const userId = useSelector((state) => state.user.user?.id);
  const userCompanyId = useSelector((state) => state.user.user?.company);

  useEffect(() => {
    if (!items) dispatch(loadProgramTemplates());

    dispatch(loadProgramIds({ id: client.id }));
  }, [client.id, items]);

  const perms = createPermittedIdList(items, userType, userId, userCompanyId);

  const handleClickNewProgram = () =>
    history.push(PROGRAM_TEMPLATES.root + PROGRAM_TEMPLATES.addProgramTemplate);

  const filteredList = useFilteredList(items, searchValue);

  const favItems = useMemo(
    () => items?.filter((p) => favs?.includes(p.id)),
    [items, favs]
  );

  const pastItems = useMemo(
    () =>
      programIds
        ?.map(({ template_id }) => items?.find(({ id }) => template_id === id))
        .reverse()
        .filter((e) => !!e),
    [items, programIds]
  );

  const onSearchChange = (e) => setSearchValue(e.target.value);

  return (
    <Widget className="add-program">
      <Widget.Header>
        <Widget.Title>add program</Widget.Title>
        {isMobileVersion && (
          <Button type="button" onClick={hideWidget}>
            back
          </Button>
        )}
      </Widget.Header>
      <Widget.Body>
        {!isClient && (
          <button
            className="add-program__button"
            type="button"
            onClick={handleClickNewProgram}
          >
            create new program...
          </button>
        )}
        <div className="add-program__input-wrapper">
          <FontAwesomeIcon
            icon={faSearch}
            className="add-program__search-icon"
          />
          <input
            type="text"
            className="add-program__input"
            value={searchValue}
            onChange={onSearchChange}
            placeholder="search"
          />
        </div>
        {loading && <Spinner />}
        {!loading && items && (
          <>
            <AddProgramItem
              title="all programs"
              data={filteredList}
              clientId={client.id}
              perms={perms}
            />
            {favItems.length > 0 && (
              <AddProgramItem
                title="favourite programs"
                data={favItems}
                clientId={client.id}
                perms={perms}
              />
            )}
            {loadingIds ? (
              <Spinner />
            ) : (
              pastItems.length > 0 && (
                <AddProgramItem
                  title={`${client.first_name}'s past programs`}
                  data={pastItems}
                  clientId={client.id}
                  perms={perms}
                />
              )
            )}
          </>
        )}
      </Widget.Body>
    </Widget>
  );
};

AddProgramWidget.propTypes = {};

export default AddProgramWidget;

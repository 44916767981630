import * as yup from "yup";

import { getFullName } from "./index";
import { validatePhoneNumber } from "./validators";
import { USER_STATUS } from "../constants/common";

export const prepareList = (list) =>
  list?.map((item) => ({
    id: item.id,
    name: getFullName(item),
    clients: "",
    active: item?.is_active ? item?.active_clients : USER_STATUS.inactive,
    total: item?.all_clients,
  }));

export const prepareSelectOptions = (
  list,
  defaultOption = { value: "", label: "select a company" }
) => {
  const options =
    list?.map((item) => ({ value: item.id, label: item.name })) || [];

  options.splice(0, 0, defaultOption);

  return options;
};

export const trainerSchema = (isEditPage) => {
  return {
    first_name: yup.string().trim().required(),
    last_name: yup.string().trim().required(),
    company: yup
      .string()
      .required("Please select a company")
      .test("validate-company", "Please select a company", (val) => !!val),
    phone: yup
      .string()
      .required()
      .test(
        "validate-phone",
        "Phone number is invalid",
        (val) => !validatePhoneNumber(val)
      ),
    email: yup.string().required().email(),
    password: isEditPage ? yup.string() : yup.string().required(),
  };
};

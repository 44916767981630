import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { EDITED_TYPES } from "../../../../../../../../constants/templates";

function ExerciseSet({
  isError,
  withDelete,
  idx,
  onRemove,
  weightValue,
  repsValue,
  notNullableError,
  handleChange,
}) {
  return (
    <div className="exercise-set">
      <div className="exercise-set__title">{`set ${idx + 1}:`}</div>
      <div className="exercise-set__body">
        <label className="exercise-set__input-wrapper">
          <input
            value={weightValue || ""}
            onChange={(e) => handleChange(e, EDITED_TYPES.WEIGHT)}
            placeholder="max"
            className={cn("exercise-set__input", {
              "exercise-set__input--error": isError || notNullableError,
            })}
          />
          <span className="exercise-set__label">%</span>
        </label>
        <span className="exercise-set__multiplier">x</span>
        <label className="exercise-set__input-wrapper">
          <input
            placeholder="max"
            value={repsValue || ""}
            onChange={(e) => handleChange(e, EDITED_TYPES.REPS)}
            className={cn("exercise-set__input exercise-set__input--reps", {
              "exercise-set__input--error": isError || notNullableError,
            })}
          />
          <span className="exercise-set__label">reps</span>
        </label>
      </div>
      {withDelete && (
        <button
          type="button"
          onClick={onRemove}
          className="day__icon-btn day__icon-btn--delete exercise-set__delete-btn"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
    </div>
  );
}

ExerciseSet.defaultProps = {
  isError: false,
  withDelete: true,
};

ExerciseSet.propTypes = {
  isError: PropTypes.bool,
  withDelete: PropTypes.bool,
};

export default ExerciseSet;

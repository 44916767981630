export const HEADER = [
  {
    label: "",
    style: { width: "10%" },
  },
  {
    label: "company",
    style: { width: "20%", textAlign: "left" },
  },
  {
    label: "coach",
    style: { width: "30%", textAlign: "left" },
  },
  {
    label: "amount",
    style: { width: "20%", textAlign: "center" },
  },
  {
    label: "invoice date",
    style: { width: "20%", textAlign: "center" },
  },
];

export const ACTION_PAYMENT_TYPE = {
  SAVE: "save",
  INVOICE_SENT: "invoice sent",
  FINALIZE: "finalize",
};

export const INVOICE_STATUS = {
  DUE: "DUE",
  OVERDUE: "OVERDUE",
  PAID: "PAID",
};

export const PAYMENT_DURATION = 200;

export const PAYMENT_ACCOUNT_REQUEST_DELAY = 3000;

export const LOAD_PAYMENT_ACCOUNT_ERROR = "Failed to load the payment account";

export const CREATE_PAYMENT_ACCOUNT_FULFILLED = "We are trying to save your payment account. Please wait a little longer";

export const DELETE_PAYMENT_ACCOUNT_FULFILLED = "We are trying to delete your payment account. Please wait a little longer";

export const CREATE_PAYMENT_ACCOUNT_ERROR = "Failed to create the payment account";

export const DELETE_PAYMENT_ACCOUNT_ERROR = "Failed to delete the payment account";

export const CURRENCY_MASK_OPTIONS = {
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
  inputMode: 'numeric'
};

import React from "react";

import Widget from "../../../../../components/widget/Widget";
import ToolsBlock from "../ToolsBlock/ToolsBlock";

const ToolsWidget = () => {
  return (
    <Widget className="program-template-side">
      <Widget.Header className="pl-10 pt-20">
        <Widget.Title>tools</Widget.Title>
      </Widget.Header>
      <Widget.Body>
        <ToolsBlock />
      </Widget.Body>
    </Widget>
  );
};

export default ToolsWidget;

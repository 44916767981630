import { useHistory } from "react-router-dom";

import {
  BODY_TEST_RESULT_PAGE_SLUG,
  CLIENT,
} from "../../../../../../../constants/router";
import "./TrainerClient.scss";
import { getFullName } from "../../../../../../../helpers";
import { USER_STATUS } from "../../../../../../../constants/common";

const TrainerClient = ({ client }) => {
  const { id, is_active } = client;
  const history = useHistory();

  const onClientClick = () =>
    history.push(`${CLIENT.root}${id}${BODY_TEST_RESULT_PAGE_SLUG}`);

  return (
    <div className="trainer-client">
      <span className="trainer-client__name" onClick={onClientClick}>
        {getFullName(client)}
      </span>
      <span className="trainer-client__status">
        {is_active ? USER_STATUS.active : USER_STATUS.inactive}
      </span>
    </div>
  );
};

TrainerClient.propTypes = {};

export default TrainerClient;

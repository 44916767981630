import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { CLIENT_INFO_TYPES } from "../constants/clientApi";
import { TOASTR_TYPE } from "../constants/toastr";
import {
  editMainInfoClient,
  clearError,
  clearSuccessfullyClientEdited,
  loadClientById,
  updateClientActiveHistory,
} from "../redux/clientSlice";

import { useCallDebouncedCb } from "./useCallDebouncedCb";
import { useToastr } from "./useToastr";

const useSendUpdateClient = (formClientVal, delay) => {
  const dispatch = useDispatch();

  const client = useSelector((state) => state.client.client);
  const error = useSelector((state) => state.client.error);
  const successfullyClientEdited = useSelector(
    (state) => state.client.successfullyClientEdited
  );

  useToastr({
    messages: "Client info saved",
    deps: successfullyClientEdited,
    cb: () => dispatch(clearSuccessfullyClientEdited()),
  });
  useToastr({
    messages: error,
    deps: error,
    type: TOASTR_TYPE.ERROR,
    cb: () => dispatch(clearError()),
  });

  const onActiveHistoryChange = (body) => {
    dispatch(updateClientActiveHistory({ id: client.id, body})).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(loadClientById({id: client.id, infoType: CLIENT_INFO_TYPES.MAIN}));
      }
    });
  };

  useCallDebouncedCb(
    (id, body) => {
      const {trainers, company, ...info} = body;
      if (company) {
        onActiveHistoryChange({company, trainers});
      } else if (trainers) {
        onActiveHistoryChange({trainers});
      }
      if (!_.isEmpty(info)) {
        dispatch(editMainInfoClient({ id, body: info })).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            dispatch(loadClientById({ id, infoType: CLIENT_INFO_TYPES.MAIN }));
          }
        });
      }
    },
    [client.id, formClientVal],
    delay,
    [formClientVal]
  );
};

export default useSendUpdateClient;

import "./AuthPage.scss";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import FormField from "../../../components/FormField/FormField";
import Spinner from "../../../components/Spinner";

const ForgotPasswordForm = ({ onSubmit, back }) => {
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector(
    (state) => state.forgotPassword
  );

  return (
    <div>
      {success && (
        <div className="form-auth">
          <p className="forgot-password-tip">link was sent to your email</p>
          <button onClick={back} className="default-btn auth-btn">
            ok
          </button>
        </div>
      )}
      {!success && (
        <div className="form-auth">
          {loading && <Spinner />}
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("email is invalid")
                .required("please enter email"),
            })}
          >
            {() => (
              <Form>
                {error && (
                  <div className="auth__error">
                    User with this email doesn't exist.
                  </div>
                )}
                {!error && (
                  <p className="forgot-password-tip">
                    please, enter your email
                  </p>
                )}
                <FormField
                  className="auth-field"
                  placeholder="email@example.com"
                  type="email"
                  name="email"
                />
                <button className="default-btn auth-btn" type="submit">
                  send
                </button>
                <button
                  onClick={back}
                  className="default-btn auth-btn"
                  type="submit"
                >
                  cancel
                </button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;

import { CLIENT_INFO_TYPES } from "../constants/clientApi";
import { baseUrl } from "../env/env";
import withCache from "../helpers/cache";

import { loadClientByIdWithCache } from "./clientApi";
import myFetch from "./myFetch";

export const loadDiets = (id) => {
  return myFetch(`${baseUrl}/api/clients/${id}/diets`);
};

export const loadDietsWithCache = withCache(loadDiets);

export const updateDiet = ({ id, body }) => {
  loadClientByIdWithCache.invalidateByArgs({
    id,
    infoType: CLIENT_INFO_TYPES.DIETS_PAGE_INIT,
  });
  loadDietsWithCache.invalidateByArgs(id);
  return myFetch(`${baseUrl}/api/clients/${id}/diets/last`, {
    method: "PUT",
    body: { last_diet: body },
  });
};

export const loadDietTemplates = () => {
  return myFetch(`${baseUrl}/api/diet-templates`);
};

export const loadDietTemplatesWithCache = withCache(loadDietTemplates);

export const getDietTemplates = (id) => {
  return myFetch(`${baseUrl}/api/diet-templates/${id}`);
};

export const getDietTemplatesWithCache = withCache(getDietTemplates);

export const addDietTemplates = (body) => {
  loadDietTemplatesWithCache.invalidate();
  return myFetch(`${baseUrl}/api/diet-templates`, {
    method: "POST",
    body: body,
  });
};

export const deleteDietTemplates = (id) => {
  loadDietTemplatesWithCache.invalidate();
  getDietTemplatesWithCache.invalidateByArgs(id);
  return myFetch(`${baseUrl}/api/diet-templates/${id}`, {
    method: "DELETE",
  });
};

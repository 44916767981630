import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

import HistoryDetailsRow from "./HistoryDetailsRow/HistoryDetailsRow";

function HistoryDetailsItem({ data, isHighlighted, highlightedSet }) {
  return (
    <div className="history-details-item">
      <div
        className={cn("history-details-item__header", {
          "history-details-item__header--highlighted": isHighlighted,
        })}
      >
        {data.name}
      </div>
      {data.sets.filter(set => set.is_completed).map((set, idx) => (
        <HistoryDetailsRow
          key={set.id}
          data={set}
          idx={idx}
          isHighlighted={isHighlighted && idx === highlightedSet}
          repCalculator={data.rep_calculator}
        />
      ))}
    </div>
  );
}

HistoryDetailsItem.propTypes = {
  data: PropTypes.object.isRequired,
  isHighlighted: PropTypes.bool,
  highlightedSet: PropTypes.number,
};

export default HistoryDetailsItem;

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import VirtualList from "../../../../../../components/list/VirtualList";
import Widget from "../../../../../../components/widget/Widget";
import WidgetBody from "../../../../../../components/widget/WidgetBody";
import WidgetHeader from "../../../../../../components/widget/WidgetHeader";
import WidgetTitle from "../../../../../../components/widget/WidgetTitle";
import { createPermittedIdList } from "../../../../../../helpers";
import { setDietFromTemplate } from "../../../../../../redux/dietPageSlice";
import {
  clearSelectedItem,
  getDietTemplates,
  setRemovableItem,
} from "../../../../../../redux/dietTemplatesSlice";

const ITEM_HEIGHT = 34;

const DietTemplateItem = ({ name, id, isCanRemove }) => {
  const dispatch = useDispatch();

  const onTemplateChoose = () => {
    dispatch(getDietTemplates(id));
  };

  const onTemplateDelete = (item) => {
    dispatch(setRemovableItem(item));
  };
  return (
    <div className="templates-list__item">
      <span className="new-diet__btn">
        {isCanRemove && (
          <button
            type="button"
            className="remove-btn"
            onClick={() => onTemplateDelete({ name, id })}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
      </span>
      <span className="new-diet__name" onClick={onTemplateChoose}>
        {name}
      </span>
    </div>
  );
};

const DietTemplatesList = () => {
  const dispatch = useDispatch();
  const dietTemplates = useSelector((state) => state.dietTemplates.items);
  const success = useSelector((state) => state.dietTemplates.success);
  const selectedItem = useSelector((state) => state.dietTemplates.selectedItem);
  const loading = useSelector((state) => state.dietTemplates.loading);

  const userType = useSelector((state) => state.user.user?.type);
  const userId = useSelector((state) => state.user.user?.id);
  const userCompanyId = useSelector((state) => state.user.user?.company);

  useEffect(() => {
    if (selectedItem) {
      dispatch(setDietFromTemplate({ diet: selectedItem }));
      dispatch(clearSelectedItem());
    }
  }, [selectedItem]);

  const perms = createPermittedIdList(dietTemplates, userType, userId, userCompanyId);

  return (
    <Widget className="new-diet">
      <WidgetHeader>
        <WidgetTitle>templates</WidgetTitle>
      </WidgetHeader>
      <WidgetBody className="diet-template__widget-body">
        {!success && (
          <div className="diet-side-ch">
            <div className="diet-loading-side">
              <span>Loading...</span>
            </div>
          </div>
        )}
        {!loading && success && (
          <div className="templates-list">
            <div className="templates-list__header">template name</div>
            <VirtualList
              isDynamic
              list={dietTemplates}
              size={ITEM_HEIGHT}
              row={(virtualRow) => {
                const item = dietTemplates[virtualRow.index];
                return (
                  <DietTemplateItem
                    isCanRemove={perms.includes(item.id)}
                    key={item.id}
                    name={item.name}
                    id={item.id}
                  />
                );
              }}
            />
          </div>
        )}
      </WidgetBody>
    </Widget>
  );
};

export default DietTemplatesList;

import {
  BODY_TEST_RESULT_PAGE_SLUG,
  EXERCISES,
  FOODS,
  PROGRAM_TEMPLATES,
  DIET,
  AUTH,
  TRAINING_PROGRAM_SLUG
} from "../../constants/router.js";

export const TOOLS_ITEMS = [
  { link: FOODS.root, label: "food" },
  { link: EXERCISES.root, label: "exercises" },
  { link: PROGRAM_TEMPLATES.root, label: "program templates" },
  { link: AUTH.root, label: "logout" },
];

export const CLIENTS_ITEMS = [
  { link: "/", label: "client info", needActive: false },
  { link: BODY_TEST_RESULT_PAGE_SLUG, label: "body", needActive: false },
  { link: DIET, label: "diet", needActive: true },
  { link: TRAINING_PROGRAM_SLUG, label: "training", needActive: true },
];

import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  onChangeFoodMeal,
  onChangeFoodWOMeal,
} from "../../../../../redux/dietPageSlice";

import MealCheckbox from "./components/MealCheckbox";

const MealCheckboxRow = ({ meals, isPrePostWorkout, idxFood }) => {
  const dispatch = useDispatch();
  const isShowPrePostWorkout = useSelector(
    (state) => state.dietPage.isShowPrePostWorkout
  );
  const selectedDietIndex = useSelector(
    (state) => state.dietPage.selectedDietIndex
  );
  const mealColumnCount = useSelector(
    (state) => state.dietPage.mealColumnCount
  );
  const onChange = useCallback((idxFood, idxMeal) => {
    dispatch(onChangeFoodMeal({ idxFood, idxMeal }));
  }, []);
  const onChangeWO = useCallback((idxFood) => {
    dispatch(onChangeFoodWOMeal({ idxFood }));
  }, []);
  return (
    <>
      {meals.map((checked, idxMeal) => {
        if (idxMeal >= mealColumnCount[selectedDietIndex]) {
          return;
        }
        return (
          <MealCheckbox
            key={idxMeal}
            idxFood={idxFood}
            idxMeal={idxMeal}
            onChangeCheck={onChange}
            checked={checked}
          />
        );
      })}
      {isShowPrePostWorkout[selectedDietIndex] && (
        <MealCheckbox
          idxFood={idxFood}
          idxMeal={null}
          onChangeCheck={onChangeWO}
          checked={isPrePostWorkout}
        />
      )}
    </>
  );
};

MealCheckboxRow.propTypes = {};

export default MealCheckboxRow;

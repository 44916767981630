import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as DietsApi from "../api/dietsApi";

export const loadDiets = createAsyncThunk("diets/loadDiets", (args) =>
  DietsApi.loadDietsWithCache(args)
);

export const updateDiet = createAsyncThunk(
  "diets/updateDiet",
  DietsApi.updateDiet
);

const dietsSlice = createSlice({
  name: "diets",
  initialState: {
    items: null,
    loading: false,
    success: false,
    successfullyDietEdited: false,
    error: "",
  },
  reducers: {
    clearDiets(state) {
      state.items = null;
      state.successfullyDietEdited = false;
    },
    clearSuccessfullyDietEdited(state) {
      state.successfullyDietEdited = false;
    },
  },
  extraReducers: {
    [loadDiets.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [loadDiets.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = "";
      state.items = action.payload;
    },
    [loadDiets.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.error.message;
    },
    [updateDiet.pending]: (state) => {
      state.saving = true;
      state.successfullyDietEdited = false;
    },
    [updateDiet.fulfilled]: (state, action) => {
      state.successfullyDietEdited = true;
      state.saving = false;
      if (action.payload?.last_diet.is_new && state.items) {
        state.items.push(action.payload.last_diet);
      }
    },
    [updateDiet.rejected]: (state, action) => {
      state.successfullyDietEdited = false;
      state.saving = false;
      state.error = action.error.message;
    },
  },
});

export const { clearDiets, clearSuccessfullyDietEdited } = dietsSlice.actions;

export default dietsSlice.reducer;

import React, { memo } from "react";

import Button from "../../../../../components/Button";
import { SIDEBAR_NAMES } from "../../../../../constants/diet";

import MealsBody from "./MealsBody";
import MealsHeader from "./MealsHeader";

const DietTableMeals = ({ sideBar, setSideBar }) => {
  return (
    <div className="diet-meals__wrapper">
      <div className="diet-meals">
        <div className="diet-meals-header">
          <span>meals</span>
        </div>
        <div className="diet-meals-body">
          <table className="diet-table">
            <thead>
              <tr style={{ height: "30px" }}>
                <MealsHeader />
              </tr>
            </thead>
            <tbody>
              <MealsBody />
              <tr>
                <td colSpan="9">
                  <div className="diet-meals-button-wrapper">
                    <Button
                      disabled={sideBar === SIDEBAR_NAMES.MEALS}
                      onClick={() => setSideBar(SIDEBAR_NAMES.MEALS)}
                    >
                      show meals
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default memo(DietTableMeals);

import React, { memo } from "react";

import useIsSmallScreen from "../../../../../../../hooks/useIsSmallScreen";

const DietTableTotalPercent = ({
  minCalories,
  foodEnergyInPercent,
  isShowMeal,
}) => {
  const isSmallScreen = useIsSmallScreen();

  const { percent, minCal } = minCalories;
  return (
    <>
      <td className="diet-table__td diet-table__td--noborder diet-table__td__total-rows">{`${percent.toFixed(
        0
      )}% of ${minCal.toFixed(0)} minimum`}</td>
      {(!isSmallScreen || !isShowMeal) && (
        <>
          <td className="diet-table__td diet-table__td--noborder diet-table__td__total-rows">
            {foodEnergyInPercent.proteins}%
          </td>
          <td className="diet-table__td diet-table__td--noborder diet-table__td__total-rows">
            {foodEnergyInPercent.carbs}%
          </td>
          <td className="diet-table__td diet-table__td--noborder diet-table__td__total-rows">
            {foodEnergyInPercent.fats}%
          </td>
        </>
      )}
    </>
  );
};

export default memo(DietTableTotalPercent);

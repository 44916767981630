import "./NoteBlock.scss";
import PropTypes from "prop-types";

const NoteBlock = ({ children, className, ...props }) => {
  return (
    <div className={`note-block ${className}`} {...props}>
      {children}
    </div>
  );
};

NoteBlock.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
};

NoteBlock.defaultProps = {
  className: "",
};

export default NoteBlock;

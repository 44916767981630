import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import cn from "classnames";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  clearTrainers,
  loadTrainersPicker,
} from "../../../../redux/trainersSlice";

const CompanyAutocomplete = ({ submitOnBlur }) => {
  const dispatch = useDispatch();
  const {
    errors,
    setErrors,
    touched,
    setFieldTouched,
    values,
    setFieldValue,
    handleSubmit,
  } = useFormikContext();
  const companies = useSelector((state) => state.companies.companiesPicker);
  const onChangeCompany = (company) => {
    if (company?.id) {
      dispatch(loadTrainersPicker(company.id));
      if (submitOnBlur) {
        handleSubmit();
      }
      return;
    }
    dispatch(clearTrainers());
  };
  return (
    <Autocomplete
      name="company"
      className={cn("form-input", "w-100", {
        "field-error": errors.company && touched.company,
      })}
      options={companies}
      value={values.company}
      getOptionLabel={(option) => option?.name || ""}
      onBlur={() => {
        setFieldTouched("company");
      }}
      disableClearable
      onChange={(e, company) => {
        setFieldValue("company", company);
        if (!company || company.id !== values.company?.id) {
          setFieldValue("trainers", []);
          onChangeCompany(company);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
          value={values.id}
          onChange={(e) => {
            setErrors({ ...errors, company: e.target.value ? null : "error" });
          }}
        />
      )}
    />
  );
};

CompanyAutocomplete.propTypes = {
  submitOnBlur: PropTypes.bool,
};

CompanyAutocomplete.defaultProps = {
  submitOnBlur: false,
};

export default CompanyAutocomplete;

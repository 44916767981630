export const ERRORS_DETAIL = {
  NOT_FOUND: "Not found.",
  NOT_ACTIVATED: "Not activated",
}

export const ERRORS_MESSAGE = {
  NOT_FOUND: "Wrong email or password",
  NOT_ACTIVATED: "User is not activated",
}

export const ERRORS_STATUSES = {
  NOT_FOUND_NUMBER: 404,
  UNAUTHORIZED_NUMBER: 401,
}

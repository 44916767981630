import { useFormikContext } from "formik";
import { cloneDeep } from "lodash";
import React, { useState, useEffect } from "react";

import Button from "../../../../../../components/Button";
import { DEFAULT_START_DUPLICATE_IDX } from "../../../../../../constants/templates";
import { validateRangeIdx } from "../../../../../../helpers/templates";

import SectionSelector from "./components/SectionSelector";

const DuplicateBlock = () => {
  const { values, setFieldValue } = useFormikContext();
  const [startDuplicateIdx, setStartDuplicateIdx] = useState(
    DEFAULT_START_DUPLICATE_IDX
  );
  const [endDuplicateIdx, setEndDuplicateIdx] = useState(values.days.length);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setEndDuplicateIdx(values.days.length);
    if (values.days.length === 0) {
      setStartDuplicateIdx(DEFAULT_START_DUPLICATE_IDX);
    }
  }, [values]);

  useEffect(() => {
    setErrors(validateRangeIdx(startDuplicateIdx, endDuplicateIdx));
  }, [startDuplicateIdx, endDuplicateIdx]);

  const handleClick = () => {
    const itemsForDuplicate = values.days.slice(
      startDuplicateIdx - 1,
      endDuplicateIdx
    );
    const newItems = itemsForDuplicate.map((item) => cloneDeep(item));

    setFieldValue("days", [...values.days, ...newItems]);
  };

  return (
    <div className="tools-block__form">
      <div className="tools-block__form-title">duplicate section</div>
      {values.days.length ? (
        <>
          {errors.length !== 0 &&
          errors.map((error) => (
            <div className="tools-block__error">{error}</div>
          ))}
          <SectionSelector
            startIdx={startDuplicateIdx}
            endIdx={endDuplicateIdx}
            setStartIdx={setStartDuplicateIdx}
            setEndIdx={setEndDuplicateIdx}
          />
          <div className="tools-block__button-wrapper">
            <Button
              onClick={handleClick}
              className="tools-block__button"
              disabled={!!errors.length}
            >
              duplicate
            </Button>
          </div>
        </>
      ) : (
        <div>no days for duplicate</div>
      )}
    </div>
  );
};

export default DuplicateBlock;

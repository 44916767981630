import "./CompanyInvoices.scss";
import { useDispatch, useSelector } from "react-redux";

import {
  clearCurrentPayment,
  loadPaymentInvoiceDays,
  setCurrentPayment,
} from "../../redux/paymentSlice";
import Spinner from "../Spinner";

import InvoiceDetails from "./components/InvoiceDetails";
import InvoicesPerMonth from "./components/InvoicePerMounth";

const CompanyInvoices = () => {
  const dispatch = useDispatch();
  const currentPayment = useSelector((state) => state.payment.currentPayment);
  const loading = useSelector((state) => state.payment.loading);
  const onInvoiceSelect = (invoice) => {
    dispatch(setCurrentPayment(invoice));
    dispatch(loadPaymentInvoiceDays(invoice.id));
  };
  const onClickBack = () => {
    dispatch(clearCurrentPayment());
  };
  return (
    <>
      {loading && <Spinner />}
      {!loading && currentPayment && (
        <InvoiceDetails onClickBack={onClickBack} />
      )}
      {!loading && !currentPayment && (
        <InvoicesPerMonth onInvoiceSelect={onInvoiceSelect} />
      )}
    </>
  );
};

CompanyInvoices.propTypes = {};

export default CompanyInvoices;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as TrainerApi from "../api/trainerApi";

export const loadTrainersPicker = createAsyncThunk(
  "trainers/loadTrainersPicker",
  (args) => TrainerApi.loadTrainersPickerWithCache.call(args)
);

export const loadTrainersList = createAsyncThunk(
  "trainers/loadTrainersList",
  (args) => TrainerApi.loadTrainersWithCache.call(args)
);

export const loadTrainerById = createAsyncThunk(
  "trainers/loadTrainerById",
  (args) => TrainerApi.loadTrainerByIdWithCache.call(args)
);

export const loadActiveClients = createAsyncThunk(
  "trainers/loadActiveClients",
  (args) => TrainerApi.loadActiveClientsWithCache.call(args)
);

export const loadAllClients = createAsyncThunk(
  "trainers/loadAllClients",
  (args) => TrainerApi.loadAllClientsWithCache.call(args)
);

export const addTrainer = createAsyncThunk("trainers/addTrainer", (args) =>
  TrainerApi.addTrainer(args)
);

export const editTrainer = createAsyncThunk("trainers/editTrainer", (args) =>
  TrainerApi.editTrainer(args)
);

export const deleteTrainer = createAsyncThunk(
  "trainers/deleteTrainer",
  (args) => TrainerApi.deleteTrainer(args)
);

const trainersSlice = createSlice({
  name: "trainers",
  initialState: {
    trainers: null,
    trainerActiveClients: {}, // key - trainers.id, value - loadActiveClients
    trainersAllClients: {}, // key - trainers.id, value - loadAllClients
    currentTrainer: null,
    page: 0,
    totalCount: 0,
    filter: "",
    loading: false,
    loadingById: false,
    successfullyTrainersLoaded: false,
    loadingActiveClients: false,
    loadingAllClients: false,
    successfullyTrainerLoaded: false,
    successfullyTrainerAdded: false,
    successfullyTrainerEdited: false,
    successfullyTrainerDeleted: false,
    success: false,
    error: "",
  },
  reducers: {
    clearTrainers(state) {
      state.trainers = null;
      trainersSlice.caseReducers.clearStatuses(state);
    },
    clearCurrentTrainer(state) {
      state.currentTrainer = null;
      trainersSlice.caseReducers.clearStatuses(state);
    },
    clearStatuses(state) {
      state.loading = false;
      state.formChangeLoading = false;
      state.successfullyTrainersLoaded = false;
      state.successfullyTrainerLoaded = false;
      state.successfullyTrainerAdded = false;
      state.successfullyTrainerEdited = false;
      state.successfullyTrainerDeleted = false;
      state.success = false;
      state.error = "";
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    clearFilter(state) {
      state.filter = "";
    },
    setCurrentTrainer(state, action) {
      state.currentTrainer = action.payload;
    },
    clearSuccessfullyTrainerEdited(state) {
      state.successfullyTrainerEdited = false;
    },
    clearSuccessfullyTrainerAdded(state) {
      state.successfullyTrainerAdded = false;
    },
    clearSuccessfullyTrainerDeleted(state) {
      state.successfullyTrainerDeleted = false;
    },
    clearError(state) {
      state.error = "";
    }
  },
  extraReducers: {
    [loadTrainersPicker.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [loadTrainersPicker.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = "";
      state.trainers = action.payload;
    },
    [loadTrainersPicker.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    [loadTrainersList.pending]: (state) => {
      state.loading = true;
      state.successfullyTrainersLoaded = false;
    },
    [loadTrainersList.fulfilled]: (state, action) => {
      state.loading = false;
      state.successfullyTrainersLoaded = true;
      state.error = "";
      state.trainers = action.payload?.trainers;
      state.page = action.payload?.page;
      state.totalCount = action.payload?.total_count;
    },
    [loadTrainersList.rejected]: (state, action) => {
      state.loading = false;
      state.successfullyTrainersLoaded = false;
      state.error = action.payload;
    },
    [loadTrainerById.pending]: (state) => {
      state.loadingById = true;
      state.successfullyTrainerLoaded = false;
    },
    [loadTrainerById.fulfilled]: (state, action) => {
      state.loadingById = false;
      state.successfullyTrainerLoaded = true;
      state.currentTrainer = action.payload;
      state.error = "";
    },
    [loadTrainerById.rejected]: (state, action) => {
      state.loadingById = false;
      state.successfullyTrainerLoaded = false;
      state.error = action.payload;
    },
    [addTrainer.pending]: (state) => {
      state.formChangeLoading = true;
      state.successfullyTrainerAdded = false;
    },
    [addTrainer.fulfilled]: (state, action) => {
      state.formChangeLoading = false;
      state.successfullyTrainerAdded = true;
      const { id, first_name, last_name } = action.payload;
      const newCoach = {
        id,
        first_name,
        last_name,
        all_clients: 0,
        active_clients: 0,
      };
      state.trainers = [...state.trainers, newCoach];
      state.totalCount += 1;
      state.currentTrainer = action.payload;
      state.error = "";
    },
    [addTrainer.rejected]: (state, action) => {
      state.formChangeLoading = false;
      state.loading = false;
      state.successfullyTrainerAdded = false;
      state.error = action.error.message;
    },
    [editTrainer.pending]: (state) => {
      state.formChangeLoading = true;
      state.successfullyTrainerEdited = false;
    },
    [editTrainer.fulfilled]: (state, action) => {
      state.formChangeLoading = false;
      state.successfullyTrainerEdited = true;
      state.currentTrainer = action.payload;
      const foundIndex = state.trainers.findIndex(
        (f) => f.id === action.payload.id
      );
      state.trainers[foundIndex] = {
        ...state.trainers[foundIndex],
        ...action.payload,
      };
      if (action.payload?.is_active === false) {
        state.trainers[foundIndex].active_clients = 0;
      };
      state.error = "";
    },
    [editTrainer.rejected]: (state, action) => {
      state.formChangeLoading = false;
      state.successfullyTrainerEdited = false;
      state.error = action.error.message;
    },
    [deleteTrainer.pending]: (state) => {
      state.formChangeLoading = true;
      state.successfullyTrainerDeleted = false;
    },
    [deleteTrainer.fulfilled]: (state) => {
      state.formChangeLoading = false;
      state.successfullyTrainerDeleted = true;
      state.trainers = state.trainers.filter(
        (f) => f.id !== state.currentTrainer.id
      );
      state.currentTrainer = null;
      state.error = "";
    },
    [deleteTrainer.rejected]: (state, action) => {
      state.formChangeLoading = false;
      state.successfullyTrainerDeleted = false;
      state.error = action.payload.detail;
    },
    [loadActiveClients.pending]: (state) => {
      state.loadingActiveClients = true;
    },
    [loadActiveClients.fulfilled]: (state, action) => {
      state.loadingActiveClients = false;
      state.trainerActiveClients = {
        ...state.trainerActiveClients,
        ...action.payload,
      };
    },
    [loadActiveClients.rejected]: (state) => {
      state.loadingActiveClients = false;
    },
    [loadAllClients.pending]: (state) => {
      state.loadingAllClients = true;
    },
    [loadAllClients.fulfilled]: (state, action) => {
      state.trainersAllClients = {
        ...state.trainersAllClients,
        ...action.payload,
      };
      state.loadingAllClients = false;
    },
    [loadAllClients.rejected]: (state) => {
      state.loadingAllClients = false;
    },
  },
});

export const {
  setFilter,
  setCurrentTrainer,
  clearTrainers,
  clearCurrentTrainer,
  clearStatuses,
  clearFilter,
  clearSuccessfullyTrainerEdited,
  clearSuccessfullyTrainerAdded,
  clearSuccessfullyTrainerDeleted,
  clearError,
} = trainersSlice.actions;

export default trainersSlice.reducer;

import { Box } from "@material-ui/core";
import lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../../../../components/Button";
import { USER_TYPES } from "../../../../../../../constants/common";
import {
  FOOD_PREVIEW_ITEMS,
  INITIAL_FOOD_ENERGY,
  SIDEBAR_NAMES,
} from "../../../../../../../constants/diet";
import {
  calculateCalories,
  calculateFoodEnergy,
  calculateFoodEnergyInPercent,
  calculateMinimumCalories,
} from "../../../../../../../helpers/foods";
import useGetFoodsForDietPage from "../../../../../../../hooks/useGetFoodsForDietPage";
import useIsSmallScreen from "../../../../../../../hooks/useIsSmallScreen";
import useIsUserType from "../../../../../../../hooks/useIsUserType";
import {
  onDeleteFood,
  setIsShowAllFoodsForCurrentDiet,
} from "../../../../../../../redux/dietPageSlice";
import MealsHeader from "../../../DietTableMeals/MealsHeader";
import MealCheckboxRow from "../../../MealCheckboxRow";
import DietFoodItem from "../DietFoodItem";
import DietTableHeader from "../DietTableHeader";
import DietTableTotalPercent from "../DietTableTotalPercent";
import DietTableTotalRows from "../DietTableTotalRows";

const DietTableContent = ({ setSideBar, setIsOpenDrawer, sideBar }) => {
  const dispatch = useDispatch();
  const foods = useGetFoodsForDietPage();
  const client = useSelector((state) => state.client.client);
  const isClient = useIsUserType(USER_TYPES.CLIENT);
  const body = useSelector((state) => state.client.body);
  const isSmallScreen = useIsSmallScreen();
  const currentDiets = useSelector((state) => state.dietPage.diets);
  const selectedDietIndex = useSelector(
    (state) => state.dietPage.selectedDietIndex
  );
  const isShowAllFoods = useSelector((state) => state.dietPage.isShowAllFoods);
  const [foodEnergy, setFoodEnergy] = useState(INITIAL_FOOD_ENERGY);
  const [totalCalories, setTotalCalories] = useState(0);
  const [foodEnergyInPercent, setFoodEnergyInPercent] =
    useState(INITIAL_FOOD_ENERGY);
  const [minCalories, setMinCalories] = useState({ minCal: 0, percent: 0 });
  const [isShowMeal, setIsShowMeal] = useState(false);
  useEffect(() => {
    if (!currentDiets[selectedDietIndex]?.foods) {
      return;
    }
    const fe = calculateFoodEnergy(currentDiets[selectedDietIndex].foods);
    const tc = calculateCalories(fe);
    const fePercent = calculateFoodEnergyInPercent(fe, tc);
    const [minCal, percent] = calculateMinimumCalories(
      tc,
      client.mass_unit,
      body
    );
    if (minCalories.minCal !== minCal || minCalories.percent !== percent) {
      setMinCalories({ minCal, percent });
    }
    if (!lodash.isEqual(foodEnergyInPercent, fePercent)) {
      setFoodEnergyInPercent(fePercent);
    }
    if (tc !== totalCalories) {
      setTotalCalories(tc);
    }
    if (!lodash.isEqual(foodEnergy, fe)) {
      setFoodEnergy(fe);
    }
  }, [currentDiets[selectedDietIndex]?.foods]);

  const onRemove = (id) => {
    dispatch(onDeleteFood(id));
  };
  return (
    <div className="diet-table__wrapper">
      <table className="diet-table diet-table-desktop">
        <thead style={{ height: !isSmallScreen ? "30px" : "auto" }}>
          <tr>
            <DietTableHeader isShowMeal={isShowMeal} />
            {!(!isSmallScreen || !isShowMeal) && <MealsHeader />}
          </tr>
        </thead>
        <tbody>
          {foods.map((item, idxFood) => (
            <tr key={item.food.id} style={{ height: "26px" }}>
              <DietFoodItem
                name={item.food.name}
                proteins={item.food.proteins}
                carbs={item.food.carbs}
                fats={item.food.fats}
                measure={item.food.measure}
                idFood={item.food.id}
                isDeleted={item.food.is_deleted}
                idxFood={idxFood}
                isShowMeal={isShowMeal}
                quantity={item.quantity}
                onRemove={onRemove}
              />
              {!(!isSmallScreen || !isShowMeal) && (
                <MealCheckboxRow
                  meals={item.meals}
                  isPrePostWorkout={item.is_pre_post_workout}
                  idxFood={idxFood}
                />
              )}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2">
              {!isClient && (
                <Button
                  onClick={() => {
                    setIsOpenDrawer(true);
                    setSideBar(SIDEBAR_NAMES.FOODS);
                  }}
                  disabled={!isSmallScreen && sideBar === SIDEBAR_NAMES.FOODS}
                >
                  +add food...
                </Button>
              )}
            </td>
            <DietTableTotalRows
              totalCalories={totalCalories}
              foodEnergy={foodEnergy}
              isShowMeal={isShowMeal}
            />
            {!isClient && !(!isSmallScreen || !isShowMeal) && (
              <td colSpan="9">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    onClick={() => {
                      setIsOpenDrawer(true);
                      setSideBar(SIDEBAR_NAMES.MEALS);
                    }}
                    style={{ width: "120px" }}
                  >
                    show meals
                  </Button>
                </Box>
              </td>
            )}
          </tr>
          <tr>
            <td colSpan="2">
              {!isShowAllFoods[selectedDietIndex] &&
                currentDiets[selectedDietIndex]?.foods.length >
                  FOOD_PREVIEW_ITEMS && (
                  <Button
                    onClick={() => dispatch(setIsShowAllFoodsForCurrentDiet())}
                  >
                    show all foods
                  </Button>
                )}
            </td>

            <DietTableTotalPercent
              isShowMeal={isShowMeal}
              minCalories={minCalories}
              foodEnergyInPercent={foodEnergyInPercent}
            />
            {!(!isSmallScreen || !isShowMeal) && (
              <td colSpan="9">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    style={{ width: "120px" }}
                    onClick={() => setIsShowMeal(false)}
                  >
                    nutrition
                  </Button>
                </Box>
              </td>
            )}
          </tr>
          {!isClient && isSmallScreen && !isShowMeal && (
            <tr>
              <td />
              <td />
              <td />
              <td colSpan="9">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    onClick={() => setIsShowMeal(true)}
                    style={{ width: "120px" }}
                  >
                    meals...
                  </Button>
                </Box>
              </td>
            </tr>
          )}
        </tfoot>
      </table>
    </div>
  );
};
export default DietTableContent;

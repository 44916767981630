import React, { memo } from "react";

import FoodEditor from "../../../../../components/FoodEditor";
import { SIDEBAR_NAMES } from "../../../../../constants/diet";
import DietFoodsSideBar from "../DietFoodsSideBar";
import DietHistorySideBar from "../DietHistorySideBar";
import DietMeals from "../DietMealsSideBar";
import SaveDietSideBar from "../SaveDietSideBar";
import TemplatesList from "../TemplatesList";

const DietSideBar = ({ setSideBar, sideBarName }) => {
  const sideBarMapping = {
    [SIDEBAR_NAMES.FOODS]: <DietFoodsSideBar setSideBar={setSideBar} />,
    [SIDEBAR_NAMES.SAVE_DIET]: <SaveDietSideBar setSideBar={setSideBar} />,
    [SIDEBAR_NAMES.DIET_HISTORY]: <DietHistorySideBar />,
    [SIDEBAR_NAMES.MEALS]: <DietMeals />,
    [SIDEBAR_NAMES.TEMPLATES]: <TemplatesList />,
  };
  return (
    <div className="diet__widget-wrapper">
      {sideBarMapping[sideBarName] || (
        <FoodEditor
          stateAfterSubmit={SIDEBAR_NAMES.FOODS}
          sideBarName={sideBarName}
          setSideBar={setSideBar}
        />
      )}
    </div>
  );
};

export default memo(DietSideBar);

import lodash from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { getDifferenceField, setNullInsteadOfBlank } from "../helpers";
import { editCompany, clearSuccessfullyCompanyEdited } from "../redux/companiesSlice";

import { useCallDebouncedCb } from "./useCallDebouncedCb";
import { useToastr } from "./useToastr";

const prepareDataForSand = (values, oldVal) => {
  values = lodash.cloneDeep(values);
  if (!values?.company_admin?.password) {
    delete values?.company_admin?.password;
  }
  delete values?.company_admin?.repeat_password;
  values = getDifferenceField(values, oldVal);
  values = setNullInsteadOfBlank(values);
  return values;
};

const useSendUpdateCompany = (formCompanyVal, delay) => {
  const dispatch = useDispatch();
  const successfullyCompanyEdited = useSelector(
    (state) => state.companies.successfullyCompanyEdited
  );
  const currentCompany = useSelector((state) => state.companies.currentCompany);

  useToastr({
    messages: "Company info saved",
    deps: successfullyCompanyEdited,
    cb: () => {
      dispatch(clearSuccessfullyCompanyEdited());
    }
  });
  useCallDebouncedCb(
    (id, formCompanyVal) => {
      if (lodash.isEmpty(formCompanyVal.body)) {
        return;
      }
      const body = prepareDataForSand(formCompanyVal.body, formCompanyVal.oldVal);
      if (!lodash.isEmpty(body)) {
        dispatch(editCompany({ id: currentCompany.id, body }));
      }
    },
    [currentCompany.id, formCompanyVal],
    delay,
    [formCompanyVal]
  );
};

export default useSendUpdateCompany;

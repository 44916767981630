import "./CompanySearch.scss";

const CompanySearch = ({ value, onChange }) => (
  <input
    type="text"
    className="company-search"
    placeholder="search"
    value={value}
    onChange={onChange}
  />
);

CompanySearch.propTypes = {};

export default CompanySearch;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../../../../../components/Spinner";
import { prepareLocalFancyDateForFront } from "../../../../../../../helpers";
import { loadSetHistory } from "../../../../../../../redux/programsSlice";

import HistoryDetailsItem from "./HistoryDetailsItem/HistoryDetailsItem";

const HistoryDetails = ({ set }) => {
  const dispatch = useDispatch();

  const client = useSelector((state) => state.client.client);
  const setHistory = useSelector(
    (state) => state.programs.setHistory?.workouts[set.indexes.workout]
  );
  const loading = useSelector((state) => state.programs.loadingSetHistory);

  useEffect(() => {
    const params = {
      id: client.id,
      program_index: set.indexes.program,
      day_index: set.indexes.day,
    };
    dispatch(loadSetHistory(params));
  }, []);

  if (loading) return <Spinner stickToTop />;

  return (
    <>
      <div className="history-widget-details__header">
        <div>day {set.indexes.day + 1}</div>
        <div>{prepareLocalFancyDateForFront(set.date)}</div>
      </div>
      {setHistory?.exercises.map(
        (exercise, idx) =>
          exercise.sets.find((set) => set.is_completed) && (
            <HistoryDetailsItem
              key={exercise.id}
              data={exercise}
              isHighlighted={set.indexes.exercise === idx}
              highlightedSet={set.indexes.set}
            />
          )
      )}
      {setHistory?.notes && (
        <div className="history-details-notes">{setHistory.notes}</div>
      )}
    </>
  );
};

export default HistoryDetails;

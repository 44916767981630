import React from "react";
import { Link } from "react-router-dom";

import { PROFILE, TRAINERS } from "../../../constants/router";
import ClientDropDown from "../ClientDropDown";
import { CLIENTS_ITEMS } from "../Menu.model";
import ToolsDropDown from "../ToolsDropDown";

import { TOOLS_ITEMS } from "./../Menu.model";
const CompanyAdminMenu = ({ closeMenuAndResetEditableClientId, closeMenu, isCanAddCoaches }) => {
  return (
    <>
      <div onClick={closeMenuAndResetEditableClientId} className="menu-item">
        <Link to={PROFILE.root} className="tools">
          my profile
        </Link>
      </div>
      {isCanAddCoaches && (
        <div onClick={closeMenuAndResetEditableClientId} className="menu-item">
          <Link to={TRAINERS.root} className="tools">
            coaches
          </Link>
        </div>
      )}
      <ClientDropDown dropdownItems={CLIENTS_ITEMS} onClick={closeMenu} />
      <ToolsDropDown
        onClick={closeMenuAndResetEditableClientId}
        dropdownItems={TOOLS_ITEMS}
        value="tools"
      />
    </>
  );
};

export default React.memo(CompanyAdminMenu);

import { faClone, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { FieldArray } from "formik";
import { cloneDeep } from "lodash";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import {
  DEFAULT_REPS_VALUE,
  DEFAULT_WEIGHT_VALUE,
  SIDEBAR_TYPE,
} from "../../../../../../constants/templates";
import { setSelectedExercise } from "../../../../../../redux/programTemplatesSlice";
import { Context } from "../../../ProgramTemplateForm";

import ExerciseConstructor from "./ExerciseConstructor/ExerciseConstructor";

const Exercises = ({
  remove,
  push,
  insert,
  replace,
  isAddExerciseDisable,
  dayIdx,
  workoutIdx,
  exercises,
}) => {
  const dispatch = useDispatch();
  const {
    replaceAllExercisesFrom: replaceAllFrom,
    setIsAddExercise,
    setSideBarState,
    setOnExerciseClick,
  } = useContext(Context);

  const handleAddExerciseClick = () => {
    setIsAddExercise(true);
    setSideBarState(SIDEBAR_TYPE.ADD_EXERCISE);
    setOnExerciseClick(
      () => (_, exercise) =>
        push({
          ...exercise,
          sets: [{ weight: DEFAULT_WEIGHT_VALUE, reps: DEFAULT_REPS_VALUE }],
        })
    );
  };
  const handleEditExercise = (idx) => () => {
    const payload = {
      data: exercises[idx],
      dayIdx,
      workoutIdx,
      exerciseIdx: idx,
    };
    dispatch(setSelectedExercise(payload));
    setIsAddExercise(false);
    setSideBarState(SIDEBAR_TYPE.ADD_EXERCISE);

    setOnExerciseClick(() => (changeAll, exercise) => {
      if (changeAll) {
        replaceAllFrom(
          {
            id: exercises[idx].id,
            day: dayIdx,
            workout: workoutIdx,
            exercise: idx,
          },
          {
            id: exercise.id,
            name: exercise.name,
          }
        );
      } else {
        replace(idx, {
          ...exercise,
          sets: exercises[idx].sets,
        });
      }

      dispatch(setSelectedExercise(null));
    });
  };

  const handleRemoveExercise = (idx) => () => remove(idx);

  const handleCopyExercise = (item, idx) => () => {
    const newItem = cloneDeep(item);
    insert(idx + 1, newItem);
  };

  return (
    <>
      <div className="workout__exercises">
        {exercises.map((item, idx) => {
          return (
            <ExerciseConstructor
              key={idx}
              data={item}
              onRemove={handleRemoveExercise(idx)}
              onCopy={handleCopyExercise(item, idx)}
              editExercise={handleEditExercise(idx)}
              workoutIdx={workoutIdx}
              dayIdx={dayIdx}
              exerciseIdx={idx}
            />
          );
        })}
      </div>
      <button
        type="button"
        onClick={handleAddExerciseClick}
        className={cn(
          "day__icon-btn day__icon-btn--add workout__add-exercise-btn",
          { "workout__add-exercise-btn--disable": isAddExerciseDisable }
        )}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </>
  );
};

const MemoExercises = React.memo(Exercises);

const Workout = ({ className, idx, onRemove, onCopy, dayIdx, data }) => {
  return (
    <div className={`workout${className && ` ${className}`}`}>
      <div className="workout__header">
        <span className="workout__header-text">{`Workout ${idx + 1}`}</span>
        <button
          className="day__icon-btn day__icon-btn--delete workout__delete-btn"
          type="button"
          onClick={onRemove}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <button
          className="day__icon-btn day__icon-btn--duplicate"
          type="button"
          onClick={onCopy}
        >
          <FontAwesomeIcon icon={faClone} />
        </button>
      </div>
      <FieldArray name={`days[${dayIdx}].workouts[${idx}].exercises`}>
        {({ push, insert, remove, replace }) => (
          <MemoExercises
            insert={insert}
            push={push}
            remove={remove}
            replace={replace}
            workoutIdx={idx}
            dayIdx={dayIdx}
            exercises={data.exercises}
          />
        )}
      </FieldArray>
    </div>
  );
};

export default Workout;

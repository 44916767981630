import "./PageLabel.scss";
import { ITEMS_PER_PAGE } from "../../../constants/common";

/* page - page number, itemsCount - number of elements on the current page, totalCount - total items count in the list */
const PageLabel = ({
  page,
  itemsCount,
  totalCount,
  perPage = ITEMS_PER_PAGE,
}) => {
  const pageLabel =
    totalCount <= perPage
      ? totalCount
      : (page - 1) * perPage + 1 + "-" + ((page - 1) * perPage + itemsCount);
  return <p className="paging-label">{pageLabel + " of " + totalCount}</p>;
};

export default PageLabel;

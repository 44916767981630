/**
 * Alphabetical order sorting (ascending)
 *
 * @param {*[]} arr array of things to compare
 * @param {(any) => string} selector select the field to compare (default el => el)
 * @returns {*[]} sorted array
 */
export const alphabeticalOrder = (arr, selector = (el) => el) => {
  return arr.sort((a, b) => {
    if (selector(a)?.toLowerCase() < selector(b)?.toLowerCase()) {
      return -1;
    } else {
      return 1;
    }
  })
};

import { createSlice } from "@reduxjs/toolkit";

const utilsSlice = createSlice({
  name: "utils",
  initialState: {
    isOpenDrawer: true,
    modal: null,
  },
  reducers: {
    setIsOpenDrawer(state, action) {
      state.isOpenDrawer = action.payload;
    },
    setModal(state, action) {
      state.modal = action.payload;
    },
  },
});

export const {
  setIsOpenDrawer,
  setModal,
} = utilsSlice.actions;

export default utilsSlice.reducer;

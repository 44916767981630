export const ITEMS_PER_PAGE = 50;

export const LENGTH_STRING_FOR_SHOW_TOOLTIP = 60;

export const CLICK_AWAY_REASON = "clickaway";

export const DATE_FORMAT_ON_BACKEND = "YYYY-MM-DD";

export const UTC_FORMAT_TO_BACKEND = "YYYY-MM-DDTHH:mm:ss[Z]";

export const UTC_FORMAT_ON_BACKEND = "YYYY-MM-DDTHH:mm:ssZ";

export const DATE_FORMAT_ON_FRONTEND = "DD/MM/YYYY";

export const SHORT_DATE_FORMAT_ON_FRONTEND = "D/M/YY";

export const FULL_MONTH_DATE_FORMAT_ON_FRONTEND = "MMMM'YY";

export const SHORT_DATE_DOTS_FORMAT_ON_FRONTEND = "D.M.YY";

export const LONG_DATE_DOTS_FORMAT_ON_FRONTEND = "DD.MM.YY";

export const FANCY_DATE_FORMAT_ON_FRONTEND = "D MMM YY";

export const MAX_MOBILE_SCREEN_WIDTH = {
  SHOW_DIET_DAYS_SELECTOR: 1600,
  SHOW_WIDGET_IN_DRAWER: 1200,
  COMPANY_FORM_BTN: 425,
  SLIDER_GOAL: 768,
  MOBILE_VIEW_ON_TRAINING: 428,
};

export const FOR_TABLE = {
  BREAKPOINTS: {
    WIDTH_1200: 1200,
    WIDTH_580: 580,
    WIDTH_425: 425,
  },
  HEIGHT_VALUE: {
    MIN: 60,
    MEDIUM_1: 80,
    MEDIUM_2: 85,
    MAX: 100,
  },
};

export const mobile = {
  trainingScreens: {
    ADD_PROGRAM_MOBILE: "ADD_PROGRAM_MOBILE",
    PROGRAM_VIEW: "PROGRAM_VIEW",
    DAY_VIEW: "DAY_VIEW",
    EXERCISE_VIEW: "EXERCISE_VIEW",
    SET_ENTRY_FORM: "SET_ENTRY_FORM",
    HISTORY_VIEW: "HISTORY_VIEW",
    SHOW_WORKOUT_HISTORY: "SHOW_WORKOUT_HISTORY",
    EDIT_EXERCISE_IN_PLACE: "EDIT_EXERCISE_IN_PLACE",
  },
};

export const templateEditor = {
  widgetArea: {
    TOOLS: "TOOLS",
    EXERCISES: "EXERCISES",
    DELETE_CONFIRM: "DELETE_CONFIRM",
    ADD_NEW_EXERCISE: "ADD_NEW_EXERCISE",
    EDIT_EXERCISE: "EDIT_EXERCISE",
    DELETE_EXERCISE_CONFIRM: "DELETE_EXERCISE_CONFIRM",
  },
  exerciseWidgetMode: {
    ADD: "ADD",
    EDIT: "EDIT",
    EDIT_IN_PLACE: "EDIT_IN_PLACE",
    INIT: "INIT",
  },
};

export const templateViewer = {
  widgetArea: {
    MAIN: "MAIN",
    ADD_PROGRAM: "ADD_PROGRAM",
    SHOW_NOTES: "SHOW_NOTES",
    SHOW_WORKOUT_HISTORY: "SHOW_WORKOUT_HISTORY",
    EDIT_EXERCISE_IN_PLACE: "EDIT_EXERCISE_IN_PLACE",
  },
};

export const RECOMP_COMPANY = {
  NAME: "Recomp",
  ID: "427f1f77bcf86cd799439011",
};

export const ENDUSER_COMPANY = {
  NAME: "EndUserCompany",
  ID: "5f195f0183fe8d5eeb99d411",
};

export const INVOICE_STATUS = {
  PAID: "PAID",
  DUE: "DUE",
  OVERDUE: "OVERDUE",
};

export const SIDE_TYPES = {
  THEME: 1,
  INVOICE_DETAILS: 2,
  CREDIT_CARD: 3,
};

export const MAX_DEACTIVATION_PERIOD = 45;
export const MIN_DEACTIVATION_PERIOD = 1;
export const DEACTIVATION_PERIOD_DAYS = 30;

export const STRIPE_PUBLISHABLE_KEY =
  process.env.SENTRY_ENVIRONMENT === "production"
    ? "pk_live_HrQKfTKcbhWbzDJ39O5XaAqY"
    : "pk_test_48o7QtV8TuPPNx7fFvVwI9Au00UnescwL0";

export const SETUP_PAGES = {
  LOADING: "loading",
  GETTING_STARTED: "getting_started",
  FIRST_PROGRAM_MESSAGE: "first_program_message",
  FIRST_PROGRAM_LIST: "first_program_list",
  STRENGTH: "strength",
};

export const DELAY_FOR_AUTO_SAVE = 700;

export const DELAY_FOR_SEARCH = 400;

export const DELAY_FOR_EDIT_GOAL = 2500;

export const USER_TYPES = {
  CLIENT: "Client",
  SUPERUSER: "Superuser",
  COMPANY_ADMIN: "CompanyAdmin",
  TRAINER: "Trainer",
};

export const USER_STATUS = {
  active: "active",
  inactive: "inactive",
};

export const MAX_OF_STRENGTH_TEST = {
  KG: 700,
  LB: 1500,
};

export const MAX_OF_PB = {
  KG: 1500,
  LB: 3300,
};


export const STATUS_CODE = {
  OK: 200,
};

export const IMG_MAX_VALUE_FOR_CACHE = 50;

export const TIMES = {
  SECONDS_IN_MONTH: 60 * 60 * 24 * 30,
};

export const NUMBERS_OF_REPEAT_EXERCISE = {
  MIN: 1,
  MAX: 30,
};

export const FORM_ERRORS = {
  EMAIL_ALREADY_USED: "This email is already taken",
};

export const LEAVE_MESSAGES = {
  UNSAVED: "You have some unsaved changes! Are you sure you want to leave?",
}

import {Box} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import "./CompanyProfilePage.scss";
import {CSVLink} from "react-csv/lib";
import {useDispatch, useSelector} from "react-redux";

import {REACT_APP_CHECKOUT_TEST_COMPANY_ID} from "../../../env/env";
import Button from "../../../components/Button";
import CompanyForm from "../../../components/CompanyForm";
import CompanyInvoices from "../../../components/CompanyInvoces";
import MobileDrawer from "../../../components/MobileDrawer";
import Spinner from "../../../components/Spinner";
import Widget from "../../../components/widget/Widget";
import {DELAY_FOR_AUTO_SAVE} from "../../../constants/common";
import {COMPANY_PROFILE_WIDGET} from "../../../constants/company";
import useGetCompanyInvoice from "../../../hooks/useGetCompanyInvoice";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import useSendUpdateCompany from "../../../hooks/useSendUpdateCompany";
import {loadCSV} from "../../../redux/userSlice";

import ThemeSideBar from "./components/ThemeSideBar";
import PaymentSideBar from "./components/PaymentSideBar";
import {clearMessage, loadPaymentAccount} from "../../../redux/companiesSlice";
import {useToastr} from "../../../hooks/useToastr";
import {useSearchParam} from "../../../hooks/useSearchParam";

const WIDGETS = {
  [COMPANY_PROFILE_WIDGET.INVOICES]: <CompanyInvoices/>,
  [COMPANY_PROFILE_WIDGET.THEME]: <ThemeSideBar/>,
  [COMPANY_PROFILE_WIDGET.PAYMENT]: <PaymentSideBar/>
};

const CompanyProfilePage = () => {
  const dispatch = useDispatch();
  useGetCompanyInvoice();

  const csv = useSelector((state) => state.user.csv);
  const loadingCSV = useSelector((state) => state.user.loadingCSV);
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const success = useSelector(
    (state) => state.companies.successfullyGettingCompanyById
  );
  const loading = useSelector((state) => state.companies.loading);
  const message = useSelector(state => state.companies.message);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [formCompanyVal, setFormCompanyVal] = useState({body: {}, oldVal: {}});
  const [sideBarParam, setSideBarParam] = useSearchParam("side");
  const [sideBarState, setSideBarState] = useState(sideBarParam || COMPANY_PROFILE_WIDGET.THEME);

  useSendUpdateCompany(formCompanyVal, DELAY_FOR_AUTO_SAVE);
  const isSmallScreen = useIsSmallScreen();

  const onChangeSideBar = sideBarType => {
    setSideBarState(sideBarType);
    setSideBarParam(sideBarType);
    setIsOpenDrawer(true);
  };

  // test checkout payments only on Damon's company on prod
  const isTestCompany = currentCompany.id === REACT_APP_CHECKOUT_TEST_COMPANY_ID;

  useEffect(() => {
    if (sideBarState === COMPANY_PROFILE_WIDGET.PAYMENT) {
      dispatch(loadPaymentAccount({id: currentCompany.id}));
    }
  }, [sideBarState]);

  useToastr({
    messages: message,
    deps: message,
    cb: () => dispatch(clearMessage()),
  });

  return (
    <div className="content-rhs content">
      <div className="page-content d-flex w-100">
        <div className="body-main">
          <Widget>
            <Widget.Header>
              <Widget.Title>my profile</Widget.Title>
            </Widget.Header>
            <Widget.Body>
              {loading ?
                <Spinner/>
                :
                success && (
                  <CompanyForm
                    onSubmit={(body, oldVal) => {
                      setFormCompanyVal({body, oldVal});
                    }}
                    isAutoSave
                    isEditPage
                    initialValues={currentCompany}
                    children={
                      <Box
                        display="flex"
                        className="mb-15"
                        justifyContent="flex-end"
                        flexWrap="wrap"
                      >
                        <Button
                          disabled={
                            (((isSmallScreen && isOpenDrawer) || !isSmallScreen) &&
                            sideBarState === COMPANY_PROFILE_WIDGET.PAYMENT) || !isTestCompany
                          }
                          onClick={() => onChangeSideBar(COMPANY_PROFILE_WIDGET.PAYMENT)}
                          className="default-btn"
                        >
                          payments
                        </Button>
                        <Button
                          disabled={
                            ((isSmallScreen && isOpenDrawer) || !isSmallScreen) &&
                            sideBarState === COMPANY_PROFILE_WIDGET.INVOICES
                          }
                          onClick={() => onChangeSideBar(COMPANY_PROFILE_WIDGET.INVOICES)}
                          className="default-btn"
                        >
                          invoices
                        </Button>
                        <Button
                          disabled={
                            ((isSmallScreen && isOpenDrawer) || !isSmallScreen) &&
                            sideBarState === COMPANY_PROFILE_WIDGET.THEME
                          }
                          onClick={() => onChangeSideBar(COMPANY_PROFILE_WIDGET.THEME)}
                          className="default-btn"
                        >
                          theme
                        </Button>
                        {!csv?.length && (
                          <Button
                            disabled={loadingCSV}
                            className="btn-primary"
                            onClick={() => dispatch(loadCSV())}
                          >
                            export csv
                          </Button>
                        )}
                        {!!csv?.length && (
                          <CSVLink data={csv}>
                            <Button
                              className="btn-primary"
                            >
                              download csv
                            </Button>
                          </CSVLink>
                        )}
                      </Box>
                    }
                  />
                )}
            </Widget.Body>
          </Widget>
        </div>
        <div className="body-side">
          {!loading && success && !isSmallScreen && WIDGETS[sideBarState]}
          {!loading && success && isSmallScreen && (
            <MobileDrawer
              open={isOpenDrawer}
              onClose={() => {
                setIsOpenDrawer(false);
              }}
            >
              {WIDGETS[sideBarState]}
            </MobileDrawer>
          )}
        </div>
      </div>
    </div>
  );
};

CompanyProfilePage.propTypes = {};

export default CompanyProfilePage;

import "./Drums.scss";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { useFormikContext } from "formik";
import { debounce } from "lodash/function";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {useDispatch, useSelector} from "react-redux";

import Spinner from "../../../../../../../components/Spinner";
import {MAX_MOBILE_SCREEN_WIDTH} from "../../../../../../../constants/common";
import { coefficients } from "../../../../../../../constants/repCoefficients";
import { capBy, selectClosestInArray } from "../../../../../../../helpers";
import {
  calculateGoalReps,
  calculateGoalWeight,
  calculateMax,
  getMergedId,
} from "../../../../../../../helpers/training";
import useIsSmallScreen from "../../../../../../../hooks/useIsSmallScreen";
import {setPreselectedRep, setPreselectedWeight} from "../../../../../../../redux/programsSlice";

const INIT_OFFSET = 100000;
const OFFSET_ITEMS = 4.5;
const OFFSET_ITEMS_MOBILE = 3.5;
const PRE_SCROLL_TIMEOUT = 5;
const SCROLL_TIMEOUT = 300;

const EmptyItems = () => <div className="empty" />;

const useColumnFunctionality = (selectedItem, key, onChange, ref, isNested) => {
  const isMobileVersion = useIsSmallScreen(MAX_MOBILE_SCREEN_WIDTH.MOBILE_VIEW_ON_TRAINING);
  const debouncedScrollListener = debounce(() => {
    const wrapper = ref.current;
    if (!wrapper) return;

    const innerElements = !isNested
      ? wrapper.childNodes
      : wrapper.childNodes[0].childNodes;
    const position = wrapper && wrapper.scrollTop;
    let minimalOffset = INIT_OFFSET;
    let elementWithMinimalOffset;

    innerElements.forEach((element) => {
      const diffOffset = Math.abs(
        element.offsetTop - position - scrollOffset(element)
      );
      if (diffOffset < minimalOffset) {
        minimalOffset = diffOffset;
        elementWithMinimalOffset = element;
      }
    });

    const value = elementWithMinimalOffset?.dataset?.value;

    if (value) {
      onChange(value);
    } else {
      scrollToSelected(selectedItem);
    }
  }, SCROLL_TIMEOUT);

  const debouncedScrollListenerRef = useRef(debouncedScrollListener);

  const scrollOffset = (element) => {
    const rect = element.getBoundingClientRect();
    return rect.height * (isMobileVersion ? OFFSET_ITEMS_MOBILE : OFFSET_ITEMS);
  }

  const scrollToSelected = useCallback(
    (selectedItem) => {
      const element = document.getElementById(`${key}-${selectedItem}`);

      if (element) {
        const offset = element.offsetTop - scrollOffset(element);
        isNested
          ? (element.parentNode.parentNode.scrollTop = offset)
          : (element.parentNode.scrollTop = offset);
      }
    },
    [isNested, key]
  );

  useEffect(() => {
    setTimeout(() => {
      scrollToSelected(selectedItem);
    }, PRE_SCROLL_TIMEOUT);
  }, [selectedItem]);

  const onScroll = () => {
    debouncedScrollListenerRef.current?.cancel();
    debouncedScrollListenerRef.current();
  };

  return {
    onScroll,
  };
};

const Drums = React.memo(
  ({
    setToBeat,
    setNumber,
    onWeightChange,
    onRepsChange,
    weights,
    reps,
    pb,
    pbIsCalculated,
    selectedWeight,
    selectedRep,
    maxWeights,
    onPlusWeight,
    onMinusWeight,
    onMinusRep,
    onPlusRep,
    highlightedWeightIdx,
    highlightedRepsIdx,
    isCompleted,
  }) => {
    const massUnit = useSelector((state) => state.client.client.mass_unit);
    const weightsRef = useRef();
    const repsRef = useRef();

    const isHighlightingMax = weight => {
      const toBeat = setNumber === 1 ? null : setToBeat?.calculated_max;
      return toBeat ?
        weight > toBeat
        :
        weight > pb && !pbIsCalculated
    };

    const { onScroll: onWeightsScroll } = useColumnFunctionality(
      selectedWeight,
      "weight",
      onWeightChange,
      weightsRef
    );
    const { onScroll: onRepsScroll } = useColumnFunctionality(
      selectedRep,
      "rep",
      onRepsChange,
      repsRef,
      true
    );

    return (
      <div className="drums">
        <div className="top-shadow" />
        <div className="inner">
          <div className={cn("line", { "line--highlighted": isCompleted })} />
          <div className="actions">
            <FontAwesomeIcon onClick={onMinusWeight} icon={faMinus} />
            <FontAwesomeIcon onClick={onMinusRep} icon={faMinus} />
            <FontAwesomeIcon onClick={onMinusRep} icon={faMinus} />
          </div>
          <div className="drums-icon__multiply">X</div>
          <div className="drums-icon__eql">=</div>
          <div className="columns">
            <div className="weight" ref={weightsRef} onScroll={onWeightsScroll}>
              <EmptyItems />
              {weights.map((w, idx) => (
                <div
                  key={idx}
                  className={cn("drum-item", {
                    "drum-item--highlighted":
                      !isCompleted && !pbIsCalculated && idx === highlightedWeightIdx,
                  })}
                  data-value={w}
                  id={`weight-${w}`}
                >
                  {w} {massUnit}
                </div>
              ))}
              <EmptyItems />
            </div>
            <div className="reps" onScroll={onRepsScroll} ref={repsRef}>
              <div className="reps__column">
                <EmptyItems />
                {reps.map((r, idx) => (
                  <div
                    key={idx}
                    className={cn("drum-item", {
                      "drum-item--highlighted":
                        !isCompleted && !pbIsCalculated && idx === highlightedRepsIdx,
                    })}
                    id={`rep-${r}`}
                    data-value={r}
                  >
                    {r} reps
                  </div>
                ))}
                <EmptyItems />
              </div>
              <div className="max__column">
                <EmptyItems />
                {maxWeights.map((w, idx) => {
                  return (
                    <div
                      key={idx}
                      className={cn("drum-item", {
                        "drum-item__new-pb": isHighlightingMax(w),
                      })}
                      id={`max-weight-${w}`}
                      data-value={w}
                    >
                      {w} max {massUnit}
                    </div>
                  );
                })}
                <EmptyItems />
              </div>
            </div>
          </div>
          <div className="actions pluses">
            <FontAwesomeIcon icon={faPlus} onClick={onPlusWeight} />
            <FontAwesomeIcon icon={faPlus} onClick={onPlusRep} />
            <FontAwesomeIcon icon={faPlus} onClick={onPlusRep} />
          </div>
        </div>
        <div className="bottom-shadow" />
      </div>
    );
  }
);

const DrumsWrapper = ({ weights, reps, data }) => {
  const dispatch = useDispatch();

  const isMobileVersion = useIsSmallScreen(MAX_MOBILE_SCREEN_WIDTH.MOBILE_VIEW_ON_TRAINING);

  const currentSet = useSelector((state) => state.programs.currentSet);
  const history = useSelector((state) => state.programs.history);
  const loading = useSelector((state) => state.programs.loadingHistory);
  const preselectedWeight = useSelector((state) => state.programs.preselectedWeight);
  const preselectedRep = useSelector((state) => state.programs.preselectedRep);

  const maxReps = coefficients[data.repCalculator].length;

  const setToBeat = useMemo(() => {
    const {exerciseId, id} = data;
    const mergedId = getMergedId(exerciseId, id);
    return history?.sets[history?.history[mergedId]?.previous?.ranked];
  }, [data, history, loading]);

  const toBeat = useMemo(() => {
    const {set, isCompleted} = data;
    return !(loading || set?.number === 1 || isCompleted)
      ? setToBeat?.calculated_max
      : null;
  }, [setToBeat]);

  const initWeight = useMemo(() => {
    if (preselectedWeight) return preselectedWeight;
    const { repCalculator, values, pb, set } = data;
    const { weight, reps: setReps } = values;
    const goalWeight =
      weight ||
      calculateGoalWeight(
        toBeat || pb,
        capBy(set.reps || setReps, maxReps),
        repCalculator
      );
    return selectClosestInArray(goalWeight, weights);
  }, [data, weights, preselectedWeight]);

  const initReps = useMemo(() => {
    if (preselectedRep) return parseInt(preselectedRep);
    const { pb, repCalculator, set, values, isCompleted } = data;
    if (isCompleted) return values.reps;
    const repsToBeatPb = calculateGoalReps(pb, initWeight, repCalculator);
    const repsToBeatPbOrDefault = set.reps > repsToBeatPb && set.weight
      ? repsToBeatPb
      : capBy(values.reps || set.reps, maxReps);
    return toBeat && set.weight
      ? calculateGoalReps(toBeat, initWeight, repCalculator)
      : repsToBeatPbOrDefault;
  }, [data, history, loading, initWeight, preselectedRep]);

  const [selectedWeight, setSelectedWeight] = useState(initWeight);
  const [selectedRep, setSelectedRep] = useState(
    reps[reps.indexOf(data.values.reps)]
  );
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setSelectedWeight(initWeight);
    setSelectedRep(reps[reps.indexOf(initReps)]);
  }, [reps, weights, data.isCompleted, initWeight, initReps]);

  const generateMaxWeightsArray = useCallback(
    () =>
      reps.map((r) => {
        return calculateMax(selectedWeight, r, data.repCalculator);
      }),
    [data.repCalculator, reps, selectedWeight]
  );

  const [maxWeights, setMaxWeights] = useState(() => generateMaxWeightsArray());
  // recreate max weights on selectedWeight change
  useEffect(() => {
    const newMaxWeightsArray = generateMaxWeightsArray();
    setMaxWeights(newMaxWeightsArray);
    setFieldValue("weight", selectedWeight);
  }, [generateMaxWeightsArray, selectedWeight, setFieldValue]);

  useEffect(() => {
    setFieldValue("weight", selectedWeight);
    setFieldValue("reps", selectedRep);
    setFieldValue(
      "calculatedMax",
      calculateMax(selectedWeight, selectedRep, data.repCalculator)
    );
  }, [
    selectedRep,
    selectedWeight,
    data.repCalculator,
    setFieldValue,
    currentSet,
  ]);

  const onWeightChange = useCallback((value) => {
    if (isMobileVersion) dispatch(setPreselectedWeight(value));
    setSelectedWeight(value);
  }, []);

  const onRepsChange = useCallback((value) => {
    if (isMobileVersion) dispatch(setPreselectedRep(value));
    setSelectedRep(value);
  }, []);

  const onPlusWeight = useCallback(() => {
    const indexOfCurrent = weights.indexOf(+selectedWeight);
    if (indexOfCurrent !== weights.length - 1 && indexOfCurrent > -1) {
      onWeightChange(weights[indexOfCurrent + 1]);
    }
  }, [onWeightChange, selectedWeight, weights]);

  const onMinusWeight = useCallback(() => {
    const indexOfCurrent = weights.indexOf(+selectedWeight);
    if (indexOfCurrent !== 0 && indexOfCurrent > -1) {
      onWeightChange(weights[indexOfCurrent - 1]);
    }
  }, [onWeightChange, selectedWeight, weights]);

  const onMinusRep = useCallback(() => {
    const indexOfCurrent = reps.indexOf(+selectedRep);
    if (indexOfCurrent !== 0 && indexOfCurrent > -1) {
      onRepsChange(reps[indexOfCurrent - 1]);
    }
  }, [onRepsChange, reps, selectedRep]);

  const onPlusRep = useCallback(() => {
    const indexOfCurrent = reps.indexOf(+selectedRep);
    if (indexOfCurrent !== reps.length - 1 && indexOfCurrent > -1) {
      onRepsChange(reps[indexOfCurrent + 1]);
    }
  }, [onRepsChange, reps, selectedRep]);

  if (loading && data.set?.number > 1 && !data.isCompleted)
    return <Spinner stickToTop />;
  return (
    <div>
      <Drums
        setToBeat={setToBeat}
        setNumber={data.set.number}
        onWeightChange={onWeightChange}
        onRepsChange={onRepsChange}
        reps={reps}
        weights={weights}
        pb={data.pb}
        pbIsCalculated={data.pbIsCalculated}
        selectedWeight={selectedWeight}
        selectedRep={selectedRep}
        maxWeights={maxWeights}
        onPlusWeight={onPlusWeight}
        onMinusWeight={onMinusWeight}
        onMinusRep={onMinusRep}
        onPlusRep={onPlusRep}
        highlightedWeightIdx={data.set.weight && weights.indexOf(initWeight)}
        highlightedRepsIdx={
          data.set.reps && reps.indexOf(capBy(data.set.reps, maxReps))
        }
        isCompleted={data.isCompleted}
      />
    </div>
  );
};

DrumsWrapper.propTypes = {
  weights: PropTypes.arrayOf(PropTypes.number).isRequired,
  reps: PropTypes.arrayOf(PropTypes.number).isRequired,
  data: PropTypes.object.isRequired,
};

export default DrumsWrapper;

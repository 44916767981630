import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { calculateMax } from "../../../../../../../../../helpers/training";

function HistoryDetailsRow({ data, idx, isHighlighted, repCalculator }) {
  const massUnit = useSelector((state) => state.client.client.mass_unit);

  return (
    <div
      className={cn("history-details-row", {
        "history-details-row--highlight": isHighlighted,
      })}
    >
      <div className="history-details-row__set">set {idx + 1}:</div>
      <div className="history-details-row__weight">
        {data.weight} {massUnit} X {data.reps}
        {data.other_reps !== 0 && ` + ${data.other_reps}`}
      </div>
      <div className="history-details-row__max">
        ({calculateMax(data.weight, data.reps, repCalculator)} {massUnit} max)
      </div>
      {data.notes && (
        <div className="history-details-row__notes">{data.notes}</div>
      )}
    </div>
  );
}

HistoryDetailsRow.propTypes = {
  data: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  isHighlighted: PropTypes.bool,
  repCalculator: PropTypes.string.isRequired,
};

export default HistoryDetailsRow;

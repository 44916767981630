import "./WidgetBody.scss";
import cn from "classnames";
import PropTypes from "prop-types";

const WidgetBody = ({ children, className, isScrollable, ...props }) => (
  <div
    className={cn("widget-body", className, {
      scrollable: isScrollable,
    })}
    {...props}
  >
    {children}
  </div>
);

WidgetBody.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  isScrollable: PropTypes.bool,
};

WidgetBody.defaultProps = {
  className: "",
  isScrollable: false,
};

export default WidgetBody;

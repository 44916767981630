import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../../components/Button";
import {
  deleteCompanyById,
  editCompany,
} from "../../../../../redux/companiesSlice";

const Buttons = ({ onClickShowInvoices }) => {
  const dispatch = useDispatch();
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  return (
    <>
      <Button
        className="default-btn"
        onClick={() => {
          dispatch(deleteCompanyById({ id: currentCompany.id }));
        }}
      >
        delete
      </Button>
      <Button
        onClick={() => {
          dispatch(
            editCompany({
              id: currentCompany.id,
              body: { is_active: !currentCompany.is_active },
            })
          );
        }}
        className="default-btn"
      >
        {currentCompany.is_active ? "deactivate" : "activate"}
      </Button>
      <Button onClick={onClickShowInvoices} className="default-btn">
        show invoices
      </Button>
    </>
  );
};

Buttons.propTypes = {
  onClickShowInvoices: PropTypes.func.isRequired,
};

export default Buttons;

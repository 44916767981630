import cn from "classnames";
import "./DateInput.scss";

const DateInput = ({ error, isMobileVersion, ...props }) => (
  <input
    type="text"
    className={cn("goal-date-input", {
      "goal-date-input--mobile": isMobileVersion,
      "goal-date-input--error": !!error,
    })}
    autoComplete="off"
    {...props}
  />
);

DateInput.propTypes = {};

export default DateInput;

import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {deletePaymentAccount} from "../../../../../redux/companiesSlice";

const DeletePaymentAccount = () => {
  const dispatch = useDispatch();
  const last4 = useSelector(state => state.companies.last4);
  const cardHolderName = useSelector(state => state.companies.cardHolderName);
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <div className={"credit-card-form"}>
      <div className={"credit-card-form__input-wrapper"}>
        <label className={"credit-card-form__label form-label"}>
          card holder
        </label>
        <input
          type={"text"}
          className={"credit-card-form__input credit-card-form__input--single form-input"}
          disabled
          value={cardHolderName}
        />
      </div>
      <div className={"credit-card-form__input-wrapper"}>
        <label className={"credit-card-form__label form-label"}>
          card number
        </label>
        <input
          type={"text"}
          className={"credit-card-form__input credit-card-form__input--single form-input"}
          disabled
          value={`**** **** **** ${last4}`}
        />
      </div>
      <div className={"credit-card-form__footer"}>
        <button
          className={"btn default-btn btn--warning"}
          disabled={isSubmitting}
          onClick={() => {
            setIsSubmitting(true)
            dispatch(deletePaymentAccount({id: currentCompany.id})).finally(
              () => setIsSubmitting(false)
            );
          }}
        >
          delete
        </button>
      </div>
    </div>
  );
};

export default DeletePaymentAccount;

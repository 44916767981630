import "./Widget.scss";
import WidgetBody from "../WidgetBody";
import WidgetHeader from "../WidgetHeader";
import WidgetTitle from "../WidgetTitle";

const Widget = ({ children, className = "", ...props }) => (
  <div className={`widget ${className}`} {...props}>
    {children}
  </div>
);

Widget.Body = WidgetBody;
Widget.Header = WidgetHeader;
Widget.Title = WidgetTitle;

export default Widget;

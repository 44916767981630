import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";

import "./TrainingDay.scss";
import { useDispatch, useSelector } from "react-redux";

import Divider from "../../../../../components/Divider";
import { UTC_FORMAT_ON_BACKEND } from "../../../../../constants/common";
import { prepareLocalFancyDateForFront } from "../../../../../helpers";
import { saveDayNotes } from "../../../../../redux/programsSlice";
import { clientIdSelector } from "../../../../../redux/selectors";

import Workout from "./components/Workout/Workout";

// b moment - a moment
const dateCompare = (a, b) =>
  moment(b.date, UTC_FORMAT_ON_BACKEND).diff(
    moment(a.date, UTC_FORMAT_ON_BACKEND)
  );

const getCompletedSets = (data) =>
  data.workouts.reduce(
    (acc, w) => [
      ...acc,
      ...w.exercises.reduce(
        (acc, e) => [
          ...acc,
          ...e.sets.filter(({ is_completed }) => is_completed),
        ],
        []
      ),
    ],
    []
  );

const TrainingDay = ({ title, data, dayIdx, setRightWidgetPerformanceAndHistory }) => {
  const [newPbCount, setNewPbCount] = useState(0);
  const trueWorkouts = data.workouts.filter((w) => w.exercises.length);
  const dayTitle = trueWorkouts.length ? title : `${title} - rest day`;

  const dispatch = useDispatch();
  const clientId = useSelector(clientIdSelector);
  const numberOfPrograms = useSelector(state => state.programs.numberOfPrograms);
  const currentProgramIdx = useSelector(
    (state) => state.programs.currentProgramIdx
  );
  const notes = useSelector((state) => state.programs.currentProgram.notes);
  const [noteText, setNoteText] = useState(notes);
  const [note, setNote] = useState(
    notes?.find(({ day_index }) => day_index === dayIdx)
  );
  const [workoutsOpened, setWorkoutsOpened] = useState(0);

  useEffect(() => {
    setNote(notes?.find(({ day_index }) => day_index === dayIdx));
    setNoteText(note?.text || "");
  }, [notes, dayIdx, note?.text]);

  const onNotesChange = (e) => setNoteText(e.target.value);

  const handleBlur = () => {
    if (noteText === note?.text) return;

    const params = {
      id: clientId,
      body: {
        program_index: currentProgramIdx,
        note: {
          day_index: dayIdx,
          text: noteText,
        },
      },
      isLast: currentProgramIdx + 1 === numberOfPrograms,
    };
    dispatch(saveDayNotes(params)).then(
      (res) =>
        res.meta.requestStatus === "rejected" && setNoteText(note?.text || "")
    );
  };

  /** @param {boolean} isOpen */
  const handleToggleShowWorkout = (isOpen) => {
    setWorkoutsOpened(isOpen ? workoutsOpened + 1 : workoutsOpened - 1);
  }

  const completedSets = getCompletedSets(data);

  useEffect(() => {
    const newData = trueWorkouts.reduce((acc, w) => {
      return (
        acc +
        w.exercises.reduce((acc, e) => {
          return (
            acc +
            e.sets.reduce((acc, s) => {
              return acc + s.has_new_pb;
            }, 0)
          );
        }, 0)
      );
    }, 0);

    setNewPbCount(newData);
  }, [trueWorkouts]);

  return (
    <div className="training-day">
      <div className="training-day__header">
        <div className="training-day__title">{dayTitle}</div>
        <div className="training-day__date">
          {isEmpty(completedSets) ? null : (
            <>
              <span className="training-day__date-text">
                {prepareLocalFancyDateForFront(completedSets.sort(dateCompare)[0].date)}
                <span className="training-day__new-pb">
                  {newPbCount ? ` pb: ${newPbCount}` : null}
                </span>
              </span>
              <FontAwesomeIcon
                icon={faCheck}
                className={cn({"training-day__new-pb": newPbCount})}
              />
            </>
          )}
        </div>
      </div>
      {!trueWorkouts && <Divider />}
      {trueWorkouts
        ? trueWorkouts.map((workout, idx, arr) => (
          <Workout
            key={workout.id || idx}
            data={workout}
            dayIdx={dayIdx}
            workoutIdx={idx}
            isShowWorkoutPrefix={arr.length > 1}
            setRightWidgetPerformanceAndHistory={setRightWidgetPerformanceAndHistory}
            onToggleShow={handleToggleShowWorkout}
          />
        ))
        : null}
      {workoutsOpened ? (
        <div className="training-day__notes">
          <div className="training-day__notes-title">notes:</div>
          <textarea
            className="training-day__notes-textarea"
            rows="3"
            value={noteText}
            onChange={onNotesChange}
            onBlur={handleBlur}
          />
        </div>
      ) : null}
    </div>
  );
};

export default TrainingDay;

import lodash from "lodash";
import {
  INITIAL_FOOD_ENERGY,
  MIN_CALORIES_COEF_KG,
  MIN_CALORIES_COEF_LB,
} from "../constants/diet";
import {
  CARBS_COEFFICIENT,
  FATS_COEFFICIENT,
  PROTEINS_COEFFICIENT,
} from "../constants/foods";
import { KG } from "../constants/unit";

export const calculateCalories = (foodEnergy) => {
  return (
    foodEnergy.proteins * PROTEINS_COEFFICIENT +
    foodEnergy.carbs * CARBS_COEFFICIENT +
    foodEnergy.fats * FATS_COEFFICIENT
  );
};

export const calculateMinimumCalories = (totalCalories, unit, lastBody) => {
  let minimumCalories = 0,
    percentMinimumCalories = 0;
  if (!lodash.isEmpty(lastBody)) {
    const MIN_CALORIES_COEF =
      unit === KG ? MIN_CALORIES_COEF_KG : MIN_CALORIES_COEF_LB;
    const currentLeanMass =
      lastBody.body_weight - (lastBody.body_weight * lastBody.body_fat) / 100;
    minimumCalories = currentLeanMass * MIN_CALORIES_COEF;
    if (minimumCalories > 0)
      percentMinimumCalories = (totalCalories / minimumCalories) * 100;
  }
  return [minimumCalories, percentMinimumCalories];
};

export const calculateFoodEnergy = (foods) => {
  const result = foods.reduce((acc, item) => {
    const { quantity, food } = item;
    const fats = acc.fats + food.fats * quantity;
    const proteins = acc.proteins + food.proteins * quantity;
    const carbs = acc.carbs + food.carbs * quantity;
    return { fats, proteins, carbs };
  }, INITIAL_FOOD_ENERGY);

  return {
    proteins: result.proteins || 0,
    carbs: result.carbs || 0,
    fats: result.fats || 0,
  };
};

export const calculateFoodEnergyInPercent = (foodEnergy, totalCalories) => {
  return {
    proteins: (
      ((foodEnergy.proteins * PROTEINS_COEFFICIENT) / totalCalories) * 100 || 0
    ).toFixed(0),
    carbs: (
      ((foodEnergy.carbs * CARBS_COEFFICIENT) / totalCalories) * 100 || 0
    ).toFixed(0),
    fats: (
      ((foodEnergy.fats * FATS_COEFFICIENT) / totalCalories) * 100 || 0
    ).toFixed(0),
  };
};

export const prepareFoodsList = (list) =>
  list?.map((item) => ({
    id: item.id,
    name: item.name,
    unit: item.measure,
    protein: item.proteins.toFixed(3),
    carb: item.carbs.toFixed(3),
    fat: item.fats.toFixed(3),
    calories: calculateCalories(item).toFixed(3),
    owner: item.type === "global" ? "" : item.type,
  }));

import lodash from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  useRouteMatch,
  Switch,
  useParams,
  Redirect,
} from "react-router-dom";

import GuardedRoute from "../../components/GuardedRoute";
import Spinner from "../../components/Spinner";
import { CLIENT_INFO_TYPES } from "../../constants/clientApi";
import { USER_TYPES } from "../../constants/common";
import { CLIENT, ID_SLUG, HOME } from "../../constants/router";
import useIsUserType from "../../hooks/useIsUserType";
import { clearStatuses, getClientProgress } from "../../redux/clientSlice";
import { loadClientById } from "../../redux/clientSlice";

import ClientAddPage from "./ClientAddPage";
import ClientBodyPage from "./ClientBodyPage";
import ClientBodyTestResult from "./ClientBodyTestResult";
import ClientDietPage from "./ClientDietPage/";
import ClientInfoPage from "./ClientInfoPage";
import ClientListPage from "./ClientListPage";
import ClientProgressPage from "./ClientProgressPage";
import ClientTrainingEditPage from "./ClientTrainingEditPage";
import ClientTrainingPage from "./ClientTrainingPage";

const Client = () => {
  const match = useRouteMatch();
  const client = useSelector((state) => state.client?.client);
  const isHaveStrengthTest = useSelector(
    (state) => state.client?.has_actual_strength
  );
  const isClient = useIsUserType(USER_TYPES.CLIENT);
  return (
    <Switch>
      <Route exact path={`${match.url}`}>
        {isClient ? <Redirect to={HOME.root} /> : <ClientListPage />}
      </Route>
      <Route exact path={`${match.url}/${CLIENT.addClient}`}>
        {isClient ? <Redirect to={HOME.root} /> : <ClientAddPage />}
      </Route>
      <Route exact path={`${match.url}/${ID_SLUG}`}>
        <NeedsClientData infoType={CLIENT_INFO_TYPES.MAIN} key={1}>
          <GuardedRoute isShow={!lodash.isEmpty(client)} to={CLIENT.root}>
            <ClientInfoPage />
          </GuardedRoute>
        </NeedsClientData>
      </Route>
      <Route exact path={`${match.url}/${CLIENT.bodyTestResult}`}>
        <NeedsClientData
          infoType={CLIENT_INFO_TYPES.BODY}
          key={2}
          isNeedLastProgress
        >
          <GuardedRoute isShow={!lodash.isEmpty(client)} to={CLIENT.root}>
            <ClientBodyTestResult />
          </GuardedRoute>
        </NeedsClientData>
      </Route>
      <Route exact path={`${match.url}/${CLIENT.bodyClient}`}>
        <NeedsClientData infoType={CLIENT_INFO_TYPES.BODYMAIN} key={3}>
          <GuardedRoute isShow={!lodash.isEmpty(client)} to={CLIENT.root}>
            <ClientBodyPage />
          </GuardedRoute>
        </NeedsClientData>
      </Route>
      <Route exact path={`${match.url}/${CLIENT.diet}`}>
        <NeedsClientData infoType={CLIENT_INFO_TYPES.DIETS_PAGE_INIT} key={4}>
          <GuardedRoute isShow={!lodash.isEmpty(client)} to={match.url}>
            <ClientDietPage />
          </GuardedRoute>
        </NeedsClientData>
      </Route>
      <Route exact path={`${match.url}/${CLIENT.bodyProgress}`}>
        <NeedsClientData key={5}>
          <GuardedRoute
            isShow={!lodash.isEmpty(client) && isHaveStrengthTest}
            to={CLIENT.root}
          >
            <ClientProgressPage />
          </GuardedRoute>
        </NeedsClientData>
      </Route>
      <Route exact path={`${match.url}/${CLIENT.trainingProgram}`}>
        <NeedsClientData infoType="body" key={6}>
          <GuardedRoute
            exact
            path={`${match.url}/${CLIENT.trainingProgram}`}
            isShow={!lodash.isEmpty(client)}
            to={match.url}
          >
            <ClientTrainingPage />
          </GuardedRoute>
        </NeedsClientData>
      </Route>
      <Route exact path={`${match.url}/${CLIENT.trainingProgram}/edit`}>
        <NeedsClientData infoType={CLIENT_INFO_TYPES.TRAINING} key={7}>
          <GuardedRoute
            exact
            path={`${match.url}/${CLIENT.trainingProgram}/edit`}
            isShow={!lodash.isEmpty(client)}
            to={match.url}
          >
            <ClientTrainingEditPage />
          </GuardedRoute>
        </NeedsClientData>
      </Route>
    </Switch>
  );
};

const NeedsClientData = ({
  children,
  infoType,
  isNeedLastProgress = false,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isShowSpinner, setIsShowSpinner] = useState(true);
  useEffect(() => {
    async function fetchMyAPI() {
      dispatch(clearStatuses());
      await Promise.all([
        dispatch(loadClientById({ id, infoType })),
        isNeedLastProgress && dispatch(getClientProgress(id)),
      ]);
      setIsShowSpinner(false);
    }

    fetchMyAPI();
  }, []);
  if (isShowSpinner) {
    return <Spinner />;
  }
  return children;
};

export default Client;

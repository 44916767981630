import { isEmpty } from "lodash";
import moment from "moment";

import { UTC_FORMAT_ON_BACKEND } from "../constants/common";
import { coefficients } from "../constants/repCoefficients";
import {
  PB_DEFAULT_VALUE,
  MAX_PB_COEFFICIENT,
  MIN_PB_COEFFICIENT,
  PB_STEP_THRESHOLD,
  STEP_SMALL,
  STEP_BIG,
} from "../constants/training";

import { BasicHelper, incToCondition } from "./index";

/**
 * Calculate number of goal repetitions
 * @param {number} pb personal best
 * @param {number} weight
 * @param {string} repCalculator type of the coefficients to use
 * @returns {number} repetitions
 */
export const calculateGoalReps = (pb, weight, repCalculator) => {
  const maxReps = coefficients[repCalculator].length;
  if (!pb) return maxReps;
  return incToCondition(
    (reps) => reps >= maxReps || pb < calculateMax(weight, reps, repCalculator)
  );
};

export const getMergedId = (exerciseId, setId) => `${exerciseId}-${setId}`;

export const roundMax = (max) => BasicHelper.roundTo(max, max < 100);

export const stepCalculate = (pb) =>
  (pb || PB_DEFAULT_VALUE) <= PB_STEP_THRESHOLD ? STEP_SMALL : STEP_BIG;

export const calculateNearestMultipleOfNumber = (number, multiple) =>
  Math.round(number / multiple) * multiple;

export const calculateGoalWeight = (pb, reps, repCalculator) => {
  const notNullPb = pb || PB_DEFAULT_VALUE;
  const step = stepCalculate(notNullPb);
  const maxWeight = notNullPb * MAX_PB_COEFFICIENT;
  const minWeight = notNullPb * MIN_PB_COEFFICIENT;
  const start = calculateNearestMultipleOfNumber(minWeight, step);

  return incToCondition(
    (weight) =>
      weight >= maxWeight ||
      notNullPb < calculateMax(weight, reps, repCalculator),
    start,
    step
  );
};

/**
 *
 * @param {number} weight
 * @param {number} reps number of repetitions
 * @param {string} repCalculator type of the coefficients to use
 * @returns {number} calculated max
 */
export const calculateMax = (weight, reps, repCalculator) => {
  // const repCalc =
  //   repCalculator === "legPressExtension"
  //     ? REP_CALCULATOR_TYPES.LEG_PRESS_EXT
  //     : repCalculator;
  // const coeff = coefficients[repCalc][reps - 1];
  const coeff = coefficients[repCalculator][reps - 1];
  const value = weight * coeff;
  return roundMax(value) || 0;
};

/**
 * @param {number} currentPb current personal best
 * @param {number} weight weight as a percentage
 * @returns calculated current weight
 */
export const calculateWeight = (currentPb, weight) => {
  const pb = currentPb || PB_DEFAULT_VALUE;
  return Math.round((pb * weight) / 100);
};

/**
 *
 * @param {number} currentDayIdx
 * @param {number} currentWorkoutIdx
 * @param {number} currentExerciseIdx
 * @param {object} currentExercise
 * @param {number} currentSetIdx
 * @param {object} currentSet
 * @param {number} pb
 * @param {boolean} pbIsCalculated
 * @param {TrainingProgramTemplate} template
 * @return {ComposedSet}
 */
export const composeSetValues = (
  currentDayIdx,
  currentWorkoutIdx,
  currentExerciseIdx,
  currentExercise,
  currentSetIdx,
  currentSet,
  pb,
  pbIsCalculated,
  template
) => {
  const {
    weight,
    reps,
    date,
    notes,
    other_reps,
    other_reps_notes,
    is_completed,
  } = currentSet;
  const { rep_calculator, name, id } = currentExercise;

  const set =
    template.days[currentDayIdx].workouts[currentWorkoutIdx].exercises[
      currentExerciseIdx
    ].sets[currentSetIdx];

  const calculatedWeight = weight && (is_completed ? weight : calculateWeight(pb, set?.weight));
  const calculatedReps =
    reps ?? calculateGoalReps(pb, calculatedWeight, rep_calculator);

  return {
    values: {
      weight: calculatedWeight,
      reps: calculatedReps,
    },
    isCompleted: is_completed,
    id: currentSet.id,
    day: currentDayIdx + 1,
    workoutIndex: currentWorkoutIdx + 1,
    exerciseName: name,
    exerciseId: id,
    exerciseIndex: currentExerciseIdx + 1,
    repCalculator: rep_calculator,
    pb: pb ?? null,
    pbIsCalculated: pbIsCalculated ?? true,
    set: {
      number: currentExercise.sets.indexOf(currentSet) + 1,
      date,
      ...set,
    },
    notes,
    other_reps,
    other_reps_notes,
  };
};

/**
 *
 * @param {object} days
 * @param {number} currentDayIdx
 * @param prev get not next, but previous
 * @returns {number || false}
 */
export const getNextDayIdx = (days, currentDayIdx, prev = false) => {
  const isRestDay = (day) => !day.workouts.length;

  const prevDays = days.slice(0, currentDayIdx);
  const nextDays = days.slice(currentDayIdx + 1, days.length);
  const remainingDays = prev ? prevDays.reverse() : nextDays;

  const nextNotRestDay = remainingDays.find((d) => !isRestDay(d));
  return nextNotRestDay ? days.indexOf(nextNotRestDay) : false;
};

/**
 * @param {object} program
 * @returns {{ day, workout, exercise, set, isToday?: boolean } || false}
 */
export const findInitial = (program) => {
  const now = moment();

  const findRecentSet = (sets) =>
    sets.reduce(
      (acc, s) => {
        if (!s.is_completed) return acc;
        const age = now.diff(moment.utc(s.date, UTC_FORMAT_ON_BACKEND).local());
        return acc.age > age ? { set: s, age } : acc;
      },
      { set: null, age: Infinity }
    );

  const findRecentExercise = (exercises) =>
    exercises.reduce(
      (acc, e) => {
        if (isEmpty(e.sets)) return acc;
        const { set, age } = findRecentSet(e.sets);
        return acc.age > age ? { exercise: e, set, age } : acc;
      },
      { exercise: null, set: null, age: Infinity }
    );

  const findRecentWorkout = (workouts) =>
    workouts.reduce(
      (acc, w) => {
        if (isEmpty(w.exercises)) return acc;
        const { exercise, set, age } = findRecentExercise(w.exercises);
        return acc.age > age ? { workout: w, exercise, set, age } : acc;
      },
      { workout: null, exercise: null, set: null, age: Infinity }
    );

  const findRecentDay = (days) =>
    days.reduce(
      (acc, d) => {
        if (isEmpty(d.workouts)) return acc;
        const { workout, exercise, set, age } = findRecentWorkout(d.workouts);
        return acc.age > age ? { day: d, workout, exercise, set, age } : acc;
      },
      { day: null, workout: null, exercise: null, set: null, age: Infinity }
    );

  const recent = findRecentDay(program.days);
  if (!recent?.day) return false;

  const { day, workout, exercise, set, age } = recent;
  const isToday = now.diff(now.clone().startOf("day")) >= age;

  return { day, workout, exercise, set, isToday };
};

/**
 * @param {object} program
 * @param {object} selectedExerciseInfo
 * @param {number} selectedExerciseInfo.day_index
 * @param {number} selectedExerciseInfo.workout_index
 * @param {number} selectedExerciseInfo.exercise_index
 * @param {string} selectedExerciseInfo.exercise_id
 * @returns {boolean}
 */
export const hasSubsequentSimilarExercises = (program, selectedExerciseInfo) => {
  const {day_index, workout_index, exercise_index, exercise_id} = selectedExerciseInfo;
  for (let i = day_index; i < program.days.length; i++) {
    const day = program.days[i];
    for (let j = 0; j < day.workouts.length; j++) {
      const workout = day.workouts[j];
      for (let k = 0; k < workout.exercises.length; k++) {
        const exercise = workout.exercises[k];
        if (
          exercise.id === exercise_id && (
            i > day_index ||
            j > workout_index ||
            (
              j === workout_index &&
              k > exercise_index
            )
          )
        ) {
          return true;
        }
      }
    }
  }
  return false;
};

export const getEditExerciseModalMessage = (exerciseName) => (
  <>
    <div className="training-page__modal-title">Warning:</div>
    There are already instances of "{exerciseName}" in the program.
    Are you sure you want to create additional instances of "{exerciseName}"?
  </>
);

import { Box } from "@material-ui/core";
import { Form, Formik } from "formik";
import "./PermissionsForm.scss";
import { useDispatch, useSelector } from "react-redux";

import FormCheckbox from "../../../../../components/FormCheckbox";
import FormLabel from "../../../../../components/FormLabel";
import {
  CHECKBOX_LIST,
  CLIENT_PERM,
} from "../../../../../constants/clientInfo";
import { getDifferenceField } from "../../../../../helpers";
import { editMainInfoClient } from "../../../../../redux/clientSlice";

const PermissionsForm = () => {
  const dispatch = useDispatch();
  const { client, loading } = useSelector((state) => state.client);
  const checkboxState = {
    [CLIENT_PERM.CAN_CHANGE_EXERCISE]: client.can_change_exercise,
    [CLIENT_PERM.CAN_CHANGE_PB]: client.can_change_pb,
    [CLIENT_PERM.HIDE_DIET_HISTORY]: client.hide_diet_history,
    [CLIENT_PERM.HIDE_WORKOUT_HISTORY]: client.hide_workout_history,
  };
  return (
    <Formik
      initialValues={checkboxState}
      enableReinitialize={true}
      onSubmit={(values) => {
        const body = getDifferenceField(values, checkboxState);
        dispatch(editMainInfoClient({ id: client.id, body }));
      }}
    >
      {({}) => (
        <Form>
          <FormLabel>permissions:</FormLabel>
          <Box
            className="checkbox-list"
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            {CHECKBOX_LIST.map((checkbox) => (
              <FormCheckbox
                key={checkbox.name}
                label={checkbox.label}
                name={checkbox.name}
                disabled={loading}
                submitOnBlur={true}
              />
            ))}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

PermissionsForm.propTypes = {};

export default PermissionsForm;

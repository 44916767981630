import Tooltip from "rc-tooltip";
import { useState, useEffect, memo, forwardRef } from "react";
import "./TableTooltip.scss";

const TableTooltip = (
  {
    overlay,
    content,
    contentWrapperStyle,
    contentWrapperClassName,
    hasOverflowingContent,
    contentClassName,
  },
  ref
) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);

  const isVisible =
    isHovering &&
    (!hasOverflowingContent || (isTextOverflowing && hasOverflowingContent));

  useEffect(() => {
    function compareSize() {
      if (ref.current) {
        const { scrollWidth, clientWidth } = ref.current;
        setIsTextOverflowing(scrollWidth > clientWidth);
      }
    }

    if (hasOverflowingContent) {
      compareSize();
      window.addEventListener("resize", compareSize);
    }

    return () => {
      if (hasOverflowingContent) {
        window.removeEventListener("resize", compareSize);
      }
    };
  }, []);

  const onEnter = () => setIsHovering(true);
  const onLeave = () => setIsHovering(false);

  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      placement="top"
      overlayClassName="table-tooltip"
      visible={isVisible}
      overlay={overlay}
      delay={0}
      overlayStyle={{
        visibility: isVisible ? "visible" : "hidden",
        position: "absolute",
      }}
    >
      <div
        style={{ ...contentWrapperStyle }}
        className={contentWrapperClassName}
        onMouseUp={onEnter}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        onTouchStart={onEnter}
        onTouchEnd={onLeave}
      >
        {hasOverflowingContent && <span ref={ref} className={contentClassName}>{content}</span>}
        {!hasOverflowingContent && content}
      </div>
    </Tooltip>
  );
};

/*TableTooltip.propTypes = {
  overlay: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
  contentWrapperStyle: PropTypes.object,
  contentWrapperClassName: PropTypes.string,
  hasOverflowingContent: PropTypes.bool,
};

TableTooltip.defaultProps = {
  hasOverflowingContent: false,
};*/

export default memo(forwardRef(TableTooltip));

import { Route, useRouteMatch } from "react-router-dom";

import ExercisesPage from "./ExercisesPage";

const Exercises = () => {
  const match = useRouteMatch();

  return (
    <Route exact path={`${match.url}`}>
      <ExercisesPage />
    </Route>
  );
};

export default Exercises;

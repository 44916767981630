import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../../../components/Button";
import Divider from "../../../../../../components/Divider";
import Widget from "../../../../../../components/widget/Widget";
import WidgetBody from "../../../../../../components/widget/WidgetBody";
import WidgetHeader from "../../../../../../components/widget/WidgetHeader";
import WidgetTitle from "../../../../../../components/widget/WidgetTitle";

import "./DietTemplatesEditWindow.scss";
import {
  clearRemovableItem,
  deleteDietTemplates,
} from "../../../../../../redux/dietTemplatesSlice";

const DietTemplatesEditWindow = () => {
  const dispatch = useDispatch();
  const removableItem = useSelector(
    (state) => state.dietTemplates.removableItem
  );

  useEffect(() => {
    return () => dispatch(clearRemovableItem());
  }, []);

  const formik = useFormik({
    initialValues: {
      template: removableItem.name,
    },
    onSubmit: () => {
      dispatch(deleteDietTemplates(removableItem.id));
    },
  });

  return (
    <Widget className="diet-template">
      <WidgetHeader>
        <WidgetTitle>delete template</WidgetTitle>
      </WidgetHeader>
      <WidgetBody className="diet-template__widget-body">
        <div className="foods-block" style={{ paddingBottom: "10px" }}>
          <form className="diet-template__form" onSubmit={formik.handleSubmit}>
            <div className="diet-template__input-wrapper">
              <label className="field-food-label">template name</label>
              <input
                className="foods-input"
                name="template"
                disabled
                value={formik.values.template}
                onChange={formik.handleChange}
              />
            </div>
            <div />
            <Divider />
            <Button type="submit" className="confirm-delete">
              confirm delete
            </Button>
          </form>
        </div>
      </WidgetBody>
    </Widget>
  );
};

export default DietTemplatesEditWindow;

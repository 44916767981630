import { Form, Formik } from "formik";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ACTIVE_USER_BODY_TEST_FIELDS,
  ALL_BODY_TEST_FIELDS,
  BODY_TEST_FIELDS_FEMALE,
  BODY_TEST_FIELDS_MALE,
  NOTE_OPTIONS,
  PERMANENT_BODY_TEST_FIELDS,
  TABS_STATE,
  BODY_TEST_FIELDS_NAME, BODY_TEST_SENT_MESSAGE,
} from "../../constants/bodyTestForm";
import { ALCO_OPTIONS, COMPLIANCE_OPTIONS } from "../../constants/clientForm";
import { TOASTR_TYPE } from "../../constants/toastr";
import { isFemale } from "../../helpers";
import { validateBodyWeight, validateRequired } from "../../helpers/validators";
import { useToastr } from "../../hooks/useToastr";
import {
  clearSuccessfullySendBodyTest,
  clearError,
  sendBodyTest, getClientProgress,
} from "../../redux/clientSlice";
import Divider from "../Divider";
import FormErrorAlert from "../FormErrorAlert";
import FormField from "../FormField/FormField";
import NoteBlock from "../NoteBlock";
import Tab from "../tab/Tab";
import TabItem from "../tab/TabItem";
import Widget from "../widget/Widget";
import WidgetBody from "../widget/WidgetBody";
import WidgetHeader from "../widget/WidgetHeader";
import WidgetTitle from "../widget/WidgetTitle";
import _ from "lodash";

import "./ClientsBodyTest.scss";

const getFieldsForShow = (currentItems, isFemale) => {
  return isFemale
    ? BODY_TEST_FIELDS_FEMALE[currentItems]
    : BODY_TEST_FIELDS_MALE[currentItems];
};

const getBodyInfoField = (body, field, unit) => {
  if (body?.[field]) {
    return `prev:${body[field]} ${unit}`;
  }
  return "";
};

const isToday = (someDate) => {
  return moment(someDate).isSame(moment(), "day");
}

const ClientsBodyTest = () => {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.client.client);
  const successfullySendBodyTest = useSelector((state) => state.client.successfullySendBodyTest);
  const error = useSelector((state) => state.client.error);
  const body = useSelector((state) => state.client.body);
  const loading = useSelector((state) => state.client.loading);
  const [tab, setTab] = useState(TABS_STATE);

  useToastr({
    messages: BODY_TEST_SENT_MESSAGE,
    deps: successfullySendBodyTest,
    cb: () => {
      dispatch(clearSuccessfullySendBodyTest());
    }
  });

  useToastr({
    messages: error,
    deps: error,
    type: TOASTR_TYPE.ERROR,
    cb: () => {
      dispatch(clearError());
    }
  });

  // all fields that can be in the form
  let allField = ALL_BODY_TEST_FIELDS;
  // fields we are showing now
  const fieldsForShow = getFieldsForShow(tab.currentItems, isFemale(client));
  // fields that we always show
  const alwaysShowFields = Object.keys(PERMANENT_BODY_TEST_FIELDS);

  const initialValues = useMemo(() => {
    if (!body || !isToday(body.date)) {
      return allField;
    }

    const baseFieldsObject = alwaysShowFields.reduce((acc, el) => {
      acc[el] =  body[el] || "";
      return acc;
    }, {});

    return Object.assign(
      {
        ...allField,
        ...baseFieldsObject,
        ...(client.is_active && {
          alcohol: body.alcohol || ALCO_OPTIONS[0].value,
          compliance: _.isNumber(body.compliance) ? body.compliance : COMPLIANCE_OPTIONS[0].value
        }),
      },
      ...Object.entries(fieldsForShow).map(([key]) => ({
        [key]: body[key] || "",
      }))
    );
  }, [allField, body, client.is_active, fieldsForShow]);

  const changeTabItems = (item) => {
    const newTabItems = { items: [], currentItems: item.value };
    newTabItems.items = tab.items.map((i) => {
      i.isActive = i.id === item.id;
      return i;
    });
    setTab(newTabItems);
  };

  return (
    <Widget>
      <WidgetHeader>
        <WidgetTitle>body test</WidgetTitle>
      </WidgetHeader>
      <WidgetBody>
        <Tab>
          {tab.items.map((item) => (
            <TabItem
              item={item}
              onClick={changeTabItems}
              isActive={item.value === tab.currentItems}
              key={item.value}
            />
          ))}
        </Tab>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values) => {
            const dataForSend = {};
            Object.keys(values).map((key) => {
              if (key in fieldsForShow || key in PERMANENT_BODY_TEST_FIELDS) {
                if (values[key] === "" && key !== BODY_TEST_FIELDS_NAME.NOTES) {
                  return;
                }
                dataForSend[key] = values[key];
                return;
              }
              if (key in ACTIVE_USER_BODY_TEST_FIELDS) {
                if (client.is_active) {
                  dataForSend[key] = values[key];
                }
              }
            });
            dataForSend.body_test_type = tab.currentItems;
            await dispatch(sendBodyTest({ body: dataForSend, id: client.id }));
            await dispatch(getClientProgress(client.id));
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <FormField
                info={getBodyInfoField(body, "body_weight", client.mass_unit)}
                label="bodyweight"
                name="body_weight"
                type="number"
                validate={(value) =>
                  validateBodyWeight(value, client.mass_unit)
                }
                isInline={true}
                inputDivClass="w-60"
                allowFloatToFixedOne={true}
                disabled={loading}
              />
              {Object.keys(fieldsForShow).map((field) => {
                const filedState = fieldsForShow[field];
                return (
                  <FormField
                    key={field}
                    info={getBodyInfoField(body, field, filedState.unit  || "")}
                    label={filedState?.label}
                    name={field}
                    options={filedState?.options}
                    validate={filedState.validate}
                    type={filedState.type}
                    as={filedState.as}
                    inputDivClass={filedState.inputDivClass}
                    inputClassDefault={filedState?.inputClassDefault}
                    inputClassError={filedState?.inputClassError}
                    isInline={true}
                    allowFloatToFixedOne={true}
                    disabled={loading}
                  />
                );
              })}
              {client.is_active && (
                <FormField
                  label="dietary compliance"
                  name="compliance"
                  type="select"
                  as="select"
                  inputClassDefault="form-select w-100"
                  validate={validateRequired}
                  inputClassError=""
                  isInline={true}
                  options={COMPLIANCE_OPTIONS}
                  inputDivClass="w-40"
                  disabled={loading}
                />
              )}
              {client.is_active && (
                <FormField
                  label="alcohol"
                  name="alcohol"
                  type="select"
                  as="select"
                  inputClassDefault="form-select w-100"
                  validate={validateRequired}
                  inputClassError=""
                  isInline={true}
                  options={ALCO_OPTIONS}
                  inputDivClass="w-40"
                  disabled={loading}
                />
              )}
              <FormField
                label="notes"
                inputClassDefault="form-input mb-20"
                name="notes"
                component="textarea"
                className="textarea-resize-vertical"
                rows={5}
                disabled={loading}
              />
              {Object.keys(errors).map((field, index) => (
                <FormErrorAlert
                  key={index}
                  isShow={
                    touched[field] &&
                    (field in fieldsForShow ||
                      field in PERMANENT_BODY_TEST_FIELDS)
                  }
                  errorText={errors[field]}
                />
              ))}
              <Divider />
              <button
                type="submit"
                className="default-btn body-test-btn"
                disabled={loading}
              >
                save
              </button>
              <NoteBlock>
                <>
                  <p>
                    note: different bodyfat test equations/methods will result
                    in different bodyfat percentages. to reliably measure body
                    composition change, always use the same test, with the same
                    test equipment and tester.
                  </p>
                  <p>{NOTE_OPTIONS[tab.currentItems]}</p>
                </>
              </NoteBlock>
            </Form>
          )}
        </Formik>
      </WidgetBody>
    </Widget>
  );
};

ClientsBodyTest.propTypes = {};

export default ClientsBodyTest;

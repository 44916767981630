import { baseUrl } from "../env/env";
import withCache from "../helpers/cache";

import myFetch from "./myFetch";

export const loadProgramTemplates = () => {
  return myFetch(`${baseUrl}/api/program-templates`);
};

export const loadProgramTemplatesWithCache = withCache(loadProgramTemplates);

export const loadProgramTemplatesForCurrentUser = () => {
  return myFetch(`${baseUrl}/api/program-templates/current_user`);
};

export const loadProgramTemplatesForCurrentUserWithCache = withCache(
  loadProgramTemplatesForCurrentUser
);

export const loadCurrentProgramTemplate = (id) => {
  return myFetch(`${baseUrl}/api/program-templates/${id}`);
};

export const loadCurrentProgramTemplateWithCache = withCache(
  loadCurrentProgramTemplate
);

export const addProgramTemplate = ({ body }) => {
  loadProgramTemplatesWithCache.invalidate();
  loadProgramTemplatesForCurrentUserWithCache.invalidate();
  return myFetch(`${baseUrl}/api/program-templates`, {
    method: "POST",
    body: body,
  });
};

export const editProgramTemplate = ({ id, body }) => {
  loadProgramTemplatesWithCache.invalidate();
  loadProgramTemplatesForCurrentUserWithCache.invalidate();
  loadCurrentProgramTemplateWithCache.invalidateByArgs(id);
  return myFetch(`${baseUrl}/api/program-templates/${id}`, {
    method: "PUT",
    body: body,
  });
};

export const deleteProgramTemplate = (id) => {
  loadProgramTemplatesWithCache.invalidate();
  loadProgramTemplatesForCurrentUserWithCache.invalidate();
  loadCurrentProgramTemplateWithCache.invalidateByArgs(id);
  return myFetch(`${baseUrl}/api/program-templates/${id}`, {
    method: "DELETE",
  });
};

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ClientsForm from "../../../components/ClientsForm/ClientsForm";
import Spinner from "../../../components/Spinner";
import Widget from "../../../components/widget/Widget";
import { USER_TYPES } from "../../../constants/common";
import { BODY_PAGE_SLUG, CLIENT } from "../../../constants/router";
import { TOASTR_TYPE } from "../../../constants/toastr";
import { clearEmptyField, prepareClientDataForSend } from "../../../helpers";
import { useToastr } from "../../../hooks/useToastr";
import "./ClientAddPage.scss";
import {
  clearClient,
  addClient,
  clearSuccessfullyClientAdded,
  clearError,
} from "../../../redux/clientSlice";
import { loadCompaniesPicker } from "../../../redux/companiesSlice";
import { clearTrainers } from "../../../redux/trainersSlice";

import useIsUserType from "./../../../hooks/useIsUserType";

const ClientAddPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isCompaniesLoaded = useSelector(
    (state) => state.companies.successfullyGettingCompanyPicker
  );
  const loading = useSelector((state) => state.companies.loading);
  const client = useSelector((state) => state.client.client);
  const isSuperUser = useIsUserType(USER_TYPES.SUPERUSER);
  const error = useSelector((state) => state.client.error);
  const successfullyClientAdded = useSelector(
    (state) => state.client.successfullyClientAdded
  );

  useEffect(() => {
    dispatch(clearClient());
    dispatch(clearTrainers());
    if (!isCompaniesLoaded && isSuperUser) {
      dispatch(loadCompaniesPicker());
    }
  }, []);
  useToastr({
    messages: "Client added",
    deps: successfullyClientAdded,
    cb: () => {
      history.push(`${CLIENT.root}${client.id}${BODY_PAGE_SLUG}`);
      dispatch(clearSuccessfullyClientAdded());
    },
  });
  useToastr({
    messages: error,
    deps: error,
    type: TOASTR_TYPE.ERROR,
    cb: () => dispatch(clearError()),
  });

  const createClient = (values) => {
    let sendData = prepareClientDataForSend({ ...values });
    sendData = clearEmptyField(sendData);
    dispatch(addClient(sendData));
  };

  return (
    <div className="content-rhs content">
      <div className="left-widget__wrapper">
        <Widget>
          <Widget.Header>
            <Widget.Title>add new client</Widget.Title>
          </Widget.Header>
          <Widget.Body>
            {loading && <Spinner />}
            {!loading && (isCompaniesLoaded || !isSuperUser) && (
              <ClientsForm onSubmit={createClient} />
            )}
          </Widget.Body>
        </Widget>
      </div>
    </div>
  );
};

ClientAddPage.propTypes = {};

export default ClientAddPage;

import cn from "classnames";
import { ErrorMessage, Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import Button from "../../../../../components/Button";
import FormField from "../../../../../components/FormField";
import { FORM_ERRORS, USER_TYPES } from "../../../../../constants/common";
import { trainerFormVariants } from "../../../../../constants/trainers";
import {
  prepareSelectOptions,
  trainerSchema,
} from "../../../../../helpers/trainers";
import "./TrainerForm.scss";
import { loadCompaniesPicker } from "../../../../../redux/companiesSlice";
import { editTrainer } from "../../../../../redux/trainersSlice";

import useIsUserType from "./../../../../../hooks/useIsUserType";

const TrainerForm = ({ initialValues, onSubmit, variant, isEditPage }) => {
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.companies.companiesPicker);
  const currentTrainer = useSelector((state) => state.trainers.currentTrainer);
  const formChangeLoading = useSelector((state) => state.trainers.formChangeLoading);
  const user = useSelector((state) => state.user.user);
  const companiesOptions = prepareSelectOptions(companies);
  const isCompaniesLoaded = useSelector(
    (state) => state.companies.successfullyGettingCompanyPicker
  );
  const isCompanyAdmin = useIsUserType(USER_TYPES.COMPANY_ADMIN);
  const isSuperUser = useIsUserType(USER_TYPES.SUPERUSER);
  const { error } = useSelector((state) => state.trainers);

  const init = { ...initialValues };
  if (isCompanyAdmin) {
    init.company = user.company;
  }

  const validationSchema = yup
    .object()
    .shape(
      variant === trainerFormVariants.DELETE ? {} : trainerSchema(isEditPage)
    );

  useEffect(() => {
    if (!isCompaniesLoaded && isSuperUser) {
      dispatch(loadCompaniesPicker());
    }
  }, []);

  const toggleActiveStatus = () => {
    dispatch(
      editTrainer({
        id: currentTrainer.id,
        body: { is_active: !currentTrainer.is_active },
      })
    );
  };

  const isActive = currentTrainer?.is_active;

  return (
    <Formik
      initialValues={init}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ errors, setFieldError }) => {
        if (!errors.email && error === FORM_ERRORS.EMAIL_ALREADY_USED) {
          setFieldError("email", FORM_ERRORS.EMAIL_ALREADY_USED);
        }
        return (
          <Form className="trainer-form">
            <FormField
              label="first name"
              name="first_name"
              inputDivClass="trainer-form__field"
              disabled={variant === trainerFormVariants.DELETE}
            />
            <FormField
              label="last name"
              name="last_name"
              inputDivClass="trainer-form__field"
              disabled={variant === trainerFormVariants.DELETE}
            />
            {!isCompanyAdmin && (
              <FormField
                label="company:"
                name="company"
                as="select"
                type="select"
                options={companiesOptions}
                className="trainer-form__field--horizontal"
                isInline
                disabled={variant === trainerFormVariants.DELETE}
              />
            )}
            <FormField
              label="phone number:"
              name="phone"
              className="trainer-form__field--horizontal"
              isInline
              disabled={variant === trainerFormVariants.DELETE}
            />
            <ErrorMessage
              component="div"
              name="phone"
              className="auth-error"
            />
            <FormField
              label="email:"
              name="email"
              type="email"
              className="trainer-form__field--horizontal"
              isInline
              disabled={variant === trainerFormVariants.DELETE}
            />
            {variant !== trainerFormVariants.DELETE && (
              <FormField
                label="password:"
                name="password"
                type="password"
                className="trainer-form__field--horizontal"
                isInline
              />
            )}
            <div
              className={cn("trainer-form__button-wrapper", {
                "trainer-form__button-wrapper--one-button":
                  variant === trainerFormVariants.CREATE ||
                  variant === "delete",
                "trainer-form__button-wrapper--two-buttons":
                  variant === trainerFormVariants.UPDATE,
              })}
            >
              {variant === trainerFormVariants.CREATE && (
                <Button type="submit" disabled={formChangeLoading}>save</Button>
              )}
              {variant === trainerFormVariants.UPDATE && (
                <>
                  {isActive === undefined ? (
                    <div />
                  ) : (
                    <Button
                      disabled={formChangeLoading}
                      variant={isActive ? "warning" : "primary"}
                      onClick={toggleActiveStatus}
                    >
                      {isActive ? "deactivate" : "activate"}
                    </Button>
                  )}
                  <Button type="submit"  disabled={formChangeLoading}>save</Button>
                </>
              )}
              {variant === trainerFormVariants.DELETE && (
                <Button variant="warning" type="submit"  disabled={formChangeLoading}>
                  confirm delete
                </Button>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

TrainerForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  variant: PropTypes.string.isRequired,
  isEditPage: PropTypes.bool.isRequired,
};

TrainerForm.defaultProps = {
  isEditPage: false,
  initialValues: {
    first_name: "",
    last_name: "",
    company: "",
    phone: "",
    email: "",
    password: "",
  },
};

export default TrainerForm;

import { FOODS_SIDEBAR_NAMES } from "./foods";

export const ADD_TEMPLATE_SUCCESS = "The diet template has been added";
export const DELETE_TEMPLATE_SUCCESS = "The diet template has been deleted";
export const ADD_FOOD_SUCCESS = "food has been added";
export const EDIT_FOOD_SUCCESS = "food has been edited";
export const DELETE_FOOD_SUCCESS = "food has been deleted";

// These coefficients were provided by client.
// To convert kg to lb the mass should be multiplied by 2.2
// but the coefficient should be divided by 2.2
// So in this case: 29.3333 / 2.2 = 13.3333
export const MIN_CALORIES_COEF_KG = 29.3333;
export const MIN_CALORIES_COEF_LB = 13.3333;

export const FOR_DAYS_OPTIONS = [
  { key: 0, label: "for 1 day", value: "1" },
  { key: 1, label: "for 2 days", value: "2" },
  { key: 2, label: "for 3 days", value: "3" },
  { key: 3, label: "for 4 days", value: "4" },
  { key: 4, label: "for 5 days", value: "5" },
  { key: 5, label: "for training days", value: "training" },
  { key: 6, label: "for non-training days", value: "non-training" },
];

export const MIN_MEAL_COLUMN_COUNT = 5;

export const MAX_MEAL_COLUMN_COUNT = 8;

export const MAX_FOODS_PREVIEW = 25;

export const INITIAL_FOOD_ENERGY = {
  proteins: 0,
  carbs: 0,
  fats: 0,
};

export const INITIAL_MEALS_STATE = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];

export const INITIAL_FOR_DAYS = "1";

export const INITIAL_QUANTITY = 1;

export const INITIAL_WO = false;

export const TRAINING = "training";

export const NON_TRAINING = "non-training";

export const SIDEBAR_NAMES = {
  FOODS: "foods",
  DIET_HISTORY: "diet history",
  MEALS: "meals",
  SAVE_DIET: "add template",
  TEMPLATES: "templates",
  ...FOODS_SIDEBAR_NAMES,
};

export const DEFAULT_SUCCESS_MESSAGE = "Diet info saved";

export const TEMPLATE_ADDED_MESSAGE = "The diet template has been added";

export const DIET_PREVIEW_ITEMS = 6;

export const FOOD_PREVIEW_ITEMS = 25;

export const DIET_ITEM_DISABLED_COLOR = "#555"

export const SHOW_ALL_FOODS_INIT = [true, true];

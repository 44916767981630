import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { USER_TYPES } from "../../../../../constants/common";
import useIsUserType from "../../../../../hooks/useIsUserType";
import { onChangeNotes } from "../../../../../redux/dietPageSlice";

const DietNotes = () => {
  const dispatch = useDispatch();
  const diets = useSelector((state) => state.dietPage.diets);
  const isClient = useIsUserType(USER_TYPES.CLIENT);
  return (
    <div className="diet-main-notes">
      <textarea
        value={diets[0]?.notes}
        className="diet__input textarea-resize-vertical"
        onChange={(event) => {
          dispatch(onChangeNotes(event.target.value));
        }}
        rows="5"
        placeholder="notes..."
        disabled={isClient}
      />
    </div>
  );
};

export default memo(DietNotes);

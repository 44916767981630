import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import Spinner from "../../../../../components/Spinner";
import Widget from "../../../../../components/widget/Widget";
import { SIDEBAR_NAMES } from "../../../../../constants/diet";

import BodyFoods from "./components/BodyFoods";

const DietFoodsSideBar = ({ setSideBar }) => {
  const loading = useSelector((state) => state.foods.loading);
  const success = useSelector((state) => state.foods.success);
  const [search, setSearch] = useState("");

  return (
    <Widget className="diet__widget-wrapper">
      <Widget.Header className="widget-head">
        <Widget.Title>foods</Widget.Title>
      </Widget.Header>
      <Widget>
        <Widget.Body>
          <div className="diet-side-ch">
            <table style={{ width: "100%" }} className="dietdropdown-list">
              <thead>
                <tr>
                  <td colSpan="2">
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      type="text"
                      className="diet__input"
                      placeholder="search.."
                      style={{
                        margin: "0px 5px 2px 5px",
                        width: "90%",
                        background: "rgba(255, 255, 255, 0.1",
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ float: "left", width: "70%" }}>food name</td>
                  <td style={{ float: "left", width: "18%" }}>unit</td>
                  <td style={{ float: "left", width: "6%" }} />
                  <td style={{ float: "left", width: "6%" }} />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    onClick={() => setSideBar(SIDEBAR_NAMES.FOOD_ADD)}
                    colSpan="2"
                    style={{
                      cursor: "pointer",
                      color: "rgba(255, 255, 255, 0.6)",
                      padding: "5px 0px 5px 0px",
                    }}
                  >
                    add new food...
                  </td>
                </tr>
                {!loading && success && (
                  <BodyFoods setSideBar={setSideBar} search={search} />
                )}
              </tbody>
            </table>
            {loading && !success && <Spinner />}
          </div>
        </Widget.Body>
      </Widget>
    </Widget>
  );
};

DietFoodsSideBar.propTypes = {
  setSideBar: PropTypes.func.isRequired,
};

export default DietFoodsSideBar;

import "./StrengthTest.scss";
import { Box } from "@material-ui/core";
import { Formik, Form } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DATE_FORMAT_ON_BACKEND } from "../../constants/common";
import {
  ACTUAL_STRENGTH_TEST_FIELDS,
  ESTIMATE_STRENGTH_TEST_FIELDS,
  STRENGTH_TEST_SENT_MESSAGE,
} from "../../constants/strengthTestForm";
import { TOASTR_TYPE } from "../../constants/toastr";
import { clearEmptyField, isFemale, isObjectFulfilled } from "../../helpers";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useToastr } from "../../hooks/useToastr";
import ModalWindow from "../../pages/Clients/ClientTrainingPage/components/ModalWindow";
import {
  clearSuccessfullySendStrengthTest,
  getClientProgress,
  sendStrengthTest,
  updateClientActiveHistory,
  sendDoubleStrengthTest,
  clearError,
} from "../../redux/clientSlice";
import { setModal } from "../../redux/utilsSlice";
import Divider from "../Divider";
import FormErrorAlert from "../FormErrorAlert";
import FormField from "../FormField";
import FormLabel from "../FormLabel";
import Widget from "../widget/Widget";
import WidgetBody from "../widget/WidgetBody";
import WidgetHeader from "../widget/WidgetHeader";
import WidgetTitle from "../widget/WidgetTitle";

import StrengthTestField from "./StrengthTestField";

const isEstimateStrengthFulfilled = (values) => {
  const estimateStrength = {
    bench: values.estimateBench,
    deadlift: values.estimateDeadlift,
    squat: values.estimateSquat,
  };
  return isObjectFulfilled(estimateStrength);
};

const isActualStrengthFulfilled = (values) => {
  const actualStrength = {
    bench: values.benchMax,
    deadlift: values.deadliftMax,
    squat: values.squatMax,
  };
  return isObjectFulfilled(actualStrength);
};

const isEstimateStronger = (estimateStrength, actualStrength) =>
  estimateStrength.bench > actualStrength.bench ||
  estimateStrength.deadlift > actualStrength.deadlift ||
  estimateStrength.squat > actualStrength.squat;

const STRENGTH_TYPES = {
  ESTIMATE: "estimate",
  ACTUAL: "actual",
};

const prepareData = (estimateStrength, actualStrength, isActive) => {
  estimateStrength = clearEmptyField(estimateStrength);
  actualStrength = clearEmptyField(actualStrength);

  const isEstimateData = Object.keys(estimateStrength).length === 3;
  const isActualData = Object.keys(actualStrength).length === 3;

  const strengthData = {
    isNeedActivateClient: false,
    date: moment().format(DATE_FORMAT_ON_BACKEND),
  };

  if (isActualData) {
    strengthData.isNeedActivateClient = !isActive;
    strengthData.actualData = {
      strength_type: STRENGTH_TYPES.ACTUAL,
      ...actualStrength,
    };

    if (
      isEstimateData &&
      isEstimateStronger(estimateStrength, actualStrength)
    ) {
      strengthData.estimateData = {
        strength_type: STRENGTH_TYPES.ESTIMATE,
        ...estimateStrength,
      };
    }

    return strengthData;
  }

  strengthData.estimateData = {
    strength_type: STRENGTH_TYPES.ESTIMATE,
    ...estimateStrength,
  };

  return strengthData;
};

const StrengthTest = () => {
  useDocumentTitle("recomposition");
  const dispatch = useDispatch();
  const formikRef = useRef();
  const client = useSelector((state) => state.client.client);
  const loadingStrength = useSelector((state) => state.client.loadingStrength);
  const modal = useSelector((state) => state.utils.modal);
  const successfullySendStrengthTest = useSelector(
    (state) => state.client.successfullySendStrengthTest
  );
  const error = useSelector((state) => state.client.error);
  const has_actual_strength = useSelector(
    (state) => state.client.has_actual_strength
  );
  const confirmText = `Please confirm you want to activate this client and add ${
    isFemale(client) ? "her" : "him"
  } to the payment schedule?`;
  const [dataForSend, setDataForSend] = useState(null);

  const sendActualData = useCallback(async () => {
    const { estimateData, actualData } = dataForSend;
    if (actualData) {
      if (estimateData) {
        await dispatch(
          sendDoubleStrengthTest({ id: client.id, actualData, estimateData })
        );
      } else {
        await dispatch(sendStrengthTest({ id: client.id, body: actualData }));
      }
    } else {
      await dispatch(sendStrengthTest({ id: client.id, body: estimateData }));
    }
    await dispatch(getClientProgress(client.id));
  }, [client.id, dataForSend, dispatch]);

  useToastr({
    messages: STRENGTH_TEST_SENT_MESSAGE,
    deps: successfullySendStrengthTest,
    cb: () => {
      dispatch(clearSuccessfullySendStrengthTest());
    },
  });
  useToastr({
    messages: error,
    deps: error,
    type: TOASTR_TYPE.ERROR,
    cb: () => dispatch(clearError()),
  });

  useEffect(() => {
    if (dataForSend) {
      const sendData = async () => {
        const { isNeedActivateClient } = dataForSend;
        if (isNeedActivateClient) {
          dispatch(setModal(confirmText));
        } else {
          await sendActualData();
          formikRef.current?.resetForm();
        }
      };
      sendData();
    }
  }, [dataForSend]);

  return (
    <>
      {modal && (
        <ModalWindow
          modal={modal}
          close={async () => {
            await sendActualData();
            dispatch(setModal(null));
          }}
          confirm={async () => {
            await sendActualData();
            const body = { is_active: true };
            await dispatch(updateClientActiveHistory({ id: client.id, body }));
            dispatch(setModal(null));
          }}
          isQuestion={true}
        />
      )}
      <Widget>
        <WidgetHeader>
          <WidgetTitle>strength test</WidgetTitle>
        </WidgetHeader>
        <WidgetBody>
          <Formik
            validateOnBlur
            initialValues={{
              bench: "",
              repsBench: "1",
              deadlift: "",
              repsDeadlift: "1",
              squat: "",
              repsSquat: "1",
              estimateBench: "",
              estimateDeadlift: "",
              estimateSquat: "",
            }}
            onSubmit={async (values) => {
              const estimateStrength = {
                bench: values.estimateBench,
                deadlift: values.estimateDeadlift,
                squat: values.estimateSquat,
              };
              const actualStrength = {
                bench: values.benchMax,
                deadlift: values.deadliftMax,
                squat: values.squatMax,
              }
              const data = prepareData(
                estimateStrength,
                actualStrength,
                client.is_active,
              );
              setDataForSend(data);
            }}
            innerRef={formikRef}
          >
            {({ errors, touched, values }) => (
              <Form className="strength-test__form">
                <FormLabel className="strength-test__form__label">
                  actual strength
                </FormLabel>
                {ACTUAL_STRENGTH_TEST_FIELDS.map((field) => {
                  const isNotRequiredThisTest = isEstimateStrengthFulfilled(values);
                  return (
                    <StrengthTestField
                      values={values}
                      name={field.name}
                      validate={(value) =>
                        isNotRequiredThisTest
                          ? field.validate(value, client.mass_unit)
                          : field.validateRequired(value, client.mass_unit)
                      }
                      validateAmount={
                        isNotRequiredThisTest
                          ? field.validateAmount
                          : field.validateRequiredAmount
                      }
                      key={field.name}
                      unit={client.mass_unit}
                    />
                  );
                })}
                {!has_actual_strength && (
                  <>
                    <div className="mb-40" />
                    <FormLabel className="strength-test__form__label">
                      estimate strength
                    </FormLabel>
                    <Box margin="5px" width="100%">
                      {ESTIMATE_STRENGTH_TEST_FIELDS.map((field) => (
                        <FormField
                          key={field.name}
                          label={field.label}
                          name={field.name}
                          type="number"
                          validate={(value) =>
                            isActualStrengthFulfilled(values)
                              ? field.validate(value, client.mass_unit)
                              : field.validateRequired(value, client.mass_unit)
                          }
                          isInline={true}
                          inputDivClass="w-38"
                        />
                      ))}
                    </Box>
                  </>
                )}
                {Object.keys(errors).map((field, index) => (
                  <FormErrorAlert
                    key={index}
                    isShow={touched[field]}
                    errorText={errors[field]}
                  />
                ))}
                <Divider />
                <button
                  type="submit"
                  className="default-btn strength-test-btn"
                  disabled={loadingStrength}
                >
                  save
                </button>
              </Form>
            )}
          </Formik>
        </WidgetBody>
      </Widget>
    </>
  );
};

export default StrengthTest;

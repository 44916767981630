import { baseUrl } from "../env/env";
import withCache from "../helpers/cache";

import myFetch from "./myFetch";

export const loadPaymentList = () => {
  return myFetch(`${baseUrl}/api/invoices/manual`);
};

export const loadPaymentListWithCache = withCache(loadPaymentList);

export const loadCompanyPaymentList = async (id) => {
  const invoices = await myFetch(`${baseUrl}/api/invoices?company_id=${id}`);
  return { [id]: invoices };
};

export const loadCompanyPaymentListWithCache = withCache(
  loadCompanyPaymentList
);

export const loadPaymentInvoiceDays = async (id) => {
  const invoice = await myFetch(`${baseUrl}/api/invoices/${id}/invoice_days`);
  return { [id]: invoice };
};

export const loadPaymentInvoiceDaysWithCache = withCache(
  loadPaymentInvoiceDays
);

export const updatePayment = ({ id, body }) => {
  loadPaymentListWithCache.invalidate();
  return myFetch(`${baseUrl}/api/invoices/${id}`, {
    method: "PATCH",
    body: body,
  });
};

import { Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PROGRAM_TEMPLATES } from "../../../constants/router";
import { addProgramTemplate, setHistory } from "../../../redux/programTemplatesSlice";
import "./ProgramTemplate.scss";
import ProgramTemplateForm from "../ProgramTemplateForm/ProgramTemplateForm";

const initialValues = {
  name: "",
  days: [],
  description: "",
};

const ProgramTemplateAddPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHistory([]));
  }, []);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={(values, { setValues }) => {
        const body = { ...values };
        dispatch(addProgramTemplate({ body })).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            setValues(initialValues);
            const { id } = res.payload;
            history.push(PROGRAM_TEMPLATES.root + id);
          }
        });
      }}
    >
      <ProgramTemplateForm title="Add a Program Template" />
    </Formik>
  );
};

export default ProgramTemplateAddPage;

import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import "./CompanyDropZone.scss";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import { THEME_DIV_STYLE, THEME_DROPZONE_PROPS } from "../../constants/company";
import FooterButtons from "../../pages/Companies/CompanyManagePage/components/FooterButtons";
import {
  addThemeCompany,
  deleteThemeCompany,
} from "../../redux/companiesSlice";
import Button from "../Button";

const CompanyDropZone = ({ onClickShowInvoices, isProfilePage }) => {
  const dispatch = useDispatch();
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const loadingTheme = useSelector((state) => state.companies.loadingTheme);
  const [style, setStyle] = useState({});
  const [fileURL, setFileURL] = useState("");
  const [file, setFile] = useState();

  useEffect(() => {
    if (file) {
      setFileURL(URL.createObjectURL(file));
    } else {
      setFileURL("");
    }
    if (typeof file !== "undefined" && isProfilePage) {
      onClickSave();
    }
  }, [file]);

  useEffect(() => {
    if (currentCompany?.bg_file_name) {
      const url = encodeURI(currentCompany?.bg_file_name);
      setFileURL(url);
    }
  }, [currentCompany?.bg_file_name]);

  useEffect(() => {
    if (fileURL) {
      setStyle({
        ...THEME_DIV_STYLE,
        backgroundImage: `url(${fileURL})`,
      });
      return;
    }
    !fileURL && setStyle({});
  }, [fileURL]);

  const onClickSave = () => {
    if (file) {
      dispatch(addThemeCompany({ id: currentCompany.id, file }));
      return;
    }
    if (!file && currentCompany?.bg_file_name) {
      dispatch(deleteThemeCompany({ id: currentCompany.id }));
    }
  };

  const onRemove = () => {
    setFile(null);
  };

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  return (
    <Dropzone
      onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
      {...THEME_DROPZONE_PROPS}
    >
      {({ getRootProps, getInputProps }) => (
        <div>
          <label className="dropzone__label">background image</label>
          <div className="img_div">
            {!isProfilePage && (
              <div className="icons cross" onClick={onRemove} />
            )}
            <div className="pic_container" style={style} {...getRootProps()}>
              <input {...getInputProps()} />
            </div>
          </div>
          {!isProfilePage && (
            <FooterButtons
              onClickShowInvoices={onClickShowInvoices}
              onClickSave={() => onClickSave()}
            />
          )}

          {isProfilePage && (
            <>
              (note: safari browser may behave strangely with image uploads.
              please use any other browser)
              <Box display="flex" justifyContent="flex-end">
                <Button
                  disabled={loadingTheme}
                  variant="warning"
                  className="default-btn"
                  onClick={onRemove}
                >
                  delete
                </Button>
              </Box>
            </>
          )}
        </div>
      )}
    </Dropzone>
  );
};

CompanyDropZone.propTypes = {
  onClickShowInvoices: PropTypes.func,
  isProfilePage: PropTypes.bool,
};

CompanyDropZone.defaultProps = {
  isProfilePage: false,
};

export default CompanyDropZone;

import React, { memo } from "react";

import useGetFoodsForDietPage from "../../../../../hooks/useGetFoodsForDietPage";
import MealCheckboxRow from "../MealCheckboxRow";

const MealsBody = () => {
  const foods = useGetFoodsForDietPage();
  return (
    <>
      {foods.map((item, idxFood) => (
        <tr key={item.food.id} style={{ height: "26px" }}>
          <MealCheckboxRow
            meals={item.meals}
            isPrePostWorkout={item.is_pre_post_workout}
            idxFood={idxFood}
          />
        </tr>
      ))}
    </>
  );
};

MealsBody.propTypes = {};

export default memo(MealsBody);

import PropTypes from "prop-types";
import React from "react";

const ExerciseSelector = ({ options, exerciseId, setExerciseId }) => (
  <div className="tools-block__line  tools-block__line--select">
    <div className="tools-block__line-text">or exercise:</div>
    <select
      className="form-select w-100"
      onChange={(e) => setExerciseId(e.target.value)}
    >
      {options.map((option) => (
        <option
          key={option.value}
          value={option.value}
          selected={option.value === exerciseId}
        >
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

ExerciseSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  exerciseId: PropTypes.string.isRequired,
  setExerciseId: PropTypes.func.isRequired,
};

export default ExerciseSelector;

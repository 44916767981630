import { createSlice } from "@reduxjs/toolkit";
import lodash from "lodash";
import moment from "moment";

import {
  DEFAULT_SUCCESS_MESSAGE,
  INITIAL_FOR_DAYS,
  INITIAL_MEALS_STATE,
  INITIAL_QUANTITY,
  INITIAL_WO,
  MIN_MEAL_COLUMN_COUNT,
  SHOW_ALL_FOODS_INIT,
} from "../constants/diet";
import { prepareDietState } from "../helpers/diet";

const dietPageSlice = createSlice({
  name: "dietPage",
  initialState: {
    diets: [null, null],
    isShowPrePostWorkout: [false, false],
    mealColumnCount: [0, 0],
    meals: [[], []],
    selectedDietIndex: 0,
    isDoubleDiet: false,
    isShowAllFoods: SHOW_ALL_FOODS_INIT,
    messages: DEFAULT_SUCCESS_MESSAGE,
    isEdited: false,
  },
  reducers: {
    initDietPage(state, action) {
      state.isEdited = false;
      const lastDiet = action.payload.diet;
      const firstDiet = lastDiet;
      const { second_diet: secondDiet } = firstDiet;
      const diets = [firstDiet];
      if (lastDiet.is_double_diet) {
        diets.push(secondDiet);
      }
      dietPageSlice.caseReducers.setIsDoubleDiet(state, {
        payload: lastDiet.is_double_diet,
      });
      dietPageSlice.caseReducers.prepareDietState(state, {
        payload: diets,
      });
    },
    restoreDiet(state, action) {
      const diet = action.payload?.diet;
      if (!diet) return;
      state.isEdited = true;

      if (!diet.is_double_diet) {
        state.selectedDietIndex = 0;
      }
      dietPageSlice.caseReducers.initDietPage(state, {
        payload: { diet },
      });

      state.messages = `DIET FROM ${moment(diet.start_date).format("ll")} RESTORED`;
    },
    setDietFromTemplate(state, action) {
      const diet = lodash.cloneDeep(action.payload.diet);
      state.isEdited = true;
      diet.foods = diet.foods.map((d) => ({
        ...d,
        meals: INITIAL_MEALS_STATE,
      }));
      if (diet.is_double_diet) {
        diet.second_diet.foods = diet.second_diet.foods.map((d) => ({
          ...d,
          meals: INITIAL_MEALS_STATE,
        }));
        diet.for_days = INITIAL_FOR_DAYS;
        diet.second_diet.for_days = INITIAL_FOR_DAYS;
      } else {
        state.selectedDietIndex = 0;
      }
      state.messages = `DIET FROM ${diet.name} LOADED`;
      dietPageSlice.caseReducers.initDietPage(state, {
        payload: { diet },
      });
    },
    prepareDietState(state, action) {
      const { diets, mealColumnCount, isShowPrePostWorkout, meals } =
        prepareDietState(action.payload);
      dietPageSlice.caseReducers.setDiets(state, {
        payload: diets,
      });
      dietPageSlice.caseReducers.setMealColumnCount(state, {
        payload: mealColumnCount,
      });
      dietPageSlice.caseReducers.setIsShowPrePostWorkout(state, {
        payload: isShowPrePostWorkout,
      });
      dietPageSlice.caseReducers.setMeals(state, { payload: meals });
    },
    setDiets(state, action) {
      state.diets = action.payload;
    },
    setIsDoubleDiet(state, action) {
      state.isDoubleDiet = action.payload;
    },
    setMealColumnCount(state, action) {
      state.mealColumnCount = action.payload;
    },
    setDefaultMessages(state) {
      state.messages = DEFAULT_SUCCESS_MESSAGE;
    },
    onChangeMealColumnCount(state) {
      if (!state.isShowPrePostWorkout[state.selectedDietIndex]) {
        state.isShowPrePostWorkout[state.selectedDietIndex] = true;
        return;
      }
      state.mealColumnCount[state.selectedDietIndex]++;
    },
    setIsShowPrePostWorkout(state, action) {
      state.isShowPrePostWorkout = action.payload;
    },
    setMeals(state, action) {
      state.meals = action.payload;
    },
    setIsShowAllFoodsForCurrentDiet(state) {
      state.isShowAllFoods[state.selectedDietIndex] = true;
    },
    onEditDiet(state, action) {
      state.isEdited = true;
      const { diets, meals } = prepareDietState(action.payload);
      dietPageSlice.caseReducers.setDiets(state, {
        payload: diets,
      });
      dietPageSlice.caseReducers.setMeals(state, { payload: meals });
    },
    onChangeQuantity(state, action) {
      const path = `diets[${state.selectedDietIndex}].foods[${action.payload.idxFood}].quantity`;
      const newState = state;
      const value = action.payload.value || "0";
      if (/^\d{0,4}$/.test(value)) {
        lodash.update(newState, path, () => Number(value));
        dietPageSlice.caseReducers.onEditDiet(state, { payload: newState.diets });
      }
    },
    onChangeFoodMeal(state, action) {
      const path = `diets[${state.selectedDietIndex}].foods[${action.payload.idxFood}].meals[${action.payload.idxMeal}]`;
      const newState = state;
      lodash.update(newState, path, (isCheck) => !isCheck);
      dietPageSlice.caseReducers.onEditDiet(state, { payload: newState.diets });
    },
    onChangeFoodWOMeal(state, action) {
      const path = `diets[${state.selectedDietIndex}].foods[${action.payload.idxFood}].is_pre_post_workout`;
      const newState = state;
      lodash.update(
        newState,
        path,
        (is_pre_post_workout) => !is_pre_post_workout
      );
      dietPageSlice.caseReducers.onEditDiet(state, { payload: newState.diets });
    },
    onDeleteFood(state, action) {
      state.diets[state.selectedDietIndex].foods = state.diets[
        state.selectedDietIndex
      ].foods.filter((item) => item.food.id !== action.payload);

      if (
        state.selectedDietIndex === 0 &&
        state.diets[1] &&
        state.diets[0].foods.length === 0
      ) {
        state.isDoubleDiet = false;
        state.diets[0] = state.diets[1];
        state.diets[0] = {
          ...state.diets[0],
          second_diet: null,
          is_double_diet: false,
          id: state.diets[1].id,
          foods: state.diets[1].foods,
          for_days: state.diets[1].for_days,
        };
        state.mealColumnCount[0] = state.mealColumnCount[1];
        state.meals[0] = state.meals[1];
        state.isShowPrePostWorkout[0] = state.isShowPrePostWorkout[1];
        state.isShowAllFoods[0] = state.isShowAllFoods[1];
        dietPageSlice.caseReducers.onDeleteDiet(state);
      }

      dietPageSlice.caseReducers.onEditDiet(state, { payload: state.diets });
    },
    onAddFood(state, action) {
      if (
        !state.diets[state.selectedDietIndex].foods.find(
          (i) => i.food.id === action.payload.id
        )
      ) {
        state.diets[state.selectedDietIndex].foods.push({
          food: action.payload,
          meals: INITIAL_MEALS_STATE,
          is_pre_post_workout: INITIAL_WO,
          quantity: INITIAL_QUANTITY,
          food_id: action.payload.id,
        });
        dietPageSlice.caseReducers.onEditDiet(state, {
          payload: state.diets,
        });
      }
    },
    onCopyDiet(state) {
      state.isDoubleDiet = true;
      state.diets[1] = {
        foods: state.diets[0].foods,
        for_days: INITIAL_FOR_DAYS,
      };
      state.diets[0].for_days = INITIAL_FOR_DAYS;
      state.mealColumnCount[1] = state.mealColumnCount[0];
      state.isShowPrePostWorkout[1] = state.isShowPrePostWorkout[0];
      state.isShowAllFoods[1] = state.isShowAllFoods[0];
      state.meals[1] = [];
      dietPageSlice.caseReducers.onEditDiet(state, {
        payload: state.diets,
      });
    },
    onAddDiet(state) {
      state.isDoubleDiet = true;
      state.diets[0].for_days = INITIAL_FOR_DAYS;
      state.diets[1] = {
        foods: [],
        for_days: INITIAL_FOR_DAYS,
      };
      state.mealColumnCount[1] = MIN_MEAL_COLUMN_COUNT;
      state.isShowPrePostWorkout[1] = false;
      state.isShowAllFoods[1] = SHOW_ALL_FOODS_INIT[1];
      state.meals[1] = [];
      state.isEdited = true;
    },
    onDeleteDiet(state) {
      delete state.diets[0].for_days;
      state.selectedDietIndex = 0;
      state.diets = state.diets.slice(0, 1);
      state.meals = state.meals.slice(0, 1);
      state.mealColumnCount = state.mealColumnCount.slice(0, 1);
      state.isShowPrePostWorkout = state.isShowPrePostWorkout.slice(0, 1);
      state.isShowAllFoods = state.isShowAllFoods.slice(0, 1);
      state.isDoubleDiet = false;
      state.isEdited = true;
    },
    onChangeForDay(state, action) {
      state.diets[state.selectedDietIndex].for_days = action.payload.newForDays;
      state.diets[action.payload.otherDietIndex].for_days =
        action.payload.newForDaysOtherDiet;
      state.isEdited = true;
    },
    onChangeSelectIndex(state, action) {
      state.selectedDietIndex = action.payload;
    },
    onChangeNotes(state, action) {
      state.isEdited = true;
      state.diets[0].notes = action.payload;
    },
  },
});

export const {
  onChangeFoodWOMeal,
  onChangeFoodMeal,
  onChangeQuantity,
  onChangeNotes,
  onChangeMealColumnCount,
  setIsDoubleDiet,
  setIsShowAllFoodsForCurrentDiet,
  setMealColumnCount,
  setDiets,
  setIsShowPrePostWorkout,
  setMeals,
  onDeleteFood,
  onAddFood,
  onCopyDiet,
  onAddDiet,
  onDeleteDiet,
  initDietPage,
  restoreDiet,
  onChangeSelectIndex,
  onChangeForDay,
  setDietFromTemplate,
  setDefaultMessages,
} = dietPageSlice.actions;

export default dietPageSlice.reducer;

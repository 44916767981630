import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

import { PRIVACY, TERMS } from "../../constants/router";

import "./Copyright.scss";

const Copyright = () => (
  <div className="copyright-wrapper">
    <span className="copyright">
      © {moment().year()} copyright recomposer fze.
      <Link to={TERMS}>terms.</Link>
      <Link to={PRIVACY}>privacy.</Link>
    </span>
  </div>
);

export default Copyright;

import cn from "classnames";

import "./ExerciseName.scss";

const ExerciseName = ({ name, isActive, onClick }) => {
  return (
    <div className="training-day__exercise-row__name">
      <span
        onClick={onClick}
        className={cn(
          "exercise-name",
          { "exercise-name--active": isActive },
        )}
      >
        {name}
      </span>
    </div>
  );
};

ExerciseName.propTypes = {};

export default ExerciseName;

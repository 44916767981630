import "./DeleteClient.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./DeleteClient.scss";
import { useHistory } from "react-router-dom";

import { deleteClient } from "../../api/clientApi";
import { CLIENT } from "../../constants/router";
import { TOASTR_TYPE } from "../../constants/toastr";
import { getFullName } from "../../helpers";
import DisabledField from "../../pages/Clients/ClientListPage/components/DisabledField";
import {
  clearClientForDelete,
  loadClientListThunk,
} from "../../redux/clientListSlice";
import { clearClient } from "../../redux/clientSlice";
import { openToastr } from "../../redux/toastrSlice";
import Button from "../Button";
import FormLabel from "../FormLabel";
import Spinner from "../Spinner";
import Widget from "../widget/Widget";
import WidgetBody from "../widget/WidgetBody";
import WidgetHeader from "../widget/WidgetHeader";
import WidgetTitle from "../widget/WidgetTitle";

const DeleteClient = ({ closeWidget, isNeedGoToListPage = false }) => {
  const history = useHistory();
  const [name, setName] = useState("");
  const { loading, clientForDelete, page, filter } = useSelector(
    (state) => state.clientList
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const clientName = getFullName(clientForDelete);
    setName(clientName);
  }, [clientForDelete?.id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    deleteClient({ id: clientForDelete.id })
      .then(() => {
        dispatch(loadClientListThunk({ page: page, search: filter }));
        dispatch(
          openToastr({
            text: "The client has been deleted!",
          })
        );
        dispatch(clearClientForDelete());
        dispatch(clearClient());
      })
      .catch((error) => {
        dispatch(
          openToastr({
            text: error,
            type: TOASTR_TYPE.ERROR,
          })
        );
      });
    closeWidget();
    setName("");
    if (isNeedGoToListPage) {
      history.push(CLIENT.root);
    }
  };

  const Field = ({ label, name, value }) => (
    <div className="delete-client__field-wrapper">
      <FormLabel htmlFor={name} className="delete-client__label">
        {label}
      </FormLabel>
      <DisabledField
        name={name}
        value={value}
        className="delete-client__input"
      />
    </div>
  );

  return (
    <Widget className="delete-client">
      <WidgetHeader className="delete-client__title">
        <WidgetTitle>delete client</WidgetTitle>
      </WidgetHeader>
      <WidgetBody className="delete-client__form-wrapper">
        {loading && <Spinner />}
        {!loading && (
          <form onSubmit={handleSubmit}>
            <Field
              label="client name"
              name="name"
              value={name}
              disabled={true}
            />
            <Button
              variant="warning"
              type="submit"
              className="delete-client__btn"
            >
              confirm delete
            </Button>
          </form>
        )}
        <div className="delete-client__footer" />
      </WidgetBody>
    </Widget>
  );
};

export default DeleteClient;

import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import DeleteProgramTemplate from "../../../../components/DeleteProgramTemplate";
import { PROGRAM_TEMPLATES } from "../../../../constants/router";
import { SIDEBAR_TYPE } from "../../../../constants/templates";
import { clearItemForDelete } from "../../../../redux/programTemplatesSlice";
import { setChangeAllExercise } from "../../../../redux/programTemplatesSlice";
import { Context } from "../ProgramTemplateForm";

import ExercisesWidget from "./ExercisesWidget/ExercisesWidget";
import ToolsWidget from "./ToolsWidget/ToolsWidget";

const ProgramTemplateSide = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAddExercise, onExerciseClick, setOnExerciseClick, sideBarState, setSideBarState } =
    useContext(Context);

  const changeAllExercise = useSelector(
    (state) => state.programTemplates.changeAllExercise
  );

  const closeDeleteWidget = () => {
    dispatch(clearItemForDelete());
    history.push(PROGRAM_TEMPLATES.root);
  };

  const handleExerciseClick = (data) => () => {
    onExerciseClick(changeAllExercise, data);
    setOnExerciseClick(null);
    setSideBarState(SIDEBAR_TYPE.TOOLS);
  };

  const widgetMap = {
    delete: <DeleteProgramTemplate closeWidget={closeDeleteWidget} />,
    tools: <ToolsWidget />,
    addExercise: (
      <ExercisesWidget
        edit={!isAddExercise}
        onExerciseClick={handleExerciseClick}
        changeAllExercise={changeAllExercise}
        setChangeAllExercise={(payload) => {
          dispatch(setChangeAllExercise(payload));
        }}
      />
    ),
  };

  return widgetMap[sideBarState.value];
};

export default ProgramTemplateSide;

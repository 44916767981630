export const trainerFormVariants = {
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
};

export const HEADER_TRAINERS_TABLE = [
  {
    label: "coach name",
    style: { width: "73%" },
    colspan: 2,
  },
  {
    label: "clients:",
    style: { width: "10%", textAlign: "right" },
  },
  {
    label: "active",
    style: { width: "10%", textAlign: "center" },
  },
  {
    label: "total",
    style: { width: "10%", textAlign: "center" },
  },
];

export const WIDGET_VIEW_TYPES = {
  ADD_TRAINER: "add-trainer",
  EDIT_TRAINER: "edit-trainer",
  DELETE_TRAINER: "delete-trainer",
  ACTIVE_CLIENTS: "active clients",
  ALL_CLIENTS: "all clients",
};

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import FavIcon from "../../../../../../../../components/FavIcon";
import { PROGRAM_TEMPLATES } from "../../../../../../../../constants/router";

const AddProgramRow = ({ data, onClick, isEditable }) => {
  const history = useHistory();
  const favs = useSelector((state) => state.user.user?.favourite_programs);

  const handleEdit = (e) => {
    e.stopPropagation();
    history.push(`${PROGRAM_TEMPLATES.root}${data.id}/`);
  }

  return (
    <div className="add-program-row" onClick={onClick}>
      <div className="add-program-row__text">{data.name}</div>
      <div className="add-program-row__icon-buttons">
        <span className="add-program-row__icon-btn fa-layers fa-fw">
          <FavIcon favs={favs} type="favourite_programs" id={data.id} />
        </span>
        {isEditable && <span className="add-program-row__icon-btn">
          <FontAwesomeIcon icon={faEdit} onClick={handleEdit} />
        </span>}
      </div>
    </div>
  );
};

export default AddProgramRow;

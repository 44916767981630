import "./AuthPage.scss";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import logo from "../../../assets/images/welcome.png";
import Copyright from "../../../components/CopyrightTermsPrivacy";
import FormField from "../../../components/FormField/FormField";
import Spinner from "../../../components/Spinner";
import { AUTH } from "../../../constants/router";
import { saveRestoredPassword } from "../../../redux/restorePasswordSlice";
import { setShowHeader, setShowMenu } from "../../../redux/windowSlice";

const RestorePassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const { loading, successRestore } = useSelector(
    (state) => state.forgotPassword
  );
  const onSubmitPassword = (value) => {
    dispatch(
      saveRestoredPassword({ restore_token: token, password: value.password })
    );
  };

  useEffect(() => {
    dispatch(setShowHeader(false));
    dispatch(setShowMenu(false));
  }, []);

  const back = () => {
    history.push(AUTH.root);
  };

  return (
    <div>
      <div className="auth-main-block">
        <img alt="Logo" src={logo} className="page-auth-img" />
        <div>
          {loading && <Spinner />}
          {successRestore && (
            <div className="form-auth">
              <p className="forgot-password-tip">password changed</p>
              <button onClick={back} className="default-btn auth-btn">
                ok
              </button>
            </div>
          )}
          {!successRestore && (
            <div className="form-auth">
              {loading && <Spinner />}
              <Formik
                initialValues={{
                  password: "",
                  password2: "",
                }}
                validationSchema={Yup.object().shape({
                  password: Yup.string().required().min(8),
                  password2: Yup.string()
                    .oneOf([Yup.ref("password")], "Passwords must match")
                    .required("re-enter password is required"),
                })}
                onSubmit={onSubmitPassword}
              >
                {() => (
                  <Form>
                    <p className="forgot-password-tip">
                      Please, enter new password
                    </p>
                    <FormField
                      className="auth-field"
                      placeholder="password"
                      name="password"
                      type="password"
                      isInline={false}
                    />
                    <ErrorMessage
                      className="auth-error"
                      component="div"
                      name="password"
                    />
                    <FormField
                      className="auth-field"
                      placeholder="re-enter password"
                      name="password2"
                      type="password"
                      isInline={false}
                    />
                    <ErrorMessage
                      className="auth-error"
                      component="div"
                      name="password2"
                    />
                    <button className="default-btn auth-btn" type="submit">
                      change password
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>
      <Copyright />
    </div>
  );
};

RestorePassword.propTypes = {};

export default RestorePassword;

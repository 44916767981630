import axios from "axios";
import Cookies from "js-cookie";

import { AUTH_ERRORS, JSON_HEADERS, PERMISSION_ERRORS } from "../constants/api";
import {
  ERRORS_DETAIL,
  ERRORS_MESSAGE,
  ERRORS_STATUSES,
} from "../constants/auth";
import { FORM_ERRORS } from "../constants/common";
import { NOT_FOUND } from "../constants/router";
import { checkNestedField } from "../helpers";

const myFetch = async (url, options, handlerError = null) => {
  const method = options?.method || "GET";
  const response = await axios({
    validateStatus: false,
    method: method,
    url: url,
    data: options?.body,
    headers: options?.headers || JSON_HEADERS,
    withCredentials: true,
  });

  if (AUTH_ERRORS.includes(response.data?.detail)) {
    Cookies.remove("user_id")
    Cookies.remove("token")
    return;
  }

  if (PERMISSION_ERRORS.includes(response.data?.detail)) {
    document.location.href = NOT_FOUND;
    return;
  }

  if (response.status < 200 || response.status >= 300) {
    const errors = {
      ...response.data,
      detail: response.data?.detail || "",
      status: response.status,
    };
    if (handlerError) {
      throw Error(handlerError(errors));
    }
    throw simpleHandlerError(errors);
  }

  try {
    return response.data;
  } catch (e) {
    return response;
  }
};

export const emailHandlerError = (errors) => {
  if (checkNestedField(errors, "email")) {
    return FORM_ERRORS.EMAIL_ALREADY_USED;
  }
  return simpleHandlerError(errors);
};

export const activateClientError = (errors) => {
  if (errors?.detail) {
    return errors?.detail;
  }
  return simpleHandlerError(errors);
};

export const authHandlerError = (errors) => {
  if (errors.detail === ERRORS_DETAIL.NOT_ACTIVATED) {
    return ERRORS_MESSAGE.NOT_ACTIVATED;
  }
  if (
    errors.detail === ERRORS_DETAIL.NOT_FOUND ||
    errors.status === ERRORS_STATUSES.UNAUTHORIZED_NUMBER
  ) {
    return ERRORS_MESSAGE.NOT_FOUND;
  }
  return simpleHandlerError(errors);
};

export const restorePasswordHandlerError = (errors) => {
  if (errors.detail === "Not found.") {
    errors.detail = "User with this email doesn't exist.";
  }
  return simpleHandlerError(errors);
};

export const sendBodyTestHandlerError = (errors) => {
  // TODO: написать красивые ошибки
  return simpleHandlerError(errors);
};

export const undefinedHandlerError = () => {
  return "Something went wrong :(";
}

export const simpleHandlerError = (errors) => {
  if (typeof errors[Object.keys(errors)[0]] === 'string')
    return errors[Object.keys(errors)[0]];
  return errors[Object.keys(errors)[0]][0];
};
export default myFetch;

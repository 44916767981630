import cn from "classnames";
import "./TabButton.scss";

const TabButton = ({ variant, active = false, withoutBorder = false, children, ...props }) => (
  <button
    className={cn(
      "tab-button",
      { "tab-button--left": variant === "left" },
      { "tab-button--right": variant === "right" },
      { "tab-button--inactive": !active },
      { "tab-button--active": active },
      { "tab-button--without-bottom": withoutBorder }
    )}
    {...props}
  >
    <span className="tab-button__text">{children}</span>
  </button>
);

TabButton.propTypes = {};

export default TabButton;

import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./DietHistorySideBar.scss";
import { useDispatch, useSelector } from "react-redux";
import lodash, { isEmpty } from "lodash";

import Button from "../../../../../components/Button";
import Spinner from "../../../../../components/Spinner";
import Widget from "../../../../../components/widget/Widget";
import { DIET_PREVIEW_ITEMS } from "../../../../../constants/diet";
import { restoreDiet } from "../../../../../redux/dietPageSlice";
import { loadDiets, updateDiet } from "../../../../../redux/dietsSlice";

import DietHistoryItem from "./components/DietHistoryItem";

const prepareDietForShow = (diets) => {
  let rDiets = [...diets].reverse();
  // удаляем текущую диету из истории
  rDiets.shift();
  return rDiets;
};

const DietHistorySideBar = () => {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.client.client);
  const diets = useSelector((state) => state.diets.items);
  const loading = useSelector((state) => state.diets.loading);
  const [isShowFull, setIsShowFull] = useState(false);
  const [reverseDiets, setReverseDiets] = useState([]);

  useEffect(() => {
    if (!diets) {
      dispatch(loadDiets(client.id));
    }
  }, []);

  useEffect(() => {
    if (lodash.isEmpty(diets)) {
      return;
    }
    let rDiets = prepareDietForShow(diets);
    setIsShowFull(rDiets <= DIET_PREVIEW_ITEMS);
    setReverseDiets(rDiets.slice(0, DIET_PREVIEW_ITEMS));
  }, [diets]);

  const showFull = () => {
    let rDiets = prepareDietForShow(diets);
    setReverseDiets(rDiets);
    setIsShowFull(true);
  };

  const handleRestoreDiet = (diet) => {
    dispatch(restoreDiet({ diet }));

    const { second_diet, ...diets } = diet;
    dispatch(
      updateDiet({
        id: client.id,
        body: {
          ...diets,
          ...(diets.is_double_diet && { second_diet }),
        },
      })
    );
  };

  return (
    <Widget className="diet__history">
      <Widget.Header>
        <Widget.Title>diet history</Widget.Title>
      </Widget.Header>
      <Widget.Body>
        {loading ? (
          <Spinner stickToTop />
        ) : (
          <div className="scrollable">
            {reverseDiets.map((diet) => (
              <DietHistoryItem
                key={diet.id}
                onRestore={() => handleRestoreDiet(diet)}
                diets={diet}
                isDoubleDiet={diet.is_double_diet}
                start={diet.start_date}
              />
            ))}
            {isEmpty(reverseDiets) ? (
              <h3>history is empty</h3>
            ) : (
              !isShowFull && (
                <Box display="flex" justifyContent="flex-end">
                  <Button onClick={showFull}>show all</Button>
                </Box>
              )
            )}
          </div>
        )}
      </Widget.Body>
    </Widget>
  );
};

export default DietHistorySideBar;

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext, FieldArray, Field } from "formik";
import { cloneDeep, isEqual, isEmpty, last } from "lodash";
import React, { useContext, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "../../../../components/Button";
import Widget from "../../../../components/widget/Widget";
import { SIDEBAR_TYPE } from "../../../../constants/templates";
import { exerciseSetErrorsSorting } from "../../../../helpers";
import {
  setItemForDelete,
  setSelectedExercise,
  removeExerciseSetErrors,
  clearExerciseSetErrors,
  setHistory,
} from "../../../../redux/programTemplatesSlice";
import { Context } from "../ProgramTemplateForm";

import ProgramTemplateDay from "./ProgramTemplateDay/ProgramTemplateDay";

const Days = ({ push, insert, remove, days }) => {
  const handleAddDay = () => push({ workouts: [] });
  const handleRemoveDay = (idx) => () => remove(idx);
  const handleCopyDay = (item, idx) => () => {
    const newItem = { ...cloneDeep(item), id: new Date().valueOf() };
    insert(idx + 1, newItem);
  };

  return (
    <div className="program-template-main__days">
      {days.map((item, idx) => (
        <ProgramTemplateDay
          key={idx}
          data={item}
          idx={idx}
          onRemove={handleRemoveDay(idx)}
          onCopy={handleCopyDay(item, idx)}
        />
      ))}
      <div className="program-template-main__add-day">
        <span>{`day ${days.length + 1}`}</span>
        <button
          className="day__icon-btn day__icon-btn--add program-template-main__add-day-btn"
          onClick={handleAddDay}
          type="button"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </div>
  );
};

const MemoDays = React.memo(Days);

function ProgramTemplateMain() {
  const { sideBarState, setSideBarState, canDelete, isValid, setIsValid, isEditingClientProgram } =
    useContext(Context);
  const { values, setValues, initialValues } = useFormikContext();

  const dispatch = useDispatch();
  const history = useSelector((state) => state.programTemplates.history);
  const client = useSelector((state) => state.client.client);
  const { days } = values;
  const exerciseSetErrors = useSelector(
    (state) => state.programTemplates.exerciseSetErrors
  );
  const saving = useSelector((state) => state.programTemplates.saving);

  const handleClickTools = () => {
    dispatch(setSelectedExercise(null));
    setSideBarState(SIDEBAR_TYPE.TOOLS);
  };

  const handleDelete = () => {
    dispatch(setItemForDelete(initialValues));
    setSideBarState(SIDEBAR_TYPE.DELETE);
  };

  useEffect(() => {
    if (isEqual(values, last(history)) || isEqual(values, initialValues)) {
      return;
    }

    const newHistory = [...history, values];
    dispatch(setHistory(newHistory));
  }, [values]);

  useEffect(() => {
    if (isEmpty(history)) {
      const newHistory = [...history, values];
      dispatch(setHistory(newHistory));
    }

    dispatch(setSelectedExercise(null));

    return () => {
      dispatch(clearExerciseSetErrors());
    };
  }, []);

  const onUndo = () => {
    if (history.length === 1) {
      return;
    }
    setValues(history[history.length - 2]);
    dispatch(setHistory(history.slice(0, history.length - 1)));
  };

  const hasEmptyWorkout = useMemo(
    () =>
      days.some((day) =>
        day.workouts.some((workout) => workout.exercises.length === 0)
      ),
    [days]
  );

  useEffect(() => {
    for (const value of Object.values(exerciseSetErrors)) {
      const { dayIdx, workoutIdx, setIdx, exerciseIdx } = value;
      const errorSet =
        values?.days[dayIdx]?.workouts[workoutIdx]?.exercises[exerciseIdx]
          ?.sets[setIdx];
      if (errorSet === undefined) {
        dispatch(removeExerciseSetErrors(value));
        return;
      }
      if (errorSet.weight !== null || errorSet.reps !== null) {
        dispatch(removeExerciseSetErrors(value));
      }
    }
  }, [values]);

  const isEmptyTemplate = useMemo(() => !!values.days.length, [values.days]);

  const errorsListSorted = useMemo(
    () => Object.values(exerciseSetErrors).sort(exerciseSetErrorsSorting),
    [exerciseSetErrors]
  );

  const errorsView = errorsListSorted.map((el) => {
    const text = `day ${+el.dayIdx + 1}
                  workout ${+el.workoutIdx + 1}
                  exercise "${el.exerciseName}"
                  set ${+el.setIdx + 1}`;
    return <p key={text}>{text}</p>;
  });

  useEffect(() => {
    if (
      !isEmptyTemplate ||
      !isEmpty(exerciseSetErrors) ||
      hasEmptyWorkout ||
      history?.length <= 1
    ) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
  }, [
    isEmptyTemplate,
    exerciseSetErrors,
    hasEmptyWorkout,
    history?.length,
    setIsValid,
  ]);

  return (
    <Widget className="program-template-main">
      <Widget.Header className="pl-10">
        <Widget.Title>
          {isEditingClientProgram ? `${client?.first_name}'s ${values.name}` : values.name}
        </Widget.Title>
        <div className="widget-header__controls">
          <Button
            onClick={handleClickTools}
            disabled={sideBarState === SIDEBAR_TYPE.TOOLS}
          >
            tools
          </Button>
          <Button onClick={onUndo} disabled={history?.length <= 1}>
            undo
          </Button>
          {canDelete && (
            <Button
              onClick={handleDelete}
              disabled={sideBarState === SIDEBAR_TYPE.DELETE}
            >
              delete
            </Button>
          )}
          <Button type="submit" disabled={!isValid || !values.name || saving}>
            save
          </Button>
        </div>
      </Widget.Header>
      {!isEmpty(exerciseSetErrors) && (
        <div className="program-template-main__error">
          Please, check next sets (set cannot be empty, reps cannot be 0):
          {errorsView}
        </div>
      )}
      {!isEditingClientProgram &&
        <>
          <label className="form-label">template name</label>
          <Field
            id="name"
            name="name"
            type="text"
            className="form-input program-template-main__input"
          />
        </>
      }
      <FieldArray name="days">
        {({ push, insert, remove }) => (
          <MemoDays
            push={push}
            insert={insert}
            remove={remove}
            days={values.days}
          />
        )}
      </FieldArray>
    </Widget>
  );
}

export default ProgramTemplateMain;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { last } from "lodash";
import moment from "moment";

import * as ClientApi from "../api/clientApi";
import { GOAL_SLIDER_STATE } from "../constants/body";

const LAST_DIET = {
  is_double_diet: false,
  foods: [],
  notes: "",
  second_diet: null,
  start_date: moment().date(),
};

const SET_GOAL_VIEW = "setGoalView";

export const SET_GOAL_VIEW_ACTION = `client/${SET_GOAL_VIEW}`;

export const CLIENT_ADD_CLIENT_ACTION = "client/addClient";
export const addClient = createAsyncThunk(
  CLIENT_ADD_CLIENT_ACTION,
  ClientApi.addClient
);

export const CLIENT_SEND_BODY_TEST_ACTION = "client/sendBodyTest";
export const sendBodyTest = createAsyncThunk(
  CLIENT_SEND_BODY_TEST_ACTION,
  ClientApi.sendBodyTest
);

export const CLIENT_SEND_STRENGTH_TEST_ACTION = "client/sendStrengthTest";
export const sendStrengthTest = createAsyncThunk(
  CLIENT_SEND_STRENGTH_TEST_ACTION,
  ClientApi.sendStrengthTest
);

export const sendDoubleStrengthTest = createAsyncThunk(
  CLIENT_SEND_STRENGTH_TEST_ACTION,
  ClientApi.sendDoubleStrengthTest
);

export const CLIENT_LOAD_CLIENT_BY_ID_ACTION = "client/loadClientById";
export const loadClientById = createAsyncThunk(
  CLIENT_LOAD_CLIENT_BY_ID_ACTION,
  (args) => ClientApi.loadClientByIdWithCache(args)
);

export const CLIENT_EDIT_MAIN_INFO_CLIENT_ACTION = "client/editMainInfoClient";
export const editMainInfoClient = createAsyncThunk(
  CLIENT_EDIT_MAIN_INFO_CLIENT_ACTION,
  ClientApi.editMainInfoClient
);

export const CLIENT_UPDATE_ACTIVE_HISTORY_ACTION = "client/updateActiveHistory";
export const updateClientActiveHistory = createAsyncThunk(
  CLIENT_UPDATE_ACTIVE_HISTORY_ACTION,
  ClientApi.updateClientActiveHistory
);

export const CLIENT_EDIT_MAIN_INFO_CLIENT_WITHOUT_SAVE_ACTION =
  "client/editMainInfoClientWithOutSave";
export const editMainInfoClientWithOutSave = createAsyncThunk(
  CLIENT_EDIT_MAIN_INFO_CLIENT_WITHOUT_SAVE_ACTION,
  ClientApi.editMainInfoClientWithOutSave
);

export const CLIENT_UPDATE_CLIENT_GOAL_ACTION = "client/updateClientGoal";
export const updateClientGoal = createAsyncThunk(
  CLIENT_UPDATE_CLIENT_GOAL_ACTION,
  ClientApi.updateClientGoal
);

export const CLIENT_UPDATE_CLIENT_GOAL_DATE_ACTION =
  "client/updateClientGoalDate";
export const updateClientGoalDate = createAsyncThunk(
  CLIENT_UPDATE_CLIENT_GOAL_DATE_ACTION,
  ClientApi.updateClientGoalDate
);

// export const updateClientPb = createAsyncThunk(
//   "client/updateClientPb",
//   ({ id, data }) => {
//     return myFetch(`${baseUrl}/api/clients/update-pb`, {
//       method: "POST",
//       body: { id, data },
//     });
//   }
// );

export const CLIENT_GET_CLIENT_PROGRESS_ACTION = "client/getClientProgress";
export const getClientProgress = createAsyncThunk(
  CLIENT_GET_CLIENT_PROGRESS_ACTION,
  ClientApi.getClientProgress
);

export const CLIENT_GET_LATEST_PROGRESS_ACTION = "client/getLatestProgress";

const clientSlice = createSlice({
  name: "client",
  initialState: {
    client: null,
    // TODO: хоть body/goal/pb/strength/programs/diets лежат в client,
    //       лучше их вытащить из него на уровень выше для удобства.
    //       Так же надо будет поступить со всем остальным полями,
    //       что не относятся к основной инфе о клиенте
    body: null,
    goal: null,
    goalView: GOAL_SLIDER_STATE.DATE,
    // TODO: осознать нужно ли нам получать все тесты с бека
    //       или можно только последний(strength)
    strength: null,
    programs: null,
    lastDiet: LAST_DIET,
    progress: null,
    // TODO: осознать нужно ли оно нам(latest,pb)
    latest: null,
    pb: null,
    has_actual_strength: false,
    // statuses request
    loading: false,
    loadingStrength: false,
    isGetClientSent: false,
    successfullyClientAdded: false,
    successfullySendBodyTest: false,
    successfullyClientEdited: false,
    successfullySendStrengthTest: false,
    currentGoalRequests: 0,
    currentDateRequests: 0,
    error: "",
  },
  reducers: {
    clearClient(state) {
      state.client = null;
      state.body = null;
      state.goal = null;
      state.goalView = GOAL_SLIDER_STATE.DATE;
      state.pb = null;
      state.strength = null;
      state.programs = null;
      state.lastDiet = LAST_DIET;
      state.progress = null;
      state.latest = null;
      clientSlice.caseReducers.clearStatuses(state);
    },
    clearStatuses(state) {
      state.loading = false;
      state.loadingStrength = false;
      state.isGetClientSent = false;
      state.successfullyClientAdded = false;
      state.successfullySendBodyTest = false;
      state.successfullyClientEdited = false;
      state.successfullySendStrengthTest = false;
      state.currentGoalRequests = 0;
      state.currentDateRequests = 0;
      state.error = "";
    },
    clearSuccessfullySendBodyTest(state) {
      state.successfullySendBodyTest = false;
    },
    clearError(state) {
      state.error = "";
    },
    clearSuccessfullySendStrengthTest(state) {
      state.successfullySendStrengthTest = false;
    },
    clearSuccessfullyClientEdited(state) {
      state.successfullyClientEdited = false;
    },
    clearSuccessfullyClientAdded(state) {
      state.successfullyClientAdded = false;
    },
    setGoals(state, action) {
      const { goalWeight, goalFat } = action.payload;
      if (goalWeight) state.goal.goal_weight = goalWeight;
      if (goalFat) state.goal.goal_body_fat = goalFat;
    },
    [SET_GOAL_VIEW]: (state, action) => {
      state.goalView = action.payload;
    },
  },
  extraReducers: {
    [addClient.pending]: (state) => {
      state.loading = true;
    },
    [addClient.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.successfullyClientAdded = true;
      state.client = action.payload;
    },
    [addClient.rejected]: (state, action) => {
      state.loading = false;
      state.successfullyClientAdded = false;
      state.error = action.error.message;
    },
    [getClientProgress.pending]: (state) => {
      state.loading = true;
    },
    [getClientProgress.fulfilled]: (state, action) => {
      state.progress = action.payload;
      if (action.payload?.length) {
        state.latest = last(action.payload);
      } else {
        state.latest = null;
      }
      state.loading = false;
      state.error = "";
    },
    [getClientProgress.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [sendBodyTest.pending]: (state) => {
      state.loading = true;
    },
    [sendBodyTest.fulfilled]: (state, action) => {
      state.loading = false;
      state.successfullySendBodyTest = true;
      state.error = "";
      state.body = action.payload.last_body;
      state.strength = action.payload.last_strength;
    },
    [sendBodyTest.rejected]: (state, action) => {
      state.loading = false;
      state.successfullySendBodyTest = false;
      state.error = action.error.message;
    },
    [sendStrengthTest.pending]: (state) => {
      state.loadingStrength = true;
    },
    [sendStrengthTest.fulfilled]: (state, action) => {
      state.loadingStrength = false;
      state.successfullySendStrengthTest = true;
      state.error = "";
      const { last_strength = null, strength_tests = null } = action.payload;

      if (last_strength) {
        state.strength = action.payload.last_strength;
        if (action.payload.last_strength.strength_type === "actual") {
          state.has_actual_strength = true;
        }
      }

      if (strength_tests) {
        state.has_actual_strength = true;
        const estimateTestResult = strength_tests
          .find(({ strength_type }) => strength_type === "estimate");
        state.strength = estimateTestResult;
      }
    },
    [sendStrengthTest.rejected]: (state, action) => {
      state.loadingStrength = false;
      state.successfullySendStrengthTest = false;
      state.error = action.error.message;
    },
    [loadClientById.pending]: (state) => {
      state.loading = true;
      state.isGetClientSent = false;
    },
    [loadClientById.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isGetClientSent = true;
      state.body = action.payload?.last_body;
      state.goal = action.payload?.last_goal;
      state.pb = action.payload?.pb;
      state.strength = action.payload?.last_strength;
      state.programs = action.payload?.programs;
      state.lastDiet = action.payload?.last_diet || LAST_DIET;
      state.client = action.payload;
      state.has_actual_strength = action.payload?.has_actual_strength;
    },
    [loadClientById.rejected]: (state, action) => {
      state.loading = false;
      state.isGetClientSent = true;
      state.error = action.error.message;
    },
    [editMainInfoClient.pending]: (state) => {
      state.successfullyClientEdited = false;
      state.loading = true;
    },
    [editMainInfoClient.fulfilled]: (state, action) => {
      state.loading = false;
      state.successfullyClientEdited = true;
      state.error = "";
      state.client = action.payload;
    },
    [editMainInfoClient.rejected]: (state, action) => {
      state.loading = false;
      state.successfullyClientEdited = false;
      state.error = action.error.message;
    },
    [updateClientActiveHistory.pending]: (state) => {
      state.successfullyClientEdited = false;
      state.loading = true;
    },
    [updateClientActiveHistory.fulfilled]: (state, action) => {
      state.loading = false;
      state.successfullyClientEdited = true;
      state.error = "";
      state.client.is_active = action.payload.is_active;
    },
    [updateClientActiveHistory.rejected]: (state, action) => {
      state.loading = false;
      state.successfullyClientEdited = false;
      state.error = action.error.message;
    },
    [editMainInfoClientWithOutSave.pending]: (state) => {
      state.successfullyClientEdited = false;
      state.loading = true;
    },
    [editMainInfoClientWithOutSave.fulfilled]: (state) => {
      state.loading = false;
      state.successfullyClientEdited = true;
      state.error = "";
    },
    [editMainInfoClientWithOutSave.rejected]: (state, action) => {
      state.loading = false;
      state.successfullyClientEdited = false;
      state.error = action.error;
    },
    [updateClientGoal.pending]: (state) => {
      state.loading = true;
      state.currentGoalRequests = state.currentGoalRequests + 1;
    },
    [updateClientGoal.fulfilled]: (state, action) => {
      state.goal = action.payload?.goal;
      state.body = action.payload?.body;
      state.loading = false;
      state.currentGoalRequests = state.currentGoalRequests - 1;
    },
    [updateClientGoal.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
      state.currentGoalRequests = state.currentGoalRequests - 1;
    },
    [updateClientGoalDate.pending]: (state) => {
      state.loading = true;
      state.currentDateRequests = state.currentDateRequests + 1;
    },
    [updateClientGoalDate.fulfilled]: (state, action) => {
      state.goal = action.payload?.goal;
      state.body = action.payload?.body;
      state.loading = false;
      state.currentDateRequests = state.currentDateRequests - 1;
    },
    [updateClientGoalDate.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
      state.currentDateRequests = state.currentDateRequests - 1;
    },
  },
});

export const {
  clearClient,
  clearStatuses,
  clearSuccessfullySendBodyTest,
  clearSuccessfullySendStrengthTest,
  clearSuccessfullyClientEdited,
  clearSuccessfullyClientAdded,
  clearError,
  setGoalView,
  setGoals,
} = clientSlice.actions;

export default clientSlice.reducer;

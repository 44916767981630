import { useSelector, useDispatch } from "react-redux";

import menuIcon from "../../assets/icons/menu.png";
import logoLite from "../../assets/images/recomposer_lite_logo.png";
import logo from "../../assets/images/recomposerapp.png";
import { ENDUSER_COMPANY } from "../../constants/common";
import { setShowMenu } from "../../redux/windowSlice";

const Header = () => {
  const dispatch = useDispatch();
  const companyId = 1; // TODO: получать из redux  //const { companyId } = useSelector(state => ...)
  const isEndUserClient = companyId === ENDUSER_COMPANY.ID;
  const showMenu = useSelector((state) => state.window.showMenu);
  const showHeader = useSelector((state) => state.window.showHeader);

  const toggleMenu = () => {
    dispatch(setShowMenu(true));
  };

  return (
    <div>
      {showHeader && (
        <header id="top-header-bar" className="top-header-bar">
          {!showMenu && (
            <img alt="Menu icon" onClick={toggleMenu} src={menuIcon} />
          )}
          <img
            alt="Logo"
            className="header_logo"
            src={isEndUserClient ? logoLite : logo}
          />
        </header>
      )}
    </div>
  );
};

export default Header;

import cn from "classnames";
import PropTypes from "prop-types";
import React, { memo } from "react";
import "./TabItem.scss";

const TabItem = ({ item, onClick, isActive }) => {
  const { value } = item;
  return (
    <li
      className={cn("tab-panel-item", {
        "tab-panel-item__active": isActive,
      })}
      onClick={() => onClick(item)}
    >
      {value}
    </li>
  );
};

TabItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(TabItem);

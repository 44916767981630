import RcSlider from "rc-slider";
import "./GoalSlider.scss";

const GoalSlider = ({ ...props }) => {
  return (
    <div className="rc-slider-wrap">
      <RcSlider tipFormatter={null} {...props} />
    </div>
  );
};

GoalSlider.propTypes = {};

export default GoalSlider;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie"

import myFetch, { authHandlerError } from "../api/myFetch";
import { baseUrl } from "../env/env";
import * as Sentry from "@sentry/react";

export const authClient = createAsyncThunk("auth/login", (body) => {
  return myFetch(
    `${baseUrl}/api/login`,
    { method: "POST", body: body },
    authHandlerError
  );
});

export const logoutClient = createAsyncThunk("auth/logout", () => {
  return myFetch(`${baseUrl}/api/logout`, {});
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    error: false,
    authStatus: false,
    user_id: null,
    user: null,
    token: null,
    errorMessage: "",
  },
  reducers: {},
  extraReducers: {
    [authClient.pending]: (state) => {
      state.authStatus = false;
      state.loading = true;
      state.error = false;
      state.errorMessage = "";
    },
    [authClient.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = false;
      state.authStatus = true;
      state.errorMessage = "";
      Cookies.set("user_id", action.payload.user_id, { expires: 30, path: "" });
      Cookies.set("token", action.payload.token, { expires: 30, path: "" });
    },
    [logoutClient.fulfilled]: (state) => {
      state.authStatus = false;
      state.user_id = null;
      state.user = null;
      state.token = null;
      state.errorMessage = "";
      Cookies.remove("user_id");
      Cookies.remove("token");
      Sentry.setUser({});
    },
    [authClient.rejected]: (state, action) => {
      state.authStatus = false;
      state.loading = false;
      state.error = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default authSlice.reducer;

//import "../../../assets/styles-old/pages/diets/index.scss";
import "./ListHeader.scss";
import Search from "../../Search";

const ListHeader = ({ header, searchValue, onSearchChange, cellCount }) => (
  <thead>
    <tr>
      <td colSpan={cellCount * 2} className="list-search-wrapper">
        <Search value={searchValue} onChange={onSearchChange} />
      </td>
    </tr>
    <tr>
      {header.map((item, index) => (
        <td
          className="list__cell--header"
          key={index}
          style={item.style || null}
          colSpan={item.colspan || null}
        >
          {item.label}
        </td>
      ))}
    </tr>
  </thead>
);

export default ListHeader;

import { Modal } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ADD_FOOD_SUCCESS,
  DELETE_FOOD_SUCCESS,
  EDIT_FOOD_SUCCESS,
  SIDEBAR_NAMES,
} from "../../constants/diet";
import { CUSTOM_OPTIONS, OPTIONS_LIST } from "../../constants/foods";
import { REGEXP_FORM_FLOAT_NUM_FIXED_FOUR } from "../../constants/regexp";
import {validateRequiredString, validateRequired} from "../../helpers/validators";

import "./FoodEditor.scss";
import useIsSmallScreen from "../../hooks/useIsSmallScreen";
import { useToastr } from "../../hooks/useToastr";
import {
  addFood,
  clearSuccessfullyFoodAdded,
  clearSuccessfullyFoodDeleted,
  clearSuccessfullyFoodEdited,
  deleteFood,
  editFood,
  setEditableFoodItem,
} from "../../redux/foodsSlice";
import Button from "../Button";
import FormField from "../FormField";
import Widget from "../widget/Widget";

const ModalWindow = ({ open, closeModal }) => (
  <Modal open={open} className="food-editor__modal">
    <div className="food-editor__modal-inner">
      <div className="field-food-label">Input custom measure</div>
      <Formik
        validateOnChange={false}
        initialValues={{ name: "" }}
        onSubmit={(values) => {
          closeModal(values);
        }}
      >
        {() => (
          <Form>
            <FormField
              name="name"
              type="text"
              validate={(value) => validateRequiredString(value)}
              isInline={false}
              inputDivClass="food-editor__formfield"
            />
            <div className="food-editor__btn-wrapper">
              <Button onClick={closeModal}>cancel</Button>
              <Button type="submit">save</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </Modal>
);

const FormikContext = ({ options, setOptions }) => {
  const formik = useFormikContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (formik.values.measure === CUSTOM_OPTIONS) {
      setIsModalOpen(true);
      formik.setFieldValue("measure", "");
    }
    const isDefaultOpt = OPTIONS_LIST.find(
      (o) => o.value === formik.values.measure
    );
    if (!isDefaultOpt && formik.values.measure) {
      setNewOpt(formik.values.measure);
    }
  }, [formik.values.measure]);

  const closeModal = (val) => {
    setIsModalOpen(false);
    const valOrEmpty = val?.name || "";
    formik.setFieldValue("measure", valOrEmpty);
  };

  const setNewOpt = (value) => {
    const newOption = [...options];
    newOption[0] = { value: value, label: value };
    setOptions(newOption);
  };

  return <ModalWindow closeModal={closeModal} open={isModalOpen} />;
};

const FoodEditor = ({ setSideBar, sideBarName, stateAfterSubmit }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState(OPTIONS_LIST);
  const loading = useSelector((state) => state.foods.loadingCRUD);
  const editableFoodItem = useSelector((state) => state.foods.editableFoodItem);
  const successfullyFoodAdded = useSelector(
    (state) => state.foods.successfullyFoodAdded
  );
  const successfullyFoodEdited = useSelector(
    (state) => state.foods.successfullyFoodEdited
  );
  const successfullyFoodDeleted = useSelector(
    (state) => state.foods.successfullyFoodDeleted
  );
  const isSmallScreen = useIsSmallScreen();
  const measure = !isSmallScreen ? "unit of measure" : "measure";

  useToastr({
    messages: ADD_FOOD_SUCCESS,
    deps: successfullyFoodAdded,
    cb: () => dispatch(clearSuccessfullyFoodAdded()),
  });
  useToastr({
    messages: EDIT_FOOD_SUCCESS,
    deps: successfullyFoodEdited,
    cb: () => dispatch(clearSuccessfullyFoodEdited()),
  });
  useToastr({
    messages: DELETE_FOOD_SUCCESS,
    deps: successfullyFoodDeleted,
    cb: () => dispatch(clearSuccessfullyFoodDeleted()),
  });

  useEffect(() => {
    if (sideBarName === SIDEBAR_NAMES.FOOD_ADD && editableFoodItem)
      dispatch(setEditableFoodItem(null));
  }, [sideBarName]);

  const onSubmit = async (body) => {
    switch (sideBarName) {
      case SIDEBAR_NAMES.FOOD_ADD:
        await dispatch(addFood({ body }));
        break;
      case SIDEBAR_NAMES.FOOD_EDIT:
        await dispatch(editFood({ body, id: editableFoodItem.id }));
        break;
      case SIDEBAR_NAMES.FOOD_DELETE:
        await dispatch(deleteFood({ id: editableFoodItem.id }));
        break;
      default:
        break;
    }
    setSideBar(stateAfterSubmit);
  };

  return (
    <Widget className="food-editor">
      <Widget.Header className="pl-10">
        <Widget.Title>{sideBarName}</Widget.Title>
      </Widget.Header>
      <Widget.Body>
        <div className="foods-block">
          <Formik
            validateOnChange={true}
            enableReinitialize={true}
            initialValues={{
              name: editableFoodItem?.name || "",
              measure: editableFoodItem?.measure || "",
              proteins: editableFoodItem?.proteins.toString() || "",
              carbs: editableFoodItem?.carbs.toString() || "",
              fats: editableFoodItem?.fats.toString() || "",
            }}
            onSubmit={(values) => {
              return onSubmit(values);
            }}
          >
            {() => {
              return (
                <Form>
                  <>
                    <FormikContext setOptions={setOptions} options={options} />
                    <FormField
                      label="food name"
                      name="name"
                      type="text"
                      disabled={
                        loading || sideBarName === SIDEBAR_NAMES.FOOD_DELETE
                      }
                      validate={(value) => validateRequired(value)}
                      isInline={false}
                      inputDivClass="food-editor__formfield"
                    />
                    <FormField
                      label={measure}
                      name="measure"
                      type="select"
                      as="select"
                      disabled={
                        loading || sideBarName === SIDEBAR_NAMES.FOOD_DELETE
                      }
                      validate={(value) => validateRequired(value)}
                      isInline={true}
                      inputDivClass="food-editor__formfield food-editor__foods-select"
                      options={options}
                    />
                    <div className="foods-inline food-editor__foods-inline">
                      <FormField
                        label="protein"
                        name="proteins"
                        type="number"
                        allowRegexp={REGEXP_FORM_FLOAT_NUM_FIXED_FOUR}
                        disabled={
                          loading || sideBarName === SIDEBAR_NAMES.FOOD_DELETE
                        }
                        validate={(value) => validateRequired(value)}
                        isInline={false}
                        inputDivClass="food-editor__formfield"
                      />
                      <FormField
                        label="carb"
                        name="carbs"
                        type="number"
                        allowRegexp={REGEXP_FORM_FLOAT_NUM_FIXED_FOUR}
                        disabled={
                          loading || sideBarName === SIDEBAR_NAMES.FOOD_DELETE
                        }
                        validate={(value) => validateRequired(value)}
                        isInline={false}
                        inputDivClass="food-editor__formfield"
                      />
                      <FormField
                        label="fat"
                        name="fats"
                        type="number"
                        allowRegexp={REGEXP_FORM_FLOAT_NUM_FIXED_FOUR}
                        disabled={
                          loading || sideBarName === SIDEBAR_NAMES.FOOD_DELETE
                        }
                        validate={(value) => validateRequired(value)}
                        isInline={false}
                        inputDivClass="food-editor__formfield"
                      />
                    </div>
                    {sideBarName === SIDEBAR_NAMES.FOOD_EDIT && (
                      <Button
                        variant="warning"
                        disabled={loading}
                        onClick={() => setSideBar(SIDEBAR_NAMES.FOOD_DELETE)}
                      >
                        delete
                      </Button>
                    )}
                    <Button
                      variant={
                        sideBarName === SIDEBAR_NAMES.FOOD_DELETE
                          ? "warning"
                          : "primary"
                      }
                      className="food-editor__btn"
                      type="submit"
                      disabled={loading}
                    >
                      {sideBarName === SIDEBAR_NAMES.FOOD_DELETE
                        ? "confirm delete"
                        : "save"}
                    </Button>
                  </>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Widget.Body>
    </Widget>
  );
};

export default FoodEditor;

import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../../../components/Spinner";
import Widget from "../../../../../components/widget/Widget";
import { WIDGET_VIEW_TYPES } from "../../../../../constants/trainers";
import {
  sClients,
  sCurrentTrainer,
  sLoadingAllClients,
  sLoadingActiveClients,
} from "../../../../../redux/trainersSelectors";
import {
  loadActiveClients,
  loadAllClients,
} from "../../../../../redux/trainersSlice";

import TrainerClient from "./components/TrainerClient/TrainerClient";
import "./TrainerClients.scss";

const TrainerClients = ({ title }) => {
  const dispatch = useDispatch();
  const loadingAllClients = useSelector(sLoadingAllClients);
  const loadingActiveClients = useSelector(sLoadingActiveClients);

  const currentTrainer = useSelector(sCurrentTrainer);
  const clients = useSelector((state) => sClients(state, { title }));

  const isActiveClients = title === WIDGET_VIEW_TYPES.ACTIVE_CLIENTS;
  const isAllClients = title === WIDGET_VIEW_TYPES.ALL_CLIENTS;

  const spinnerCondition =
    (isActiveClients && loadingActiveClients) ||
    (isAllClients && loadingAllClients);

  const contentCondition =
    (isAllClients && !loadingAllClients) ||
    (isActiveClients && !loadingActiveClients);

  useEffect(() => {
    if (title === WIDGET_VIEW_TYPES.ALL_CLIENTS) {
      dispatch(loadAllClients(currentTrainer.id));
    }
    if (isActiveClients) {
      dispatch(loadActiveClients(currentTrainer.id));
    }
  }, [currentTrainer.id, title]);

  const trainerName = useMemo(
    () =>
      currentTrainer?.name ||
      `${currentTrainer?.first_name} ${currentTrainer?.last_name}`,
    [currentTrainer]
  );

  return (
    <Widget>
      <Widget.Header className="pl-10">
        <Widget.Title>{title}</Widget.Title>
      </Widget.Header>
      <Widget.Body className="trainer-clients__body" isScrollable>
        <div className="trainer-clients__subtitle">{`${trainerName}'s clients`}</div>
        {spinnerCondition && (
          <div className="trainer-clients__spinner">
            <Spinner />
          </div>
        )}
        {contentCondition &&
          clients?.map((client) => (
            <TrainerClient client={client} key={client?.id} />
          ))}
      </Widget.Body>
    </Widget>
  );
};

TrainerClients.propTypes = {};

export default TrainerClients;

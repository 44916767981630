import debounce from "lodash.debounce";
import { useEffect, useState, useMemo } from "react";

import { DELAY_FOR_SEARCH } from "../constants/common";
import { filterList } from "../helpers";

import useCallbackWithCondition from "./useCallbackWithCondition";

const useSearchHook = (initialList, setTransformList) => {
  const [searchValue, setSearchValue] = useState(null);

  let debounceSearch;
  debounceSearch = useMemo(() => {
    debounceSearch?.cancel();
    return debounce((_searchValue) => {
      setTransformList(filterList(initialList, _searchValue));
    }, DELAY_FOR_SEARCH);
  }, [initialList]);

  useEffect(() => debounceSearch.cancel, []);

  useCallbackWithCondition(searchValue, null, debounceSearch);

  const onSearchChange = (e) => setSearchValue(e.target.value);

  return [searchValue, onSearchChange];
};

export default useSearchHook;

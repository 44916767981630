import { PropTypes } from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import {
  calculateCalories,
  calculateFoodEnergy,
  calculateFoodEnergyInPercent,
  calculateMinimumCalories,
} from "../../../../../helpers/foods";

const FoodTotal = ({ foods, isHistory}) => {
  const client = useSelector((state) => state.client.client);
  const body = useSelector((state) => state.client.body);
  const foodData = calculateFoodEnergy(foods);
  const calories = calculateCalories(foodData);
  const foodDataPercent = calculateFoodEnergyInPercent(foodData, calories);
  const [minCal, percent] = calculateMinimumCalories(
    calories,
    client.mass_unit,
    body
  );

  return (
    <>
      <tr className="diet-table__row diet-table__row--white">
        <td colSpan="5" className="white_tr">
          {`    ${calories.toFixed(0)} cal    p: ${foodData.proteins.toFixed(
            0
          )}g    c: ${foodData.carbs.toFixed(0)}g    f: ${foodData.fats.toFixed(
            0
          )}g`}
        </td>
      </tr>
      {isHistory && (
        <tr className="diet-table__row diet-table__row--white">
          <td colSpan="5" className="white_tr">
            {`    ${percent.toFixed(0)}% min     ${
              foodDataPercent.proteins
            }%        ${foodDataPercent.carbs}%        ${foodDataPercent.fats}%`}
          </td>
        </tr>
      )}
    </>
  );
};

FoodTotal.defaultProps = {
  isHistory: false,
}

FoodTotal.propTypes = {
  isHistory: PropTypes.bool,
  foods: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FoodTotal;

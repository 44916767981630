import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../../components/Button";
import DeleteClient from "../../../components/DeleteClient";
import MobileDrawer from "../../../components/MobileDrawer/MobileDrawer";
import List from "../../../components/list/List";
import Widget from "../../../components/widget/Widget";
import "./ClientListPage.scss";
import { CLIENT_LIST_HEADER } from "../../../constants/clientList";
import { DELAY_FOR_SEARCH, USER_TYPES } from "../../../constants/common";
import { ITEMS_PER_PAGE } from "../../../constants/common";
import { CLIENT, BODY_TEST_RESULT_PAGE_SLUG } from "../../../constants/router";
import { TOASTR_TYPE } from "../../../constants/toastr";
import { getFullName } from "../../../helpers";
import { useCallDebouncedCbWithAbort } from "../../../hooks/useCallDebouncedCb";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import { useSearchParam } from "../../../hooks/useSearchParam";
import { useToastr } from "../../../hooks/useToastr";
import {
  setFilter,
  clearClientForDelete,
  setForDeleteClient,
  clearFilter,
  clearClientList,
  loadClientListThunk,
  clearError,
} from "../../../redux/clientListSlice";
import { clearClient } from "../../../redux/clientSlice";

import useIsUserType from "./../../../hooks/useIsUserType";

const prepareList = (list) =>
  list?.map((item) => ({
    id: item.id,
    name: getFullName(item),
    status: item.is_active ? "active" : "inactive"
  }));

const ClientListPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [companyId] = useSearchParam("companyId");
  const clients = useSelector((state) => state.clientList.clients);
  const clientForDeleteId = useSelector(
    (state) => state.clientList.clientForDelete?.id
  );
  const page = useSelector((state) => state.clientList.page);
  const totalCount = useSelector((state) => state.clientList.totalCount);
  const filter = useSelector((state) => state.clientList.filter);
  const loading = useSelector((state) => state.clientList.loading);
  const error = useSelector((state) => state.clientList.error);
  const isSmallScreen = useIsSmallScreen();
  const isSuperUser = useIsUserType(USER_TYPES.SUPERUSER);

  const [isShowDelete, setIsShowDelete] = useState(false);

  useCallDebouncedCbWithAbort(
    (filter) =>
      dispatch(
        loadClientListThunk({
          page: 1,
          search: filter,
          ...(isSuperUser && { companyId }),
        })
      ),
    [filter, companyId],
    DELAY_FOR_SEARCH,
    [filter, companyId]
  );

  useEffect(() => {
    dispatch(clearClient());

    return () => {
      dispatch(clearFilter());
      dispatch(clearClientList());
    };
  }, []);

  useToastr({
    messages: error,
    deps: error,
    type: TOASTR_TYPE.ERROR,
    cb: () => dispatch(clearError()),
  });

  const list = prepareList(clients);

  const changeFilter = (e) => {
    dispatch(setFilter(e.target.value));
  };

  const deleteItemHandler = (item) => {
    const client = clients.find((c) => c.id === item.id);
    dispatch(setForDeleteClient(client));
    setIsShowDelete(true);
  };

  const closeDeleteWidget = () => {
    dispatch(clearClientForDelete());
    setIsShowDelete(false);
  };

  const itemClickHandler = (id) => {
    dispatch(clearClient());
    history.push(CLIENT.root + id + BODY_TEST_RESULT_PAGE_SLUG);
  };

  const loadPrevPage = () => {
    if (page > 1)
      dispatch(
        loadClientListThunk({
          page: page - 1,
          search: filter,
          ...(isSuperUser && { companyId }),
        })
      );
  };

  const loadNextPage = () => {
    if (totalCount > page * ITEMS_PER_PAGE)
      dispatch(
        loadClientListThunk({
          page: page + 1,
          search: filter,
          ...(isSuperUser && { companyId }),
        })
      );
  };

  return (
    <div className="content-rhs content">
      <div className="clients-page__content page-content d-flex w-100">
        <div className="clients-page__body-main">
          <Widget>
            <Widget.Header className="pl-10">
              <Widget.Title>clients</Widget.Title>
              <Button
                onClick={() => {
                  history.push(CLIENT.root + CLIENT.addClient);
                }}
                disabled={false}
              >
                add client
              </Button>
            </Widget.Header>
            <Widget.Body className="widget-body--with-mh">
              <List
                currentItem={clientForDeleteId}
                isEdit={false}
                isDelete={isShowDelete}
                header={CLIENT_LIST_HEADER}
                list={list}
                page={page}
                totalCount={totalCount}
                loadPrevPage={loadPrevPage}
                loadNextPage={loadNextPage}
                deleteItemHandler={deleteItemHandler}
                itemClickHandler={itemClickHandler}
                searchValue={filter}
                onSearchChange={changeFilter}
                isLoading={loading}
                isShowPagination={true}
              />
            </Widget.Body>
          </Widget>
        </div>
        <div className="clients-page__body-side">
          {isSmallScreen && (
            <MobileDrawer onClose={closeDeleteWidget} open={isShowDelete}>
              <DeleteClient closeWidget={closeDeleteWidget} />
            </MobileDrawer>
          )}
          {!isSmallScreen && isShowDelete && (
            <DeleteClient closeWidget={closeDeleteWidget} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientListPage;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Divider from "../../../../../components/Divider";
import Spinner from "../../../../../components/Spinner";
import Widget from "../../../../../components/widget/Widget";
import { trainerFormVariants } from "../../../../../constants/trainers";
import { useToastr } from "../../../../../hooks/useToastr";
import {
  clearSuccessfullyTrainerDeleted,
  deleteTrainer,
  loadTrainerById,
} from "../../../../../redux/trainersSlice";
import TrainerForm from "../TrainerForm/TrainerForm";
import "./DeleteTrainer.scss";

const DeleteTrainer = () => {
  const dispatch = useDispatch();
  const successfullyTrainerDeleted = useSelector(
    (state) => state.trainers.successfullyTrainerDeleted
  );
  const loading = useSelector((state) => state.trainers.loadingById);
  const currentTrainer = useSelector((state) => state.trainers.currentTrainer);

  useEffect(() => {
    if (currentTrainer?.id) dispatch(loadTrainerById(currentTrainer.id));
  }, [currentTrainer?.id]);

  useToastr({
    messages: "Trainer deleted!",
    deps: successfullyTrainerDeleted,
    cb: () => dispatch(clearSuccessfullyTrainerDeleted()),
  });

  const onSubmit = () => {
    dispatch(deleteTrainer({ id: currentTrainer.id }));
  };

  return (
    <Widget>
      <Widget.Header className="pl-10">
        <Widget.Title>delete coach</Widget.Title>
      </Widget.Header>
      <Widget.Body className="delete-trainer">
        {loading && <Spinner />}
        {!loading && (
          <TrainerForm
            isEditPage={false}
            variant={trainerFormVariants.DELETE}
            initialValues={currentTrainer}
            onSubmit={onSubmit}
          />
        )}
        <Divider />
      </Widget.Body>
    </Widget>
  );
};

DeleteTrainer.propTypes = {};

export default DeleteTrainer;

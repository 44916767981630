import React, { memo } from "react";

import DietTableContent from "./components/DietTableContent";
import DietTableTabs from "./components/DietTableTabs";

const DietTable = ({ setSideBar, setIsOpenDrawer, sideBar }) => {
  return (
    <>
      <DietTableTabs />
      <DietTableContent
        setIsOpenDrawer={setIsOpenDrawer}
        setSideBar={setSideBar}
        sideBar={sideBar}
      />
    </>
  );
};

export default memo(DietTable);

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { FOOD_PREVIEW_ITEMS } from "../constants/diet";

const useGetFoodsForDietPage = () => {
  const selectedDietIndex = useSelector(
    (state) => state.dietPage.selectedDietIndex
  );
  const isShowAllFoods = useSelector(
    (state) => state.dietPage.isShowAllFoods
  );
  const currentDiets = useSelector((state) => state.dietPage.diets);
  const [foods, setFoods] = useState([]);
  useEffect(() => {
    if (!currentDiets[selectedDietIndex]?.foods) {
      return;
    }
    if (!isShowAllFoods[selectedDietIndex]) {
      setFoods(
        currentDiets[selectedDietIndex]?.foods.slice(0, FOOD_PREVIEW_ITEMS)
      );
      return;
    }
    setFoods(currentDiets[selectedDietIndex]?.foods);
  }, [
    currentDiets[selectedDietIndex]?.foods,
    isShowAllFoods[selectedDietIndex],
  ]);

  return foods;
};

export default useGetFoodsForDietPage;

import { KG, LB } from "./unit";

export const GENDER = [
  {
    value: "Male",
    label: "male",
  },
  {
    value: "Female",
    label: "female",
  },
];

export const DATE_MASK = [
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const MASS_UNITS = [
  {
    value: KG,
    label: KG,
  },
  {
    value: LB,
    label: LB,
  },
];

export const SELECT = "select";

export const MAX_HEIGHT = 300;

export const MIN_HEIGHT = 100;

export const COMPLIANCE_OPTIONS = [
  {
    value: "100",
    label: "100%",
  },
  {
    value: "90",
    label: "90%",
  },
  {
    value: "80",
    label: "80%",
  },
  {
    value: "70",
    label: "70%",
  },
  {
    value: "60",
    label: "60%",
  },
  {
    value: "50",
    label: "50%",
  },
  {
    value: "40",
    label: "40%",
  },
  {
    value: "30",
    label: "30%",
  },
  {
    value: "20",
    label: "20%",
  },
  {
    value: "10",
    label: "10%",
  },
  {
    value: "0",
    label: "0%",
  },
  {
    value: "-1",
    label: "iifym",
  },
];

export const ALCO_OPTIONS = [
  {
    value: "0",
    label: "0",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3+",
  },
];

export const DEVICE_OPTIONS = [
  {
    value: "",
    label: "select...",
    disabled: true,
  },
  {
    value: "dexa",
    label: "DEXA",
  },
  {
    value: "inbody",
    label: "InBody",
  },
  {
    value: "h2o",
    label: "H2O",
  },
  {
    value: "skulpt",
    label: "Skulpt",
  },
  {
    value: "bia",
    label: "BIA",
  },
  {
    value: "bodpod",
    label: "BodPod",
  },
  {
    value: "deuterium",
    label: "Deuterium",
  },
  {
    value: "other",
    label: "Other",
  },
];

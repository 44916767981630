import "./AuthPage.scss";
import { ErrorMessage, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { ERRORS_MESSAGE } from "../../../constants/auth";
import FormField from "../../../components/FormField/FormField";
import Spinner from "../../../components/Spinner";

const AuthForm = ({ onSubmit, forgotPassword }) => {
  const error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);
  const errorMessage = useSelector((state) => state.auth.errorMessage);

  const isUnknownError = !Object.values(ERRORS_MESSAGE).includes(errorMessage);

  return (
    <div className="form-auth">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string().required("please enter email"),
          password: Yup.string().required("please enter password"),
        })}
      >
        {() => (
          <Form>
            {loading && <Spinner />}
            {error && (
              <div className="auth__error">
                {isUnknownError
                  ? "an error has occurred, please try again later"
                  : errorMessage}
              </div>
            )}
            <FormField
              placeholder="email@example.com"
              className="auth-field"
              type="email"
              name="email"
              autoComplete="on"
            />
            <ErrorMessage component="div" name="email" className="auth-error" />
            <FormField
              placeholder="password"
              type="password"
              className="auth-field"
              name="password"
              autoComplete="current-password"
            />
            <ErrorMessage
              component="div"
              className="auth-error"
              name="password"
            />
            <button className="default-btn auth-btn" type="submit">
              login
            </button>
          </Form>
        )}
      </Formik>
      <div className="auth-link-wrapper">
        <div onClick={forgotPassword} className="auth-link">
          forgot password?
        </div>
      </div>
    </div>
  );
};

AuthForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AuthForm;

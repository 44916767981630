import React from "react";

import CompanyDropZone from "../../../../../components/CompanyDropZone";
import Widget from "../../../../../components/widget/Widget";

const ThemeSideBar = () => {
  return (
    <Widget>
      <Widget.Header>
        <Widget.Title>
          theme
        </Widget.Title>
      </Widget.Header>
      <Widget.Body>
        <CompanyDropZone isProfilePage />
      </Widget.Body>
    </Widget>
  );
};

ThemeSideBar.propTypes = {

};

export default ThemeSideBar;

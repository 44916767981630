import { faBolt, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { saveProfile } from "../../redux/userSlice";

const FavIcon = ({ favs, type, id }) => {
  const isFav = favs.includes(id);
  const dispatch = useDispatch();

  const toggle = (e) => {
    e.stopPropagation();
    if (!isFav) {
      dispatch(saveProfile({ [type]: [...favs, id] }));
      return;
    }
    const newFavs = [...favs].filter((i) => i !== id);
    dispatch(saveProfile({ [type]: newFavs }));
  };
  return (
    <span onClick={toggle} className="fa-layers fa-fw">
      <FontAwesomeIcon icon={faHeart} className="program-control" />
      {isFav && (
        <FontAwesomeIcon
          icon={faBolt}
          className="fa-inverse program-control__fav"
        />
      )}
    </span>
  );
};

FavIcon.propTypes = {
  favs: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
};

export default memo(FavIcon);

import "./main.scss";

import ReactDOM from "react-dom";
import TagManager from 'react-gtm-module';
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import {SENTRY_DSN, ENV, TAG_MANAGER_ID} from "./env/env";
import { store } from "./redux";
import * as serviceWorker from './helpers/serviceWorker';
import reportWebVitals from "./reportWebVitals";

TagManager.initialize({
  gtmId: TAG_MANAGER_ID
});

Sentry.init({
  dsn: SENTRY_DSN,
  beforeBreadcrumb: (breadcrumb, hint) => (
    breadcrumb?.category === 'console' ? null : breadcrumb
  ),
  maxBreadcrumbs: 5,
  environment: ENV,
  normalizeDepth: 4,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
serviceWorker.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

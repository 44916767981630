import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { CLIENT_PERM } from "../../../../../../../constants/clientInfo";
import { BasicHelper } from "../../../../../../../helpers";
import useCheckClientPermission from "../../../../../../../hooks/useCheckClientPermission";
import { setSelectedExercise } from "../../../../../../../redux/programsSlice";
import ExerciseName from "../ExerciseName";
import PbInput from "../PbInput";
import SetButton from "../SetButton";

function ExerciseRow({
  data,
  workoutIdx,
  dayIdx,
  exerciseIdx,
  isPbEditOpen,
  setRightWidgetPerformanceAndHistory,
  onPbEditOpen,
}) {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.client.client);
  const exercises = useSelector((state) => state.programs.exercises);
  const selectedExercise = useSelector(
    (state) => state.programs.selectedExercise
  );
  const selectedExerciseDayIdx = useSelector(
    (state) => state.programs.selectedExerciseDayIdx
  );
  const selectedExerciseWorkoutIdx = useSelector(
    (state) => state.programs.selectedExerciseWorkoutIdx
  );
  const selectedExerciseIdx = useSelector(
    (state) => state.programs.selectedExerciseIdx
  );
  const changeAllExercise = useSelector(
    (state) => state.programs.changeAllExercise
  );
  const isCanChangeExercise = useCheckClientPermission(
    CLIENT_PERM.CAN_CHANGE_EXERCISE
  );
  const onExerciseClick = (e) => {
    e.stopPropagation();
    if (!isCanChangeExercise) return;
    if (!exercises) {
      const payload = {
        data,
        dayIdx,
        workoutIdx,
        exerciseIdx,
      };
      dispatch(setSelectedExercise(payload));
    }
  };

  const isSelectedExercise =
    dayIdx === selectedExerciseDayIdx &&
    workoutIdx === selectedExerciseWorkoutIdx &&
    exerciseIdx === selectedExerciseIdx;
  const isSameExercise = data.id === selectedExercise?.id;
  const isAfter =
    dayIdx > selectedExerciseDayIdx ||
    (dayIdx === selectedExerciseDayIdx &&
      (workoutIdx > selectedExerciseWorkoutIdx ||
        (workoutIdx === selectedExerciseWorkoutIdx &&
          exerciseIdx > selectedExerciseIdx)));
  const isActive =
    isSelectedExercise || (changeAllExercise && isSameExercise && isAfter);

  return (
    <div className="training-day__exercise-row">
      <PbInput
        weight={BasicHelper.roundTo(data.pb_value, 1)}
        massUnit={client.mass_unit}
        clientId={client?.id}
        exerciseId={data.id}
        onOpen={onPbEditOpen}
        isOpen={isPbEditOpen}
        isCalculated={data.pb_calculated}
      />
      <ExerciseName
        name={data.name}
        onClick={onExerciseClick}
        isActive={isActive}
      />
      <div className="training-day__exercise-list">
        {data.sets.map((set, idx) => (
          <SetButton
            setRightWidgetPerformanceAndHistory={setRightWidgetPerformanceAndHistory}
            key={set.id || idx}
            data={set}
            exerciseData={data}
            workoutIdx={workoutIdx}
            dayIdx={dayIdx}
            exerciseIdx={exerciseIdx}
            setIdx={idx}
          />
        ))}
      </div>
    </div>
  );
}

export default ExerciseRow;

import { CACHE_TIMEOUT } from "../constants/api";
import { baseUrl } from "../env/env";

import withCache from "./../helpers/cache";
import myFetch from "./myFetch";


const loadFoods = () => {
  return myFetch(`${baseUrl}/api/foods`);
}

const loadFoodsForCurrentUser = () => {
  return myFetch(`${baseUrl}/api/foods/current_user`);
}

export const loadFoodsWithCache = withCache(loadFoods, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const loadFoodsForCurrentUserWithCache = withCache(loadFoodsForCurrentUser, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const addFood = ({ body }) => {
  loadFoodsWithCache.invalidate();
  loadFoodsForCurrentUserWithCache.invalidate();
  return myFetch(`${baseUrl}/api/foods`, { method: "POST", body: body });
}

export const editFood = ({ body, id }) => {
  loadFoodsWithCache.invalidate();
  loadFoodsForCurrentUserWithCache.invalidate();
  return myFetch(`${baseUrl}/api/foods/${id}`, { method: "PUT", body: body });
}

export const deleteFood = ({ id }) => {
  loadFoodsWithCache.invalidate();
  loadFoodsForCurrentUserWithCache.invalidate();
  return myFetch(`${baseUrl}/api/foods/${id}`, {
    method: "DELETE",
  })
}

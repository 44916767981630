import { Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Spinner from "../../../components/Spinner";
import { TRAINING_PROGRAM_SLUG, CLIENT } from "../../../constants/router";
import { TOASTR_TYPE } from "../../../constants/toastr";
import { useToastr } from "../../../hooks/useToastr";
import { loadExercises } from "../../../redux/exercisesSlice";
import { setHistory } from "../../../redux/programTemplatesSlice";
import {
  clearError,
  getProgram,
  loadTraining,
  updateProgram,
} from "../../../redux/programsSlice";
import ProgramTemplateForm from "../../ProgramTemplates/ProgramTemplateForm/ProgramTemplateForm";
import {useDocumentTitle} from "../../../hooks/useDocumentTitle";

const ClientTrainingEditPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const numberOfPrograms = useSelector(
    (state) => state.programs.numberOfPrograms
  );
  const currentProgramIdx = useSelector(
    (state) => state.programs.currentProgramIdx
  );
  const client = useSelector((state) => state.client.client);
  const exercises = useSelector((state) => state.exercises.exercises);
  const error = useSelector((state) => state.programs.error);
  const loading = useSelector((state) => state.programs.loading);
  const clientId = client.id;

  const title = `${(client.first_name || "").toLowerCase()}'s training`;
  useDocumentTitle(title);

  useToastr({messages: error, deps: error, type: TOASTR_TYPE.ERROR, cb: () => dispatch(clearError())})

  useEffect(() => {
    const trainingPromise = dispatch(loadTraining({ id: clientId }));
    if (!(currentProgram && currentProgramIdx === numberOfPrograms - 1)) {
      dispatch(
        getProgram({
          id: clientId,
          awaitPromise: trainingPromise,
        })
      );
    }
    if (!exercises) dispatch(loadExercises());

    dispatch(setHistory([]));
  }, []);

  if (!currentProgram || !exercises || loading) return <Spinner />;
  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={{
        days: currentProgram.template.days?.map((d) => ({
          workouts: d.workouts?.map((w) => ({
            exercises: w.exercises?.map((e) => ({
              ...e,
              name: exercises?.find((ex) => e.id === ex.id)?.name,
            })),
          })),
        })),
        name: currentProgram.name,
        description: currentProgram.template.description,
      }}
      onSubmit={(values) => {
        const body = {
          program_index: currentProgramIdx,
          program_template: { days: values.days },
          name: values.name,
          description: values.description,
        };

        const params = {
          id: client.id,
          body,
          isLast: currentProgramIdx + 1 === numberOfPrograms,
        };

        dispatch(updateProgram(params)).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            history.push(`${CLIENT.root}${clientId}${TRAINING_PROGRAM_SLUG}`);
          }
        });
      }}
    >
      <ProgramTemplateForm title="edit program" isEditingClientProgram/>
    </Formik>
  );
};

export default ClientTrainingEditPage;

import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MAX_MEAL_COLUMN_COUNT } from "../../../../../constants/diet";
import useIsSmallScreen from "../../../../../hooks/useIsSmallScreen";
import { onChangeMealColumnCount } from "../../../../../redux/dietPageSlice";

const MealsHeader = () => {
  const dispatch = useDispatch();
  const isSmallScreen = useIsSmallScreen();
  const isShowPrePostWorkout = useSelector(
    (state) => state.dietPage.isShowPrePostWorkout
  );
  const mealColumnCount = useSelector(
    (state) => state.dietPage.mealColumnCount
  );
  const selectedDietIndex = useSelector(
    (state) => state.dietPage.selectedDietIndex
  );

  return (
    <>
      {[...Array(mealColumnCount[selectedDietIndex]).keys()].map((value) => (
        <td style={{ width: isSmallScreen ? "2%" : "10%" }} key={value}>
          <div className="diet-meals-label">{value + 1}</div>
        </td>
      ))}
      {isShowPrePostWorkout[selectedDietIndex] && (
        <td style={{ width: isSmallScreen ? "2%" : "20%" }}>
          <div className={"diet-meals-label"}>wo</div>
        </td>
      )}
      {(mealColumnCount[selectedDietIndex] < MAX_MEAL_COLUMN_COUNT ||
        !isShowPrePostWorkout[selectedDietIndex]) && (
        <td style={{ width: isSmallScreen ? "2%" : "10%" }}>
          <div className="diet-meals-label">
            <button
              className="btn-plus"
              onClick={() => dispatch(onChangeMealColumnCount())}
            >
              +
            </button>
          </div>
        </td>
      )}
    </>
  );
};

MealsHeader.propTypes = {};

export default memo(MealsHeader);

import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import PropTypes from "prop-types";
import React, { useRef, memo } from "react";

import "./ListItem.scss";
import TableTooltip from "../../TableTooltip/TableTooltip";

const ListItem = memo(
  ({
    item,
    deleteItemHandler,
    itemClickHandler,
    deleteDisabled,
    editDisabled,
    hideEditBtn,
    rightCellsProps,
    notLeftButtons,
    onClick,
    header,
    zeroRound,
    center,
    isCanRemove,
    useEllipsis,
    ...props
  }) => {
    const nameRef = useRef();
    const columns = Object.keys(item).filter(
      (key) => key !== "id" && key !== "name"
    );

    return (
      <tr
        className="list-item"
        style={header && { display: "flex" }}
        onClick={() => onClick(item)}
        {...props}
      >
        {!notLeftButtons && (
          <>
            <td className="table__delete-cell"
              style={
                header && header[0].hasOwnProperty("style")
                  ? header[0].style
                  : null
              }
            >
              <button
                type="button"
                className="remove-btn"
                disabled={deleteDisabled}
                hidden={!isCanRemove}
                onClick={deleteItemHandler}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </td>
            <td
              className="table__name-cell"
              style={
                header && header[1].hasOwnProperty("style")
                  ? header[1].style
                  : null
              }
              onClick={itemClickHandler}
            >
              <div className="table__name-cell-container">
                <div className="client-edit-button-wrapper">
                  <button
                    type="button"
                    className="edit-btn table__edit-btn"
                    disabled={editDisabled}
                    hidden={hideEditBtn}
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                    />
                  </button>
                </div>
                {useEllipsis ? (
                  <TableTooltip
                    ref={nameRef}
                    overlay={item.name}
                    hasOverflowingContent={true}
                    contentClassName="table__link"
                    contentWrapperClassName="ellipsis w-100"
                    content={item.name}
                  />
                ) : (
                  <span className="table__link">{item.name}</span>
                )}
              </div>
            </td>
          </>
        )}
        {columns.map((key, idx) => (
          <td
            className={cn({
              "table__common-cell table__common-cell--center": center,
            })}
            key={key}
            style={
              header && header[idx + 2]?.hasOwnProperty("style")
                ? header[idx + 2].style
                : null
            }
            {...rightCellsProps?.[key]}
          >
            <span>
              {zeroRound ? item?.[key]?.replace(/\.?0*$/, "") : item?.[key]}
            </span>
          </td>
        ))}
      </tr>
    );
  }
);

ListItem.propTypes = {
  item: PropTypes.object,
  deleteItemHandler: PropTypes.func,
  itemClickHandler: PropTypes.func,
  deleteDisabled: PropTypes.bool,
  editDisabled: PropTypes.bool,
  rightCellsProps: PropTypes.object,
  notLeftButtons: PropTypes.bool,
  onClick: PropTypes.func,
  isCanRemove: PropTypes.bool,
  useEllipsis: PropTypes.bool,
};

ListItem.defaultProps = {
  onClick: () => {},
  rightCellsProps: {},
  notLeftButtons: false,
  zeroRound: false,
  center: false,
  isCanRemove: true,
  useEllipsis: true,
};

export default ListItem;

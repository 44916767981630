import React, { memo } from "react";

import useIsSmallScreen from "../../../../../../../hooks/useIsSmallScreen";

const DietTableHeader = ({ isShowMeal }) => {
  const isSmallScreen = useIsSmallScreen();
  return (
    <>
      <td style={{ width: !isSmallScreen ? "5%" : "2%" }} />
      <td style={{ width: !isSmallScreen ? "7%" : "auto" }}>quantity</td>
      <td style={{ width: !isSmallScreen ? "55%" : "auto" }}>food</td>
      {!isShowMeal && (
        <>
          <td style={{ width: !isSmallScreen ? "11%" : "8%" }}>protein</td>
          <td style={{ width: !isSmallScreen ? "11%" : "8%" }}>carb</td>
          <td style={{ width: !isSmallScreen ? "11%" : "8%" }}>fat</td>
        </>
      )}
    </>
  );
};

export default memo(DietTableHeader);

import PropTypes from "prop-types";
import "./Graph.scss";

const Graph = ({
  mainContent,
  subContent,
  height,
  percentHeightMain,
  percentHeightSub,
}) => {
  const MIN_PERCENT_HEIGHT = 10;
  const MAX_PERCENT_HEIGHT = 100 - MIN_PERCENT_HEIGHT;

  const isNeedMinHeightSub = percentHeightSub < MIN_PERCENT_HEIGHT;
  const maxHeightSub = percentHeightSub > 100 ? 101 : percentHeightSub;
  // 101 чтобы граница элемента под ним перекрывалась, при 100 небольшое наложение происходит

  return (
    <div className="graph" style={{ height }}>
      {!!subContent && (
        <div
          className="graph__sub"
          style={{
            height: `${
              isNeedMinHeightSub ? MIN_PERCENT_HEIGHT : maxHeightSub
            }%`,
          }}
        >
          {subContent}
        </div>
      )}

      <div
        className="graph__main"
        style={{
          height: `${
            isNeedMinHeightSub ? MAX_PERCENT_HEIGHT : percentHeightMain || 100
          }%`,
        }}
      >
        {mainContent}
      </div>
    </div>
  );
};

Graph.propTypes = {
  mainContent: PropTypes.element,
  subContent: PropTypes.element,
  height: PropTypes.any,
  percentHeightMain: PropTypes.number,
  percentHeightSub: PropTypes.number,
};

Graph.defaultProps = {
  subContent: null,
};

export default Graph;

import { Formik } from "formik";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import Spinner from "../../../components/Spinner";
import { TEMPLATES_MESSAGES } from "../../../constants/templates";
import { permsFilter } from "../../../helpers";
import { useToastr } from "../../../hooks/useToastr";
import {
  loadCurrentProgramTemplate,
  editProgramTemplate,
  setHistory,
  clearSuccess,
} from "../../../redux/programTemplatesSlice";
import ProgramTemplateForm from "../ProgramTemplateForm/ProgramTemplateForm";

export const Context = React.createContext();

const ProgramTemplateEditPage = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const item = useSelector((state) => state.programTemplates.item);
  const loading = useSelector((state) => state.programTemplates.loading);
  const success = useSelector((state) => state.programTemplates.success);

  const userType = useSelector((state) => state.user.user?.type);
  const userId = useSelector((state) => state.user.user?.id);
  const userCompanyId = useSelector((state) => state.user.user?.company);

  useToastr({
    messages: TEMPLATES_MESSAGES.TEMPLATE_SAVED,
    deps: success,
    cb: () => dispatch(clearSuccess()),
  });

  useEffect(() => {
    dispatch(setHistory([]));
  }, []);

  useEffect(() => {
    dispatch(loadCurrentProgramTemplate(match.params.id));
  }, [match.params.id]);

  return (
    <>
      {loading && <Spinner />}
      {!loading && item && (
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize
          initialValues={item}
          onSubmit={(values) => {
            const body = { ...values };
            dispatch(editProgramTemplate({ id: item.id, body }));
          }}
        >
          <ProgramTemplateForm
            canDelete={permsFilter(userType, userId, userCompanyId)(item)}
          />
        </Formik>
      )}
    </>
  );
};

export default ProgramTemplateEditPage;

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import FavIcon from "../../../../../../components/FavIcon";
import { setSelectedExercises } from "../../../../../../redux/exercisesSlice";

function ExerciseRow({ data, onClick, setEditMode, perms }) {
  const dispatch = useDispatch();
  const favs = useSelector(state => state.user.user?.fav_exercises);

  const handleEdit = (e) => {
    e.stopPropagation();
    dispatch(setSelectedExercises(data));
    setEditMode();
  }

  return (
    <div
      onClick={onClick(data)}
      className={cn("exercise-row", { "exercise-row--pressed": false })}
    >
      <div className="exercise-row__title">{data.name}</div>
      <div className="exercise-row__buttons">
        <button
          type="button"
          className="add-exercise-block__icon-btn add-exercise-block__icon-btn--heart"
        >
          <FavIcon favs={favs} type="fav_exercises" id={data.id} />
        </button>
        {perms.includes(data.id) && <button
          type="button"
          className="add-exercise-block__icon-btn add-exercise-block__icon-btn--edit"
        >
          <FontAwesomeIcon icon={faEdit} onClick={handleEdit} />
        </button>}
      </div>
    </div>
  );
}

export default ExerciseRow;

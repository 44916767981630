import "./ProfilePage.scss";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../components/Spinner";
import Widget from "../../../components/widget/Widget";
import { DELAY_FOR_AUTO_SAVE } from "../../../constants/common";
import { TOASTR_TYPE } from "../../../constants/toastr";
import { useCallDebouncedCb } from "../../../hooks/useCallDebouncedCb";
import { useToastr } from "../../../hooks/useToastr";
import {
  clearError,
  clearSuccessSave,
  saveProfile,
} from "../../../redux/userSlice";

import ProfileForm from "./components/ProfileForm/ProfileForm";

const ProfilePage = () => {
  const dispatch = useDispatch();

  const [newProfile, setNewProfile] = useState(null);

  const successSave = useSelector((state) => state.user.successSave);
  const loading = useSelector((state) => state.user.loading);
  const error = useSelector((state) => state.user.error);
  const user = useSelector((state) => state.user.user);

  const prepareValues = (user) => ({
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    phone: user?.phone || "",
    email: user?.email || "",
    password: "",
    "re-enter password": "",
  });

  useToastr({
    messages: "Info was saved successfully",
    type: TOASTR_TYPE.SAVED,
    deps: successSave,
    cb: () => dispatch(clearSuccessSave()),
  });
  useToastr({
    messages: error,
    deps: error,
    type: TOASTR_TYPE.ERROR,
    cb: () => dispatch(clearError()),
  });

  const onSubmit = (values) => {
    const valuesCopy = Object.assign({}, values);
    if (!valuesCopy.password?.length) {
      delete valuesCopy.password;
    }
    setNewProfile(valuesCopy);
  };

  useCallDebouncedCb(
    (profile) => {
      if (profile && Object.keys(profile).length) {
        dispatch(saveProfile(profile));
      }
    },
    [newProfile],
    DELAY_FOR_AUTO_SAVE,
    [newProfile]
  );

  return (
    <div className="content-rhs content">
      <div className="page-content d-flex w-100">
        <div className="body-main left-widget__wrapper profile__widget">
          <Widget>
            <Widget.Header className="profile__form">
              <Widget.Title>my profile</Widget.Title>
            </Widget.Header>
            <Widget.Body className="profile__form">
              {loading && <Spinner />}
              {!loading && (
                <ProfileForm
                  initialValues={prepareValues(user)}
                  onSubmit={onSubmit}
                />
              )}
            </Widget.Body>
          </Widget>
        </div>
      </div>
    </div>
  );
};

ProfilePage.propTypes = {};

export default ProfilePage;

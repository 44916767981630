import { Box } from "@material-ui/core";
import { Form, Formik } from "formik";
import lodash from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";

import Button from "../../../../../components/Button";
import FormField from "../../../../../components/FormField";
import FormLabel from "../../../../../components/FormLabel";
import Widget from "../../../../../components/widget/Widget";
import {
  BODY_PART_OPTIONS,
  CUSTOM_STYLES, EXERCISE_TYPES,
  INITIAL_VALUES,
  MAX_EQUATION_BASE_OPTIONS,
  REP_CALCULATOR_OPTIONS,
} from "../../../../../constants/exercises";
import "../../ExercisesPage.scss";
import { SIDEBAR_MODE } from "../../../../../constants/sidebar";
import {
  validateRequired,
  validateExercisesMultiplier,
} from "../../../../../helpers/validators";
import {
  addExercises,
  deleteExercises,
  editExercises,
} from "../../../../../redux/exercisesSlice";

const prepareInitialValues = (selectedExercise) => {
  const initialValues = lodash.cloneDeep(selectedExercise);
  initialValues.body_part = initialValues.body_part.map((part) => {
    return BODY_PART_OPTIONS.find((option) => option.value === part);
  });
  return {
    name: initialValues.name,
    body_part: initialValues.body_part,
    rep_calculator: initialValues.rep_calculator,
    max_equation_base: initialValues.max_equation_base,
    max_equation_multiplier: initialValues.max_equation_multiplier,
  };
};

const ExerciseEditor = ({ sidebarMode: _sidebarMode, onBack, onDelete }) => {
  const dispatch = useDispatch();
  const selectedExercises = useSelector(
    (state) => state.exercises.selectedExercises
  );
  const loading = useSelector((state) => state.exercises.loading);

  const sidebarModeFromStore = useSelector((state) => state.exercises.sidebarMode);
  const sidebarMode = _sidebarMode || sidebarModeFromStore;

  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  useEffect(() => {
    if (!sidebarMode) return;

    if (sidebarMode !== SIDEBAR_MODE.ADD) {
      setInitialValues(prepareInitialValues(selectedExercises));
    } else {
      setInitialValues(INITIAL_VALUES);
    }
  }, [selectedExercises, sidebarMode]);

  const isSystemExercise = EXERCISE_TYPES.SYSTEM_REG.test(selectedExercises?.type);
  const canDelete = !isSystemExercise;

  const isDeleteMode = sidebarMode === SIDEBAR_MODE.DELETE;

  const deleteExercise = useCallback(() => {
    dispatch(deleteExercises({ id: selectedExercises.id }));
  }, [selectedExercises]);

  return (
    <Widget>
      <Widget.Header className="pl-10">
        <Widget.Title>{sidebarMode} exercise</Widget.Title>
        {onBack && (
          <Button type="button" onClick={onBack}>
            back
          </Button>
        )}
      </Widget.Header>
      <Widget.Body>
        <Formik
          disabled={isDeleteMode || loading}
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values) => {
            const body = {
              ...values,
              max_equation_multiplier: values.max_equation_multiplier?.toString().replace(",", "."),
              body_part: values.body_part.map((v) => v.value),
            };
            switch (sidebarMode) {
              case SIDEBAR_MODE.ADD:
                dispatch(addExercises({ body }));
                break;
              case SIDEBAR_MODE.EDIT:
                dispatch(editExercises({ body, id: selectedExercises.id }));
                break;
              default:
                break;
            }
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <FormField
                disabled={isDeleteMode}
                inputDivClass="exercise__exercise-element pt-7 pb-7"
                label="exercise name"
                name="name"
                type="text"
                validate={validateRequired}
              />
              <Box
                display="flex"
                padding="7px 0"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormLabel htmlFor="body_part" style={{ marginRight: "5px" }}>
                  body part
                </FormLabel>
                <CreatableSelect
                  isDisabled={isDeleteMode}
                  name="body_part"
                  isMulti
                  value={values.body_part}
                  styles={CUSTOM_STYLES}
                  defaultValue={BODY_PART_OPTIONS[10]}
                  onChange={(newValue, e) => {
                    if (
                      e?.option?.value === BODY_PART_OPTIONS[10].value ||
                      !newValue.length
                    ) {
                      setFieldValue("body_part", [BODY_PART_OPTIONS[10]]);
                      return;
                    }
                    newValue = newValue.filter(
                      (v) => v.value !== BODY_PART_OPTIONS[10].value
                    );
                    setFieldValue("body_part", newValue);
                  }}
                  options={BODY_PART_OPTIONS}
                />
              </Box>
              <FormField
                disabled={isDeleteMode}
                inputClassDefault="form-select w-100"
                inputDivClass="w-53 pt-7 pb-7"
                label="1rm calculator"
                name="rep_calculator"
                type="select"
                as="select"
                validate={validateRequired}
                isInline={true}
                options={REP_CALCULATOR_OPTIONS}
              />
              <Box
                display="flex"
                justifyContent="space-between"
                padding="7px 0"
              >
                <FormField
                  disabled={isDeleteMode}
                  label="1rm estimate base"
                  name="max_equation_base"
                  type="select"
                  as="select"
                  inputClassDefault="form-select w-100"
                  inputDivClass="w-60"
                  validate={validateRequired}
                  options={MAX_EQUATION_BASE_OPTIONS}
                />
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-end"
                  padding="7px 0"
                >
                  <span className="exercises__field-food-label">x</span>
                </Box>
                <FormField
                  disabled={isDeleteMode}
                  label="multiplier"
                  name="max_equation_multiplier"
                  type="text"
                  validate={validateExercisesMultiplier}
                  inputDivClass="w-30"
                />
              </Box>
              <Box display="flex" justifyContent="flex-end">
                {!isDeleteMode ? (
                  <>
                    {onDelete && canDelete && (
                      <Button
                        disabled={loading}
                        className="exercises__btn"
                        variant="warning"
                        onClick={onDelete}
                      >
                        delete
                      </Button>
                    )}
                    <Button
                      disabled={loading}
                      className="exercises__btn"
                      variant="primary"
                      type="submit"
                    >
                      save
                    </Button>
                  </>
                ) : (
                  <Button
                    disabled={loading}
                    className="exercises__btn"
                    variant="warning"
                    onClick={deleteExercise}
                  >
                    confirm delete
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Widget.Body>
    </Widget>
  );
};

ExerciseEditor.propTypes = {
  sidebarMode: PropTypes.string,
  onBack: PropTypes.func,
  onDelete: PropTypes.func,
};

ExerciseEditor.defaultProps = {
  onBack: null,
  onDelete: null,
};

export default ExerciseEditor;

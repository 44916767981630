import "./MonthlyChargesInfo.scss";

const MonthlyChargesInfo = () => {
  return (
    <div className={"monthly-charges"}>
      <span>your invoice is calculated according to the following daily rates (in usd):</span>
      <ol className={"monthly-charges__list"}>
        <li>
          <span>up to 300 ‘active days’*: 45c (USD)</span>
        </li>
        <li>
          <span>301-1000: 41c</span>
        </li>
        <li>
          <span>1001-2000: 37c</span>
        </li>
        <li>
          <span>2001-3000: 33c</span>
        </li>
        <li>
          <span>3001-4000: 29c</span>
        </li>
        <li>
          <span>4001+ : 25c</span>
        </li>
      </ol>
      <span>
        * ‘active days’ refers to the sum of days that each client is in the ‘active’ state during the 1 month invoice period
      </span>
    </div>
  );
};

export default MonthlyChargesInfo;

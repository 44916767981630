export const CLIENT_PERM = {
  CAN_CHANGE_EXERCISE: "can_change_exercise",
  CAN_CHANGE_PB: "can_change_pb",
  HIDE_DIET_HISTORY: "hide_diet_history",
  HIDE_WORKOUT_HISTORY: "hide_workout_history",
};

export const CHECKBOX_LIST = [
  {
    name: CLIENT_PERM.CAN_CHANGE_EXERCISE,
    label: "change exercises",
  },
  {
    name: CLIENT_PERM.CAN_CHANGE_PB,
    label: "change pbs",
  },
  {
    name: CLIENT_PERM.HIDE_DIET_HISTORY,
    label: "hide diet history",
  },
  {
    name: CLIENT_PERM.HIDE_WORKOUT_HISTORY,
    label: "hide workout history",
  },
];

export const WIDGETS = {
  HEALTH: "health",
  INJURES: "injures",
  DIET_HISTORY: "diet history",
  BODY_HISTORY: "body history",
  TRAINING: "training",
};

export const WIDGETS_PROPERTY = {
  [WIDGETS.HEALTH]: "general_health",
  [WIDGETS.BODY_HISTORY]: "weight_training_exp",
  [WIDGETS.INJURES]: "activity",
  [WIDGETS.TRAINING]: "training_environment",
  [WIDGETS.DIET_HISTORY]: "dietary_practices",
};

export const BTN_TOP_LIST = [
  {
    name: WIDGETS.HEALTH,
    widget: WIDGETS.HEALTH,
  },
  {
    name: WIDGETS.INJURES,
    widget: WIDGETS.INJURES,
  },
  {
    name: WIDGETS.DIET_HISTORY,
    widget: WIDGETS.DIET_HISTORY,
  },
];

export const BTN_BOT_LIST = [
  {
    name: WIDGETS.BODY_HISTORY,
    widget: WIDGETS.BODY_HISTORY,
  },
  {
    name: WIDGETS.TRAINING,
    widget: WIDGETS.TRAINING,
  },
];

export const WIDGET_HEALTH_STATE = {
  health_conditions: "",
  medications: "",
  food_alergies: "",
  signed_disclosure_form: "",
  notes: "",
};

export const WIDGET_INJURES_STATE = {
  current_injuries: "",
  old_injuries: "",
  physical_limitations: "",
  other_activities: "",
  notes: "",
};

export const WIDGET_DIET_HISTORY_STATE = {
  meal1: "",
  meal2: "",
  meal3: "",
  meal4: "",
  meal5: "",
  meal6: "",
  meal7: "",
  snacks: "",
  alcohol: "",
  under_over_eater: "",
  notes: "",
};

export const WIDGET_BODY_HISTORY_STATE = {
  years_training: "",
  formal_instruction: "",
  condition: "",
  performances: "",
  training_split: "",
  notes: "",
};

export const WIDGET_TRAINING_STATE = {
  training_at: "",
  current_gym: "",
  equipment_limitations: "",
  lifestyle_limitations: "",
  notes: "",
};

export const WIDGET_FIELDS = {
  [WIDGETS.HEALTH]: [
    {
      name: "health_conditions",
      label: "health conditions/considerations",
    },
    {
      name: "medications",
      label: "medications",
    },
    {
      name: "food_alergies",
      label: "food alergies (or aversions)",
    },
    {
      name: "signed_disclosureForm",
      label: "signed disclosure form",
    },
    {
      name: "notes",
      label: "notes",
    },
  ],
  [WIDGETS.INJURES]: [
    {
      name: "current_injuries",
      label: "current injuries/insufficiencies",
    },
    {
      name: "old_injuries",
      label: "old injuries",
    },
    {
      name: "physical_limitations",
      label: "physical limitations",
    },
    {
      name: "other_activities",
      label: "other sports/activities",
    },
    {
      name: "notes",
      label: "notes",
    },
  ],
  [WIDGETS.DIET_HISTORY]: [
    {
      name: "meal1",
      label: "meal 1 (time and content)",
    },
    {
      name: "meal2",
      label: "meal 2 (time and content)",
    },
    {
      name: "meal3",
      label: "meal 3 (time and content)",
    },
    {
      name: "meal4",
      label: "meal 4 (time and content)",
    },
    {
      name: "meal5",
      label: "meal 5 (time and content)",
    },
    {
      name: "meal6",
      label: "meal 6 (time and content)",
    },
    {
      name: "meal7",
      label: "meal 7 (time and content)",
    },
    {
      name: "snacks",
      label: "cheats/snacks",
    },
    {
      name: "alcohol",
      label: "alcohol",
    },
    {
      name: "under_over_eater",
      label: "underovereater",
    },
    {
      name: "notes",
      label: "notes",
    },
  ],
  [WIDGETS.BODY_HISTORY]: [
    {
      name: "years_training",
      label: "years training",
    },
    {
      name: "formal_instruction",
      label: "formal instruction",
    },
    {
      name: "condition",
      label: "best/worst condition",
    },
    {
      name: "performances",
      label: "best/current performances",
    },
    {
      name: "training_split",
      label: "recent/typical training split",
    },
    {
      name: "notes",
      label: "notes",
    },
  ],
  [WIDGETS.TRAINING]: [
    {
      name: "training_at",
      label: "training at ?",
    },
    {
      name: "current_gym",
      label: "current gym",
    },
    {
      name: "equipment_limitations",
      label: "equipment limitations",
    },
    {
      name: "lifestyle_limitations",
      label: "lifestyle/time limitations",
    },
    {
      name: "notes",
      label: "notes on training program to prescribe",
    },
  ],
};

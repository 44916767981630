import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../../components/Button";
import Widget from "../../../../../components/widget/Widget";
import { ACTION_PAYMENT_TYPE } from "../../../../../constants/payment";
import { getFullName } from "../../../../../helpers";
import { prepareDateForFront } from "../../../../../helpers";
import convertCentsToDollars from "../../../../../helpers/convertCentsToDollars";
import {
  changeCurrentPayment,
  updatePayment,
} from "../../../../../redux/paymentSlice";
import "./SentInvoice.scss";
import {REGEXP_ANY_FLOAT_FIXED_TWO} from "../../../../../constants/regexp";

const SentInvoice = () => {
  const dispatch = useDispatch();
  const currentPayment = useSelector((state) => state.payment.currentPayment);
  const loading = useSelector((state) => state.payment.loading);
  let discount = currentPayment?.discount / 100 ?? "";
  let received = currentPayment?.received / 100 ?? "";
  const [discountInputValue, setDiscountInputValue] = useState(discount);
  const [receivedInputValue, setReceivedInputValue] = useState(received);

  let titleText = "";
  if (currentPayment) {
    const { type, company } = currentPayment.user;
    titleText =
      type === "Trainer" ? `${getFullName(currentPayment.user)}` : company.name;
  }

  const calculateOwing = (obj) =>
    Number((obj.invoice_total - obj.discount - obj.received).toFixed());

  const inputHandler = (e, setInputValue) => {
    const { name, value, min, max } = e.target;
    let newValue;
    if (!value || Number.isNaN(Number(value))) {
      newValue = 0;
    } else {
      // to limit input value in range [min, max]
      newValue = Math.max(Number(min), Math.min(Number(max), Number(value)));
      if (value.indexOf(".") > -1 && value.length - value.indexOf(".") > 2) {
        newValue = Number(newValue.toFixed(2));
      }
    }
    let newInputValue;
    if (REGEXP_ANY_FLOAT_FIXED_TWO.test(value) && Number(value) === Number(newValue)) {
      newInputValue = value;
    } else {
      newInputValue = newValue.toString();
    }
    setInputValue(newInputValue);
    let tempObj = {};
    tempObj[name] = Number(newValue) * 100;
    let newCurrentPayment = { ...currentPayment, ...tempObj };
    newCurrentPayment.owing = calculateOwing(newCurrentPayment);
    dispatch(changeCurrentPayment(newCurrentPayment));
  };

  let buttonText;
  if (Number(currentPayment?.owing?.toFixed()) === 0) {
    buttonText = ACTION_PAYMENT_TYPE.FINALIZE;
  } else {
    buttonText = currentPayment?.sent_date
      ? ACTION_PAYMENT_TYPE.SAVE
      : ACTION_PAYMENT_TYPE.INVOICE_SENT;
  }

  const onInputFocus = (event) => {
    event.target.select();
  };

  const buttonHandler = (e) => {
    e.preventDefault();
    let dataToRequest;
    if (currentPayment?.id) {
      dataToRequest = {
        id: currentPayment.id,
        body: {
          received: Math.round(Number(received) * 100) || 0,
          discount: Math.round(Number(discount) * 100) || 0,
        },
      };
    }
    dispatch(updatePayment(dataToRequest));
  };

  return (
    <Widget>
      <Widget.Header className="pl-10">
        <Widget.Title>{titleText}</Widget.Title>
      </Widget.Header>
      <Widget.Body className="invoice-sent">
        <form className="invoice-form w-50%">
          <div className="invoice-form__main-info">
            <div className="d-flex invoice-form__row">
              <div>invoice date:</div>
              <div>{prepareDateForFront(currentPayment?.end_date)}</div>
            </div>
            <div className="d-flex invoice-form__row">
              <div>active clients:</div>
              <div>{currentPayment?.active_clients_count}</div>
            </div>
            <div className="d-flex invoice-form__row">
              <div>total active days:</div>
              <div>{currentPayment?.active_days_total}</div>
            </div>
            {currentPayment?.sent_date && (
              <div className="d-flex invoice-form__row">
                <div>invoice sent:</div>
                <div className="right">
                  {prepareDateForFront(currentPayment?.sent_date)}
                </div>
              </div>
            )}
          </div>
          <div className="invoice-form__amount">
            <div className="d-flex invoice-form__row">
              <div>invoice total:</div>
              <div>$ {convertCentsToDollars(currentPayment?.invoice_total)}</div>
            </div>
            <div className="d-flex invoice-form__row">
              <div>discount:</div>
              <input
                type="number"
                placeholder="0,00"
                min="0"
                step="0.01"
                max={convertCentsToDollars(
                  currentPayment?.invoice_total - currentPayment?.received
                )}
                name="discount"
                className="form-input invoice-form__input"
                onFocus={(e) => onInputFocus(e)}
                onChange={(e) => inputHandler(e, setDiscountInputValue)}
                value={discountInputValue}
              />
            </div>
            <div className="d-flex invoice-form__row">
              <div>received:</div>
              <input
                type="number"
                placeholder="0,00"
                min="0"
                step="0.01"
                max={convertCentsToDollars(
                  currentPayment?.invoice_total - currentPayment?.discount
                )}
                name="received"
                className="form-input invoice-form__input"
                onFocus={(e) => onInputFocus(e)}
                onChange={(e) => inputHandler(e, setReceivedInputValue)}
                value={receivedInputValue}
              />
            </div>
            <div className="d-flex invoice-form__row">
              <div>owing:</div>
              <div>$ {convertCentsToDollars(currentPayment?.owing)}</div>
            </div>
          </div>
        </form>
      </Widget.Body>
      <Button
        className="invoice-form__button"
        disabled={loading}
        variant={
          buttonText === ACTION_PAYMENT_TYPE.FINALIZE ? "warning" : "primary"
        }
        onClick={(e) => {
          buttonHandler(e);
        }}
        children={buttonText}
      />
    </Widget>
  );
};

export default SentInvoice;

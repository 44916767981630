import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import FavIcon from "../../../../../../../components/FavIcon";
import { SIDEBAR_NAMES } from "../../../../../../../constants/diet";
import { onAddFood } from "../../../../../../../redux/dietPageSlice";
import { setEditableFoodItem } from "../../../../../../../redux/foodsSlice";

const FoodRow = ({ item, setSideBar, perms }) => {
  const favs = useSelector((state) => state.user.user?.fav_foods);
  const dispatch = useDispatch();
  const onClickEdit = () => {
    setSideBar(SIDEBAR_NAMES.FOOD_EDIT);
    dispatch(setEditableFoodItem(item));
  };

  const onClickFood = (item) => {
    dispatch(onAddFood(item));
  };

  return (
    <div className="dietdropdown-option">
      <div
        onClick={() => onClickFood(item)}
        style={{ float: "left", width: "70%" }}
      >
        {item.name}
      </div>
      <div style={{ float: "left", width: "18%" }}>{item.measure}</div>
      <div style={{ float: "left", width: "6%" }}>
        <FavIcon favs={favs} type="fav_foods" id={item.id} />
      </div>
      <div style={{ float: "left", width: "6%" }}>
        {perms.includes(item.id) && <button
          onClick={onClickEdit}
          type="button"
          className="edit-btn"
          style={{ padding: "0px 5px 0px 0px" }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>}
      </div>
    </div>
  );
};

FoodRow.propTypes = {
  setSideBar: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  perms: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FoodRow;

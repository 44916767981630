import { faClone, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray } from "formik";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import "./ProgramTemplateDay.scss";
import Workout from "./Workout/Workout";

const Workouts = ({ push, insert, remove, dayIdx, workouts }) => {
  const handleAddWorkout = () => push({ exercises: [], id: new Date().valueOf() });
  const handleRemoveWorkout = (idx) => () => remove(idx);
  const handleCopyWorkout = (item, idx) => () => {
    const newItem = { ...cloneDeep(item), id: new Date().valueOf() };
    insert(idx + 1, newItem);
  };

  return (
    <div className="workouts">
      {workouts.map((item, idx) => (
        <Workout
          key={item.id || idx}
          idx={idx}
          dayIdx={dayIdx}
          onRemove={handleRemoveWorkout(idx)}
          onCopy={handleCopyWorkout(item, idx)}
          data={item}
        />
      ))}
      <button
        type="button"
        onClick={handleAddWorkout}
        className="day__icon-btn day__icon-btn--add day__add-workout-btn"
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  );
};

const MemoWorkouts = React.memo(Workouts);

function ProgramTemplateDay({ className, data, idx, onRemove, onCopy }) {
  const title = data.workouts.length
    ? `Day ${idx + 1}`
    : `Day ${idx + 1} - rest day`;

  return (
    <div className={`day${className ? ` ${className}` : ""}`}>
      <div className="day__info">
        <div className="day__header">
          <span className="day__header-text">{title}</span>
          <button
            className="day__icon-btn day__icon-btn--delete day__delete-btn"
            type="button"
            onClick={onRemove}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <button
            className="day__icon-btn day__icon-btn--duplicate"
            type="button"
            onClick={onCopy}
          >
            <FontAwesomeIcon icon={faClone} />
          </button>
        </div>
        <FieldArray name={`days[${idx}].workouts`}>
          {({ push, insert, remove }) => (
            <MemoWorkouts
              insert={insert}
              push={push}
              remove={remove}
              dayIdx={idx}
              workouts={data.workouts}
            />
          )}
        </FieldArray>
      </div>
    </div>
  );
}

ProgramTemplateDay.defaultProps = {
  className: "",
  hasWorkouts: false,
};

ProgramTemplateDay.propTypes = {
  className: PropTypes.string,
  hasWorkouts: PropTypes.bool,
};

export default ProgramTemplateDay;

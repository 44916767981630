import {
  validateBodyFat,
  validateFatDevice,
  validateBodyPart,
} from "../helpers/validators";

import { ALCO_OPTIONS, COMPLIANCE_OPTIONS, DEVICE_OPTIONS } from "./clientForm";
import { MM, PERCENT } from "./unit";

export const THREE_SITE_BODY_TEST = "3-site";
export const SEVEN_SITE_BODY_TEST = "7-site";
export const NINE_SITE_BODY_TEST = "9-site";
export const OTHER_BODY_TEST = "other";

export const THREE_SITE_BODY_TEST_FIELDS = {
  pectoral: {
    unit: MM,
    label: "pectoral",
    validate: validateBodyPart("pectoral"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
  abdominal: {
    unit: MM,
    label: "abdominal",
    validate: validateBodyPart("abdominal"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
  quadricep: {
    unit: MM,
    label: "quadricep",
    validate: validateBodyPart("quadricep"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
};

export const THREE_SITE_BODY_TEST_FIELDS_FEMALE = {
  tricep: {
    unit: MM,
    label: "tricep",
    validate: validateBodyPart("tricep"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
  suprailliac: {
    unit: MM,
    label: "suprailliac",
    validate: validateBodyPart("suprailliac"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
  quadricep: {
    unit: MM,
    label: "quadricep",
    validate: validateBodyPart("quadricep"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
};

export const SEVEN_SITE_BODY_TEST_FIELDS = {
  tricep: {
    unit: MM,
    label: "tricep",
    validate: validateBodyPart("tricep"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
  chest: {
    unit: MM,
    label: "chest",
    validate: validateBodyPart("chest"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
  subscap: {
    unit: MM,
    label: "sub-scap",
    validate: validateBodyPart("sub-scap"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
  abdominal: {
    unit: MM,
    label: "abdominal",
    validate: validateBodyPart("abdominal"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
  suprailliac: {
    unit: MM,
    label: "suprailliac",
    validate: validateBodyPart("suprailliac"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
  thigh: {
    unit: MM,
    label: "thigh",
    validate: validateBodyPart("thigh"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
  midaxillary: {
    unit: MM,
    label: "mid-axillary",
    validate: validateBodyPart("mid-axillary"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
};

export const NINE_SITE_BODY_TEST_FIELDS = {
  ...SEVEN_SITE_BODY_TEST_FIELDS,
  bicep: {
    unit: MM,
    label: "bicep",
    validate: validateBodyPart("bicep"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
  lumbar: {
    unit: MM,
    label: "lumbar",
    validate: validateBodyPart("lumbar"),
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
};

export const OTHER_BODY_TEST_FIELDS = {
  body_fat: {
    unit: PERCENT,
    label: "bodyfat",
    validate: validateBodyFat,
    type: "number",
    as: "input",
    inputDivClass: "w-60",
  },
  device: {
    type: "select",
    as: "select",
    label: "device",
    validate: validateFatDevice,
    options: DEVICE_OPTIONS,
    inputClassDefault: "form-select w-100",
    inputDivClass: "w-40",
    inputClassError: "",
  },
};

export const PERMANENT_BODY_TEST_FIELDS = {
  body_weight: "",
  notes: "",
};

export const ACTIVE_USER_BODY_TEST_FIELDS = {
  alcohol: ALCO_OPTIONS[0].value,
  compliance: COMPLIANCE_OPTIONS[0].value,
};

export const ALL_BODY_TEST_FIELDS = {
  ...PERMANENT_BODY_TEST_FIELDS,
  ...ACTIVE_USER_BODY_TEST_FIELDS,
  tricep: "",
  chest: "",
  subscap: "",
  abdominal: "",
  suprailliac: "",
  thigh: "",
  midaxillary: "",
  pectoral: "",
  quadricep: "",
  bicep: "",
  lumbar: "",
  body_fat: "",
  device: DEVICE_OPTIONS[DEVICE_OPTIONS.length - 1].value,
};

export const BODY_TEST_FIELDS = {
  [SEVEN_SITE_BODY_TEST]: SEVEN_SITE_BODY_TEST_FIELDS,
  [NINE_SITE_BODY_TEST]: NINE_SITE_BODY_TEST_FIELDS,
  [OTHER_BODY_TEST]: OTHER_BODY_TEST_FIELDS,
};

export const BODY_TEST_FIELDS_MALE = {
  ...BODY_TEST_FIELDS,
  [THREE_SITE_BODY_TEST]: THREE_SITE_BODY_TEST_FIELDS,
};

export const BODY_TEST_FIELDS_FEMALE = {
  ...BODY_TEST_FIELDS,
  [THREE_SITE_BODY_TEST]: THREE_SITE_BODY_TEST_FIELDS_FEMALE,
};

export const TABS_STATE = {
  items: [
    {
      value: THREE_SITE_BODY_TEST,
    },
    {
      value: SEVEN_SITE_BODY_TEST,
    },
    {
      value: NINE_SITE_BODY_TEST,
    },
    {
      value: OTHER_BODY_TEST,
    },
  ],
  currentItems: THREE_SITE_BODY_TEST,
};

export const NOTE_OPTIONS = {
  [OTHER_BODY_TEST]: "",
  [THREE_SITE_BODY_TEST]:
    "3-site test: average of siri and brozek 3-site equations",
  [SEVEN_SITE_BODY_TEST]: "7-site test: jackson pollock 7-site equation",
  [NINE_SITE_BODY_TEST]: "9-site test: parillo 9-site equation",
};

export const BODY_TEST_FIELDS_NAME = {
  NOTES: "notes",
};

export const BODY_TEST_SENT_MESSAGE = "Body test was sent successfully!";

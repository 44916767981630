import "./StrengthGraphContent.scss";
import PropTypes from "prop-types";

import { MAX_MOBILE_SCREEN_WIDTH } from "../../../../../../../constants/common";
import { BasicHelper } from "../../../../../../../helpers";
import useIsSmallScreen from "../../../../../../../hooks/useIsSmallScreen";

const StrengthGraphContent = ({ strengthTest, unit }) => {
  const isMobileVersion = useIsSmallScreen(MAX_MOBILE_SCREEN_WIDTH.SLIDER_GOAL);
  return (
    <div className="strength-content">
      <div>
        dead <br />
        <span>
          {BasicHelper.roundTo(Number(strengthTest.deadlift), 0)}
          {!isMobileVersion && unit}
        </span>
      </div>
      <div>
        bench <br />
        <span>
          {BasicHelper.roundTo(Number(strengthTest.bench), 0)}
          {!isMobileVersion && unit}
        </span>
      </div>
      <div>
        squat <br />
        <span>
          {BasicHelper.roundTo(Number(strengthTest.squat), 0)}
          {!isMobileVersion && unit}
        </span>
      </div>
    </div>
  );
};

StrengthGraphContent.propTypes = {
  strengthTest: PropTypes.object.isRequired,
  unit: PropTypes.string.isRequired,
};

export default StrengthGraphContent;

import { Box } from "@material-ui/core";
import PropTypes from "prop-types";

import "./FooterButtons.scss";
import Button from "../../../../../components/Button";
import {
  BTN_BOT_LIST,
  BTN_TOP_LIST,
} from "../../../../../constants/clientInfo";

const FooterButtons = ({ onClickFooterBtn, isDisableBtn }) => {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-between">
      <Box
        display="flex"
        flex="1 1 auto"
        flexDirection="row"
        justifyContent="center"
      >
        {BTN_TOP_LIST.map((btn) => (
          <Button
            key={btn.name}
            className="footer-button"
            onClick={() => {
              onClickFooterBtn(btn);
            }}
            disabled={isDisableBtn(btn)}
          >
            {btn.name}
          </Button>
        ))}
      </Box>
      <Box
        display="flex"
        flex="1 1 auto"
        flexDirection="row"
        justifyContent="center"
      >
        {BTN_BOT_LIST.map((btn) => (
          <Button
            key={btn.name}
            className="footer-button"
            onClick={() => {
              onClickFooterBtn(btn);
            }}
            disabled={isDisableBtn(btn)}
          >
            {btn.name}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

FooterButtons.propTypes = {
  onClickFooterBtn: PropTypes.func.isRequired,
  isDisableBtn: PropTypes.func.isRequired,
};

export default FooterButtons;

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./MainLayout.scss";
import { useWindowSize } from "../../hooks/useWindowSize";
import { setWindowSize } from "../../redux/windowSlice";
import Document from "../Document";
import Header from "../Header";
import Menu from "../Menu";
import Toastr from "../Toastr";

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const bgFile = useSelector(
    (state) => state.companies.currentCompany?.bg_file_name
  );
  const userCompany = useSelector((state) => state.user.user?.company);
  const bgRef = useRef(null);

  // useEffect(() => {
  //   const viewport = document.querySelector("meta[name=viewport]");
  //   viewport.setAttribute(
  //     "content",
  //     viewport.content + ", height=" + window.innerHeight
  //   );
  // }, []);

  useEffect(() => {
    if (!userCompany || !bgFile) return;

    const url = encodeURI(bgFile);
    const bg = bgRef.current;

    bg.style.backgroundImage = `url(${url})`;

    return () => {
      bg.style.backgroundImage = "";
    };
  }, [bgFile, userCompany]);

  useEffect(() => {
    dispatch(setWindowSize(windowSize));
  }, [windowSize]);

  /*TODO: Обернуть в Error Boundary (!)*/

  return (
    <Document>
      <div className="bg" ref={bgRef} />
      <div className="main-layout">
        <Menu />
        <Header />
        <div className="main-layout__body">
          <div className="main-layout__content">{children}</div>
        </div>
      </div>
      <Toastr />
    </Document>
  );
};

export default MainLayout;

import "./Button.scss";

import cn from "classnames";
import PropTypes from "prop-types";

const Button = ({ variant, className, onClick, children, ...props }) => (
  <button
    type="button"
    className={cn("btn", className, {
      "btn--primary": variant === "primary",
      "btn--warning": variant === "warning",
    })}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

Button.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.string,
};

Button.defaultProps = {
  variant: "primary",
};

export default Button;

import { useFormikContext } from "formik";
import { uniqBy, uniq } from "lodash";
import React, { useState, useEffect } from "react";

import Button from "../../../../../../components/Button";
import {
  DEFAULT_START_REPLACE_IDX,
  OLD_VALUE,
  EDITED_TYPES,
} from "../../../../../../constants/templates";
import {
  sortArrayOfObjectsByFieldValue,
  editProgramsValue,
} from "../../../../../../helpers";
import { alphabeticalOrder } from "../../../../../../helpers/sorters";
import {
  notZero,
  validateRangeIdx,
  exercisesUniqValuesCreate,
  createValuesToReplaceUnsorted,
  findSetByOldValue,
} from "../../../../../../helpers/templates";
import { useToastr } from "../../../../../../hooks/useToastr";

import ExerciseSelector from "./components/ExerciseSelector";
import ReplaceListElement from "./components/ReplaceListElement";
import SectionSelector from "./components/SectionSelector";

// TODO: make it sane and similar to the one in the old project
const ReplaceBlock = () => {
  const { values, setFieldValue } = useFormikContext();
  const [startReplaceIdx, setStartReplaceIdx] = useState(
    DEFAULT_START_REPLACE_IDX
  );
  const [endReplaceIdx, setEndReplaceIdx] = useState(values.days.length);
  const [errors, setErrors] = useState([]);
  const [options, setOptions] = useState([{ label: "", value: { id: null } }]);
  const [exerciseId, setExerciseId] = useState(null);
  const [weightsToReplace, setWeightsToReplace] = useState([]);
  const [repsToReplace, setRepsToReplace] = useState([]);
  const [valuesChanged, setValuesChanged] = useState(0);

  useToastr({
    messages: `${valuesChanged} values was changed`,
    deps: valuesChanged,
    cb: () => setValuesChanged(0),
  });

  useEffect(() => {
    const exercisesList = values.days
      .reduce(
        (acc, day, idx) => [
          ...acc,
          ...day.workouts.reduce(
            (acc, workout) => [
              ...acc,
              ...workout.exercises.map((e) => ({ ...e, dayIdx: idx })),
            ],
            []
          ),
        ],
        []
      )

    const uniqExercises = uniqBy(exercisesList, "id");
    const sortedExercises = alphabeticalOrder(uniqExercises, (el) => el.name);
    const newOptions = sortedExercises.reduce(
      (acc, item) => [
        ...acc,
        {
          label: item.name,
          value: item.id,
        },
      ],
      [{ label: "", value: null }]
    );

    const isExerciseInDaySlice = ({ dayIdx }) =>
      dayIdx >= startReplaceIdx - 1 && dayIdx < endReplaceIdx;

    let newWeightToReplaceUnsorted;
    let newRepsToReplaceUnsorted;

    if (!exerciseId) {
      const exercisesUniqWeights = exercisesUniqValuesCreate(
        exercisesList,
        isExerciseInDaySlice,
        EDITED_TYPES.WEIGHT
      );
      const exercisesUniqReps = exercisesUniqValuesCreate(
        exercisesList,
        isExerciseInDaySlice,
        EDITED_TYPES.REPS
      );

      newWeightToReplaceUnsorted = createValuesToReplaceUnsorted(
        exercisesUniqWeights,
        weightsToReplace
      );
      newRepsToReplaceUnsorted = createValuesToReplaceUnsorted(
        exercisesUniqReps,
        repsToReplace
      );
    } else {
      const mapExerciseTo = (setsMap) =>
        exercisesList.filter(isExerciseInDaySlice).reduce(
          (acc, exercise) => ({
            ...acc,
            [exercise.id]: acc[exercise.id]
              ? [...acc[exercise.id], ...exercise.sets.map(setsMap)]
              : [...exercise.sets.map(setsMap)],
          }),
          {}
        );

      const mapExerciseToWeights = mapExerciseTo((set) => Number(set.weight));
      const mapExerciseToReps = mapExerciseTo((set) => Number(set.reps));

      const currentExerciseToWeights = mapExerciseToWeights[exerciseId] ?? [];
      const currentExerciseToReps = mapExerciseToReps[exerciseId] ?? [];

      const exercisesUniqWeights = uniq(
        currentExerciseToWeights.filter(notZero)
      );
      const exercisesUniqReps = uniq(currentExerciseToReps.filter(notZero));

      newWeightToReplaceUnsorted = createValuesToReplaceUnsorted(
        exercisesUniqWeights,
        weightsToReplace
      );
      newRepsToReplaceUnsorted = createValuesToReplaceUnsorted(
        exercisesUniqReps,
        repsToReplace
      );
    }

    const newWeightToReplace = sortArrayOfObjectsByFieldValue(
      newWeightToReplaceUnsorted,
      OLD_VALUE
    );
    const newRepsToReplace = sortArrayOfObjectsByFieldValue(
      newRepsToReplaceUnsorted,
      OLD_VALUE
    );

    setWeightsToReplace(newWeightToReplace);
    setRepsToReplace(newRepsToReplace);
    setOptions(newOptions);
  }, [values, exerciseId, startReplaceIdx, endReplaceIdx]);

  useEffect(() => {
    setEndReplaceIdx(values.days.length);
  }, [values.days.length]);

  useEffect(() => {
    setErrors(validateRangeIdx(startReplaceIdx, endReplaceIdx));
  }, [startReplaceIdx, endReplaceIdx]);

  const handleChangeNewValue = (idx, type) => (e) => {
    const isWeight = type === EDITED_TYPES.WEIGHT;
    const validateValue = editProgramsValue(e.target.value, type);
    const currentValuesReplace = isWeight ? weightsToReplace : repsToReplace;
    const newValue = currentValuesReplace.reduce(
      (acc, item, itemIdx) => [
        ...acc,
        itemIdx === idx ? { ...item, newValue: validateValue } : item,
      ],
      []
    );
    isWeight ? setWeightsToReplace(newValue) : setRepsToReplace(newValue);
  };

  const handleClick = () => {
    let countChanged = 0;
    const changeValue = (set) => {
      const newWeightValue = findSetByOldValue(weightsToReplace, set.weight);
      const newRepsValue = findSetByOldValue(repsToReplace, set.reps);

      const newWeight = newWeightValue?.newValue ?? null;
      const newReps = newRepsValue?.newValue ?? null;

      if (set.weight !== null && Number(set.weight) !== Number(newWeight)) {
        countChanged++;
      }
      if (set.reps !== null && Number(set.reps) !== Number(newReps)) {
        countChanged++;
      }

      return {
        ...set,
        weight: newWeight ? Number(newWeight) : null,
        reps: newReps ? Number(newReps) : null,
      };
    };

    const isIdxToChange = (idx) =>
      idx >= startReplaceIdx - 1 && idx < endReplaceIdx;
    const isExerciseToChange = (exId) => {
      if (!exerciseId) {
        return true;
      }
      return exId === exerciseId;
    };

    const newItems = values.days.reduce(
      (acc, day, idx) => [
        ...acc,
        isIdxToChange(idx)
          ? {
            ...day,
            workouts: day.workouts.map((workout) => ({
              ...workout,
              exercises: workout.exercises.map((exercise) =>
                isExerciseToChange(exercise.id)
                  ? { ...exercise, sets: exercise.sets.map(changeValue) }
                  : exercise
              ),
            })),
          }
          : day,
      ],
      []
    );
    setValuesChanged(countChanged);
    setFieldValue("days", newItems);
    setStartReplaceIdx(DEFAULT_START_REPLACE_IDX);
    setEndReplaceIdx(values.days.length);
  };

  return (
    <div className="tools-block__form">
      <div className="tools-block__form-title">find & replace</div>
      {values.days.length ? (
        <>
          {errors.length !== 0 &&
            errors.map((error) => (
              <div className="tools-block__error">{error}</div>
            ))}
          <SectionSelector
            title="find in section"
            startIdx={startReplaceIdx}
            endIdx={endReplaceIdx}
            setStartIdx={setStartReplaceIdx}
            setEndIdx={setEndReplaceIdx}
          />
          <ExerciseSelector
            options={options}
            exerciseId={exerciseId}
            setExerciseId={setExerciseId}
          />
          {weightsToReplace.length > 0 && (
            <div className="tools-block__change-section">
              <div className="tools-block__change-section-head">change:</div>
              <div className="tools-block__change-section-grid">
                {weightsToReplace.map(({ oldValue, newValue }, idx) => (
                  <ReplaceListElement
                    key={idx}
                    type={EDITED_TYPES.WEIGHT}
                    oldValue={oldValue}
                    newValue={newValue}
                    handleChange={handleChangeNewValue(
                      idx,
                      EDITED_TYPES.WEIGHT
                    )}
                  />
                ))}
                {repsToReplace.map(({ oldValue, newValue }, idx) => (
                  <ReplaceListElement
                    key={idx}
                    type={EDITED_TYPES.REPS}
                    oldValue={oldValue}
                    newValue={newValue}
                    handleChange={handleChangeNewValue(idx, EDITED_TYPES.REPS)}
                  />
                ))}
              </div>
            </div>
          )}
          <div className="tools-block__button-wrapper">
            <Button
              disabled={
                !!errors.length ||
                repsToReplace.length + weightsToReplace.length === 0 ||
                weightsToReplace.some(({ newValue }) => newValue === "") ||
                repsToReplace.some(({ newValue }) => newValue === "")
              }
              onClick={handleClick}
              className="tools-block__button"
            >
              change
            </Button>
          </div>
        </>
      ) : (
        <div>no days for replace</div>
      )}
    </div>
  );
};

export default ReplaceBlock;

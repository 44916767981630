import { useSelector } from "react-redux";

import DietTemplatesEditWindow from "./DietTemplatesEditWindow";
import DietTemplatesList from "./DietTemplatesList";

import "../../../../../assets/styles-old/pages/diets/index.scss";
import "./TemplatesList.scss";

const TemplatesList = () => {
  const removableItem = useSelector(
    (state) => state.dietTemplates.removableItem
  );
  return (
    <>
      {removableItem && <DietTemplatesEditWindow />}
      {!removableItem && <DietTemplatesList />}
    </>
  );
};

export default TemplatesList;

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";

import TableTooltip from "../../../../../../../components/TableTooltip/TableTooltip";
import { USER_TYPES } from "../../../../../../../constants/common";
import useIsSmallScreen from "../../../../../../../hooks/useIsSmallScreen";
import useIsUserType from "../../../../../../../hooks/useIsUserType";
import { onChangeQuantity } from "../../../../../../../redux/dietPageSlice";

const DietFoodItem = ({
  name,
  proteins,
  carbs,
  fats,
  measure,
  quantity,
  idxFood,
  isShowMeal,
  onRemove,
  isDeleted,
  idFood,
}) => {
  const dispatch = useDispatch();
  const foodRef = useRef();
  const isSmallScreen = useIsSmallScreen();
  const isClient = useIsUserType(USER_TYPES.CLIENT);
  return (
    <>
      <td
        className={cn({
          deleted_food: isDeleted,
        })}
      >
        {!isClient && (
          <button
            type="button"
            className="remove-btn"
            onClick={() => onRemove(idFood)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
      </td>
      <td
        className={cn({
          deleted_food: isDeleted,
        })}
        style={{
          height: !isSmallScreen ? "24px" : "auto",
        }}
      >
        <div
          className="diet-table-column-wrapper"
          style={{ flexFlow: isSmallScreen ? "row wrap" : "row" }}
        >
          <input
            disabled={isDeleted || isClient}
            value={quantity}
            className="diet-quantity-input"
            onChange={(event) => {
              if (event.target.validity.valid) {
                dispatch(
                  onChangeQuantity({ idxFood, value: event.target.value })
                );
              }
            }}
          />
          <span>{measure}</span>
        </div>
      </td>
      <td
        className={cn({
          deleted_food: isDeleted,
        })}
        style={{
          height: !isSmallScreen ? "24px" : "auto",
        }}
      >
        <div className="diet-table-column-wrapper">
          {isSmallScreen && <span>{name}</span>}
          {!isSmallScreen && (
            <TableTooltip
              ref={foodRef}
              overlay={<span>{name}</span>}
              contentWrapperClassName="ellipsis w-100"
              content={name}
              hasOverflowingContent={true}
            />
          )}
        </div>
      </td>
      {(!isSmallScreen || !isShowMeal) && (
        <>
          <td
            className={cn({
              deleted_food: isDeleted,
            })}
          >
            <div className="diet-table-column-wrapper">
              <span>{(proteins * quantity).toFixed(0)}g</span>
            </div>
          </td>
          <td
            className={cn({
              deleted_food: isDeleted,
            })}
          >
            <div className="diet-table-column-wrapper">
              <span>{(carbs * quantity).toFixed(0)}g</span>
            </div>
          </td>
          <td
            className={cn({
              deleted_food: isDeleted,
            })}
          >
            <div className="diet-table-column-wrapper">
              <span>{(fats * quantity).toFixed(0)}g</span>
            </div>
          </td>
        </>
      )}
    </>
  );
};
export default DietFoodItem;

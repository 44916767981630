import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { loadClientListWithCache } from "../api/clientApi";

export const loadClientListThunk = createAsyncThunk(
  "clientList/loadClientListThunk",
  (args) => loadClientListWithCache.call(args)
);

const clientListSlice = createSlice({
  name: "clientList",
  initialState: {
    clientForDelete: null,
    clients: null,
    page: 0,
    totalCount: 0,
    filter: "",
    loading: false,
    success: false,
    error: "",
  },
  reducers: {
    setForDeleteClient(state, action) {
      state.clientForDelete = action.payload;
    },
    clearClientForDelete(state) {
      state.clientForDelete = null;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    clearFilter(state) {
      state.filter = "";
    },
    clearClientList(state) {
      state.clients = null;
    },
    clearError(state) {
      state.error = "";
    },
  },
  extraReducers: {
    [loadClientListThunk.pending]: (state) => {
      state.loading = true;
    },
    [loadClientListThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = "";
      state.clients = action.payload.clients;
      state.page = action.payload.page;
      state.totalCount = action.payload.total_count;
    },
    [loadClientListThunk.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  setFilter,
  setForDeleteClient,
  clearClientForDelete,
  clearFilter,
  clearClientList,
  clearError,
} = clientListSlice.actions;

export default clientListSlice.reducer;

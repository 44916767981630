import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import "./CompaniesPage.scss";
import Spinner from "../../../components/Spinner";
import Widget from "../../../components/widget/Widget";
import { COMPANY, MANAGE_COMPANY_SLUG } from "../../../constants/router";
import { filterList } from "../../../helpers";
import {
  clearCurrentCompany,
  clearSuccessfullyCompanyDeleted,
  loadCompaniesPicker,
} from "../../../redux/companiesSlice";
import TabButton from "../components/TabButton";

import CompanySearch from "./components/CompanySearch";
import TableItem from "./components/TableItem";

const CompaniesPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const loading = useSelector((state) => state.companies.loading);
  const companies = useSelector((state) => state.companies.companiesPicker);
  useEffect(() => {
    dispatch(loadCompaniesPicker());
  }, []);

  useEffect(() => {
    if (companies) {
      setFilteredCompanies(companies);
    }
  }, [companies]);

  const onFilterChange = (e) => {
    const { value } = e.target;
    setFilteredCompanies(filterList(companies, value));
  };

  const onCompanyClick = (id) => {
    dispatch(clearSuccessfullyCompanyDeleted());
    history.push(`${id}${MANAGE_COMPANY_SLUG}`);
  };

  const onTrainersClick = () => {}; //TODO: add redirect to the company trainers list
  const onClientsClick = () => {
    //history.push()
  }; //TODO: add redirect to the company clients list

  const goToAddCompany = () => {
    dispatch(clearCurrentCompany());
    history.push(COMPANY.root + COMPANY.addCompany);
  };

  return (
    <div className="content-rhs content companies-page">
      <div className="companies-page__tab-buttons">
        <TabButton variant="left" active>
          companies list
        </TabButton>
        <TabButton variant="right" onClick={goToAddCompany}>
          add a company
        </TabButton>
      </div>
      <div className="page-content w-100">
        <div className="companies-page__header">
          <Widget.Title>companies list</Widget.Title>
          <CompanySearch onChange={onFilterChange} />
        </div>
        <Widget.Body className="widget-body--with-mh">
          {loading && <Spinner />}
          {!loading && (
            <div className="companies-page__body">
              <table className="companies-page__table">
                <thead className="companies-page__table-header">
                  <tr>
                    <td>company name</td>
                    <td></td>
                    <td></td>
                  </tr>
                </thead>
                <tbody className="companies-page__table-body">
                  {filteredCompanies?.map((company) => (
                    <TableItem
                      {...company}
                      key={company.id}
                      onCompanyClick={onCompanyClick}
                      onTrainersClick={onTrainersClick}
                      onClientsClick={onClientsClick}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Widget.Body>
      </div>
    </div>
  );
};

CompaniesPage.propTypes = {};

export default CompaniesPage;

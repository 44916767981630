export const CLIENT_LIST_HEADER = [
  {
    label: "client name",
    colspan: 2,
  },
  {
    label: "status",
    style: { width: "20%" },
  }
];


import "./BodyCompGraph.scss";

import BodyCharts from "./components/BodyCharts";
import SlidersHeader from "./components/SlidersHeader";

const BodyCompGraph = () => {
  return (
    <div className="form-client-body-test">
      <div className="body-comp-graph-wrap">
        <SlidersHeader />
        <BodyCharts />
      </div>
    </div>
  );
};

BodyCompGraph.propTypes = {};

export default BodyCompGraph;

import { Link } from "react-router-dom";
import "./RedirectButton.scss";

const RedirectButton = ({ children, ...props }) => (
  <Link className="redirect-btn" {...props}>
    {children}
    <span className="redirect-btn__icon"></span>
  </Link>
);

export default RedirectButton;

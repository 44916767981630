import "./Divider.scss";
import React from "react";

const Divider = ({ ...props }) => {
  return <div className="mb-15 mt-15 border-top" {...props} />;
};

Divider.propTypes = {};

export default Divider;

import { CACHE_TIMEOUT } from "../constants/api";
import { baseUrl } from "../env/env";
import withCache from "../helpers/cache";

import myFetch, { emailHandlerError } from "./myFetch";

export const loadCompaniesPicker = () => {
  return myFetch(`${baseUrl}/api/companies/picker`);
};

export const loadCompaniesPickerWithCache = withCache(loadCompaniesPicker, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const loadCompanies = () => {
  return myFetch(`${baseUrl}/api/companies`);
};

export const loadCompaniesWithCache = withCache(loadCompanies, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const addCompany = ({ body }) => {
  loadCompaniesPickerWithCache.invalidate();
  loadCompaniesWithCache.invalidate();
  return myFetch(
    `${baseUrl}/api/companies`,
    { method: "POST", body: body },
    emailHandlerError
  );
};

export const loadCompanyById = ({ id }) => {
  return myFetch(`${baseUrl}/api/companies/${id}`);
};

export const loadCompanyByIdWithCache = withCache(loadCompanyById, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const deleteCompanyById = ({ id }) => {
  loadCompanyByIdWithCache.invalidateByArgs({ id });
  loadCompaniesPickerWithCache.invalidate();
  loadCompaniesWithCache.invalidate();
  return myFetch(`${baseUrl}/api/companies/${id}`, { method: "DELETE" });
};

export const editCompany = ({ id, body }) => {
  loadCompanyByIdWithCache.invalidateByArgs({ id });
  loadCompaniesPickerWithCache.invalidate();
  loadCompaniesWithCache.invalidate();
  return myFetch(
    `${baseUrl}/api/companies/${id}`,
    {
      method: "PATCH",
      body: body,
    },
    emailHandlerError
  );
};

export const addThemeCompany = ({ id, file }) => {
  loadCompanyByIdWithCache.invalidateByArgs({ id });
  const formData = new FormData();
  formData.append("file", file);
  return myFetch(`${baseUrl}/api/companies/${id}/upload_image`, {
    method: "POST",
    body: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deleteThemeCompany = ({ id }) => {
  loadCompanyByIdWithCache.invalidateByArgs({ id });
  return myFetch(`${baseUrl}/api/companies/${id}/delete_image`, {
    method: "DELETE",
  });
};

export const createPaymentAccount = ({id, body}) => {
  return myFetch(`${baseUrl}/api/companies/${id}/payment_account`, {
    method: "POST",
    body,
  });
};

export const loadPaymentAccount = ({id}) => {
  return myFetch(`${baseUrl}/api/companies/${id}/payment_account`);
};

export const deletePaymentAccount = ({id}) => {
  return myFetch(`${baseUrl}/api/companies/${id}/payment_account`,{
    method: "DELETE",
  });
};

import { Box, Grid } from "@material-ui/core";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import lodash from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { GENDER, MASS_UNITS, SELECT } from "../../constants/clientForm";
import { USER_TYPES } from "../../constants/common";
import {
  deleteTrashFieldForClientFormik,
  prepareDateForFront,
} from "../../helpers";
import {
  validatePhoneNumber,
  validateRequired,
  validateRequiredHeight,
} from "../../helpers/validators";
import useIsUserType from "../../hooks/useIsUserType";
import { loadTrainersPicker } from "../../redux/trainersSlice";
import FormField from "../FormField";
import RequiredFieldsWarning from "../RequiredFieldsWarning";

import "./ClientsForm.scss";
import { validateRequiredDate } from "./../../helpers/validators";
import CompanyAutocomplete from "./components/CompanyAutocomplete";
import TrainersAutocomplete from "./components/TrainersAutocomplete";

const prepareDataForFormik = (value, user) => {
  const { type, company } = user;
  value = lodash.cloneDeep(value);
  value = deleteTrashFieldForClientFormik(value);

  if (value.birth) {
    value.birth = prepareDateForFront(value.birth);
  }

  if (type === USER_TYPES.COMPANY_ADMIN && company) {
    value.company = { id: company };
  }

  if (type === USER_TYPES.TRAINER) {
    value.company = { id: company };
    value.trainers = [
      {
        first_name: user.first_name,
        last_name: user.last_name,
        id: user.id,
      },
    ];
  }

  return value;
};

const ClientsForm = ({ onSubmit, initialValues, isEditPage, isShowSubmit }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [formInitialValues, setFormInitialValues] = useState(prepareDataForFormik(initialValues, user));
  const trainers = useSelector((state) => state.trainers.trainers);
  const loading = useSelector((state) => state.client.loading);
  const [selectableTrainers, setSelectableTrainers] = useState(trainers);
  const [isShowingTrainers, setIsShowingTrainers] = useState(true);
  const isCompanyAdmin = useIsUserType(USER_TYPES.COMPANY_ADMIN);
  const isTrainer = useIsUserType(USER_TYPES.TRAINER);
  const isClient = useIsUserType(USER_TYPES.CLIENT);

  useEffect(() => {
    if (formInitialValues.company?.id && !isTrainer && !isClient) {
      dispatch(loadTrainersPicker(!isCompanyAdmin && formInitialValues.company.id));
    }
  }, []);

  useEffect(() => {
    setSelectableTrainers(trainers);
    if (isCompanyAdmin && !isEditPage) {
      if (trainers?.length <= 1) {
        setIsShowingTrainers(false);
      }
      if (trainers?.length > 0) {
        setFormInitialValues({...formInitialValues, trainers: [trainers[0]]});
      }
    }
  }, [trainers]);

  return (
    <Formik
      initialValues={formInitialValues}
      validate={(values) => {
        if (!values.company) {
          return { company: "error" };
        }
      }}
      validationSchema={Yup.object().shape({
        birth: Yup.string().required("Required").test(
          "birth-error",
          "birthday is invalid",
          (val) => !validateRequiredDate(val),
        ),
        phone: Yup.string().test(
          "phone-error",
          "Phone number is invalid",
          (val) => !validatePhoneNumber(val)
        ).nullable(),
      })}
      enableReinitialize
      onSubmit={(values) => {
        onSubmit && onSubmit(values, formInitialValues);
      }}
    >
      {({ values, setFieldError, handleSubmit }) => (
        <Form>
          <Grid container spacing={1}>
            {user.type !== USER_TYPES.COMPANY_ADMIN &&
              user.type !== USER_TYPES.TRAINER && (
              <>
                <Grid item md={2} xs={12}>
                  <Box display="flex" alignItems="center" height="100%">
                    <label htmlFor="company" className="form-label">
                        company *
                    </label>
                  </Box>
                </Grid>
                <Grid item md={10} xs={12}>
                  <CompanyAutocomplete submitOnBlur={!isShowSubmit} />
                </Grid>
              </>
            )}
            {user.type !== USER_TYPES.TRAINER && selectableTrainers && isShowingTrainers && (
              <FieldArray name="trainers">
                {({ remove, push, replace }) => (
                  <>
                    {values.trainers.map((trainer, index) => (
                      <TrainersAutocomplete
                        key={index}
                        index={index}
                        remove={(e) => {
                          remove(e);
                          if (!isShowSubmit) {
                            handleSubmit();
                          }
                        }}
                        replace={replace}
                        setSelectableTrainers={setSelectableTrainers}
                        selectableTrainers={selectableTrainers}
                        submitOnBlur={!isShowSubmit}
                      />
                    ))}
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="start">
                        <button
                          className="btn-primary"
                          type="button"
                          onClick={() => push("")}
                          disabled={
                            !selectableTrainers.length ||
                            ((!values.trainers[values.trainers.length - 1] ||
                              values.trainers.length > 4) &&
                              values.trainers.length > 0)
                          }
                        >
                          +add coach
                        </button>
                      </Box>
                    </Grid>
                  </>
                )}
              </FieldArray>
            )}
            <Grid item xs={6}>
              <FormField
                label="first name *"
                validate={validateRequired}
                name="first_name"
                submitOnBlur={!isShowSubmit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                label="last name *"
                name="last_name"
                validate={validateRequired}
                submitOnBlur={!isShowSubmit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                inputClassError=""
                label="gender *"
                name="gender"
                inputClassDefault="form-select w-100"
                as={SELECT}
                type="select"
                options={GENDER}
                submitOnBlur={!isShowSubmit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                setFieldError={setFieldError}
                label="dob *"
                sublabel="(dd/mm/yyyy)"
                name="birth"
                type="birthday"
                submitOnBlur={!isShowSubmit}
              />
              <ErrorMessage
                component="div"
                name="birth"
                className="auth-error"
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                label="height *"
                sublabel="(cm)"
                validate={validateRequiredHeight}
                allowFloat
                name="height"
                type="number"
                submitOnBlur={!isShowSubmit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                label="phone"
                name="phone"
                validate={validatePhoneNumber}
                submitOnBlur={!isShowSubmit}
              />
              <ErrorMessage
                component="div"
                name="phone"
                className="auth-error"
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                label="email *"
                sublabel="(used for login)"
                validate={validateRequired}
                type="email"
                name="email"
                submitOnBlur={!isShowSubmit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                label={!isEditPage ? "password *" : "password"}
                validate={!isEditPage ? validateRequired : null}
                type="password"
                name="password"
                submitOnBlur={!isShowSubmit}
              />
            </Grid>
            {!isEditPage && (
              <Grid item xs={6}>
                <FormField
                  label="kg/lb"
                  name="mass_unit"
                  inputClassDefault="form-select w-100"
                  inputClassError=""
                  as={SELECT}
                  type="select"
                  options={MASS_UNITS}
                  submitOnBlur={!isShowSubmit}
                />
              </Grid>
            )}
          </Grid>
          <RequiredFieldsWarning />
          {!!isShowSubmit && (
            <Box display="flex" justifyContent="flex-end">
              <button className="default-btn" type="submit" disabled={loading}>
                save
              </button>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

ClientsForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  isEditPage: PropTypes.bool,
  isShowSubmit: PropTypes.bool,
};

ClientsForm.defaultProps = {
  isEditPage: false,
  isShowSubmit: true,
  initialValues: {
    gender: GENDER[0].value,
    mass_unit: MASS_UNITS[0].value,
    company: "",
    trainers: [],
    first_name: "",
    last_name: "",
    email: "",
    birth: "",
    height: "",
    phone: "",
    password: "",
  },
};

export default ClientsForm;

import React from "react";
import { useSelector } from "react-redux";

import Button from "../../../../../components/Button";
import { prepareDateForFront, getFullName } from "../../../../../helpers";
import convertCentsToDollars from "../../../../../helpers/convertCentsToDollars";
import "./FilteredDataView.scss";

const FilteredDataView = ({ dataAr, btnClick }) => {
  const currentPayment = useSelector((state) => state.payment.currentPayment);
  const view = dataAr.map((invoice) => {
    const isPay = invoice.sent_date;
    return (
      <tr key={invoice.id}>
        <td>
          <Button
            className="payment-list__table-btn"
            disabled={currentPayment?.id === invoice.id}
            variant={isPay ? "warning" : "primary"}
            onClick={(e) => btnClick(e, invoice)}
            children={isPay ? "pay" : "invoice"}
          />
        </td>
        <td className="payment-list__cell">{invoice.user?.company?.name}</td>
        <td className="payment-list__cell">{getFullName(invoice.user)}</td>
        <td className="payment-list__cell payment-list__cell--center">
          $ {convertCentsToDollars(invoice.invoice_total)}
        </td>
        <td className="payment-list__cell payment-list__cell--center">
          {prepareDateForFront(invoice.end_date)}
        </td>
      </tr>
    );
  });

  return <>{view}</>;
};

export default React.memo(FilteredDataView);

import { baseUrl } from "../env/env";

import withCache from "./../helpers/cache";
import myFetch from "./myFetch";


const loadExercises = () => {
  return myFetch(`${baseUrl}/api/exercises`);
}

const loadExercisesForCurrentUser = () => {
  return myFetch(`${baseUrl}/api/exercises/current_user`);
}

export const loadExercisesWithCache = withCache(loadExercises);

export const loadExercisesForCurrentUserWithCache = withCache(loadExercisesForCurrentUser);

export const addExercises = ({ body }) => {
  loadExercisesWithCache.invalidate();
  loadExercisesForCurrentUserWithCache.invalidate();
  return myFetch(`${baseUrl}/api/exercises`, { method: "POST", body: body });
}

export const editExercises = ({ body, id }) => {
  loadExercisesWithCache.invalidate();
  loadExercisesForCurrentUserWithCache.invalidate();
  return myFetch(`${baseUrl}/api/exercises/${id}`, {
    method: "PATCH",
    body: body,
  });
}

export const deleteExercises = ({ id }) => {
  loadExercisesWithCache.invalidate();
  loadExercisesForCurrentUserWithCache.invalidate();
  return myFetch(
    `${baseUrl}/api/exercises/${id}`,
    { method: "DELETE" },
    null
  );
}

import { MenuItem, Select } from "@material-ui/core";
import cn from "classnames";
import React, { memo, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { USER_TYPES } from "../../../../../../../constants/common";
import { FOR_DAYS_OPTIONS } from "../../../../../../../constants/diet";
import {
  forDaysToOption,
  getDietForDays,
  getForDaysLabel,
} from "../../../../../../../helpers/diet";
import useIsSmallScreen from "../../../../../../../hooks/useIsSmallScreen";
import useIsUserType from "../../../../../../../hooks/useIsUserType";
import {
  onChangeForDay,
  onChangeSelectIndex,
} from "../../../../../../../redux/dietPageSlice";
import "../../../../ClientDietPage.scss";

const RenderForDaysLabel = ({ value, dietIndex }) => {
  const selectedDietIndex = useSelector(
    (state) => state.dietPage.selectedDietIndex
  );
  const currentDiets = useSelector((state) => state.dietPage.diets);
  const isClient = useIsUserType(USER_TYPES.CLIENT);
  const label = getForDaysLabel(value, currentDiets, dietIndex);
  return (
    <span
      style={{
        color:
          selectedDietIndex === dietIndex
            ? isClient
              ? "white"
              : "#24a3f4"
            : "",
      }}
    >
      {label}
    </span>
  );
};

const DietTableSelect = ({ dietIndex, showAllSelects }) => {
  const dispatch = useDispatch();
  const isClient = useIsUserType(USER_TYPES.CLIENT);
  const isSmallScreen = useIsSmallScreen();
  const currentDiets = useSelector((state) => state.dietPage.diets);
  const [forDays, setForDays] = useState("1");

  useLayoutEffect(() => {
    const value = currentDiets[dietIndex].for_days;
    setForDays(forDaysToOption(value)?.value || "for non-training days");
  }, [currentDiets[dietIndex].for_days]);

  const forDaysChangeHandler = (event) => {
    const newForDays = event.target.value;
    const otherDietIndex = dietIndex === 0 ? 1 : 0;
    const newForDaysOtherDiet = getDietForDays(
      currentDiets[otherDietIndex].for_days,
      newForDays
    );
    dispatch(
      onChangeForDay({ newForDaysOtherDiet, newForDays, otherDietIndex })
    );
  };

  const changeCurrentDietHandler = () =>
    dispatch(onChangeSelectIndex(dietIndex));

  return (
    <div
      className={cn("diet-for-days-div", {
        "diet-for-days-div--mobile": isSmallScreen,
      })}
      style={{ marginLeft: showAllSelects ? "15px" : "5px" }}
    >
      <Select
        className={cn("diet-for-days-select", {
          "for-days-mobile": isSmallScreen,
        })}
        options={FOR_DAYS_OPTIONS}
        value={forDays}
        renderValue={() => (
          <RenderForDaysLabel value={forDays} dietIndex={dietIndex} />
        )}
        disabled={isClient}
        onChange={forDaysChangeHandler}
        onOpen={changeCurrentDietHandler}
      >
        {FOR_DAYS_OPTIONS.map((opt) => (
          <MenuItem key={opt.key} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

DietTableSelect.propTypes = {};

export default memo(DietTableSelect);

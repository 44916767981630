import React, { memo } from "react";
import { useSelector } from "react-redux";

import Widget from "../../../../../components/widget/Widget";

import "./DietMealsSideBar.scss";
import Meal from "./components/Meal";

const DietMealsSideBar = () => {
  const selectedDietIndex = useSelector(
    (state) => state.dietPage.selectedDietIndex
  );
  const meals = useSelector((state) => state.dietPage.meals);
  return (
    <Widget className="diet__meals">
      <Widget.Header>
        <Widget.Title>meals</Widget.Title>
      </Widget.Header>
      <div className="scrollable">
        {meals[selectedDietIndex].map((meal) => (
          <Meal key={meal.id} name={meal.name} foods={meal.foods} />
        ))}
      </div>
    </Widget>
  );
};

export default memo(DietMealsSideBar);

import React, { memo } from "react";
import "./MealCheckbox.scss";

const MealCheckbox = ({ onChangeCheck, checked, idxMeal, idxFood }) => {
  return (
    <td>
      <div className="meal-checkbox-wrapper">
        <input
          onChange={() => onChangeCheck(idxFood, idxMeal)}
          type="checkbox"
          checked={checked}
        />
      </div>
    </td>
  );
};

MealCheckbox.propTypes = {};

export default memo(MealCheckbox);

import "./DisabledField.scss";

const DisabledField = ({ className, name, value }) => (
  <input
    className={`disabled-field ${className}`}
    name={name}
    value={value}
    disabled
  />
);

export default DisabledField;

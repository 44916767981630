import cn from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ClientTrainingPage.scss";
import { useRouteMatch, useHistory, Link } from "react-router-dom";

import { ReactComponent as NextPageArrow } from "../../../assets/svg/next-page-arrow.svg";
import { ReactComponent as PrevPageArrow } from "../../../assets/svg/prev-page-arrow.svg";
import Button from "../../../components/Button";
import MobileDrawer from "../../../components/MobileDrawer/MobileDrawer";
import Spinner from "../../../components/Spinner/Spinner";
import Widget from "../../../components/widget/Widget";
import { CLIENT_PERM } from "../../../constants/clientInfo";
import { MAX_MOBILE_SCREEN_WIDTH, USER_TYPES } from "../../../constants/common";
import {
  BODY_TEST_RESULT_PAGE_SLUG,
  CLIENT,
  TRAINING_PROGRAM_SLUG,
} from "../../../constants/router";
import useCheckClientPermission from "../../../hooks/useCheckClientPermission";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import useIsUserType from "../../../hooks/useIsUserType";
import {
  loadHistory,
  setHistoryDetails,
  setSelectedExercise,
  changeExercise,
  setChangeAllExercise,
  loadTraining,
  getProgram,
  clearPreselectedValues,
  clearHistory,
} from "../../../redux/programsSlice";
import { setIsOpenDrawer, setModal } from "../../../redux/utilsSlice";
import ExercisesWidget from "../../ProgramTemplates/ProgramTemplateForm/ProgramTemplateSide/ExercisesWidget/ExercisesWidget";

import AddProgramWidget from "./components/AddProgramWidget";
import HistoryWidget from "./components/HistoryWidget";
import ModalWindow from "./components/ModalWindow";
import NotesWidget from "./components/NotesWidget";
import PerformanceWidget from "./components/PerformanceWidget";
import TrainingDay from "./components/TrainingDay";
import {useDocumentTitle} from "../../../hooks/useDocumentTitle";
import {getEditExerciseModalMessage, hasSubsequentSimilarExercises} from "../../../helpers/training";

const widgetViewTypes = {
  PERFORMANCE_AND_HISTORY: "performance-and-history",
  EXERCISE_HISTORY: "exercise-history",
  NOTES: "notes",
  EDIT_EXERCISE: "edit-exercise",
  ADD_PROGRAM: "add-program",
};

const widgets = {
  [widgetViewTypes.PERFORMANCE_AND_HISTORY]: ({
    setOnlyHistory,
    isMobileVersion,
    setWidgetToHistory,
    hideWidget,
  }) => {
    return (
      <>
        <PerformanceWidget
          onHistoryButtonClick={setWidgetToHistory}
          hideWidget={hideWidget}
        />
        {!isMobileVersion && <HistoryWidget setOnly={setOnlyHistory} />}
      </>
    );
  },
  [widgetViewTypes.EXERCISE_HISTORY]: ({
    setOnlyHistory,
    setWidgetToPerformanceAndHistory,
  }) => (
    <HistoryWidget
      setOnly={setOnlyHistory}
      onBackButtonClick={setWidgetToPerformanceAndHistory}
    />
  ),
  [widgetViewTypes.NOTES]: ({ hideWidget }) => (
    <NotesWidget hideWidget={hideWidget} />
  ),
  [widgetViewTypes.EDIT_EXERCISE]: ({ loading, ...props }) =>
    loading ? <Spinner /> : <ExercisesWidget {...props} />,
  [widgetViewTypes.ADD_PROGRAM]: ({ hideWidget }) => (
    <AddProgramWidget hideWidget={hideWidget} />
  ),
  [null]: () => <></>,
};

const NO_STRENGTH_TEST_TEXT =
  "you need to enter a strength test to add a training program. click here or go to the body page";

const ClientTrainingPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const client = useSelector((state) => state.client.client);
  const isStrengthTest = useSelector(
    (state) => state.client.has_actual_strength
  );
  const numberOfPrograms = useSelector(
    (state) => state.programs.numberOfPrograms
  );
  const pb = useSelector((state) => state.programs.pb);
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const currentProgramIdx = useSelector(
    (state) => state.programs.currentProgramIdx
  );

  const selectedExerciseDayIdx = useSelector(
    (state) => state.programs.selectedExerciseDayIdx
  );
  const selectedExerciseWorkoutIdx = useSelector(
    (state) => state.programs.selectedExerciseWorkoutIdx
  );
  const selectedExerciseIdx = useSelector(
    (state) => state.programs.selectedExerciseIdx
  );
  const selectedExercise = useSelector(
    (state) => state.programs.selectedExercise
  );
  const changeAllExercise = useSelector(
    (state) => state.programs.changeAllExercise
  );
  const changingExercise = useSelector(
    (state) => state.programs.changingExercise
  );

  const isSavingSet = useSelector((state) => state.programs.isSavingSet);
  const loading = useSelector((state) => state.programs.loading);
  const loadingProgram = useSelector((state) => state.programs.loadingProgram);
  const isOpenDrawer = useSelector((state) => state.utils.isOpenDrawer);
  const modal = useSelector((state) => state.utils.modal);
  const currentSet = useSelector((state) => state.programs.currentSet);
  const details = useSelector((state) => state.programs.historyDetails);

  const [isDescriptionTruncated, setIsDescriptionTruncated] = useState(true);
  const [descriptionWasTruncated, setDescriptionWasTruncated] = useState(true);
  const [descriptionNode, descriptionRef] = useState();
  const [isQuestion, setIsQuestion] = useState(false);
  const [changeExerciseParams, setChangeExerciseParams] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const wasTruncated =
        descriptionNode?.scrollHeight - 1 > descriptionNode?.clientHeight;

      if (!isDescriptionTruncated) {
        setDescriptionWasTruncated(true);
        setIsDescriptionTruncated(true);
      } else {
        setDescriptionWasTruncated(wasTruncated);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("transitionend", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("transitionend", handleResize);
    };
  }, [descriptionNode, isDescriptionTruncated]);

  const [isShowTraining, setIsShowTraining] = useState(false);
  const name = client?.first_name;

  const prevPageExists = currentProgramIdx !== 0;
  const nextPageExists = currentProgramIdx !== numberOfPrograms - 1;
  const [rightWidgetView, setRightWidgetView] = useState(null);

  const isSmallScreen = useIsSmallScreen();
  const isMobileVersion = useIsSmallScreen(
    MAX_MOBILE_SCREEN_WIDTH.MOBILE_VIEW_ON_TRAINING
  );

  const isClient = useIsUserType(USER_TYPES.CLIENT);
  const isNotHideHistory = useCheckClientPermission(
    CLIENT_PERM.HIDE_WORKOUT_HISTORY,
    false
  );

  const isEmptyProgram = currentProgram?.days?.length === 0;

  const description = currentProgram?.template?.description;

  const title = `${(name || "").toLowerCase()}'s training`;
  useDocumentTitle(title);

  useEffect(() => {
    const params = {
      id: match.params.id,
    };

    if (!isStrengthTest) {
      isOpenDrawer && dispatch(setIsOpenDrawer(false));
    }

    if (isMobileVersion) dispatch(clearPreselectedValues());

    const trainingPromise = dispatch(loadTraining(params));
    dispatch(getProgram({ ...params, awaitPromise: trainingPromise }));

    dispatch(loadHistory(params));

    return () => dispatch(clearHistory());
  }, []);

  useEffect(() => {
    if (!isStrengthTest) return;
    if (isSmallScreen) {
      dispatch(setIsOpenDrawer(false));
    } else {
      dispatch(setIsOpenDrawer(true));
    }
  }, [isSmallScreen]);

  useEffect(() => {
    if (!selectedExercise) {
      return;
    }
    dispatch(setIsOpenDrawer(true));
    setRightWidgetView(widgetViewTypes.EDIT_EXERCISE);
  }, [selectedExercise]);

  const dispatchChangeExercise = (params) => {
    dispatch(changeExercise(params))
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(loadHistory({id: client.id}));
        }
      });
  };

  const handleEditExerciseClick = (data) => () => {
    const body = {
      change_all: changeAllExercise,
      program_index: currentProgramIdx,
      day_index: selectedExerciseDayIdx,
      workout_index: selectedExerciseWorkoutIdx,
      exercise_index: selectedExerciseIdx,
      old_exercise_id: selectedExercise.id,
      new_exercise_id: data.id,
    };

    const params = {
      id: client.id,
      body,
      isLast: currentProgramIdx + 1 === numberOfPrograms,
    };

    if(
      changeAllExercise &&
      hasSubsequentSimilarExercises(
        currentProgram,
        {
          day_index: selectedExerciseDayIdx,
          workout_index: selectedExerciseWorkoutIdx,
          exercise_index: selectedExerciseIdx,
          exercise_id: data.id,
        }
      )
    ) {
      setIsQuestion(true);
      setChangeExerciseParams(params);
      dispatch(setModal(getEditExerciseModalMessage(data.name)));
    } else {
      dispatchChangeExercise(params);
    }
  };

  const handleEditExerciseBackClick = () => {
    if (isMobileVersion) {
      dispatch(setIsOpenDrawer(false));
    } else {
      setRightWidgetView(widgetViewTypes.PERFORMANCE_AND_HISTORY);
    }
    dispatch(setSelectedExercise(null));
  };

  useEffect(() => {
    if (rightWidgetView !== widgetViewTypes.EDIT_EXERCISE && selectedExercise)
      dispatch(setSelectedExercise(null));
  }, [rightWidgetView]);

  const toggleOnlyHistory = (only) => {
    setRightWidgetView(
      only
        ? widgetViewTypes.EXERCISE_HISTORY
        : widgetViewTypes.PERFORMANCE_AND_HISTORY
    );
  };

  useEffect(() => {
    dispatch(setHistoryDetails(null));
    if (!currentProgram && !isClient && isStrengthTest) {
      setRightWidgetView(widgetViewTypes.ADD_PROGRAM);
      dispatch(setIsOpenDrawer(true));
      return;
    }

    if (!currentProgram && isClient) {
      setRightWidgetView(null);
      dispatch(setIsOpenDrawer(false));
      return;
    }

    if (isMobileVersion) {
      dispatch(setIsOpenDrawer(false));
    }

    if (rightWidgetView !== widgetViewTypes.NOTES && isStrengthTest) {
      setRightWidgetView(widgetViewTypes.PERFORMANCE_AND_HISTORY);
    }

    setIsShowTraining(numberOfPrograms > 0);

    if (isEmptyProgram) {
      setRightWidgetView(null);
    }
  }, [currentProgram]);

  useEffect(() => {
    if(details) {
      dispatch(setHistoryDetails(null));
      !isMobileVersion && toggleOnlyHistory(false);
    }
  }, [currentSet]);

  const loadPrevPage = () => {
    dispatch(
      getProgram({
        id: match.params.id,
        programIndex: currentProgramIdx - 1,
      })
    );
  };
  const loadNextPage = () => {
    const index =
      currentProgramIdx === numberOfPrograms - 2
        ? undefined
        : currentProgramIdx + 1;
    dispatch(
      getProgram({
        id: match.params.id,
        programIndex: index,
      })
    );
  };
  /**
   * @description
   * setRightWidgetPerformanceAndHistory - function for change
   * RightWidget when user click on
   * @see SetButton
   */
  const setRightWidgetPerformanceAndHistory = useCallback(
    () => setRightWidgetView(widgetViewTypes.PERFORMANCE_AND_HISTORY),
    []
  );

  const onDrawerClose = () => {
    if (selectedExercise) {
      dispatch(setSelectedExercise(null));
    }

    dispatch(setHistoryDetails(null));
    dispatch(setIsOpenDrawer(false));
  };

  const prepareProgramForRender = useCallback(() => {
    return {
      days: currentProgram.days.map((day) => ({
        workouts: day.workouts.map((w) => ({
          exercises: w.exercises.map((e) => {
            /** @type Pb */
            const thePb = pb.find(({ exercise_id }) => exercise_id === e.id);
            return {
              ...e,
              pb_value: thePb?.pb_value || null,
              pb_calculated: thePb?.is_calculate ?? true,
            };
          }),
        })),
      })),
    };
  }, [currentProgram?.days, pb]);

  const needDataText = isClient
    ? "looks like you need a program?"
    : "Please choose a program";

  const emptyProgramText = "the program is empty";

  const needStrengthTestNotification = isClient ? (
    NO_STRENGTH_TEST_TEXT
  ) : (
    <Link
      to={`${CLIENT.root}${client.id}${BODY_TEST_RESULT_PAGE_SLUG}`}
      className="training-page__link training-page__link--none-style"
    >
      {NO_STRENGTH_TEST_TEXT}
    </Link>
  );

  const addButtonDisableCondition =
    rightWidgetView === widgetViewTypes.ADD_PROGRAM &&
    (isSmallScreen ? isOpenDrawer : true);

  const widgetArgsConstructor = (widgetType) => {
    switch (widgetType) {
      case widgetViewTypes.EDIT_EXERCISE:
        return {
          loading: changingExercise,
          onBack: handleEditExerciseBackClick,
          onExerciseClick: handleEditExerciseClick,
          changeAllExercise,
          setChangeAllExercise: (payload) =>
            dispatch(setChangeAllExercise(payload)),
        };

      case widgetViewTypes.PERFORMANCE_AND_HISTORY:
      case widgetViewTypes.EXERCISE_HISTORY:
        return {
          hideWidget: () => {
            dispatch(clearPreselectedValues());
            dispatch(setIsOpenDrawer(false));
          },
          setWidgetToHistory: () =>
            setRightWidgetView(widgetViewTypes.EXERCISE_HISTORY),
          setWidgetToPerformanceAndHistory: () =>
            setRightWidgetView(widgetViewTypes.PERFORMANCE_AND_HISTORY),
          isMobileVersion,
          setOnlyHistory: toggleOnlyHistory,
        };
      case widgetViewTypes.ADD_PROGRAM:
        return {
          hideWidget: () => dispatch(setIsOpenDrawer(false)),
        };
      case widgetViewTypes.NOTES:
        return {
          hideWidget: () => dispatch(setIsOpenDrawer(false)),
        };
      default:
        break;
    }
  };

  return (
    <>
      <ModalWindow
        modal={modal}
        confirm={() => {
          dispatchChangeExercise(changeExerciseParams);
          setIsQuestion(false);
          dispatch(setModal(null));
        }}
        close={() => {
          setIsQuestion(false);
          dispatch(setModal(null));
        }}
        isQuestion={isQuestion}
      />
      {(loading || loadingProgram) && !currentProgram ? (
        <Spinner />
      ) : (
        <div className="content-rhs content">
          <div className="page-content d-flex w-100 training-page">
            <div className="body-main left-widget__wrapper training-page__body-main">
              <Widget>
                <Widget.Header>
                  <Widget.Title>{`${name}'s training`}</Widget.Title>
                  <div className="training-page__header">
                    {isShowTraining && currentProgram ? (
                      <>
                        {isNotHideHistory && numberOfPrograms > 1 && (
                          <div className="training-page__header-arrows">
                            <PrevPageArrow
                              width="40px"
                              heigth="40px"
                              className={cn("body-arrow", {
                                "body-arrow--hidden": !prevPageExists,
                                "body-arrow--disabled": isSavingSet,
                              })}
                              onClick={loadPrevPage}
                            />
                            <span className="training-page__arrow-label">
                              {nextPageExists
                                ? `program ${
                                  currentProgramIdx + 1
                                } of ${numberOfPrograms}`
                                : "current program"}
                            </span>
                            <NextPageArrow
                              width="40px"
                              heigth="40px"
                              className={cn("body-arrow", {
                                "body-arrow--hidden": !nextPageExists,
                                "body-arrow--disabled": isSavingSet,
                              })}
                              onClick={loadNextPage}
                            />
                          </div>
                        )}
                        <div className="training-page__header-buttons">
                          <Button
                            disabled={
                              !isSmallScreen &&
                              rightWidgetView === widgetViewTypes.NOTES
                            }
                            onClick={() => {
                              setRightWidgetView(widgetViewTypes.NOTES);
                              if (isSmallScreen)
                                dispatch(setIsOpenDrawer(true));
                            }}
                          >
                            notes
                          </Button>
                          {!isClient && !isMobileVersion && (
                            <Button
                              onClick={() =>
                                history.push(
                                  `${CLIENT.root}${client.id}${TRAINING_PROGRAM_SLUG}/edit`
                                )
                              }
                            >
                              edit
                            </Button>
                          )}
                          {!isClient &&
                          !isSmallScreen &&
                          rightWidgetView === widgetViewTypes.ADD_PROGRAM ? (
                              <Button
                                onClick={() =>
                                  setRightWidgetView(
                                    widgetViewTypes.PERFORMANCE_AND_HISTORY
                                  )
                                }
                              >
                              back
                              </Button>
                            ) : (
                              !isClient && (
                                <Button
                                  onClick={() => {
                                    setRightWidgetView(
                                      widgetViewTypes.ADD_PROGRAM
                                    );
                                    if (isSmallScreen)
                                      dispatch(setIsOpenDrawer(true));
                                  }}
                                >
                                +new
                                </Button>
                              )
                            )}
                        </div>
                      </>
                    ) : (
                      !isClient &&
                      isStrengthTest && (
                        <Button
                          onClick={() => {
                            setRightWidgetView(widgetViewTypes.ADD_PROGRAM);
                            if (isSmallScreen) dispatch(setIsOpenDrawer(true));
                          }}
                          disabled={loading || addButtonDisableCondition}
                        >
                          add
                        </Button>
                      )
                    )}
                  </div>
                </Widget.Header>
                {isShowTraining && currentProgram && !loadingProgram ? (
                  <>
                    {description && (
                      <div className="training-page__description">
                        <div className="training-page__description-title">
                          description
                        </div>
                        <div className="training-page__description-body">
                          <p
                            className={cn({
                              "training-page__description-text--truncated":
                                isDescriptionTruncated,
                            })}
                            ref={descriptionRef}
                          >
                            {description}
                          </p>
                          {descriptionWasTruncated && (
                            <div
                              className="training-page__description-button"
                              onClick={() =>
                                setIsDescriptionTruncated((cur) => !cur)
                              }
                            >
                              show {isDescriptionTruncated ? "more" : "less"}...
                            </div>
                          )}
                        </div>
                        <hr />
                      </div>
                    )}
                    {prepareProgramForRender().days.map((day, idx) => (
                      <TrainingDay
                        key={day.id || idx}
                        data={day}
                        dayIdx={idx}
                        massUnit={client.mass_unit || "kg"}
                        title={`day ${idx + 1}`}
                        setRightWidgetPerformanceAndHistory={
                          setRightWidgetPerformanceAndHistory
                        }
                      />
                    ))}
                    {isEmptyProgram && <h3>{emptyProgramText}</h3>}
                  </>
                ) : loadingProgram ? (
                  <Spinner />
                ) : (
                  <h3>
                    {isStrengthTest
                      ? needDataText
                      : needStrengthTestNotification}
                  </h3>
                )}
              </Widget>
            </div>
            {!isSmallScreen && (
              <div className="body-side sticky-side training-page__side">
                {widgets[rightWidgetView](
                  widgetArgsConstructor(rightWidgetView)
                )}
              </div>
            )}
            {isSmallScreen && (
              <MobileDrawer
                onClose={onDrawerClose}
                open={isOpenDrawer}
                mobileView={isMobileVersion}
              >
                {widgets[rightWidgetView](
                  widgetArgsConstructor(rightWidgetView)
                )}
              </MobileDrawer>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ClientTrainingPage;

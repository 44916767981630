import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import TermsPage from "./Terms";

const Terms = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}`}>
        <TermsPage />
      </Route>
    </Switch>
  );
};

export default Terms;

import { PropTypes } from "prop-types";
import React from "react";

import { onlyNumbers } from "../../../../../../../helpers";

const SectionSelector = ({
  title,
  startIdx,
  endIdx,
  setStartIdx,
  setEndIdx,
}) => {
  const handleStartIdxChange = (e) =>
    setStartIdx(parseInt(onlyNumbers(e.target.value)));
  const handleEndIdxChange = (e) =>
    setEndIdx(parseInt(onlyNumbers(e.target.value)));

  return (
    <div className="tools-block__line tools-block__line--section">
      <div className="tools-block__line-text">{title}:</div>
      <div className="tools-block__line-text">from day</div>
      <input
        type="text"
        className="tools-block__input tools-block__input--sm"
        value={startIdx || ""}
        onChange={handleStartIdxChange}
        onBlur={() =>
          !startIdx && handleStartIdxChange({ target: { value: "1" } })
        }
      />
      <div className="tools-block__line-text">to day</div>
      <input
        type="text"
        className="tools-block__input tools-block__input--sm"
        value={endIdx || ""}
        onChange={handleEndIdxChange}
        onBlur={() =>
          !endIdx && handleEndIdxChange({ target: { value: startIdx + "" }})
        }
      />
    </div>
  );
}

SectionSelector.propTypes = {
  title: PropTypes.string,
  startIdx: PropTypes.number.isRequired,
  endIdx: PropTypes.number.isRequired,
  setStartIdx: PropTypes.func.isRequired,
  setEndIdx: PropTypes.func.isRequired,
};

SectionSelector.defaultProps = {
  title: "section",
};
export default SectionSelector;

import lodash from "lodash";
import { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch, useParams } from "react-router-dom";

import GuardedRoute from "../../components/GuardedRoute";
import Spinner from "../../components/Spinner";
import { COMPANY, ID_SLUG, MANAGE_COMPANY_SLUG } from "../../constants/router";
import { TOASTR_TYPE } from "../../constants/toastr";
import { useToastr } from "../../hooks/useToastr";
import { clearError, loadCompanyById } from "../../redux/companiesSlice";

import CompaniesPage from "./CompaniesPage";
import CompanyAddPage from "./CompanyAddPage";
import CompanyManagePage from "./CompanyManagePage";

const Company = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const error = useSelector((state) => state.companies.error);

  useToastr({ messages: error, deps: error, type: TOASTR_TYPE.ERROR, cb: () => dispatch(clearError()) });

  return (
    <Switch>
      <Route exact path={`${match.url}`}>
        <CompaniesPage />
      </Route>
      <Route exact path={`${match.url}/${COMPANY.addCompany}`}>
        <CompanyAddPage />
      </Route>
      <Route exact path={`${match.url}/${ID_SLUG}${MANAGE_COMPANY_SLUG}`}>
        <NeedGetCompanyRouters>
          <GuardedRoute
            to={COMPANY.root}
            isShow={!lodash.isEmpty(currentCompany)}
          >
            <CompanyManagePage />
          </GuardedRoute>
        </NeedGetCompanyRouters>
      </Route>
    </Switch>
  );
};

const NeedGetCompanyRouters = ({ children }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const [isShowSpinner, setIShowSpinner] = useState(true);
  useLayoutEffect(() => {
    async function fetchMyAPI() {
      if (id && id !== currentCompany?.id) {
        await dispatch(loadCompanyById({ id }));
      }
      setIShowSpinner(false);
    }

    fetchMyAPI();
  }, []);

  if (isShowSpinner) {
    return <Spinner />;
  }
  return children;
};

export default Company;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as DietsApi from "../api/dietsApi";
import addInSortArray from "../helpers/addInSortArray";

import { updateDiet } from "./dietsSlice";
import { clientIdSelector } from "./selectors";

export const loadDietTemplates = createAsyncThunk(
  "dietTemplates/loadDietTemplates",
  (args) => DietsApi.loadDietTemplatesWithCache(args)
);

export const getDietTemplates = createAsyncThunk(
  "dietTemplates/getDietTemplates",
  async (args, { getState, dispatch }) => {
    const res = await DietsApi.getDietTemplatesWithCache(args);

    const { second_diet, ...diets } = res;
    const clientId = clientIdSelector(getState());
    dispatch(
      updateDiet({
        id: clientId,
        body: {
          ...diets,
          ...(diets.is_double_diet && { second_diet }),
        },
      })
    );

    return res;
  }
);

export const addDietTemplates = createAsyncThunk(
  "dietTemplates/addDietTemplates",
  DietsApi.addDietTemplates
);

export const deleteDietTemplates = createAsyncThunk(
  "dietTemplates/deleteDietTemplates",
  DietsApi.deleteDietTemplates
);

const dietTemplatesSlice = createSlice({
  name: "dietTemplates",
  initialState: {
    items: null,
    removableItem: null,
    selectedItem: null,
    loading: false,
    success: false,
    successfullyGetById: false,
    successfullyDietTemplateDeleted: false,
    successfullyDietTemplateAdded: false,
    error: "",
  },
  reducers: {
    setRemovableItem(state, action) {
      state.removableItem = action.payload;
    },
    clearRemovableItem(state) {
      state.removableItem = null;
    },
    clearSelectedItem(state) {
      state.selectedItem = null;
    },
    clearSuccessfullyAddDietTemplate(state) {
      state.successfullyDietTemplateAdded = false;
    },
    clearSuccessfullyDeleteDietTemplate(state) {
      state.successfullyDietTemplateDeleted = false;
    },
  },
  extraReducers: {
    [loadDietTemplates.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [loadDietTemplates.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = "";
      state.items = action.payload;
    },
    [loadDietTemplates.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    [getDietTemplates.pending]: (state) => {
      state.successfullyGetById = false;
    },
    [getDietTemplates.fulfilled]: (state, action) => {
      state.successfullyGetById = true;
      state.error = "";
      state.selectedItem = action.payload;
    },
    [getDietTemplates.rejected]: (state, action) => {
      state.successfullyGetById = false;
      state.error = action.payload;
    },
    [deleteDietTemplates.pending]: (state) => {
      state.successfullyDietTemplateDeleted = false;
    },
    [deleteDietTemplates.fulfilled]: (state) => {
      state.successfullyDietTemplateDeleted = true;
      state.error = "";
      state.items = state.items.filter((i) => i.id !== state.removableItem.id);
      state.removableItem = null;
    },
    [deleteDietTemplates.rejected]: (state, action) => {
      state.successfullyDietTemplateDeleted = false;
      state.error = action.payload;
    },
    [addDietTemplates.pending]: (state) => {
      state.successfullyDietTemplateAdded = false;
    },
    [addDietTemplates.fulfilled]: (state, action) => {
      state.successfullyDietTemplateAdded = true;
      state.error = "";
      state.items = addInSortArray(state.items, action.payload, "name");
    },
    [addDietTemplates.rejected]: (state, action) => {
      state.successfullyDietTemplateAdded = false;
      state.error = action.payload;
    },
  },
});

export const {
  setRemovableItem,
  clearRemovableItem,
  clearSelectedItem,
  clearSuccessfullyAddDietTemplate,
  clearSuccessfullyDeleteDietTemplate,
} = dietTemplatesSlice.actions;

export default dietTemplatesSlice.reducer;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import FoodEditor from "../../../components/FoodEditor";
import MobileDrawer from "../../../components/MobileDrawer";
import "./FoodsPage.scss";
import { FOODS_SIDEBAR_NAMES } from "../../../constants/foods";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import {
  loadFoodsForCurrentUser,
  setEditableFoodItem,
} from "../../../redux/foodsSlice";

import FoodsList from "./components/FoodsList";

const FoodsPage = () => {
  const dispatch = useDispatch();
  const isSmallScreen = useIsSmallScreen();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [sidebarMode, setSidebarMode] = useState(FOODS_SIDEBAR_NAMES.FOOD_ADD);

  useEffect(() => {
    dispatch(loadFoodsForCurrentUser());
  }, []);

  const onFoodClick = (food) => {
    setIsOpenDrawer(true);
    setSidebarMode(FOODS_SIDEBAR_NAMES.FOOD_EDIT);
    dispatch(setEditableFoodItem(food));
  };

  const onFoodDelete = (food) => {
    setIsOpenDrawer(true);
    setSidebarMode(FOODS_SIDEBAR_NAMES.FOOD_DELETE);
    dispatch(setEditableFoodItem(food));
  };

  return (
    <div className="foods__content-rhs foods__content">
      <div className="foods__page-content">
        <div className="foods__main">
          <FoodsList
            onFoodClick={onFoodClick}
            setIsOpenDrawer={setIsOpenDrawer}
            isOpenDrawer={isOpenDrawer}
            setSidebarMode={setSidebarMode}
            sidebarMode={sidebarMode}
            onFoodDelete={onFoodDelete}
          />
        </div>
        <div className="foods__side">
          {isSmallScreen && (
            <MobileDrawer
              open={isOpenDrawer}
              onClose={() => {
                setIsOpenDrawer(false);
              }}
            >
              <FoodEditor
                setSideBar={setSidebarMode}
                sideBarName={sidebarMode}
                stateAfterSubmit={
                  sidebarMode === FOODS_SIDEBAR_NAMES.FOOD_DELETE
                    ? FOODS_SIDEBAR_NAMES.FOOD_ADD
                    : FOODS_SIDEBAR_NAMES.FOOD_EDIT
                }
              />
            </MobileDrawer>
          )}
          {!isSmallScreen && (
            <FoodEditor
              setSideBar={setSidebarMode}
              sideBarName={sidebarMode}
              stateAfterSubmit={
                sidebarMode === FOODS_SIDEBAR_NAMES.FOOD_DELETE
                  ? FOODS_SIDEBAR_NAMES.FOOD_ADD
                  : FOODS_SIDEBAR_NAMES.FOOD_EDIT
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FoodsPage;

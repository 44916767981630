import cn from "classnames";
import "./ProgressHeaders.scss";

const ProgressHeaders = ({ fullPage = false, progress }) => (
  <table
    className={cn("progress-headers", {
      "progress-headers--widget": !fullPage,
      "progress-headers--fullPage": fullPage,
    })}
  >
    <thead>
      <tr className="hidden">
        <td>
          <div style={{fontSize: "1.2em"}}>a</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>body</div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div>weight</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>fat%</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>lean</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>fat</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>
            {progress?.body?.skin_fold === progress?.body?.skin_notes ? "measure(s)" : "skinfolds"}
          </div>
        </td>
      </tr>
    </tbody>
    <thead>
      <tr>
        <td>
          <div>diet</div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div>calories</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>p:c:f %</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>compliance</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>alcohol</div>
        </td>
      </tr>
    </tbody>
    <thead>
      <tr>
        <td>
          <div>strength</div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div>squat</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>bench</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>dead</div>
        </td>
      </tr>
    </tbody>
    <thead>
      <tr>
        <td>
          <div>training</div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div>squat</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>bench</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>dead</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>days/wk</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>sets/w.o</div>
        </td>
      </tr>
      <tr>
        <td>
          <div>pbs/w.o</div>
        </td>
      </tr>
    </tbody>
    <thead>
      <tr>
        <td>
          <div>notes:</div>
        </td>
      </tr>
    </thead>
  </table>
);

export default ProgressHeaders;

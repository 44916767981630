import { baseUrl } from "../env/env";
import withCache from "../helpers/cache";

import myFetch, { undefinedHandlerError } from "./myFetch";

export const loadTraining = ({ id }) =>
  myFetch(`${baseUrl}/api/clients/${id}/training`);

export const getProgram = ({ id, programIndex }) => {
  const index = programIndex !== undefined ? `index=${programIndex}` : "";
  return myFetch(`${baseUrl}/api/clients/${id}/training/program?${index}`);
};

export const loadProgramIds = ({ id }) => {
  return myFetch(`${baseUrl}/api/clients/${id}/training/templates`);
};

export const loadTrainingWithCache = withCache(loadTraining);
export const getProgramWithCache = withCache(getProgram);
export const loadProgramIdsWithCache = withCache(loadProgramIds);

export const addProgram = ({ id, body }) => {
  getProgramWithCache.invalidateByArgs({ id });
  loadProgramIdsWithCache.invalidateByArgs({ id });
  loadTrainingWithCache.invalidateByArgs({ id });
  return myFetch(`${baseUrl}/api/clients/${id}/training/add_program`, {
    method: "POST",
    body,
  });
};

export const updateProgram = ({ id, body, isLast }) => {
  const { program_index: programIndex } = body;
  loadTrainingWithCache.invalidateByArgs({ id });
  getProgramWithCache.invalidateByArgs({
    id,
    ...(!isLast && { programIndex }),
  });
  return myFetch(
    `${baseUrl}/api/clients/${id}/training/update_program`,
    {
      method: "PUT",
      body,
    },
    undefinedHandlerError
  );
};

export const changeExercise = ({ id, body, isLast }) => {
  const { program_index: programIndex } = body;
  getProgramWithCache.invalidateByArgs({
    id,
    ...(!isLast && { programIndex }),
  });
  loadTrainingWithCache.invalidateByArgs({ id });
  return myFetch(`${baseUrl}/api/clients/${id}/training/change_exercise`, {
    method: "PATCH",
    body,
  });
};

export const updateSet = ({ id, body, isLast }) => {
  const {
    indexes: { program: programIndex },
  } = body;
  loadTrainingWithCache.invalidateByArgs({ id });
  return myFetch(`${baseUrl}/api/clients/${id}/training/update_set`, {
    method: "PATCH",
    body,
  }).then((res) => {
    const args = {
      id,
      ...(!isLast && { programIndex }),
    };

    if (res?.programs[0]) {
      const body = {
        ...res.programs[0],
        program_index: programIndex,
      };
      getProgramWithCache.updateByArgs(args, Promise.resolve(body));
    } else {
      getProgramWithCache.invalidateByArgs(args);
    }

    return res;
  });
};

export const updatePb = ({ id, body }) => {
  loadTrainingWithCache.invalidateByArgs({ id });
  return myFetch(`${baseUrl}/api/clients/${id}/training/update_pb`, {
    method: "PUT",
    body,
  });
};

export const saveDayNotes = ({ id, body, isLast }) => {
  const { program_index: programIndex } = body;
  return myFetch(`${baseUrl}/api/clients/${id}/training/update_notes`, {
    method: "PUT",
    body,
  }).then((res) => {
    const args = {
      id,
      ...(!isLast && { programIndex }),
    };

    if (res?.note) {
      getProgramWithCache(args).then((prog) => {
        const noteInProg = prog.notes.findIndex(
          (note) => note.day_index === res.note.day_index
        );
        // haskell devs be like
        const notes =
          noteInProg !== -1
            ? [
              ...prog.notes.slice(0, noteInProg),
              res.note,
              ...prog.notes.slice(noteInProg + 1),
            ]
            : [...prog.notes, res.note];

        const body = {
          ...prog,
          notes,
        };
        getProgramWithCache.updateByArgs(args, Promise.resolve(body));
      });
    } else {
      getProgramWithCache.invalidateByArgs(args);
    }

    return res;
  });
};

export const loadHistory = ({ id, historyOnly }) => {
  loadSetHistoryWithCache.invalidate();
  return myFetch(
    `${baseUrl}/api/clients/${id}/training/exercise_history${
      historyOnly ? "?history_only=true" : ""
    }`
  );
};

export const loadSetHistory = ({ id, program_index, day_index }) => {
  return myFetch(
    `${baseUrl}/api/clients/${id}/target_day?program_index=${program_index}&day_index=${day_index}`
  );
};

export const loadSetHistoryWithCache = withCache(loadSetHistory);

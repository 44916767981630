import { Snackbar } from "@material-ui/core";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { CLICK_AWAY_REASON } from "../../constants/common";
import { SNACKBAR_PROPS, TOASTR_TYPE } from "../../constants/toastr";
import { closeToastr } from "../../redux/toastrSlice";
import "./Toastr.scss";

const Toastr = () => {
  const dispatch = useDispatch();
  const type = useSelector((state) => state.toastr.type);
  const isOpen = useSelector((state) => state.toastr.isOpen);
  const text = useSelector((state) => state.toastr.text);
  const handleClose = (event, reason) => {
    if (reason === CLICK_AWAY_REASON) {
      return;
    }
    dispatch(closeToastr());
  };

  return (
    <Snackbar
      open={isOpen}
      onClose={handleClose}
      onClick={handleClose}
      {...SNACKBAR_PROPS}
    >
      <div className={cn("notify-item", type)}>
        <p className="notify-title">
          {type === TOASTR_TYPE.SUCCESS && "Done!"}
          {type === TOASTR_TYPE.SAVED && "Saved"}
          {type === TOASTR_TYPE.ERROR && "Error!"}
        </p>
        <p className="notify-body">{text}</p>
      </div>
    </Snackbar>
  );
};

export default Toastr;

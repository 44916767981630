import React from "react";

import Widget from "../../components/widget/Widget";
import useHideMenu from "../../hooks/useHideMenu";
import "./Privacy.scss";

const PrivacyPage = () => {
  useHideMenu();

  return (
    <div className="content-rhs content content--terms-privacy-form">
      <div className="page-content d-flex w-100">
        <div className="body-main left-widget__wrapper">
          <Widget>
            <div className="terms-privacy-form"><b className="terms-privacy__title">Privacy policy</b>
              <div>Last Updated: 14th June 2021<br /><br />Thank
                you for deciding to share your personal data with us!</div>
              <span>Who we are</span>
              <div>This privacy policy is issued on behalf of Recomposer FZE, PO Box 4422,
                Fujairah Creative Tower, Fujairah, UAE. In this Privacy Policy the
                terms <b>“Recomposer”</b>, <b>“we”</b>, <b>“us”</b> or <b>“our”</b> refer
                to Recomposer FZE.<br />For any questions about this policy,
                  including questions about your rights under data protection law, please contact our Privacy Officer
                  at: legal@recomposer.com.</div>
              <span>About this privacy policy</span>
              <div>Recomposer respects your privacy and is committed to protecting your personal
                data. This privacy policy will inform you as to how we look after your personal data when you use our
                system at https://recomposer.app
                (<b>“App”</b>, <b>“Website”</b> or <b>“System”</b>)
                and our help site at https://recomposer.com, and tell you about your privacy rights and how the law
                protects you.<br />We keep our privacy policy under regular
                  review and we therefore invite you to regularly review this webpage. It is important that the personal
                  data we hold about you is accurate and current. Please keep us informed if your personal data changes
                  during your relationship with us.</div>
              <span>Policy terms</span>
              <div>
                <ol>
                  <li><b>The data we collect about you</b> We may collect, use, store and
                    transfer different kinds of personal data about you, meaning any information that can either
                    directly or indirectly identify you, which we have grouped together as follows:
                  <ul className="privacy-list">
                    <li><b>Identity Data and Contact Data</b> includes first name, last name,
                        email address, phone number, password, date of birth, gender and other similar identifiers.
                    </li>
                    <li><b>Financial and Transactional Data</b> may include bank account and
                        payment card details and details about payments from you and other details of services you have
                        purchased from us and other similar details. Please note we do not store your full payment card
                        details. This information is stored by our trusted third-party payment processor.
                    </li>
                    <li><b>Technical and Usage Data</b> includes information about how you use
                        our Website and App such as internet protocol (IP) address, your login data, browser type and
                        version, time zone settings, browser plug-in types and versions, operating system and platform,
                        and other similar technology identifiers on the devices you use to access the Website, the App.
                    </li>
                    <li><b>Profile Data</b> includes your body composition, diet and training
                        data, and all other similar or related data you provide us with.</li>
                  </ul>
                  </li>
                  <li>We also process <b>Aggregated
                    Data</b> such as statistical or demographic data for any purpose. Aggregated
                    Data could be derived from your Profile Data but is not considered personal data in law as this data
                    will not directly or indirectly reveal your identity.</li>
                  <li><b>If you fail to provide personal data</b><br />Where we need to collect
                    personal data and you fail to provide that data, the system will be unable to provide you with it’s
                    services.</li>
                  <li><b>How is your personal data collected? Direct interactions</b>. You may
                    give us your Identity and Contact and Financial and Transactional Data by filling in forms or by
                    emailing us from the email associated with your user account in the System.</li>
                  <li><b>Automated technologies or interactions</b>. As you interact with our
                    App, we will automatically collect Technical and Usage Data about your browsing and your device. We
                    collect this personal data by using cookies, local storage and other technologies, both proprietary
                    and third-party-sourced. By using our App, you agree to the use of these cookies and technologies.
                  </li>
                  <li><b>How we use your personal data</b> Your personal data is used to provide
                    the services of the website and may include use by trusted third party suppliers to assist in
                    providing those services, or in helping us to develop our services.</li>
                  <li><b>Disclosures of your personal data Our suppliers</b><br />We use a few
                    different suppliers in order to deliver, develop and promote our services. Some of your personal
                    data may be shared with them for the purposes above. We use suppliers to process payments, to store
                    data in the cloud, to insure our services, to run analytics and data analysis, to provide customer
                    support and to do advertising and marketing.<br />All our
                      suppliers have signed data processing agreements with us. We require all third parties to respect
                      the security of your personal data and to treat it in accordance with the law. We do not allow our
                      third-party service providers to use your personal data for their own purposes (unless this is
                      necessary for them to provide their services to us, in which case you will be explicitly informed)
                      and only permit them to process your personal data for specified purposes and in accordance with
                      our instructions.<br /><b>Corporate transactions</b><br />We
                        may share your personal data with third parties to whom we may choose to sell, transfer or merge
                        parts of our business or our assets. Alternatively, we may seek to acquire other businesses or
                        merge with them. If a change happens to our business, then the new owners may use your personal
                        data.<br /><b>Professional advisers and law
                          enforcement</b><br />We may disclose your personal information to the
                          court service or regulators or law enforcement agencies in connection with proceedings or
                          investigations where compelled to do so. We may disclose your personal information to our
                          professional advisors that are usually regulated by a competent authority (legal
                          representatives, accountants, etc.) where that proves
                          necessary.<br /><b>Cookies</b> Cookies are most often
                            served by third parties and this usually involves disclosure of Technical and Usage data
                            that is often anonymised. Please use our cookies banner to accept or reject non-essential
                            cookies.</li>
                  <li><b>Data security</b> We have put in place appropriate security measures to
                    prevent your personal data from being accidentally lost, used or accessed in an unauthorised way,
                    altered or disclosed. In addition, we limit access to your personal data to those employees, agents,
                    contractors and other third parties who have a business need to know. They will only process your
                    personal data on our instructions and they are subject to a duty of confidentiality. We have put in
                    place procedures to deal with any suspected personal data breach and will notify you and any
                    applicable regulator of a breach where we are legally required to do so.</li>
                  <li><b>Data retention How long will you use my personal data for?</b> We will
                    only retain your personal data for as long as reasonably necessary to fulfil the purposes we
                    collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or
                    reporting requirements. We may retain your personal data for a longer period in the event of a
                    complaint or if we reasonably believe there is a prospect of litigation in respect to our
                    relationship with you. To determine the appropriate retention period for personal data, we consider
                    the amount, nature and sensitivity of the personal data, the potential risk of harm from
                    unauthorised use or disclosure of your personal data, the purposes for which we process your
                    personal data and whether we can achieve those purposes through other means, and the applicable
                    legal, regulatory, tax, accounting or other
                    requirements.<br />In some circumstances we will
                      irreversibly anonymise your personal data (so that it can no longer be associated with you) for
                      research or statistical purposes, in which case this policy would no longer apply and we may use
                      this information indefinitely without further notice to you.</li>
                  <li><b>Your legal rights</b> Recomposer is committed to fulfilling the
                    statutory data protection rights of customers. If you send us a request regarding your rights under
                    UAE law, we will respond within one month from the day of receipt and, where possible, address your
                    request within such time. Where necessary, this period may be extended by up to a further two months
                    in complex cases. Please contact us via email to: legal@recomposer.com, to exercise the following
                    rights in respect of the personal information Recomposer processes about you:
                  <ul className="privacy-list">
                    <li>to be informed</li>
                    <li>to access (such information)</li>
                    <li>to rectify</li>
                    <li>to erasure</li>
                    <li>to restrict processing</li>
                    <li>to object to profiling</li>
                    <li>to data portability</li>
                    <li>to complain to the data protection authority</li>
                    <li>to withdraw consent</li>
                  </ul>
                  </li>
                </ol>
                We would appreciate the chance to deal with any concerns you may have before you
                raise them with your local authority so please contact us in the first instance by emailing us at
                legal@recomposer.com.</div>
            </div>
          </Widget>
        </div>
      </div>
    </div>
  );
};

PrivacyPage.propTypes = {};

export default PrivacyPage;

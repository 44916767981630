import { ErrorMessage, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import * as Yup from "yup";

import FormField from "../../../../../components/FormField/FormField";
import { validatePhoneNumber } from "../../../../../helpers/validators";

const ProfileForm = ({ initialValues, onSubmit }) => {
  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={Yup.object().shape({
          first_name: Yup.string().required("first name is a required field"),
          last_name: Yup.string().required("last name is a required field"),
          email: Yup.string().required().email(),
          phone: Yup.string().test(
            "phone number is invalid",
            (val) => !validatePhoneNumber(val)
          ),
          password: Yup.string().min(8),
          "re-enter password": Yup.string()
            .when("password", {
              is: (password) => password?.length > 0,
              then: Yup.string().required("please confirm a password")
            })
            .oneOf([Yup.ref("password"), null], "your password and confirmation password do not match")
        })}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <div className="profile__row">
              <div className="profile__field">
                <FormField
                  label="first name"
                  name="first_name"
                  type="text"
                  isInline={false}
                  submitOnBlur
                />
                <ErrorMessage
                  component="div"
                  name="first_name"
                  className="auth-error"
                />
              </div>
              <div className="profile__field">
                <FormField
                  label="last name"
                  name="last_name"
                  type="text"
                  isInline={false}
                  submitOnBlur
                />
                <ErrorMessage
                  component="div"
                  name="last_name"
                  className="auth-error"
                />
              </div>
            </div>
            <div className="profile__row">
              <div className="profile__field">
                <FormField
                  label="phone"
                  name="phone"
                  type="text"
                  isInline={false}
                  submitOnBlur
                />
                <ErrorMessage
                  component="div"
                  name="phone"
                  className="auth-error"
                />
              </div>
              <div className="profile__field">
                <FormField
                  label="email"
                  sublabel="(used for login)"
                  name="email"
                  type="text"
                  isInline={false}
                  submitOnBlur
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="auth-error"
                />
              </div>
            </div>
            <div className="profile__row">
              <div className="profile__field">
                <FormField
                  label="password"
                  name="password"
                  type="password"
                  isInline={false}
                  submitOnBlur
                />
                <ErrorMessage
                  component="div"
                  name="password"
                  className="auth-error"
                />
              </div>
              <div className="profile__field">
                <FormField
                  label="re-enter password"
                  name="re-enter password"
                  type="password"
                  isInline={false}
                  submitOnBlur
                />
                <ErrorMessage
                  component="div"
                  name="re-enter password"
                  className="auth-error"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ProfileForm.propTypes = {
  onSubmit: PropTypes.func,
  onSubmitPassword: PropTypes.func,
  initialValues: PropTypes.object,
};

ProfileForm.defaultProps = {
  initialValues: {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    password2: "",
  },
};

export default ProfileForm;

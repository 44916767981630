import "./CompanyManagePage.scss";
import { Box } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import CompanyForm from "../../../components/CompanyForm";
import CompanyInvoices from "../../../components/CompanyInvoces";
import MobileDrawer from "../../../components/MobileDrawer";
import Widget from "../../../components/widget/Widget";
import { COMPANY_TAB } from "../../../constants/company";
import { COMPANY } from "../../../constants/router";
import useGetCompanyInvoice from "../../../hooks/useGetCompanyInvoice";
import useSendUpdateCompany from "../../../hooks/useSendUpdateCompany";
import { clearCurrentCompany } from "../../../redux/companiesSlice";
import TabButton from "../components/TabButton";

import FooterButtons from "./components/FooterButtons";
import ManageThemes from "./components/ManageThemes";

const CompanyManagePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useGetCompanyInvoice();
  const successfullyCompanyEdited = useSelector(
    (state) => state.companies.successfullyCompanyEdited
  );
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const [currentTab, setCurrentTab] = useState(COMPANY_TAB.INFORMATION);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [formCompanyVal, setFormCompanyVal] = useState({
    body: {},
    oldVal: {},
  });
  useSendUpdateCompany(formCompanyVal, 0);

  const goToCompanyList = () => {
    history.push(COMPANY.root);
  };
  const goToAddCompany = () => {
    dispatch(clearCurrentCompany());
    history.push(COMPANY.root + COMPANY.addCompany);
  };
  const onClickShowInvoices = () => {
    setIsOpenDrawer(true);
  };
  return (
    <div className="content-rhs content companies-page">
      <div className="companies-page__tab-buttons">
        <TabButton variant="left" onClick={goToCompanyList}>
          companies list
        </TabButton>
        <TabButton variant="center" active>
          manage
        </TabButton>
        <TabButton variant="right" onClick={goToAddCompany}>
          add a company
        </TabButton>
      </div>
      <div className="page-content w-100">
        <div className="companies-page__header">
          <Widget.Title>{currentCompany.name}</Widget.Title>
        </div>
        <Widget.Body>
          <Box>
            <TabButton
              variant="left"
              active={currentTab === COMPANY_TAB.INFORMATION}
              withoutBorder
              onClick={() => setCurrentTab(COMPANY_TAB.INFORMATION)}
            >
              {COMPANY_TAB.INFORMATION}
            </TabButton>
            <TabButton
              variant="right"
              active={currentTab === COMPANY_TAB.THEMES}
              withoutBorder
              onClick={() => setCurrentTab(COMPANY_TAB.THEMES)}
            >
              {COMPANY_TAB.THEMES}
            </TabButton>
          </Box>
          {currentTab === COMPANY_TAB.INFORMATION && (
            <CompanyForm
              onSubmit={(body, oldVal) => {
                setFormCompanyVal({ body, oldVal });
              }}
              isEditPage={true}
              initialValues={currentCompany}
              children={
                <FooterButtons onClickShowInvoices={onClickShowInvoices} />
              }
            />
          )}
          {currentTab === COMPANY_TAB.THEMES && (
            <ManageThemes
              onClickShowInvoices={onClickShowInvoices}
            />
          )}
        </Widget.Body>
      </div>
      <MobileDrawer
        open={isOpenDrawer}
        onClose={() => {
          setIsOpenDrawer(false);
        }}
      >
        <CompanyInvoices />
      </MobileDrawer>
    </div>
  );
};

CompanyManagePage.propTypes = {};

export default CompanyManagePage;

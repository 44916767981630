import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  DELAY_FOR_SEARCH,
  USER_TYPES,
} from "../../../../../../../constants/common";
import { FOOD_TYPES } from "../../../../../../../constants/foods";
import { filterList, createPermittedIdList } from "../../../../../../../helpers";
import useIsUserType from "../../../../../../../hooks/useIsUserType";
import FoodRow from "../FoodRow";

import VirtualList from "./../../../../../../../components/list/VirtualList/VirtualList";

const BodyFoods = ({ search, setSideBar }) => {
  const foods = useSelector((state) => state.foods.items);
  const user = useSelector((state) => state.user.user);
  const isSuper = useIsUserType(USER_TYPES.SUPERUSER);
  const [list, setList] = useState([]);

  const userType = useSelector((state) => state.user.user?.type);
  const userId = useSelector((state) => state.user.user?.id);
  const userCompanyId = useSelector((state) => state.user.user?.company);
  const checkedFoods = isSuper
    ? foods.filter((food) => food.type === FOOD_TYPES.GLOBAL)
    : foods;
  const perms = createPermittedIdList(checkedFoods, userType, userId, userCompanyId);

  const debounceSearch = useMemo(
    () =>
      debounce((_searchVal) => {
        const favF = checkedFoods.filter((food) =>
          user?.fav_foods.includes(food.id)
        );
        if (!_searchVal && favF.length) {
          setList(favF);
          return;
        }
        setList(filterList(checkedFoods, _searchVal));
      }, DELAY_FOR_SEARCH),
    [checkedFoods, user?.fav_foods]
  );

  useEffect(
    () => debounceSearch(search),
    [search, user?.fav_foods, checkedFoods]
  );

  return (
    <VirtualList
      isDynamic
      list={list}
      row={(virtualRow) => {
        const item = list[virtualRow.index];
        return (
          <FoodRow
            key={item.id}
            setSideBar={setSideBar}
            item={item}
            perms={perms}
          />
        );
      }}
    />
  );
};

BodyFoods.propTypes = {};

export default BodyFoods;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import MobileDrawer from "../../../components/MobileDrawer/MobileDrawer";
import { SIDEBAR_MODE } from "../../../constants/sidebar";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import { useToastr } from "../../../hooks/useToastr";
import {
  clearSelectedExercises,
  loadExercisesForCurrentUser,
  setSelectedExercises, setSidebarMode,
} from "../../../redux/exercisesSlice";
import "./ExercisesPage.scss";
import { setIsOpenDrawer } from "../../../redux/utilsSlice";

import ExerciseEditor from "./components/ExerciseEditor";
import ExercisesList from "./components/ExercisesList";

const ExercisesPage = () => {
  const dispatch = useDispatch();
  const isSmallScreen = useIsSmallScreen();
  const successfullyExercisesAdded = useSelector(
    (state) => state.exercises.successfullyExercisesAdded
  );
  const successfullyExercisesEdited = useSelector(
    (state) => state.exercises.successfullyExercisesEdited
  );
  const successfullyExercisesDelete = useSelector(
    (state) => state.exercises.successfullyExercisesDelete
  );
  const isOpenDrawer = useSelector((state) => state.utils.isOpenDrawer);

  useEffect(() => {
    dispatch(loadExercisesForCurrentUser());
    dispatch(clearSelectedExercises());
    dispatch(setSidebarMode(SIDEBAR_MODE.ADD));
  }, []);

  useToastr({
    messages: "The exercise has been added",
    deps: successfullyExercisesAdded,
  });
  useToastr({
    messages: "The exercise has been edited",
    deps: successfullyExercisesEdited,
  });
  useToastr({
    messages: "The exercise has been deleted",
    deps: successfullyExercisesDelete,
    cb: () => {
      dispatch(setSidebarMode(SIDEBAR_MODE.ADD));
      dispatch(clearSelectedExercises());
    },
  });

  const onExerciseClick = (exer) => {
    dispatch(setIsOpenDrawer(true));
    dispatch(setSidebarMode(SIDEBAR_MODE.EDIT));
    dispatch(setSelectedExercises(exer));
  };

  const onExerciseDelete = (exer) => {
    dispatch(setIsOpenDrawer(true));
    dispatch(setSidebarMode(SIDEBAR_MODE.DELETE));
    dispatch(setSelectedExercises(exer));
  };

  return (
    <div className="exercises__content-rhs exercises__content">
      <div className="exercises__page-content">
        <div className="exercises__main">
          <ExercisesList
            onExerciseDelete={onExerciseDelete}
            onExerciseClick={onExerciseClick}
          />
        </div>
        <div className="exercises__side">
          {isSmallScreen && (
            <MobileDrawer
              open={isOpenDrawer}
              onClose={() => {
                dispatch(setIsOpenDrawer(false));
              }}
            >
              <ExerciseEditor />
            </MobileDrawer>
          )}
          {!isSmallScreen && <ExerciseEditor />}
        </div>
      </div>
    </div>
  );
};

export default ExercisesPage;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as FoodApi from "../api/foodApi";
import addInSortArray from "../helpers/addInSortArray";

export const loadFoods = createAsyncThunk("foods/loadFoods", (args) =>
  FoodApi.loadFoodsWithCache.call(args)
);

export const loadFoodsForCurrentUser = createAsyncThunk(
  "foods/loadFoodsForCurrentUser",
  (args) => FoodApi.loadFoodsForCurrentUserWithCache.call(args)
);

export const addFood = createAsyncThunk("foods/addFood", (args) =>
  FoodApi.addFood(args)
);

export const editFood = createAsyncThunk("foods/editFood", (args) =>
  FoodApi.editFood(args)
);

export const deleteFood = createAsyncThunk("foods/deleteFood", (args) =>
  FoodApi.deleteFood(args)
);

const foodsSlice = createSlice({
  name: "foods",
  initialState: {
    items: [],
    itemCurrentUser: null,
    editableFoodItem: null,
    loadingCRUD: false,
    loading: false,
    success: false,
    successfullyFoodAdded: false,
    successfullyFoodEdited: false,
    successfullyFoodDeleted: false,
    error: "",
  },
  reducers: {
    clearFoodStatuses(state) {
      state.success = false;
      state.successfullyFoodAdded = false;
      state.successfullyFoodEdited = false;
      state.successfullyFoodDeleted = false;
      state.error = "";
    },
    clearSuccess(state) {
      state.success = false;
    },
    clearSuccessfullyFoodAdded(state) {
      state.successfullyFoodAdded = false;
    },
    clearSuccessfullyFoodEdited(state) {
      state.successfullyFoodEdited = false;
    },
    clearSuccessfullyFoodDeleted(state) {
      state.successfullyFoodDeleted = false;
    },
    setEditableFoodItem(state, action) {
      state.editableFoodItem = action.payload;
    },
    clearEditableFoodItem(state) {
      state.editableFoodItem = null;
    },
  },
  extraReducers: {
    [loadFoods.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [loadFoods.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = "";
      state.items = action.payload;
    },
    [loadFoods.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    [loadFoodsForCurrentUser.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [loadFoodsForCurrentUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = "";
      state.itemCurrentUser = action.payload;
    },
    [loadFoodsForCurrentUser.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    [addFood.pending]: (state) => {
      state.loadingCRUD = true;
      state.successfullyFoodAdded = false;
    },
    [addFood.fulfilled]: (state, action) => {
      state.loadingCRUD = false;
      state.successfullyFoodAdded = true;
      state.error = "";
      state.items?.push(action.payload);
      state.editableFoodItem = action.payload;
      if (state.itemCurrentUser)
        state.itemCurrentUser = addInSortArray(
          state.itemCurrentUser,
          action.payload,
          "name"
        );
    },
    [addFood.rejected]: (state, action) => {
      state.loadingCRUD = false;
      state.successfullyFoodAdded = false;
      state.error = action.payload;
    },
    [editFood.pending]: (state) => {
      state.loadingCRUD = true;
      state.successfullyFoodEdited = false;
    },
    [editFood.fulfilled]: (state, action) => {
      state.loadingCRUD = false;
      state.successfullyFoodEdited = true;
      state.error = "";
      if (state.items) {
        const foundIndex = state.items.findIndex(
          (f) => f.id === action.payload.id
        );
        state.items[foundIndex] = action.payload;
      }
      if (state.itemCurrentUser) {
        const foundIndex = state.itemCurrentUser.findIndex(
          (f) => f.id === action.payload.id
        );
        state.itemCurrentUser[foundIndex] = action.payload;
      }
    },
    [editFood.rejected]: (state, action) => {
      state.loadingCRUD = false;
      state.successfullyFoodEdited = false;
      state.error = action.payload;
    },
    [deleteFood.pending]: (state) => {
      state.loadingCRUD = true;
      state.successfullyFoodDeleted = false;
    },
    [deleteFood.fulfilled]: (state, action) => {
      state.loadingCRUD = false;
      state.successfullyFoodDeleted = true;
      state.error = "";
      state.items = state.items?.filter(
        (f) => f.id !== state.editableFoodItem.id
      );
      state.itemCurrentUser = state.itemCurrentUser?.filter(
        (f) => f.id !== state.editableFoodItem.id
      );
      foodsSlice.caseReducers.clearEditableFoodItem(state);
    },
    [deleteFood.rejected]: (state, action) => {
      state.loadingCRUD = false;
      state.successfullyFoodDeleted = false;
      state.error = action.payload;
    },
  },
});

export const {
  clearFoodStatuses,
  clearSuccessfullyFoodAdded,
  clearSuccessfullyFoodEdited,
  clearSuccessfullyFoodDeleted,
  setEditableFoodItem,
  clearEditableFoodItem,
} = foodsSlice.actions;

export default foodsSlice.reducer;

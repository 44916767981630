import React from "react";
import "./UiIcon.scss";
import PropTypes from "prop-types";

const UiIcon = ({ className, ...props }) => {
  return <span className={`ui-icon ${className}`} {...props} />;
};

UiIcon.propTypes = {
  className: PropTypes.string,
};

UiIcon.defaultProps = {
  className: "",
};

export default UiIcon;

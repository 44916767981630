import React from "react";

import Widget from "../../../../../../../components/widget/Widget";
import FoodTotal from "../../../FoodTotal";
import Food from "../../Food";
import "../../DietMealsSideBar.scss";

const Meal = ({ foods, name }) => {
  return (
    <Widget.Body className="diet__meals-body">
      <table className="diet-table">
        <thead>
          <tr>
            <td colSpan={2}>{name}</td>
            <td>p</td>
            <td>c</td>
            <td>f</td>
          </tr>
        </thead>
        <tbody>
          {foods.map((item) => (
            <Food
              key={item.food.id}
              food={item.food}
              quantity={item.quantity}
            />
          ))}
          <tr>
            <td colSpan="5" className="white_tr">
              <FoodTotal foods={foods} />
            </td>
          </tr>
        </tbody>
      </table>
    </Widget.Body>
  );
};

Meal.propTypes = {};

export default Meal;

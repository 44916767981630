import { useDispatch } from "react-redux";
import { Route, useRouteMatch } from "react-router-dom";

import { setShowMenu } from "../../redux/windowSlice";

import AuthPage from "./AuthPage/authWrapper";

const Auth = () => {
  const dispatch = useDispatch();
  const disableHeader = () => {
    dispatch(setShowMenu(false));
  };
  disableHeader();
  const match = useRouteMatch();
  return (
    <>
      <Route exact path={`${match.url}`}>
        <AuthPage />
      </Route>
    </>
  );
};

export default Auth;

import { default as MaterialSwitch } from "@material-ui/core/Switch";
import "./Switch.scss";
import { withStyles } from "@material-ui/core";

const StyledSwitch = withStyles({
  switchBase: {
    color: "rgb(189, 189, 189)",
    "&$checked": {
      color: "rgb(23, 115, 161)",
    },
    "&$checked + $track": {
      backgroundColor: "rgb(16, 98, 176)",
    },
  },
  checked: {},
  track: {
    backgroundColor: "rgb(189, 189, 189)",
  },
})(MaterialSwitch);

const Switch = ({ ariaLabel, ...props }) => {
  return <StyledSwitch
    inputProps={{ "aria-label": ariaLabel }}
    {...props}
  />;
};

export default Switch;

import { Route, Switch, useRouteMatch } from "react-router-dom";

import TrainersPage from "./TrainersPage";

const Trainers = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}`}>
        <TrainersPage />
      </Route>
    </Switch>
  );
};

export default Trainers;

import React from "react";
import { Link } from "react-router-dom";

import { PROFILE } from "../../../constants/router";
import ClientDropDown from "../ClientDropDown";
import { CLIENTS_ITEMS, TOOLS_ITEMS } from "../Menu.model";
import ToolsDropDown from "../ToolsDropDown";

const TrainerMenu = ({ closeMenu, closeMenuAndResetEditableClientId }) => {
  return (
    <>
      <div onClick={closeMenuAndResetEditableClientId} className="menu-item">
        <Link to={PROFILE.root} className="tools">
          my profile
        </Link>
      </div>
      <ClientDropDown dropdownItems={CLIENTS_ITEMS} onClick={closeMenu} />
      <ToolsDropDown
        onClick={closeMenuAndResetEditableClientId}
        dropdownItems={TOOLS_ITEMS}
        value="tools"
      />
    </>
  );
};

export default React.memo(TrainerMenu);

import PropTypes from "prop-types";
import React from "react";

import "./FormErrorAlert.scss";
import UiIcon from "../UiIcon";

const FormErrorAlert = ({ errorText, isShow }) => {
  if (!isShow) {
    return false;
  }
  return (
    <p>
      <UiIcon className="ui-icon-alert" />
      {errorText}
    </p>
  );
};

FormErrorAlert.propTypes = {
  errorText: PropTypes.string,
  isShow: PropTypes.bool,
};

export default FormErrorAlert;

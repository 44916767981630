import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Divider from "../../../../../components/Divider";
import Spinner from "../../../../../components/Spinner";
import Widget from "../../../../../components/widget/Widget";
import { trainerFormVariants } from "../../../../../constants/trainers";
import { useToastr } from "../../../../../hooks/useToastr";
import {
  editTrainer,
  loadTrainerById,
  clearSuccessfullyTrainerEdited,
} from "../../../../../redux/trainersSlice";
import TrainerForm from "../TrainerForm/TrainerForm";
import "./EditTrainer.scss";

const EditTrainer = () => {
  const dispatch = useDispatch();
  const successfullyTrainerEdited = useSelector(
    (state) => state.trainers.successfullyTrainerEdited
  );
  const loading = useSelector((state) => state.trainers.loadingById);
  const currentTrainer = useSelector((state) => state.trainers.currentTrainer);

  useEffect(() => {
    if (currentTrainer?.id) dispatch(loadTrainerById(currentTrainer.id));
  }, [currentTrainer?.id]);
  useToastr({
    messages: "Trainer edited!",
    deps: successfullyTrainerEdited,
    cb: () => dispatch(clearSuccessfullyTrainerEdited()),
  });

  const prepareValues = (trainer) => ({
    first_name: trainer.first_name,
    last_name: trainer.last_name,
    company: trainer.company,
    phone: trainer.phone,
    email: trainer.email,
    password: trainer.password,
  });

  const onSubmit = (values) => {
    dispatch(editTrainer({ id: currentTrainer.id, body: values }));
  };

  return (
    <Widget>
      <Widget.Header className="pl-10">
        <Widget.Title>edit coach</Widget.Title>
      </Widget.Header>
      <Widget.Body className="edit-trainer">
        {loading && <Spinner />}
        {!loading && (
          <TrainerForm
            isEditPage
            variant={trainerFormVariants.UPDATE}
            initialValues={prepareValues(currentTrainer)}
            onSubmit={onSubmit}
          />
        )}
        <Divider />
      </Widget.Body>
    </Widget>
  );
};

EditTrainer.propTypes = {};

export default EditTrainer;

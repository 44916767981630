import { useDispatch, useSelector } from "react-redux";

import "./BodyCharts.scss";
import {
  GRAPH_TYPE,
  GOAL_SLIDER_STATE,
} from "../../../../../../../constants/body";
import { BodyCompGraphHelper } from "../../../../../../../helpers/bodyGraph";
import BodyCompInfo from "../BodyCompInfo";
import BodyStrengthGraph from "../BodyStrengthGraph";
import { useWindowSize } from "../../../../../../../hooks/useWindowSize";
import { setWindowSize } from "../../../../../../../redux/windowSlice";

const chooseGoalWeightAndFat = (goalView, goal, body) => {
  const values = {
    [GOAL_SLIDER_STATE.DATE]: {
      gW: goal?.goal_weight,
      gF: goal?.goal_body_fat,
    },
    [GOAL_SLIDER_STATE.WEEK]: {
      gW: goal?.week_body_weight,
      gF: goal?.week_body_fat,
    },
    [GOAL_SLIDER_STATE.DAY]: {
      gW: goal?.today_body_weigh,
      gF: goal?.today_body_fat,
    },
  };

  const result = values[goalView];

  result.gW = result.gW || body.body_weight;
  result.gF = result.gF || body.body_fat;
  return result;
};

const BodyCharts = () => {
  const dispatch = useDispatch();
  const screenHeight = useSelector(
    (state) => state.window.size.screenHeight
  );
  const screenWidth = useSelector(
    (state) => state.window.size.screenWidth
  );
  const windowSize = useWindowSize(0);
  const isNeedResize = !screenHeight || !screenWidth;
  if (isNeedResize) dispatch(setWindowSize(windowSize));
  const body = useSelector((state) => state.client.body);
  const goal = useSelector((state) => state.client.goal);
  const goalView = useSelector((state) => state.client.goalView);

  const bodyParams = BodyCompGraphHelper.calculateBodyParams({
    currentWeight: body.body_weight,
    currentFat: body.body_fat,
    ...chooseGoalWeightAndFat(goalView, goal, body),
  });

  const chartsHeight = screenWidth >= 425 ? 438 : screenHeight * 0.5;

  return !isNeedResize && (
    <div className="body-charts" style={{ height: chartsHeight }}>
      <div>
        <BodyStrengthGraph
          type={GRAPH_TYPE.CURRENT}
          height={chartsHeight}
          bodyParams={bodyParams}
        />
      </div>
      <div>
        <BodyCompInfo bodyParams={bodyParams} />
      </div>
      <div>
        <BodyStrengthGraph
          type={GRAPH_TYPE.GOAL}
          height={chartsHeight}
          bodyParams={bodyParams}
        />
      </div>
    </div>
  );
};

BodyCharts.propTypes = {};

export default BodyCharts;

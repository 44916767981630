import { ITEMS_PER_PAGE } from "../constants/common";
import { INVOICE_STATUS } from "../constants/payment";

export const filterPaymentList = (list, currentPage, currentFilter) => {
  let filteredList;
  let viewListData;
  if (list) {
    filteredList = list.filter(
      (el) =>
        (el.status === INVOICE_STATUS.DUE ||
          el.status === INVOICE_STATUS.OVERDUE) &&
        el.user?.company?.name?.toLowerCase()?.includes(currentFilter.toLowerCase())
    );
    viewListData = filteredList.slice(
      currentPage * ITEMS_PER_PAGE,
      (currentPage + 1) * ITEMS_PER_PAGE
    );
  }
  return [filteredList, viewListData];
};

import { Drawer, makeStyles } from "@material-ui/core";
import "./MobileDrawer.scss";
import { useMemo, useEffect, useState } from "react";
import { isIOS } from "react-device-detect";
import { useSelector } from "react-redux";
import { RemoveScroll } from "react-remove-scroll";

import iconClose from "../../assets/icons/close.png";

const MobileDrawer = ({
  children,
  onClose,
  open,
  mobileView,
  ...props
}) => {
  const bgFile = useSelector(
    (state) => state.companies.currentCompany?.bg_file_name
  );
  const customBg = useMemo(() => makeStyles({
    paper: {...(bgFile && {backgroundImage: `url(${encodeURI(bgFile)})`})}
  }), [bgFile]);

  const customBgClasses = customBg();

  const classes = {
    paper: `bg ${customBgClasses?.paper ?? ""}`,
    root: "MuiDrawer-root--z-2"
  };

  const [/**number*/ previousScroll, setPreviousScroll] = useState(0);
  const [/**boolean*/ isFirstLoad, setIsFirstLoad] = useState(true);
  useEffect(() => {
    if (!mobileView) return;

    if (open) {
      setPreviousScroll(window.scrollY);
      return;
    }

    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }

    window.scrollTo(0, previousScroll);
  }, [open, mobileView]);

  const duration = mobileView ? 0 : 100;
  return (
    <RemoveScroll enabled={open && isIOS}>
      <Drawer
        open={open}
        anchor="right"
        onBackdropClick={onClose}
        transitionDuration={duration}
        classes={mobileView && classes}
      >
        <div className="mobile-drawer" {...props}>
          {!mobileView && (
            <img
              onClick={onClose}
              src={iconClose}
              className="side-closer"
              alt="Close icon"
            />
          )}
          {children}
        </div>
      </Drawer>
    </RemoveScroll>
  );
};

MobileDrawer.defaultProps = {
  open: true,
  customTrainingPageView: false,
};

export default MobileDrawer;

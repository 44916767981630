import {
  validateBench,
  validateDeadlift,
  validateRequiredBench,
  validateRequiredDeadlift,
  validateRequiredSquat,
  validateSquat,
  validateAmount,
  validateRequiredAmount,
} from "../helpers/validators";

export const ACTUAL_STRENGTH_TEST_FIELDS = [
  {
    name: "squat",
    validate: validateSquat,
    validateRequired: validateRequiredSquat,
    validateAmount,
    validateRequiredAmount,
  },
  {
    name: "bench",
    validate: validateBench,
    validateRequired: validateRequiredBench,
    validateAmount,
    validateRequiredAmount,
  },
  {
    name: "deadlift",
    validate: validateDeadlift,
    validateRequired: validateRequiredDeadlift,
    validateAmount,
    validateRequiredAmount,
  },
];

export const ESTIMATE_STRENGTH_TEST_FIELDS = [
  {
    label: "squat(1rm):",
    name: "estimateSquat",
    validate: validateSquat,
    validateRequired: validateRequiredSquat,
  },
  {
    label: "bench(1rm):",
    name: "estimateBench",
    validate: validateBench,
    validateRequired: validateRequiredBench,
  },
  {
    label: "deadlift(1rm):",
    name: "estimateDeadlift",
    validate: validateDeadlift,
    validateRequired: validateRequiredDeadlift,
  },
];

export const STRENGTH_COEFFICIENTS = [
  1.0, 1.0363, 1.0753, 1.1111, 1.1429, 1.1765, 1.2048, 1.2346, 1.2579, 1.2821,
  1.3072, 1.3333, 1.3605, 1.3831, 1.4085, 1.4286, 1.4493, 1.4706, 1.4925,
  1.5152, 1.5385, 1.5545, 1.5708, 1.5873, 1.6044, 1.6218, 1.6393, 1.6529,
  1.6667, 1.6667,
];

export const STRENGTH_TEST_SENT_MESSAGE = "Strength test was sent successfully!";

import lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./ClientDietPage.scss";
import "../../../assets/styles-old/pages/diets/index.scss";
import "../../../main.scss";
import { Redirect } from "react-router-dom";

import Button from "../../../components/Button";
import MobileDrawer from "../../../components/MobileDrawer/MobileDrawer";
import Widget from "../../../components/widget/Widget";
import { DELAY_FOR_AUTO_SAVE, USER_TYPES } from "../../../constants/common";
import { SIDEBAR_NAMES } from "../../../constants/diet";
import { CLIENT } from "../../../constants/router";
import { prepareDietRequest } from "../../../helpers/diet";
import { useCallDebouncedCb } from "../../../hooks/useCallDebouncedCb";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import useIsUserType from "../../../hooks/useIsUserType";
import { useToastr } from "../../../hooks/useToastr";
import { initDietPage, setDefaultMessages } from "../../../redux/dietPageSlice";
import {
  loadDietTemplates,
  setRemovableItem,
} from "../../../redux/dietTemplatesSlice";
import { clearDiets, clearSuccessfullyDietEdited, updateDiet } from "../../../redux/dietsSlice";
import { loadFoods } from "../../../redux/foodsSlice";

import DietNotes from "./components/DietNotes";
import DietSideBar from "./components/DietSideBar";
import DietTable from "./components/DietTable";
import DietTableMeals from "./components/DietTableMeals";

const ClientDiet = () => {
  const dispatch = useDispatch();
  const isEdited = useSelector((state) => state.dietPage.isEdited);
  const isDoubleDiet = useSelector((state) => state.dietPage.isDoubleDiet);
  const diets = useSelector((state) => state.dietPage.diets);
  const messages = useSelector((state) => state.dietPage.messages);
  const isSmallScreen = useIsSmallScreen();
  const isClient = useIsUserType(USER_TYPES.CLIENT);

  const lastDiet = useSelector((state) => state.client.lastDiet);
  const client = useSelector((state) => state.client.client);
  const successfullyDietEdited = useSelector(
    (state) => state.diets.successfullyDietEdited
  );
  const dietTemplates = useSelector((state) => state.dietTemplates.items);
  const dietTemplatesRemovable = useSelector(
    (state) => state.dietTemplates.removableItem
  );

  const title = `${client.first_name}'s diet`;
  useDocumentTitle(title);

  const initSideBar = isClient ? SIDEBAR_NAMES.MEALS : SIDEBAR_NAMES.TEMPLATES;
  const [sideBar, setSideBar] = useState(initSideBar);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  // TODO: мб создать хук loadIsEmpty
  useEffect(() => {
    dispatch(loadFoods());
  }, []);

  useEffect(() => {
    if (!dietTemplates) {
      dispatch(loadDietTemplates());
    }
  }, []);

  useEffect(() => {
    dispatch(initDietPage({ diet: lastDiet }));
    dispatch(clearDiets());
  }, []);

  useToastr({
    messages,
    deps: successfullyDietEdited,
    cb: () => {
      dispatch(setDefaultMessages());
      dispatch(clearSuccessfullyDietEdited());
    },
  });

  useCallDebouncedCb(
    (diets, isDoubleDiet, isEdited) => {
      if (!isEdited) {
        return;
      }
      dispatch(
        updateDiet({
          id: client.id,
          body: prepareDietRequest(diets, isDoubleDiet),
        })
      );
    },
    [lodash.cloneDeep(diets), isDoubleDiet, isEdited],
    DELAY_FOR_AUTO_SAVE,
    [diets, isDoubleDiet, isEdited]
  );

  if (!client.is_active) {
    return <Redirect to={`${CLIENT.root}${client.id}`} />;
  }

  return (
    <div className="diet__content-rhs content-rhs content">
      <div className="diet__page-content page-content d-flex w-100 diet">
        <div className="left-widget__wrapper diet__main">
          <Widget>
            <Widget.Header className="diet__header">
              <Widget.Title>{title}</Widget.Title>
              <div className="diet__header-btn-wrapper">
                {isClient && (
                  <Button
                    disabled={!isSmallScreen && sideBar === SIDEBAR_NAMES.MEALS}
                    onClick={() => {
                      setSideBar(SIDEBAR_NAMES.MEALS);
                      setIsOpenDrawer(true);
                    }}
                  >
                    show meals
                  </Button>
                )}
                {!isClient && (
                  <Button
                    disabled={
                      !isSmallScreen && sideBar === SIDEBAR_NAMES.SAVE_DIET
                    }
                    onClick={() => {
                      setSideBar(SIDEBAR_NAMES.SAVE_DIET);
                      setIsOpenDrawer(true);
                    }}
                  >
                    save as...
                  </Button>
                )}
                {(!isClient || !client.hide_diet_history) && (
                  <Button
                    disabled={
                      !isSmallScreen && sideBar === SIDEBAR_NAMES.DIET_HISTORY
                    }
                    onClick={() => {
                      setSideBar(SIDEBAR_NAMES.DIET_HISTORY);
                      setIsOpenDrawer(true);
                    }}
                  >
                    diet history
                  </Button>
                )}
                {!isClient && (
                  <Button
                    disabled={
                      !isSmallScreen &&
                      sideBar === SIDEBAR_NAMES.TEMPLATES &&
                      !dietTemplatesRemovable
                    }
                    onClick={() => {
                      setSideBar(SIDEBAR_NAMES.TEMPLATES);
                      setIsOpenDrawer(true);
                      dispatch(setRemovableItem(null));
                    }}
                  >
                    +new diet
                  </Button>
                )}
              </div>
            </Widget.Header>
            <div className="diet__body">
              <Widget.Body className="diet__table-wrapper">
                <DietTable
                  setSideBar={setSideBar}
                  setIsOpenDrawer={setIsOpenDrawer}
                  sideBar={sideBar}
                />
              </Widget.Body>
              {!isClient && !isSmallScreen && (
                <Widget.Body className="diet__meals-wrapper">
                  <DietTableMeals setSideBar={setSideBar} sideBar={sideBar} />
                </Widget.Body>
              )}
            </div>
            <DietNotes />
          </Widget>
        </div>
        <div className="diet__side sticky-side">
          {isSmallScreen && (
            <MobileDrawer
              open={isOpenDrawer}
              onClose={() => {
                setIsOpenDrawer(false);
              }}
            >
              <DietSideBar setSideBar={setSideBar} sideBarName={sideBar} />
            </MobileDrawer>
          )}
          {!isSmallScreen && (
            <DietSideBar setSideBar={setSideBar} sideBarName={sideBar} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientDiet;

import { Route, Switch, useRouteMatch } from "react-router-dom";

import { PROGRAM_TEMPLATES, ID_SLUG } from "../../constants/router";

import ProgramTemplateAddPage from "./ProgramTemplateAddPage";
import ProgramTemplateEditPage from "./ProgramTemplateEditPage";
import ProgramTemplatePage from "./ProgramTemplatePage";

const ProgramTemplate = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}`}>
        <ProgramTemplatePage />
      </Route>
      <Route
        exact
        path={`${match.url}/${PROGRAM_TEMPLATES.addProgramTemplate}`}
      >
        <ProgramTemplateAddPage />
      </Route>
      <Route exact path={`${match.url}/${ID_SLUG}`}>
        <ProgramTemplateEditPage />
      </Route>
    </Switch>
  );
};

export default ProgramTemplate;

import { useSelector } from "react-redux";

import { USER_TYPES } from "../constants/common";

const useCheckClientPermission = (permission, isTruthPermission = true) => {
  const userType= useSelector((state) => state.user.user?.type);
  const client = useSelector((state) => state.client.client);
  const isClient =  userType === USER_TYPES.CLIENT;
  return !isClient || (isTruthPermission ? client[permission] : !client[permission]);
}

export default useCheckClientPermission;

import { BasicHelper } from "./index";
import { DIFF_COEFFICIENTS } from "../constants/body";

export class BodyCompGraphHelper {
  static calculateBodyParams({ currentWeight, currentFat, gW, gF }) {
    let currentFatMass = currentWeight * (currentFat / 100),
      currentMuscleMass = currentWeight - currentFatMass,
      goalWeight = gW || currentWeight,
      goalFat = gF || currentFat,
      goalFatMass = goalWeight * (goalFat / 100),
      goalMuscleMass = goalWeight - goalFatMass,
      currentPercentMuscleMass = BasicHelper.roundTo(
        (currentMuscleMass / currentWeight) * 100,
        1
      ),
      goalPercentMuscleMass = BasicHelper.roundTo(
        (goalMuscleMass / goalWeight) * 100,
        1
      ),
      needMuscle = BasicHelper.roundTo(goalMuscleMass - currentMuscleMass, 1),
      diffDeadlift = BasicHelper.roundTo(
        needMuscle * DIFF_COEFFICIENTS.DEAD_LIFT,
        1
      ),
      diffSquat = BasicHelper.roundTo(needMuscle * DIFF_COEFFICIENTS.SQUAT, 1),
      diffBench = BasicHelper.roundTo(needMuscle * DIFF_COEFFICIENTS.BENCH, 1);
    return {
      needWeight: BasicHelper.roundTo(Math.abs(goalWeight - currentWeight), 1),
      needFat: BasicHelper.roundTo(goalFatMass - currentFatMass, 1),
      needMuscle,
      currentWeight,
      currentFat,
      currentFatMass,
      currentMuscleMass,
      goalWeight,
      goalFat,
      goalFatMass,
      goalMuscleMass,
      currentPercentMuscleMass,
      goalPercentMuscleMass,
      diffDeadlift,
      diffSquat,
      diffBench,
    };
  }
}

export const SIDEBAR_TYPE = {
  DELETE: { value: "delete" },
  TOOLS: { value: "tools" },
  ADD_EXERCISE: { value: "addExercise", static: true },
};

export const REDUCED_TEMPLATES_COUNT = 20;

export const DEFAULT_WEIGHT_VALUE = 75;
export const DEFAULT_REPS_VALUE = null;

export const DEFAULT_START_DUPLICATE_IDX = 1;
export const DEFAULT_START_REPLACE_IDX = 1;
export const DEFAULT_START_DELETE_IDX = 1;

export const ERROR_FIRST_IS_REQUIRED = "The first value is required";
export const ERROR_SECOND_IS_REQUIRED = "The second value is required";
export const ERROR_SECOND_MUST_BE_GREATER = "The second value must be greater";

export const OLD_VALUE = "oldValue";

export const PROGRAM_REPS_EDIT = {
  MIN: 1,
  MAX: 500,
};
export const PROGRAM_WEIGHT_PERCENT_EDIT = {
  MIN: 1,
  MAX: 200,
};

export const EDITED_TYPES = {
  WEIGHT: "weight",
  REPS: "reps",
};

export const TEMPLATES_MESSAGES = {
  TEMPLATE_SAVED: "Template saved successfully",
};

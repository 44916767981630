import React from "react";

import "./HistoryRow.scss";

function HistoryRow({
  title,
  weight,
  reps,
  otherReps,
  max,
  notes,
  date,
  massUnit,
  detailed,
}) {
  return (
    <div className="history-row" onClick={detailed}>
      <div className="history-row__info">
        <div className="history-row__title">{title}</div>
        <div className="history-row__item">{weight + massUnit}</div>
        <div className="history-row__item">X</div>
        <div className="history-row__item">
          {reps}
          {otherReps !== 0 && ` + ${otherReps}`}
        </div>
        <div className="history-row__item">=</div>
        <div className="history-row__item">{max + massUnit}</div>
      </div>
      <div className="history-row__addition">
        {date && <span>{date}</span>}
        <span>{notes}</span>
      </div>
    </div>
  );
}

export default HistoryRow;

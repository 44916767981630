import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../../components/Button";
import DeleteProgramTemplate from "../../../components/DeleteProgramTemplate";
import MobileDrawer from "../../../components/MobileDrawer/MobileDrawer";
import List from "../../../components/list/List";
import Widget from "../../../components/widget/Widget";
import { USER_TYPES } from "../../../constants/common";
import { PROGRAM_TEMPLATES } from "../../../constants/router";
import { REDUCED_TEMPLATES_COUNT } from "../../../constants/templates";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import useIsUserType from "../../../hooks/useIsUserType";
import useSearchHook from "../../../hooks/useSearchHook";
import { loadExercises } from "../../../redux/exercisesSlice";
import {
  clearItemForDelete,
  setItemForDelete,
  loadProgramTemplatesForCurrentUser,
  clearStatuses,
} from "../../../redux/programTemplatesSlice";

const header = [
  {
    label: "",
    style: { width: "3%" },
  },
  {
    style: { width: "77%" },
    label: "template name",
  },
  {
    style: { width: "20%" },
    label: "owner",
  },
];

const prepareList = (list, userId, isSuper) =>
  list?.map((item) => ({
    id: item.id,
    name: item.name,
    type: userId === item.owner_id ? "you" : item.type,
    isRemovable: !(isSuper && item.owner_id !== userId),
  }));

const ProgramTemplatePage = () => {
  const dispatch = useDispatch();
  useDocumentTitle("ProgramTemplates");

  const [isShowPreview, setIsShowPreview] = useState(true);
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [isShowDelete, setIsShowDelete] = useState(false);

  const history = useHistory();
  const isSmallScreen = useIsSmallScreen();
  const userId = useSelector((state) => state.user?.user?.id);
  const items = useSelector((state) => state.programTemplates.itemCurrentUser);
  const itemForDeleteId = useSelector(
    (state) => state.programTemplates.itemForDelete?.id
  );
  const successfullyDeleteTemplate = useSelector(
    (state) => state.programTemplates.successfullyDeleteTemplate
  );
  const loading = useSelector((state) => state.programTemplates.loading);
  const exercisesLoading = useSelector((state) => state.exercises.loading);

  const isSuper = useIsUserType(USER_TYPES.SUPERUSER);

  useEffect(() => {
    dispatch(loadExercises());
  }, []);

  useEffect(() => {
    dispatch(loadProgramTemplatesForCurrentUser());
  }, [successfullyDeleteTemplate]);

  useEffect(() => {
    if (items?.length) {
      const preparedList = prepareList(items, userId, isSuper);
      setList(preparedList);
      setFilteredList(preparedList);
      return;
    }
    setList([]);
    setFilteredList([]);
  }, [items]);

  const [searchValue, onSearchChange] = useSearchHook(list, setFilteredList);

  const itemClickHandler = (id) => {
    history.push(PROGRAM_TEMPLATES.root + id);
  };

  const closeDeleteWidget = () => {
    dispatch(clearItemForDelete());
    setIsShowDelete(false);
  };

  const deleteItemHandler = (item) => {
    const template = items.find((c) => c.id === item.id);
    dispatch(setItemForDelete(template));
    setIsShowDelete(true);
  };

  const isLoading = loading || exercisesLoading;

  return (
    <div className="content-rhs content">
      <div className="clients-page__content page-content d-flex w-100">
        <div className="clients-page__body-main">
          <Widget>
            <Widget.Header className="pl-10">
              <Widget.Title>templates</Widget.Title>
              <Button
                onClick={() => {
                  dispatch(clearStatuses());
                  history.push(
                    PROGRAM_TEMPLATES.root +
                      PROGRAM_TEMPLATES.addProgramTemplate
                  );
                }}
              >
                add template
              </Button>
            </Widget.Header>
            <Widget.Body className="widget-body--with-mh">
              <List
                header={header}
                list={
                  isShowPreview
                    ? filteredList.slice(0, REDUCED_TEMPLATES_COUNT)
                    : filteredList
                }
                currentItem={itemForDeleteId}
                isDelete={isShowDelete}
                itemClickHandler={itemClickHandler}
                deleteItemHandler={deleteItemHandler}
                searchValue={searchValue}
                onSearchChange={onSearchChange}
                isLoading={isLoading}
                hideEditBtn
                isShowPagination={false}
              />
              {!isLoading && filteredList?.length > REDUCED_TEMPLATES_COUNT && (
                <Button
                  disabled={!isShowPreview}
                  onClick={() => setIsShowPreview(false)}
                >
                  show all
                </Button>
              )}
            </Widget.Body>
          </Widget>
        </div>
        <div className="clients-page__body-side">
          {isSmallScreen && (
            <MobileDrawer onClose={closeDeleteWidget} open={isShowDelete}>
              <DeleteProgramTemplate closeWidget={closeDeleteWidget} />
            </MobileDrawer>
          )}
          {!isSmallScreen && isShowDelete && (
            <DeleteProgramTemplate closeWidget={closeDeleteWidget} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgramTemplatePage;

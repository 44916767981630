import { Box } from "@material-ui/core";
import lodash from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../../main.scss";
import "./ClientBodyTestResult.scss";
import { Redirect } from "react-router-dom";

import Button from "../../../components/Button";
import ClientsBodyTest from "../../../components/ClientsBodyTest";
import MobileDrawer from "../../../components/MobileDrawer";
import StrengthTest from "../../../components/StrengthTest";
import Widget from "../../../components/widget/Widget";
import WidgetBody from "../../../components/widget/WidgetBody";
import WidgetHeader from "../../../components/widget/WidgetHeader";
import WidgetTitle from "../../../components/widget/WidgetTitle";
import { USER_TYPES } from "../../../constants/common";
import { BODY_PAGE_SLUG, CLIENT } from "../../../constants/router";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import useIsUserType from "../../../hooks/useIsUserType";
import { getClientProgress } from "../../../redux/clientSlice";

import BodyCompGraph from "./components/BodyCompGraph";
import ProgressWidget from "./components/ProgressWidget";

const WIDGET_VIEW_TYPES = {
  BODY_TEST: "+ body test",
  STRENGTH_TEST: "+ strength test",
  PROGRESS: "show progress",
};

const widgets = {
  [WIDGET_VIEW_TYPES.BODY_TEST]: <ClientsBodyTest />,
  [WIDGET_VIEW_TYPES.STRENGTH_TEST]: <StrengthTest />,
  [WIDGET_VIEW_TYPES.PROGRESS]: <ProgressWidget />,
};

const ClientBodyTestResult = () => {
  const dispatch = useDispatch();
  const body = useSelector((state) => state.client.body);
  const client = useSelector((state) => state.client.client);
  const successfullySendBodyTest = useSelector((state) => state.client.successfullySendBodyTest);
  const progress = useSelector((state) => state.client.progress);
  const successfullySendStrengthTest = useSelector((state) => state.client.successfullySendStrengthTest);
  const strength = useSelector((state) => state.client.strength);
  const has_actual_strength = useSelector((state) => state.client.has_actual_strength);

  const title = `${(client?.first_name || "").toLowerCase()}'s recomposition`;
  useDocumentTitle(title);

  const conditionToChangeWidgetOnProgressView =
    (successfullySendBodyTest || successfullySendStrengthTest) &&
    has_actual_strength;

  useEffect(() => {
    if (conditionToChangeWidgetOnProgressView) {
      setRightWidgetView(WIDGET_VIEW_TYPES.PROGRESS);
    }
  }, [successfullySendBodyTest, successfullySendStrengthTest]);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const isSmallScreen = useIsSmallScreen();
  const isClient = useIsUserType(USER_TYPES.CLIENT);

  const BTN_BOT_LIST = [
    {
      name: WIDGET_VIEW_TYPES.BODY_TEST,
      widget: WIDGET_VIEW_TYPES.BODY_TEST,
      isHidden: isClient,
    },
    {
      name: WIDGET_VIEW_TYPES.STRENGTH_TEST,
      widget: WIDGET_VIEW_TYPES.STRENGTH_TEST,
      isHidden: isClient,
    },
    {
      name: WIDGET_VIEW_TYPES.PROGRESS,
      widget: WIDGET_VIEW_TYPES.PROGRESS,
      isHidden: false,
      disabled: !has_actual_strength,
    },
  ];

  useEffect(() => {
    if (!progress) {
      dispatch(getClientProgress(client.id));
    }
  }, []);

  const conditionToRightWidgetNotView = lodash.isEmpty(strength) && isClient;
  const conditionToStrengthTestView =
    (lodash.isEmpty(strength) || !has_actual_strength) && !isClient;

  const [rightWidgetView, setRightWidgetView] = useState(() => {
    if (conditionToRightWidgetNotView) return null;
    if (conditionToStrengthTestView) {
      return WIDGET_VIEW_TYPES.STRENGTH_TEST;
    } else {
      return WIDGET_VIEW_TYPES.PROGRESS;
    }
  });

  if (lodash.isEmpty(body)) {
    return <Redirect to={`${CLIENT.root}${client.id}${BODY_PAGE_SLUG}`} />;
  }

  return (
    <div className="content-rhs content">
      <div className="page-content d-flex w-100">
        <div className="body-main left-widget__wrapper">
          <Widget>
            <WidgetHeader>
              <WidgetTitle>{title}</WidgetTitle>
            </WidgetHeader>
            <WidgetBody className="widget-body--pb-50">
              <BodyCompGraph />
              <Box
                display="flex"
                flex="1 1 auto"
                flexWrap="wrap"
                justifyContent="center"
                className="pt-15"
              >
                {BTN_BOT_LIST.map((btn) => (
                  <Button
                    key={btn.name}
                    className="client-body-result__footer-btns"
                    style={{
                      visibility: btn.isHidden ? "hidden" : null,
                    }}
                    onClick={() => {
                      setRightWidgetView(btn.widget);
                      setIsOpenDrawer(true);
                    }}
                    disabled={
                      btn.disabled ||
                      (((isSmallScreen && isOpenDrawer) || !isSmallScreen) &&
                        btn.widget === rightWidgetView)
                    }
                  >
                    {btn.name}
                  </Button>
                ))}
              </Box>
            </WidgetBody>
          </Widget>
        </div>
        <div className="body-side">
          {!isSmallScreen && widgets[rightWidgetView]}
          {isSmallScreen && (
            <MobileDrawer
              open={isOpenDrawer}
              onClose={() => {
                setIsOpenDrawer(false);
              }}
            >
              {widgets[rightWidgetView]}
            </MobileDrawer>
          )}
        </div>
      </div>
    </div>
  );
};

ClientBodyTestResult.propTypes = {};

export default ClientBodyTestResult;

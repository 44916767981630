import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "../../../../../../../../../components/Button";
import {
  GOAL_SLIDER_STATE,
  INITIAL_GOAL_DATE_OFFSET,
} from "../../../../../../../../../constants/body";
import {
  MAX_MOBILE_SCREEN_WIDTH,
  SHORT_DATE_FORMAT_ON_FRONTEND,
  USER_TYPES,
} from "../../../../../../../../../constants/common";
import { prepareShortDateForFront } from "../../../../../../../../../helpers";
import useIsUserType from "../../../../../../../../../hooks/useIsUserType";
import { setGoalView } from "../../../../../../../../../redux/clientSlice";

import DateInput from "./components/DateInput";

import "./DateButton.scss";

const DateButton = ({
  sendDate,
  isMobileVersion,
  disableButton,
  disableInput,
  setEndDate,
  endDate,
}) => {
  const dispatch = useDispatch();
  const isClient = useIsUserType(USER_TYPES.CLIENT);
  const screenWidth = useSelector((state) => state.window.size.screenWidth);
  const goalView = useSelector((state) => state.client.goalView);

  const [showDate, setShowDate] = useState(endDate);

  const setInitialGoalDate = () => {
    const initDate = getInitialDate();
    setEndDate(initDate);
    sendDate(initDate);
    dispatch(setGoalView(GOAL_SLIDER_STATE.DATE));
    setShowDate(true);
  };

  const getInitialDate = () =>
    prepareShortDateForFront(
      new Date(moment().add(INITIAL_GOAL_DATE_OFFSET, "d")) //TODO: изменить формат даты. укоротить
    );

  const removeGoalDate = () => {
    setShowDate(false);
    sendDate(null);
  };

  const onChangeDate = (e) => {
    setEndDate(e.target.value);
  };

  const spanClasses = cn("slider__date", {
    "slider__date--mobile": isMobileVersion,
    "slider__date--desktop": screenWidth > MAX_MOBILE_SCREEN_WIDTH.SLIDER_GOAL,
  });

  const removeDateButton = (
    <button
      type="button"
      className="remove-btn goal-date-remove-button"
      onClick={removeGoalDate}
      disabled={disableButton}
    >
      <FontAwesomeIcon icon={faTimes} />
    </button>
  );

  const endDateMoment = moment(endDate, SHORT_DATE_FORMAT_ON_FRONTEND, true);
  const error = !(endDateMoment.isValid() && endDateMoment.isAfter(moment()));

  const handleOnBlur = () => {
    if (!error) sendDate(endDate);
  }

  return (
    <>
      {!showDate && !isClient && (
        <Button
          className="slider-date__btn"
          onClick={setInitialGoalDate}
          disabled={disableButton}
        >
          +date
        </Button>
      )}
      {showDate && (
        <div className="slider-date__goal-state-wrapper">
          {goalView === GOAL_SLIDER_STATE.DATE && (
            <span>
              by
              <DateInput
                isMobileVersion={isMobileVersion}
                type="text"
                name="goalDate"
                value={endDate}
                onChange={onChangeDate}
                onBlur={handleOnBlur}
                disabled={disableInput || isClient}
                error={error}
              />
              {!isClient && removeDateButton}
            </span>
          )}
          {goalView === GOAL_SLIDER_STATE.WEEK && (
            <span className={spanClasses}>
              for next week
              {!isClient && removeDateButton}
            </span>
          )}
          {goalView === GOAL_SLIDER_STATE.DAY && (
            <span className={spanClasses}>
              for today
              {!isClient && removeDateButton}
            </span>
          )}
        </div>
      )}
    </>
  );
};

DateButton.propTypes = {
  isMobileVersion: PropTypes.bool.isRequired,
  disableButton: PropTypes.bool,
  disableInput: PropTypes.bool,
};

export default DateButton;

import lodash from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import Button from "../../../components/Button";
import ClientsBodyTest from "../../../components/ClientsBodyTest";
import ClientsForm from "../../../components/ClientsForm/ClientsForm";
import DeleteClient from "../../../components/DeleteClient";
import MobileDrawer from "../../../components/MobileDrawer/MobileDrawer";
import Spinner from "../../../components/Spinner";
import Widget from "../../../components/widget/Widget";
import WidgetBody from "../../../components/widget/WidgetBody";
import WidgetHeader from "../../../components/widget/WidgetHeader";
import WidgetTitle from "../../../components/widget/WidgetTitle";
import { BODY_TEST_RESULT_PAGE_SLUG, CLIENT } from "../../../constants/router";
import {
  getDifferenceField,
  getFullName,
  prepareClientDataForSend, prepareTrainersForSend,
} from "../../../helpers";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

import "./ClientBodyPage.scss";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import useSendUpdateClient from "../../../hooks/useSendUpdateClient";
import {
  clearClientForDelete,
  setForDeleteClient,
} from "../../../redux/clientListSlice";
import { loadCompaniesPicker } from "../../../redux/companiesSlice";

import { USER_TYPES } from "./../../../constants/common";
import useIsUserType from "./../../../hooks/useIsUserType";

const ClientBodyPage = () => {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.client.client);
  const successfullySendBodyTest = useSelector(
    (state) => state.client.successfullySendBodyTest
  );
  const body = useSelector((state) => state.client.body);
  const isSuperUser = useIsUserType(USER_TYPES.SUPERUSER);
  const clientFullName = getFullName(client);
  useDocumentTitle(`${clientFullName} info`);
  const loading = useSelector((state) => state.companies.loading);
  const isCompaniesLoaded = useSelector((state) => state.companies.successfullyGettingCompanyPicker);
  const [formClientVal, setFormClientVal] = useState({});
  const [isOpenDrawer, setIsOpenDrawer] = useState(true);
  const [isShowDelete, setIsShowDelete] = useState(false);
  useSendUpdateClient(formClientVal, 0);
  const isSmallScreen = useIsSmallScreen();
  useEffect(() => {
    if (!isCompaniesLoaded && isSuperUser) {
      dispatch(loadCompaniesPicker());
    }
  }, []);

  const onCloseDrawer = () => {
    dispatch(clearClientForDelete());
    setIsShowDelete(false);
  };

  const onClickDelete = () => {
    dispatch(setForDeleteClient(client));
    setIsShowDelete(true);
  };
  if (successfullySendBodyTest) {
    return (
      <Redirect
        to={`${CLIENT.root}${client.id}${BODY_TEST_RESULT_PAGE_SLUG}`}
      />
    );
  }
  if (!lodash.isEmpty(body)) {
    return (
      <Redirect
        to={`${CLIENT.root}${client.id}${BODY_TEST_RESULT_PAGE_SLUG}`}
      />
    );
  }
  return (
    <div className="body__content-rhs body__content">
      <div className="body__page-content d-flex w-100">
        <div className="body__main">
          <Widget>
            <WidgetHeader>
              <WidgetTitle>{clientFullName}`s info</WidgetTitle>
              <Button onClick={() => onClickDelete()}>delete</Button>
            </WidgetHeader>
            <WidgetBody>
              {loading && <Spinner />}
              {!loading && (isCompaniesLoaded || !isSuperUser) && (
                <ClientsForm
                  onSubmit={(values, initialValues) => {
                    let body = getDifferenceField(values, initialValues);
                    body = prepareTrainersForSend(body, values);
                    body = prepareClientDataForSend(body);
                    setFormClientVal(body);
                  }}
                  isEditPage={true}
                  initialValues={client}
                />
              )}
            </WidgetBody>
          </Widget>
        </div>
        <div className="body__side">
          {isSmallScreen && !isShowDelete && (
            <MobileDrawer
              open={isOpenDrawer}
              onClose={() => {
                setIsOpenDrawer(false);
              }}
            >
              <ClientsBodyTest />
            </MobileDrawer>
          )}
          {!isSmallScreen && !isShowDelete && <ClientsBodyTest />}
          {isSmallScreen && (
            <MobileDrawer onClose={onCloseDrawer} open={isShowDelete}>
              <DeleteClient
                closeWidget={onCloseDrawer}
                isNeedGoToListPage={true}
              />
            </MobileDrawer>
          )}
          {!isSmallScreen && isShowDelete && (
            <DeleteClient
              closeWidget={onCloseDrawer}
              isNeedGoToListPage={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ClientBodyPage.propTypes = {};

export default ClientBodyPage;

import { useFormikContext } from "formik";
import { flattenDeep, uniqBy, without } from "lodash";
import React, { useEffect, useState } from "react";

import Button from "../../../../../../components/Button";
import { DEFAULT_START_DELETE_IDX } from "../../../../../../constants/templates";
import { alphabeticalOrder } from "../../../../../../helpers/sorters";
import { validateRangeIdx } from "../../../../../../helpers/templates";

import ExerciseSelector from "./components/ExerciseSelector";
import SectionSelector from "./components/SectionSelector";

const DeleteBlock = () => {
  const { values, setFieldValue } = useFormikContext();
  const [startDeleteIdx, setStartDeleteIdx] = useState(
    DEFAULT_START_DELETE_IDX
  );
  const [endDeleteIdx, setEndDeleteIdx] = useState(values.days.length);
  const [errors, setErrors] = useState([]);
  const [options, setOptions] = useState([{ label: "", value: { id: null } }]);
  const [exerciseId, setExerciseId] = useState(null);

  useEffect(() => {
    const exercisesList = values.days.map((day) =>
      day.workouts.map((workout) =>
        workout.exercises.map((exercise) => exercise)
      )
    );

    const uniqExercises = uniqBy(flattenDeep(exercisesList), "id");
    const sortedExercises = alphabeticalOrder(uniqExercises, (el) => el.name);
    const newOptions = sortedExercises.reduce(
      (acc, item) => [
        ...acc,
        {
          label: item.name,
          value: item.id,
        },
      ],
      [{ label: "", value: null }]
    );
    setOptions(newOptions);
    setEndDeleteIdx(values.days.length);
  }, [values]);

  useEffect(() => {
    setErrors(validateRangeIdx(startDeleteIdx, endDeleteIdx));
  }, [startDeleteIdx, endDeleteIdx]);

  const handleClick = () => {
    if (exerciseId) {
      const isExerciseInDaySlice = (dayIdx) =>
        dayIdx >= startDeleteIdx - 1 && dayIdx < endDeleteIdx;
      const getNewItem = (day) => ({
        ...day,
        workouts: day.workouts.map((workout) => ({
          ...workout,
          exercises: workout.exercises.filter(({ id }) => id !== exerciseId),
        })),
      });
      const newItems = values.days.reduce(
        (acc, day, idx) =>
          isExerciseInDaySlice(idx) ? [...acc, getNewItem(day)] : [...acc, day],
        []
      );

      setFieldValue("days", newItems);
      setExerciseId(null);
    } else {
      const itemsForDelete = values.days.slice(
        startDeleteIdx - 1,
        endDeleteIdx
      );
      const newItems = without(values.days, ...itemsForDelete);

      setFieldValue("days", newItems);
      setStartDeleteIdx(DEFAULT_START_DELETE_IDX);
    }
  };

  return (
    <div className="tools-block__form">
      <div className="tools-block__form-title">delete section</div>
      {values.days.length ? (
        <>
          {errors.length !== 0 &&
            errors.map((error) => (
              <div className="tools-block__error">{error}</div>
            ))}
          <SectionSelector
            startIdx={startDeleteIdx}
            endIdx={endDeleteIdx}
            setStartIdx={setStartDeleteIdx}
            setEndIdx={setEndDeleteIdx}
          />
          <ExerciseSelector
            options={options}
            exerciseId={exerciseId}
            setExerciseId={setExerciseId}
          />
          <div className="tools-block__button-wrapper">
            <Button
              disabled={!!errors.length}
              onClick={handleClick}
              className="tools-block__button"
            >
              delete
            </Button>
          </div>
        </>
      ) : (
        <div>no days for delete</div>
      )}
    </div>
  );
};

export default DeleteBlock;

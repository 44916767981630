import debounce from "lodash.debounce";
import { useEffect, useMemo } from "react";

export const useCallDebouncedCb = (cb, props, delay, deps) => {
  const debouncedCb = useMemo(
    () => debounce((...props) => cb(...props), delay),
    [delay]
  );
  useEffect(() => debouncedCb(...props), deps);
};

export const useCallDebouncedCbWithAbort = (cb, props, delay, deps) => {
  const debouncedCb = useMemo(
    () => debounce((...props) => cb(...props), delay),
    [delay]
  );
  useEffect(() => {
    const promise = debouncedCb(...props);
    return () => promise?.abort();
  }, deps);
};

export const BODY_PART_OPTIONS = [
  { value: "chest", label: "chest" },
  { value: "back", label: "back" },
  { value: "shoulders", label: "shoulders" },
  { value: "biceps", label: "biceps" },
  { value: "triceps", label: "triceps" },
  { value: "thighs", label: "thighs" },
  { value: "hamstrings", label: "hamstrings" },
  { value: "glutes", label: "glutes" },
  { value: "calves", label: "calves" },
  { value: "abdominals", label: "abdominals" },
  { value: "unspecified", label: "unspecified" },
];

export const REP_CALCULATOR_OPTIONS = [
  { value: "Standard", label: "Standard" },
  { value: "Back/Bicep", label: "Back/Bicep" },
  { value: "Leg Press/Ext", label: "Leg Press/Ext" },
];

export const MAX_EQUATION_BASE_OPTIONS = [
  { value: "Squat", label: "Squat" },
  { value: "Bench", label: "Bench" },
  { value: "Deadlift", label: "Deadlift" },
];

export const CUSTOM_STYLES = {
  menuList: (provided, state) => ({
    ...provided,
    color: "#24a3f4",
    backgroundColor: "#000000",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: "red",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: "white",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#000000",
  }),
  groupHeading: (provided, state) => ({
    ...provided,
    color: "#24a3f4",
    backgroundColor: "#000000",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#000000",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "white",
    backgroundColor: "#000000",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    float: "right",
    color: "red",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "white",
  }),
};

export const INITIAL_VALUES = {
  name: "",
  body_part: [BODY_PART_OPTIONS[10]],
  rep_calculator: REP_CALCULATOR_OPTIONS[0].value,
  max_equation_base: MAX_EQUATION_BASE_OPTIONS[0].value,
  max_equation_multiplier: "",
};


export const EXCERCISES_PREVIEW_COUNT = 20;

export const EXERCISES_MULTIPLIER = {
  MAX: 10,
  MIN: 0,
};

export const EXERCISE_TYPES = {
  GLOBAL: "global",
  SYSTEM_REG: /^globalSystem[a-zA-Z]*$/,
};

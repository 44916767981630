import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../components/Button";
import MobileDrawer from "../../../components/MobileDrawer/MobileDrawer";
import List from "../../../components/list/List";
import Widget from "../../../components/widget/Widget";
import {
  DELAY_FOR_SEARCH,
  ITEMS_PER_PAGE,
  USER_TYPES,
} from "../../../constants/common";
import "./TrainersPage.scss";
import { TOASTR_TYPE } from "../../../constants/toastr";
import {
  HEADER_TRAINERS_TABLE,
  WIDGET_VIEW_TYPES,
} from "../../../constants/trainers";
import { prepareList } from "../../../helpers/trainers";
import { useCallDebouncedCbWithAbort } from "../../../hooks/useCallDebouncedCb";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import useIsUserType from "../../../hooks/useIsUserType";
import { useSearchParam } from "../../../hooks/useSearchParam";
import { useToastr } from "../../../hooks/useToastr";
import {
  loadTrainersList,
  setFilter,
  setCurrentTrainer,
  clearFilter,
  clearTrainers,
  clearCurrentTrainer,
  clearError,
} from "../../../redux/trainersSlice";

import AddTrainer from "./components/AddTrainer";
import DeleteTrainer from "./components/DeleteTrainer";
import EditTrainer from "./components/EditTrainer";
import TrainerClients from "./components/TrainerClients";

const widgets = {
  [WIDGET_VIEW_TYPES.ADD_TRAINER]: <AddTrainer />,
  [WIDGET_VIEW_TYPES.EDIT_TRAINER]: <EditTrainer />,
  [WIDGET_VIEW_TYPES.DELETE_TRAINER]: <DeleteTrainer />,
  [WIDGET_VIEW_TYPES.ACTIVE_CLIENTS]: (
    <TrainerClients title={WIDGET_VIEW_TYPES.ACTIVE_CLIENTS} />
  ),
  [WIDGET_VIEW_TYPES.ALL_CLIENTS]: (
    <TrainerClients title={WIDGET_VIEW_TYPES.ALL_CLIENTS} />
  ),
};

const TrainersPage = () => {
  const dispatch = useDispatch();
  const [companyId] = useSearchParam("companyId");
  const isSmallScreen = useIsSmallScreen();
  const loading = useSelector((state) => state.trainers.loading);
  const loadingById = useSelector((state) => state.trainers.loadingById);
  const page = useSelector((state) => state.trainers.page);
  const totalCount = useSelector((state) => state.trainers.totalCount);
  const filter = useSelector((state) => state.trainers.filter);
  const trainers = useSelector((state) => state.trainers.trainers);
  const error = useSelector((state) => state.trainers.error);
  const currentTrainerId = useSelector(
    (state) => state.trainers.currentTrainer?.id
  );
  const successfullyTrainerAdded = useSelector(
    (state) => state.trainers.successfullyTrainerAdded
  );
  const successfullyTrainerDeleted = useSelector(
    (state) => state.trainers.successfullyTrainerDeleted
  );
  const [rightWidgetView, setRightWidgetView] = useState(
    WIDGET_VIEW_TYPES.ADD_TRAINER
  );
  const isSuperUser = useIsUserType(USER_TYPES.SUPERUSER);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [list, setList] = useState([]);
  const [withoutFilterPage, setWithoutFilterPage] = useState(1);

  const rightCellsProps = {
    active: { onClick: activeClientsHandler },
    total: { onClick: totalClientsHandler },
  };

  useEffect(() => {
    return () => {
      dispatch(clearFilter());
      dispatch(clearTrainers());
    };
  }, []);

  useCallDebouncedCbWithAbort(
    (filter, companyId, withoutFilterPage) =>
      dispatch(
        loadTrainersList({
          page: filter ? 1 : withoutFilterPage,
          search: filter,
          ...(isSuperUser && { companyId }),
        })
      ),
    [filter, companyId, withoutFilterPage],
    DELAY_FOR_SEARCH,
    [filter, companyId]
  );

  useEffect(() => {
    if (trainers?.length) {
      setList(prepareList(trainers));
      return;
    }
    setList([]);
  }, [trainers]);

  useToastr({
    messages: error,
    deps: error,
    type: TOASTR_TYPE.ERROR,
    cb: () => dispatch(clearError()),
  });

  useEffect(() => {
    if (successfullyTrainerAdded && !companyId) {
      dispatch(loadTrainersList({ page: 1, search: filter }));
      setRightWidgetView(WIDGET_VIEW_TYPES.EDIT_TRAINER);
    }
  }, [successfullyTrainerAdded]);

  useEffect(() => {
    if (successfullyTrainerDeleted) {
      setRightWidgetView(WIDGET_VIEW_TYPES.ADD_TRAINER);
    }
  }, [successfullyTrainerDeleted]);

  const loadPrevPage = () => {
    if (page > 1) {
      if (!filter) {
        setWithoutFilterPage(page - 1);
      }
      dispatch(
        loadTrainersList({
          page: page - 1,
          search: filter,
          ...(isSuperUser && { companyId }),
        })
      );
    }
  };

  const loadNextPage = () => {
    if (totalCount > page * ITEMS_PER_PAGE) {
      if (!filter) {
        setWithoutFilterPage(page + 1);
      }
      dispatch(
        loadTrainersList({
          page: page + 1,
          search: filter,
          ...(isSuperUser && { companyId }),
        })
      );
    }
  };

  const changeFilter = (e) => {
    dispatch(setFilter(e.target.value));
  };

  const trainerRowClickHandler = (item) => {
    if (item.id === currentTrainerId || loadingById) return;
    dispatch(setCurrentTrainer(item));
  };

  const deleteItemHandler = () => {
    setOpenDrawer(true);
    setRightWidgetView(WIDGET_VIEW_TYPES.DELETE_TRAINER);
  };

  const itemClickHandler = () => {
    setOpenDrawer(true);
    setRightWidgetView(WIDGET_VIEW_TYPES.EDIT_TRAINER);
  };

  const addTrainerHandler = () => {
    setOpenDrawer(true);
    setRightWidgetView(WIDGET_VIEW_TYPES.ADD_TRAINER);
    dispatch(clearCurrentTrainer());
  };

  function activeClientsHandler() {
    setOpenDrawer(true);
    setRightWidgetView(WIDGET_VIEW_TYPES.ACTIVE_CLIENTS);
  }

  function totalClientsHandler() {
    setOpenDrawer(true);
    setRightWidgetView(WIDGET_VIEW_TYPES.ALL_CLIENTS);
  }

  const addCoachButtonDisabled =
    rightWidgetView === WIDGET_VIEW_TYPES.ADD_TRAINER &&
    (isSmallScreen ? openDrawer : true);

  return (
    <div className="content-rhs content trainers-page">
      <div className="page-content d-flex w-100">
        <div className="body-main">
          <Widget>
            <Widget.Header className="pl-10">
              <Widget.Title>coaches</Widget.Title>
              <Button
                disabled={addCoachButtonDisabled}
                onClick={addTrainerHandler}
              >
                add coach
              </Button>
            </Widget.Header>
            <Widget.Body className="widget-body--with-mh">
              <List
                currentItem={currentTrainerId}
                isEdit={rightWidgetView === WIDGET_VIEW_TYPES.EDIT_TRAINER}
                isDelete={rightWidgetView === WIDGET_VIEW_TYPES.DELETE_TRAINER}
                header={HEADER_TRAINERS_TABLE}
                list={list}
                page={page}
                totalCount={totalCount}
                loadPrevPage={loadPrevPage}
                loadNextPage={loadNextPage}
                deleteItemHandler={deleteItemHandler}
                itemClickHandler={itemClickHandler}
                searchValue={filter}
                onSearchChange={changeFilter}
                isLoading={loading}
                isShowPagination={true}
                rightCellsProps={rightCellsProps}
                onClick={trainerRowClickHandler}
              />
            </Widget.Body>
          </Widget>
        </div>
        <div className="body-side sticky-side">
          {!isSmallScreen && widgets[rightWidgetView]}
          {isSmallScreen && (
            <MobileDrawer
              onClose={() => setOpenDrawer(false)}
              open={openDrawer}
            >
              {widgets[rightWidgetView]}
            </MobileDrawer>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrainersPage;
